import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  _l,
  copyToClipboard,
  getFileType,
  getPreviewFromURL,
  isSolarHubPlatform,
  removeDuplicateObjects,
  removeExpandedClassFromIntroOfficeList,
  searchObjects,
  showError,
  showSuccess,
  tabindexFix,
} from "../../hooks/utilities";
import {
  CaretDown,
  Hash,
  MagnifyingGlass,
  XCircle,
  GearSix,
  CaretDoubleDown,
  Funnel,
  Calendar,
  EnvelopeSimple,
  CloudArrowUp,
  UserGear,
  File,
  Wrench,
  ArrowClockwise,
  FileText,
  FileCloud,
  UsersThree,
  UserList,
  Users,
  Sun,
  Globe,
  Check,
  Eye,
  MapPin,
  Plus,
  Sparkle,
  Wallet,
  User,
  Note,
  UserCircle,
  Buildings,
  CurrencyCircleDollar,
  CurrencyEur,
  BezierCurve,
  Book,
  Cpu,
  Bell,
  CalendarBlank,
  Receipt,
} from "phosphor-react";
import SpaceListDropdown from "../Dropdowns/SpaceListDropdown";
import { Form, Spinner } from "react-bootstrap";
import BenchmarkServices from "../../services/benchmark-services";
import CustomerServices from "../../services/customer-services";
import ScheduleCard from "../BenchmarkNew/ScheduleCard";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import GeneralCardView from "../Documents/GeneralCard";
import { useDispatch, useSelector } from "react-redux";
import AddEmployee from "./AddEmployee";
import DocumentsServices from "../../services/documents-services";
import { CardView } from "../Documents/CardView";
import DocumentModal from "./DocumentModal";
import { getCustomerSpaces, getDefaultTopics, setProject, setSpaceList, updateDefaultTopicsList } from "../../actions/customer";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../actions/messages";
import CreateTeam from "./CreateTeam";
import GeneralTwoLevelDropdown from "../Dropdowns/GeneralTwoLevelDropdown";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import {
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
  GOOGLE_SERVICES_SCOPE,
  OUTLOOK_OAUTH_URL,
  TOPIC_LIST_BY_USER_TYPE,
} from "../../constants/constants";
import CustomOutlookCalendarIcon from "../../assets/icons/CustomOutlookCalendarIcon";
import CustomGoogleCalendarIcon from "../../assets/icons/CustomGoogleCalendarIcon";
import TaskCard from "../TaskCard";
import CustomGoogleIcon from "../../assets/icons/CustomGoogleIcon";
import CustomOutlookIcon from "../../assets/icons/CustomOutlookIcon";
import CustomGoogleDriveIcon from "../../assets/icons/CustomGoogleDriveIcon";
import CustomOneDriveIcon from "../../assets/icons/CustomOneDriveIcon";
import OnedriveServices from "../../services/onedrive-services";
import ManageRolePermissionModal from "./ManageRolePermissionModal";
import AddRole from "./AddRole";
import Companyprofilestep from "../CompanyProfile/companyprofilestep";
import ManageChecklistStep from "../CompanyProfile/ManageChecklistStep";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import AddAssigneeList from "./AddAssigneeList";
import { OAuth2Client } from "google-auth-library";
import GoogleCalendar from "../../services/google-calendar";
import OutlookCalendar from "../../services/outlook-calendar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CreateBenchmarkIcon from "../../assets/icons/CreateBenchmarkIcon";
import AddScheduleIcon from "../../assets/icons/AddScheduleIcon";
import EstimateIcon from "../../assets/icons/EstimateIcon";
import GoogleDriveServices from "../../services/google-drive-services";
import EquipmentsServices from "../../services/equipments-services";
import TransactionCard from "../MyWallet/TransactionCard";
import RemoveProviderConfirmation from "./RemoveProviderConfirmation";
import StopBenchnark from "./StopBenchmark";
import CategoryProviderList from "./CategoryProviderList";
import CategoryAndProviderBulkImport from "./CategoryAndProviderBulkImport";
import AddBulkEquipments from "./AddBulkEquipments";
import DocumentPreview from "../Documents/DocumentPreview";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import DocumentGalleryView from "../Documents/DocumentGalleryView";
import moment from "moment";
import PaymentPageMain from "../PaymentPageMain";
import DateRangeDropdown from "../Dropdowns/DateRangeDropdown";
import CalendarWithFilterIcon from "../../assets/icons/CalendarWithFilterIcon";
import Select from "react-select";
import PremiumSpaceCard from "../PremiumPage/PremiumSpaceCard";
import PremiumMyspace from "../PremiumPage/PremiumMyspace";
import PaymentSetupModal from "./PaymentSetupModal";
import PremiumBillingTable from "../PremiumPage/PremiumBillingTable";
import AITopicContext from "../AITopicContext";
import MyProfileSteps from "../MyProfileSteps";
import NotificationPreference from "../CompanyProfile/NotificationPreference";
import AddNewCategory from "./AddNewCategory";
import AIContextModal from "./AIContextModal";
import DatePicker from "react-datepicker";
import { USR_IMG_PLACEHOLDER } from "../../actions/chat-action-type";
import ProviderDocuments from "../../views/ProviderDocuments";
import ProviderPricingCatalog from "../SolarExternalPages/SolarComponents/ProviderPricingCatalog";
import { setShowIsChatScreenDisplay } from "../../actions/chat";

const SET_FIRST_INDEX_DEFAULT = [
  "company-profile",
  "third_party_integrations",
  "documents",
  "operator_wallet_setup",
  "proposals",
  "premium_estimate",
  "edit_user",
  "myr_support",
  "edit_profile",
  "equipments"
];
const TOPICS_WITH_ALL_FILTERS = ["documents"];
const TOPICS_WITH_USER_PLACEHOLDER = [
  "people",
  "myr_support",
  "contact",
  "operator",
];
const TOPICS_WITH_TEAM_PLACEHOLDER = ["providers", "team", "equipments"];
const INTEGRATION_TOOLS = [
  {
    type: "third_party_calendar",
    providers: [
      {
        name: "Google Calendar",
        logo: CustomGoogleCalendarIcon,
        type: "calendar",
        tool: "Google",
        all_spaces: false,
      },
      {
        name: "Outlook Calendar",
        logo: CustomOutlookCalendarIcon,
        type: "calendar",
        tool: "Outlook",
        all_spaces: false,
      },
    ],
  },
  {
    type: "third_party_email",
    providers: [
      {
        name: "G Mail",
        logo: CustomGoogleIcon,
        type: "mail",
        tool: "Google",
      },
      {
        name: "Outlook",
        logo: CustomOutlookIcon,
        type: "mail",
        tool: "Outlook",
      },
    ],
  },
  {
    type: "third_party_drive",
    providers: [
      {
        name: "Google Drive",
        logo: CustomGoogleDriveIcon,
        type: "drive",
        tool: "Google",
      },
      {
        name: "One Drive",
        logo: CustomOneDriveIcon,
        type: "drive",
        tool: "Outlook",
      },
    ],
  },
];
const TOGGLE_BULK_TOPICS = ["l_providers", "l_people", "l_equipments"];
const PAYOUT_STATUS = [
  {
    value: "paid",
    label: _l("is_paid"),
  },
  {
    value: "pending",
    label: _l("is_pending_or_in_transit"),
  },
  {
    value: "failed",
    label: _l("is_failed"),
  },
  {
    value: "canceled",
    label: _l("is_canceled"),
  },
];
const TOPIC_WITH_NO_SEARCH = ["ai_context", "notification_preference"];
const TOPIC_WITH_NO_FILTERS = ["ai_context"];

let payoutData = [];

const TopicSettingModal = ({
  show,
  handleClose,
  data,
  title,
  titlePrefix,
  hideFilterArea,
  hideSearchArea,
  project_id,
}) => {
  const projectId =
    project_id > 0 ? project_id : localStorage.getItem("selectedOffice");
  const userType = localStorage.getItem("user_type");
  const contact_id = localStorage.getItem("contact_id");
  const client_id = localStorage.getItem("client_id");
  const staff_id = localStorage.getItem("staff_id");
  const loginUserRole = localStorage.getItem("contact_role")
    ? localStorage.getItem("contact_role")
    : 0;
  const isAdminAsStaff = localStorage.getItem("adminArchieve");
  let spaceList = localStorage.getItem("spaces");
  spaceList = spaceList && spaceList.length ? JSON.parse(spaceList) : [];

  const [sectionList, setSectionList] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({});
  const [listData, setListData] = useState({});
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [paginationState, setPaginationState] = useState({});
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: data.id,
    title: data.title,
    is_active: data.is_active,
    main_sub_key: data.main_sub_key,
  });
  const [buttonText, setButtonText] = useState("");
  const [providerEmail] = useState("");
  const [overWriteMainModal, setOverWriteMainModal] = useState(false);
  const [addOperatorModal, setAddOperatorModal] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [peopleDetail, setPeopleDetail] = useState({});
  const [documentMiniModalData, setDocumentMiniModalData] = useState({});
  const [searchString, setSearchString] = useState("");
  const [categoryMenuOptions, setCategoryMenuOptions] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const [viewPermissions, setViewPermissions] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [showEditRole, setShowEditRole] = useState(false);
  const [showEditUser, setShowEditUSer] = useState(false);
  const [selectedEmployeeData] = useState("");
  const [checklists, setChecklists] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [openSpaceListDropdown, setOpenSpaceListDropdown] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(0);
  const [showManagerModal, setShowManagerModal] = useState(false);
  const [addOperationOnGoing, setAddOperationOnGoing] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const [selectedSPacesIds, setSelectedSPacesIds] = useState([]);
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);
  const [userList, setUserList] = useState([]);
  const [showRemoveProviderConfirmation, setShowRemoveProviderConfirmation] =
    useState({});
  const [topicButtonText, setTopicButtonText] = useState("");
  const [benchmarkToStop, setBenchmarkToStop] = useState({});
  const [editCategoryModalData, setEditCategoryModalData] = useState({});
  const [deleteCategoryData, setDeleteCategoryData] = useState({});
  const [showBulkCategoryUpload, setShowBulkCategoryUpload] = useState(false);
  const [bulkEquipmentsImport, setBulkEquipmentsImport] = useState(false);
  const [allowToAddNewRow, setAllowToAddNewRow] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxData, setlightBoxData] = useState([]);
  const [fileUploadSectionInfo, setFileUploadSectionInfo] = useState({});
  const [attachmentDetail, setAttachmentDetail] = useState({});
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [unsavedWalletSettings, setUnsavedWalletSettings] = useState(false);
  const [showDateRangeDropDown, setShowDateRangeDropDown] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [payoutSpace, setPayoutSpace] = useState();
  const [payoutStatus, setPayoutStatus] = useState([]);
  const [premiumEstimateData, setPremiumEstimateData] = useState({});
  const [estimateData, setEstimateData] = useState({});
  const [contactList, setContactList] = useState([]);
  const [providerCategoryList, setProviderCategoryList] = useState([]);
  const [paymentSetupModalData, setPaymentSetupModalData] = useState({});
  const [ibanList, setIbanList] = useState([]);
  const [ibanStatus, setIbanStatus] = useState();
  const [aiAssistantId, setAIAssistantId] = useState(0);
  const [pushNotificationPreference, setPushNotificationPreference] = useState(false);
  const [emailNotificationPreference, setEmailNotificationPreference] = useState(false);
  const [addCustomTopicModal, setAddCustomTopicModal] = useState(false);
  const [contactRoleId, setContactRoleId] = useState({})
  const [viewAIContext, setViewAIContext] = useState(false);

  const admin = localStorage.getItem("admin")

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const walletSetupRef = useRef();
  const fileInputRef = useRef();
  const aiContextRef = useRef();
  const premiumEstimateRef = useRef();
  let userId = searchParams.get("user");
  let step = searchParams.get("step");
  
  const location = useLocation();
  const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;

  const { defaultTopics, topicData, spaces, userTeams } =
    useSelector((state) => state.customer);

  const getProviderList = async () => {
    try {
      setIsFetchingData(true);
      let categories = [];

      if (selectedCategory.id == -1) {
        categories = categoriesList
          .map((w) => w.filter_key)
          .filter((w) => w != -1 && w != "myrai_support");
      } else {
        categories = [selectedCategory.id];
      }

      const response = await BenchmarkServices.getCategoryProviderList(
        projectId,
        categories
      );
      if (response.status) {
        response.data.sort((a, b) => a.company.localeCompare(b.company));
        let providers = response.data;
        if (selectedCategory.id === -1) {
          let categoryWiseProviders = [];
          categoriesList.forEach((category) => {
            if (category.filter_key !== -1) {
              categoryWiseProviders.push({
                ...category,
                main_key: data.main_key,
                name: category.title,
                full_name: category.title,
                profile_image_url: "",
                providers: providers
                  .filter((provider) =>
                    provider.category.includes(category.filter_key)
                  )
                  .map((provider) => {
                    return { ...provider, full_name: provider.company };
                  }),
              });
            }
          });
          setListData({
            l_providers: categoryWiseProviders,
          });
          setPaginationState({
            l_providers: 1,
          });
        } else {
          providers.forEach((provider) => {
            let providerStatus = "#";
            let pills = [];
            switch (Number(provider.is_existing)) {
              case 0:
                providerStatus = "l_past";
                break;
              case 1:
                providerStatus = "l_active";
                break;
              case 2:
                providerStatus = "l_invited";
                break;
            }
            pills = [
              {
                color: "color-green",
                content: _l(providerStatus),
              },
            ];
            if (provider.total_task > 0) {
              pills.puhs({
                color: "color-green",
                content: provider.total_task,
              });
            }
            pills = pills.concat(getCategoriesPill(provider.category));
            provider.pills = pills;
          });
          setListData({
            l_providers: providers,
          });
          setPaginationState({
            l_providers: 1,
          });
        }
      }
      else {
        setListData({
          l_providers: [],
        });
        setPaginationState({
          l_providers: 1,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const getCompanyProfile = async (resetPagination = true) => {
    try {
      setIsFetchingData(true);
      setListData({
        l_manage_companies_profile: [
          {
            l_company_name: "",
          },
        ],
      });
      if (resetPagination) {
        setPaginationState({ l_manage_companies_profile: 1 });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const getCompanyChecklist = async (resetPagination = true) => {
    try {
      setIsFetchingData(true);
      setListData({
        l_manage_checklist_label: [
          {
            l_company_name: "",
          },
        ],
      });
      if (resetPagination) {
        setPaginationState({ l_manage_checklist_label: 1 });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const getChecklist = (provider_id = 0) => {
    CustomerServices.getChecklistCompayprofile(provider_id).then((res) => {
      if (res.status && res.data) {
        setChecklists(res.data);
      }
    });
  };

  const getUserList = async (resetPagination = true) => {
    try {
      setIsFetchingData(true);
      const response = await CustomerServices.getCompanyEmployee(0, 1);
      if (response.status) {
        const formattedResponse = response.data.map((employee, index) => {
          return {
            id: employee.id,
            index: index + 1,
            full_name: employee.full_name,
            profile_image: employee.profile_image,
            is_psw_set: employee.is_psw_set,
            password_set_link: employee.password_set_link,
            team_name: employee.team_details.length
              ? employee.team_details
                  .map((td) => {
                    return td.team_name;
                  })
                  .join(", ")
              : "",
            roleDetail: employee.role_detail,
            projects: spaces.filter((p) =>
              employee.assigned_project_ids.includes(p.project_id)
            ),
            role_id: employee.role_detail.role_id,
            projectsIds: employee.assigned_project_ids,
            staff_id: employee.staff_id,
            teams:
              employee.team_details && employee.team_details.length
                ? employee.team_details
                : [],
            active: employee.active,
            lastLogin : employee.last_login,
            noOfComments : employee.no_of_comments,
            taskScore : employee.task_score,
            logDetcity : employee.session_details.city,
            logDetavg_time : employee.session_details.avg_time,
            logDetstatus : employee.session_details.status,
            logDettotal_duration : employee.session_details.total_duration,
            logDetno_of_session : employee.session_details.no_of_session,
             
          };
        });
        setListData({ l_manage_user_label: formattedResponse });
        setUserList(formattedResponse);
        setupCategoryMenu();
        if (resetPagination) {
          setPaginationState({ l_manage_user_label: 1 });
        }
      } else {
        setListData({ l_manage_user_label: [] });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const deleteUser = (id) => {
    CustomerServices.deleteCompanyProfileUser(projectId, id).then((res) => {
      if (res.status == 1) {
        setListData({
          l_manage_user_label: listData.l_manage_user_label.filter(
            (x) => x.id != id
          ),
        });
        dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const handleDataSourceChange = (updatedData, existing = false) => {
    if (existing) {
      let index = listData.l_manage_user_label.findIndex(
        (x) => x.id == updatedData.id
      );
      let targetRecord = listData.l_manage_user_label[index];
      Object.keys(updatedData).forEach((key) => {
        targetRecord[key] = updatedData[key];
      });
      targetRecord["index"] = index + 1;

      let data = listData;
      data.l_manage_user_label[index] = targetRecord;
      setListData(data);
      // index = tableDataSource.findIndex(x => x.id == updatedData.id);
      // setTableDataSource(Object.values({ ...tableDataSource, [index]: targetRecord }));
    } else {
      updatedData.index = listData.l_manage_user_label.length + 1;
      // updatedData.projects = [0]
      setListData({
        l_manage_user_label: listData.l_manage_user_label.concat(updatedData),
      });
      // updatedData.index = tableDataSource.length + 1;
      // setTableDataSource(Object.values({ ...tableDataSource, [updatedData.index]: updatedData }));
    }
  };

  const getCompanyList = async (resetPagination = true) => {
    try {
      setIsFetchingData(true);
      const response = await CustomerServices.getCustomerSpaces(
        client_id,
        contact_id,
        "company_profile"
      );
      if (response.status == 1) {
        const formattedResponse = response.data.map((company, index) => {
          return {
            id: company.id,
            index: index + 1,
            office_title: company.office_title,
            postal_address: company.postal_address,
            managers: company.managers,
            members: company.members,
            my_providers: company.my_providers,
            status: company.status,
            projectId: company.project_id,
            date_added: company.date_added,
          };
        });
        setListData({ l_manage_companies_label: formattedResponse });
        if (resetPagination) {
          setPaginationState({ l_manage_companies_label: 1 });
        }
      } else {
        setListData({ l_manage_companies_label: [] });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const getRolesList = async (resetPagination = true) => {
    try {
      setIsFetchingData(true);
      const response = await CustomerServices.getAllRoles();

      if (response.status == 1) {
        const formattedResponse = response.data;
        setListData({ l_manage_roles_label: formattedResponse });
        if (resetPagination) {
          setPaginationState({ l_manage_roles_label: 1 });
        }
      } else {
        setListData({ l_manage_roles_label: [] });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const handleDataRole = (updatedData, existing = true) => {
    if (existing) {
      let index = listData.l_manage_roles_label.findIndex(
        (x) => x.id == updatedData.id
      );
      let targetRecord = listData.l_manage_roles_label[index];
      Object.keys(updatedData).forEach((key) => {
        targetRecord[key] = updatedData[key];
      });
      targetRecord["index"] = index + 1;
      let data = listData;
      data.l_manage_roles_label[index] = targetRecord;
      data.l_manage_roles_label[index].name = updatedData.role;

      setListData(data);
    }
  };

  const handleDeleteRole = (role) => {
    CustomerServices.deleteRole(role.id).then((res) => {
      if (res.status) {
        setListData({
          l_manage_roles_label: listData.l_manage_roles_label.filter(
            (x) => x.id != role.id
          ),
        });
        dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const getTeamsList = async (resetPagination = true) => {
    try {
      setIsFetchingData(true);
      const response = await CustomerServices.getTeams();
      if (response.status) {
        // response.data.sort((a, b) => a.name.localeCompare(b.name));
        let teamList = response.data;
        if (selectedCategory.id !== -1) {
          teamList = teamList.filter(
            (w) => Math.abs(w.id) == Math.abs(selectedCategory.id)
          );
        }
        setListData({ l_teams: response.data });
        if (resetPagination) {
          setPaginationState({ l_teams: 1 });
        }
      } else {
        setListData({ l_teams: [] });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const saveCreatAIAssistant = async (content, firstname = "", lastname = "") => {
    try {
      const response = await CustomerServices.createAiAssistantForCustomer(projectId, content, firstname, lastname)
      if (response.status) {
        let fullname = firstname + ' ' + lastname;
        let deatils = [{
          full_name : fullname,
          profile_image : USR_IMG_PLACEHOLDER,
          main_key:"my_contact",
          main_sub_key :"people",
          is_active : 1,
          filter_key : "",
          role_detail : {
                            role_id : 4,
                           role_name : "Ai Assistant"
                        }
        }]
        setListData({ l_people: listData["l_people"].concat(deatils) });
        dispatch(getDefaultTopics(projectId, "", "", ""));
        dispatch(showMessage("sucess", _l("l_succuss"), response.message));
      }
    } catch (err) {
      console.error(err);
    }
    finally{
      setContactRoleId({
        ...contactRoleId,
        role_Id : 4
      })
      setViewAIContext(false)
    }
  }

  const getPeopleList = async (resetPagination = true) => {
    try {
      setIsFetchingData(true);
      const response = await CustomerServices.getCompanyEmployee(0, selectedCategory.id > -1 ? 1 : 0);
      if (response.status) {
        response.data.sort((a, b) => a.full_name.localeCompare(b.full_name));
        // For find role_id and payment status
        let roleId = response.data.filter((role) => role.role_detail && role.role_detail.role_id == 4)
        let paymentStatus = spaceList.filter((pay) => pay.is_paymented == 1)
        setContactRoleId({
          ...contactRoleId,
          role_Id : roleId[0] && roleId[0].role_detail ? roleId[0].role_detail.role_id : 0,
          payment_Status: paymentStatus[0] && paymentStatus[0].is_paymented ? paymentStatus[0].is_paymented : 0 
        })

        let peopleList = response.data
        .filter((w) =>
          adminAsStaff ? w.assigned_project_ids.includes(projectId) : w.role_detail && w.role_detail ? (w.role_detail.role_id != 4 && w.assigned_project_ids.includes(projectId)) : (w && w.assigned_project_ids.includes(projectId))
        );
        if (selectedCategory.id !== -1) {
          if (selectedCategory.main_sub_key == "team") {
            peopleList = peopleList.filter((w) =>
              w.teams.includes(selectedCategory.id)
            );
          } else {
            peopleList = peopleList.filter(
              (w) => Math.abs(w.staff_id) == Math.abs(selectedCategory.id)
            );
          }
        } else {
          peopleList = peopleList.filter((id) => id.staff_id != localStorage.getItem("staff_id"))
            .map((people) => {
              let category = categoriesList.find(
                (w) => w.filter_key == people.staff_id
              );
              if (category) {
                return {
                  ...people,
                  main_key: data.main_key,
                  is_active: category.is_active,
                  filter_key: category.filter_key,
                  main_sub_key: category.main_sub_key,
                };
              }
              return people
            })

            let teams = categoriesList
            .filter((w) => w.main_sub_key == "team")
            .map((w) => {
              return {
                ...w,
                main_key: data.main_key,
                full_name: w.title,
                main_key: data.main_key,
                profile_image: UsersThree,
                people: peopleList.filter((p) =>
                  p.teams.includes(w.filter_key)
                ),
              };
            });
          peopleList = teams.concat(peopleList);
        }
        setListData({ l_people: peopleList });
        if (resetPagination) {
          setPaginationState({ l_people: 1 });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };
  const getMyrSupportList = (resetPagination = true) => {
    let peopleList = defaultTopics;
    peopleList = defaultTopics.filter((t) => t.main_key == "myr_support")[0]
      .categories;
    if (selectedCategory.id !== -1) {
      if (selectedCategory.main_sub_key == "team") {
        peopleList = peopleList.filter((w) =>
          w.teams.includes(selectedCategory.id)
        );
      } else {
        peopleList = peopleList.filter(
          (w) => Math.abs(w.filter_key) == Math.abs(selectedCategory.id)
        );
      }
    } else {
      peopleList = peopleList
        .map((people) => {
          let category = categoriesList.find(
            (w) => w.filter_key == people.filter_key
          );
          if (category) {
            return {
              ...people,
              main_key: data.main_key,
              is_active: category.is_active,
              filter_key: category.filter_key,
              main_sub_key: category.main_sub_key,
            };
          }
        })
        .filter((people) => people); //Remove Undefined values
      let teams = categoriesList
        .filter((w) => w.main_sub_key == "team")
        .map((w) => {
          return {
            ...w,
            full_name: w.title,
            profile_image: UsersThree,
            people: peopleList.filter((p) => p.teams.includes(w.filter_key)),
          };
        });
      peopleList = teams.concat(peopleList);
    }
    setListData({ l_myr_support: peopleList });
    if (resetPagination) {
      setPaginationState({ l_myr_support: 1 });
    }
  };
  const getSchedules = async (key, skipSections = []) => {
    try {

      const contact_role = localStorage.getItem("contact_role");

      if(userType === "contact" && !projectId && contact_role == 1)
      {
       if(key === "benchmarks")
       {
        const groupsInfo = await BenchmarkServices.getBenchmarkGroupInfo();
        setListData({l_dpgf: groupsInfo.data});
        setPaginationState({l_dpgf: 1});
       }
       else {
        //To be implemented for Schedules
       }
      }
      else {
        let equipment_id = selectedCategory.id !== -1 ? selectedCategory.id : 0;
        const isPeopleSection = ["my_contact", "my_operator", "my_staff"].includes(data.main_key);
  
        let filters = [];
  
        switch(data.main_key)
        {
          case "myr_support":
            if(selectedCategory.id === -1)
            {
              filters = [{
                main_key: data.main_key,
                categories: categoriesList.filter((w) => w.filter_key !== -1).map((category) => {
                  return {
                    filter_key: category.filter_key,
                    main_sub_key: category.main_sub_key
                  }
                })
              }];
            }
            else {
              filters = [{
                main_key: data.main_key,
                categories: [{
                  filter_key: selectedCategory.id,
                  main_sub_key: selectedCategory.main_sub_key
                }]
              }];
            }   
            break;
          default:
            break;
        }
  
        setIsFetchingData(true);
        const response = await CustomerServices.getBenchmarks(
          projectId,
          selectedCategory.id !== -1 ? selectedCategory.id : 0,
          "",
          0,
          0,
          0,
          1,
          10,
          ["proposals", "dpgf"].includes(key),
          filters,
          data.main_key === "equipments" ? equipment_id : -1
        );
        if (response.status) {
          switch (key) {
            case "schedules":
              setListData({
                l_all: isPeopleSection ? [] : response.data.filter((w) => w.is_schedule == 1),
              });
              setPaginationState({ l_all: 1 });
              break;
            case "benchmarks":
              const activeBenchmarks = response.data.filter(
                (w) => (w.status == 1 || w.is_opened == 1) && w.is_schedule == 0
              );
              const closedBenchmarks = response.data.filter(
                (w) => w.status != 1 && w.is_opened != 1 && w.is_schedule == 0
              );
              setListData({
                l_active:  isPeopleSection ? [] :  activeBenchmarks,
                l_closed:  isPeopleSection ? [] : closedBenchmarks,
              });
              setPaginationState({ l_active: 1, l_closed: 1 });
              break;
            case "proposals":
              let proposalArray = response.data.filter((proposal) => proposal.group_id <= 0);
              const acceptedProposals = proposalArray.filter(
                (x) => x.proposal_status == 3
              );
              const rejectedProposals = proposalArray.filter(
                (x) => x.proposal_status == 2
              );
              const receivedProposals = proposalArray.filter(
                (x) =>
                  x.proposal_status == 4 &&
                  (x.proposal_amount == 0 || x.proposal_amount == null)
              );
              const pendingProposals = proposalArray.filter(
                (x) => x.proposal_status == 4 && x.proposal_amount > 0
              );
              let proposalList = {
                l_received: receivedProposals,
                l_pending: pendingProposals,
                l_contract: acceptedProposals,
                l_rejected: rejectedProposals,
              };
  
              Object.keys(proposalList).forEach((key) => {
                if (skipSections.includes(key)) {
                  delete proposalList[key];
                }
              });
  
              setListData(proposalList);
              setPaginationState({
                l_contract: 1,
                l_pending: 1,
                l_rejected: 1,
                l_received: 1,
              });
              break;
            case "dpgf":
              setListData({"l_dpgf": response.data.filter((proposal => proposal.group_id > 0))});
              setPaginationState({l_dpgf : 1});
              break;
            default:
              break;
          }
        }
      }

    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const getDriveData = async (rel_type = "") => {
    try {
      setIsFetchingData(true);

      let filters = [];

      if (TOPICS_WITH_ALL_FILTERS.includes(data.main_key)) {
        filters = filterArray;
      } else if (selectedCategory.id !== -1) {
        filters = [
          {
            main_key: data.main_key,
            filter_keys: [
              {
                filter_key: selectedCategory.id,
                main_sub_key: data.main_sub_key,
              },
            ],
          },
        ];
      }
      const response = await DocumentsServices.getDocuments(
        projectId,
        filters,
        rel_type
      );
      if (response.status) {
        if (rel_type) {
          const documentData = response.data[rel_type];
          if (rel_type === "credit_notes") {
            documentData.forEach((credit_note) => {
              credit_note.name = credit_note.credit_note_number;
            });
          }

          let sections = sectionList;

          if (!sections.length) {
            sections = prepareTopicList();
          }

          const listKey = sections.find((w) => w.key === rel_type);

          setListData({
            [listKey["name"]]: documentData,
          });
          setPaginationState({
            [listKey["name"]]: 1,
          });
        } else {
          const {
            credit_notes,
            estimate,
            invoice,
            space,
            subscription,
            google_drive,
            solar_document
          } = response.data;
          setListData({
            l_documents: google_drive,
            l_estimates: estimate,
            l_space_document: space,
            l_subscriptions: subscription,
            l_invoice: invoice,
            l_solar_document: solar_document,
            l_credit_note: credit_notes.map((credit_note) => {
              return {
                ...credit_note,
                name: credit_note.credit_note_number,
              };
            }),
          });
          setPaginationState({
            l_documents: 1,
            l_estimates: 1,
            l_space_document: 1,
            l_subscriptions: 1,
            l_invoice: 1,
            l_credit_note: 1,
          });
        }
      }
      else {
        setListData({
          l_documents: [],
          l_estimates: [],
          l_space_document: [],
          l_subscriptions: [],
          l_invoice: [],
          l_credit_note: [],
        });
        setPaginationState({
          l_documents: 1,
          l_estimates: 1,
          l_space_document: 1,
          l_subscriptions: 1,
          l_invoice: 1,
          l_credit_note: 1,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const getPeopleDocuments = async () => {
    try {
      setIsFetchingData(true);

      const user_staff_id = selectedCategory.id > 0 ? selectedCategory.id : 0;

      const userDocuments = await DocumentsServices.getPeopleDocuments(
        projectId,
        user_staff_id,
        "user"
      );
      const galleryDocuments = await DocumentsServices.getPeopleDocuments(
        projectId,
        user_staff_id,
        "gallery"
      );

      if (userDocuments.status && galleryDocuments.status) {
        setListData({
          l_gallery: galleryDocuments.data.gallery,
          l_files: galleryDocuments.data.files,
          l_id_card: userDocuments.data.id_card.map((document) => {
            return { ...document, type: "id_card" };
          }),
          l_company_id: userDocuments.data.company_id.map((document) => {
            return { ...document, type: "company_id" };
          }),
          l_payment_documents: userDocuments.data.payment.map((document) => {
            return { ...document, type: "payment" };
          }),
          l_insurance_documents: userDocuments.data.insurance.map(
            (document) => {
              return { ...document, type: "insurance" };
            }
          ),
        });
        setPaginationState({
          l_gallery: 1,
          l_files: 1,
          l_id_card: 1,
          l_company_id: 1,
          l_payment_documents: 1,
          l_insurance_documents: 1,
        });
        setAllowToAddNewRow([
          "l_id_card",
          "l_company_id",
          "l_payment_documents",
          "l_insurance_documents",
        ]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const getEmployeeInfo = (main_key, filter_key, titlePrefix) => {
    const usersData = defaultTopics.find((w) => w.main_key == main_key);
    const user = usersData.categories.find((w) => w.filter_key == filter_key);

    if (user) {
      return {
        full_name: `${user.title} ${titlePrefix ? "#" + titlePrefix : ""}`,
        profile_image: user.image,
      };
    }
  };

  const getEquipmentData = (resetPagination = true) => {
    let equipments = categoriesList.filter(
      (equipment) => equipment.filter_key > 0
    );

    if (selectedCategory.id > -1) {
      equipments = equipments.filter(
        (w) => w.filter_key == selectedCategory.id
      );
      equipments = equipments.flatMap((category) =>
        category.sub_category.map((item) => ({
          equipment_id: category.filter_key,
          id: parseInt(item.id),
          title: item.name,
          device_id: item.device_id,
          contact_assignee: item.contact_assignee,
          people: [
            getEmployeeInfo(
              "my_contact",
              category.contact_assignee,
              _l("l_manager")
            ),
            getEmployeeInfo(
              "my_operator",
              category.provider_assignee,
              _l("l_provider")
            ),
            getEmployeeInfo(
              userType === "operator" ? "my_operator" : "my_contact",
              category.provider_assignee,
              _l("l_contact")
            ),
          ],
          provider_assignee: item.provider_assignee,
          equipment_url:
            item.attachments && item.attachments.length
              ? item.attachments[0].image_url
              : "",
        }))
      );
    } else {
      equipments = equipments.map((equipment) => {
        return {
          ...equipment,
          main_key: data.main_key,
          equipment_id: equipment.filter_key,
          people: [
            getEmployeeInfo(
              "my_contact",
              equipment.contact_assignee,
              _l("l_manager")
            ),
            getEmployeeInfo(
              "my_operator",
              equipment.provider_assignee,
              _l("l_provider")
            ),
          ],
        };
      });
    }

    setListData({ l_equipments: equipments });

    if (resetPagination) {
      setPaginationState({ l_equipments: 1 });
    }
  };

  const getUserInvoiceData = async () => {
    setIsFetchingData(true);
    try {
      const response = await CustomerServices.getTransactions([], []);
      if (response.status && response.data && response.data.transaction_list) {
        const allTransactions = response.data.transaction_list;
        setListData({
          l_expenses: allTransactions.filter(
            (x) => x.transaction_type == "invoice"
          ),
          l_income: allTransactions.filter(
            (x) => x.transaction_type == "credit_note"
          ),
        });
        setPaginationState({ l_expenses: 1, l_income: 1 });
      } else {
        setListData({
          l_expenses: [],
          l_income: [],
        });
        setPaginationState({ l_expenses: 1, l_income: 1 });
      }
    } catch (e) {
      showError("l_something_went_wrong");
    } finally {
      setIsFetchingData(false);
    }
  };

  const checkEmailSync = async () => {
    let syncString = "";
    try {
      const response = await CustomerServices.getMailSettings();
      if (response.status) {
        response.data.map((item) => {
          if (item["provider"] == "gmail") {
            syncString = "gmail";
          } else if (item["provider"] == "outlook_mail") {
            syncString = "outlook_mail";
          }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      return syncString;
    }
  };

  const checkGoogleCalendarSync = async () => {
    let flag = false;
    try {
      const response = await GoogleCalendar.is_googlecalendar_connected(
        projectId
      );
      if (response.status) {
        flag = true;
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.log(e);
    } finally {
      return flag;
    }
  };

  const checkOutlookCalendarSync = async () => {
    let flag = false;
    try {
      const response = await OutlookCalendar.is_calendar_connected(projectId);
      if (response.status) {
        flag = true;
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.log(e);
    } finally {
      return flag;
    }
  };

  const checkGoogleDriveSync = async () => {
    let flag = false;
    try {
      const response = await GoogleDriveServices.is_connected(projectId);
      if (response.status) {
        flag = true;
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.log(e);
    } finally {
      return flag;
    }
  };

  const checkOneDriveSync = async () => {
    let flag = false;
    try {
      const response = await OnedriveServices.is_connected(projectId);
      if (response.status) {
        flag = true;
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.log(e);
    } finally {
      return flag;
    }
  };

  const getSpaceSubscriptionList = async () => {
    try {
      setIsFetchingData(true);
      const response = await CustomerServices.getSubscriptionList();
      if (response.status) {
        /**As per feedback, keep only one section for subscription */
        const space_subscription_data =  {
          l_subscriptions: response.data,
          // l_past: response.data.filter((w) => w.status === "past"),
          // l_future: response.data.filter((w) => w.status === "not_subscribed"),
        }
        setListData(space_subscription_data);
        setPaginationState({l_subscriptions: 1
          // , l_past: 1, l_future: 1
        });
      }
      else {
        const space_subscription_data =  {
          l_subscriptions: []
        }
        setListData(space_subscription_data);
        setPaginationState({l_subscriptions: 1});
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const setStaticIntegrationCards = async (key, name) => {
    let cardData = INTEGRATION_TOOLS.find((w) => w.type === key).providers;
    let isSynced = "";
    switch (key) {
      case "third_party_calendar":
        cardData.forEach(async (tool) => {
          switch (tool.tool) {
            case "Google":
              tool.connected = await checkGoogleCalendarSync();
              break;
            case "Outlook":
              tool.connected = await checkOutlookCalendarSync();
              break;
            default:
              break;
          }
        });
        break;
      case "third_party_email":
        isSynced = await checkEmailSync();
        cardData.forEach((tool) => {
          tool.connected = isSynced != "";
        });
        if (isSynced !== "") {
          setButtonText("l_emails");
        } else {
          setButtonText("");
        }
        break;
      case "third_party_drive":
        cardData.forEach(async (tool) => {
          switch (tool.tool) {
            case "Google":
              tool.connected = await checkGoogleDriveSync();
              break;
            case "Outlook":
              tool.connected = await checkOneDriveSync();
              break;
            default:
              break;
          }
        });
        break;
    }
    setListData({ [name]: cardData });
    setPaginationState({ [name]: 1 });
  };

  const showWalletConfig = () => {};

  const getProviderLeads = async () => {
    try {
      setListData({ l_leads: [] });
      setPaginationState({ l_leads: 1 });
    } catch (e) {
      showError("l_something_went_wrong");
    }
  };

  const getUserSpaceList = () => {
    let newSpaceList = []
    CustomerServices.getCustomerSpaces(client_id, contact_id, "premium_subscription").then(
      async(data) => {
        if(data.data)
        {
          newSpaceList = data.data
          let spaceArray = newSpaceList.reverse();
          let newSpaces = spaceArray.filter((office) => office.status == 0);
          let currentSpaces = spaceArray.filter((office) => office.status == 1 || office.status == 2);
          if (userType == "operator") {
            newSpaces = newSpaces.filter(
              (office) =>
                office.is_added_from_operator == 1 &&
                office.added_from_client_id == client_id
            );
            currentSpaces = currentSpaces.filter(
              (office) =>
                office.is_added_from_operator == 1 &&
                office.added_from_client_id == client_id
            );
          }
          setListData({l_current_space: currentSpaces, l_new_space: newSpaces});
          setPaginationState({l_current_space: 1, l_new_space: 1});
        }
      })
  };

  const getSpaceInvoices = async() => {
    try {
      setIsFetchingData(true);
      const response = await DocumentsServices.getDocumentInvoices(projectId, [], 1, "", 5000);
      if(response.status)
      {
        setListData({l_invoice: response.data});
        setPaginationState({l_invoice: 1}); 
      }
      else {
        setListData({l_invoice: []});
        setPaginationState({l_invoice: 1}); 
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      setIsFetchingData(false);
    }
  };

  const setSpaceBillingTab = async() => {
    setIsFetchingData(true);
    setListData({});
    setPaginationState({});
    try {
      const response = await CustomerServices.getIbanList();
      if (response.status && response.data) {
        setIbanList(response.data);
      }
      const ibanStatusResponse = await CustomerServices.getIBANStatus();
      if (ibanStatusResponse.status && ibanStatusResponse.data) {
        setIbanStatus(ibanStatusResponse.data.single_iban_for_all_spaces);
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      setIsFetchingData(false);
    }
  };

  const setForMeTags = () => {
    let userTopics = [];
    categoriesList.forEach((category) => {
      userTopics.push({
        ...category,
        main_key: data.main_key,
        filter_key: category.filter_key,
        name: _l(category.title),
        full_name: _l(category.title),
        profile_image_url: "",
        disabled: ["created_by_me", "assigned_to_me", "pinned", "overdue"].includes(category.filter_key)
      });
    });
    setListData({l_tags: userTopics.filter((w) => w.filter_key !== -1)});
    setPaginationState({l_tags: 1});
  };
 
  const changeTopic = (topic) => {
    setSelectedTopic(topic);
    switch (topic.key) {
      case "providers":
        if (userType !== "operator") {
          setButtonText(
            selectedCategory.id !== -1
              ? "l_invite_provider"
              : "l_bulk_category_upload"
          );
          setTopicButtonText(selectedCategory.id == -1 ? "l_add_provider" : "");
        }
        getProviderList();
        break;
      case "teams":
        setButtonText("l_create_team");
        getTeamsList();
        break;
      case "people":
        setButtonText(selectedCategory.main_sub_key === "team" ? "l_edit_team" : "l_add_new_people");
        getPeopleList();
        setButtonText(
          selectedCategory.main_sub_key === "team"
            ? "l_edit_team"
            : "l_add_new_people"
        );
        setTopicButtonText(
          selectedCategory.main_sub_key === "team" ? "" : "l_create_team"
        );
        // setTopicButtonText(selectedCategory.id !== -1 ? "l_create_team" : "")
        break;
      case "schedules":
        setTopicButtonText("");
        setButtonText("l_create_schedule");
        getSchedules(topic.key);
        break;
      case "benchmarks":
        setTopicButtonText("");
        setButtonText("l_create_benchmark");
        getSchedules(topic.key);
        break;
      case "proposals":
        setButtonText("");
        setTopicButtonText("");
        let sectionsToSkip = [];
        if (data.main_key === "proposals") {
          sectionsToSkip = ["l_contract"];
        }
        getSchedules(topic.key, sectionsToSkip);
        break;
      case "dpgf":
        setButtonText("");
        setTopicButtonText("");
        getSchedules(topic.key);
        break;
      case "documents":
        setButtonText("");
        setTopicButtonText("");
        if (data.main_key === "my_operator") {
          getPeopleDocuments();
        } else {
          getDriveData();
        }
        break;
      case "equipments":
        setButtonText(
          selectedCategory.id !== -1
            ? "l_add_quantity"
            : "l_bulk_equipments_upload"
        );
        setTopicButtonText("l_add_equipment");
        getEquipmentData();
        break;
      case "credit_notes":
        setButtonText("");
        getDriveData("credit_notes");
        break;
      case "estimate":
        setButtonText("");
        getDriveData("estimate");
        break;
      case "invoice":
        setButtonText("");
        getDriveData("invoice");
        break;
      case "space":
        setButtonText("");
        getDriveData("space");
        break;
      case "subscription":
        setButtonText("");
        getDriveData("subscription");
        break;
      case "google_drive":
        setButtonText("");
        getDriveData("google_drive");
        break;
      case "solar_document":
        setButtonText("");
        getDriveData("solar_document");
        break;
      case "third_party_calendar":
        setButtonText("");
        setStaticIntegrationCards("third_party_calendar", "l_calendar");
        break;
      case "third_party_email":
        setStaticIntegrationCards("third_party_email", "l_email");
        break;
      case "third_party_drive":
        setButtonText("");
        setStaticIntegrationCards("third_party_drive", "l_drive");
        break;
      case "company_profile":
        setButtonText("");
        getCompanyProfile();
        break;
      case "manage_user":
        setButtonText("l_add_user");
        getUserList();
        break;
      case "manage_company":
        setButtonText("l_new_space");
        getCompanyList();
        break;
      case "manage_roles":
        setButtonText("l_add_role");
        getRolesList();
        break;
      case "teams":
        setButtonText("");
        getTeamsList();
        break;
      case "manage_checklist":
        setButtonText("");
        getCompanyChecklist();
        break;
      case "wallet":
        setButtonText("");
        showWalletConfig();
        getUserInvoiceData();
        break;
      case "myr_support":
        setButtonText("");
        getMyrSupportList();
        break;
      case "wallet-settings":
        setButtonText(data.walletId > 0 ? "l_save" : "l_next");
        setTopicButtonText(data.autoSubScribe ? "l_back" : "");
        break;
      case "info-modal":
        setButtonText("l_next");
        setTopicButtonText("");
        break;
      case "payment-step-1":
      case "payment-step-2":
      case "payment-step-3":
        setButtonText("l_next");
        setTopicButtonText("l_back");
        break;
      case "postal-fields":
        setButtonText("l_setup");
        setTopicButtonText("l_back");
        break;
      case "payout":
        setButtonText("");
        setTopicButtonText("");
        getProviderpayoutReport([]);
        break;
      case "contracts":
        getSchedules("proposals", ["l_received", "l_pending", "l_rejected"]);
        break;
      case "leads":
        getProviderLeads();
        break;
      case "space_subscription":
        setButtonText("");
        setTopicButtonText("");
        getSpaceSubscriptionList();
        break;
      case "my_spaces":
        setButtonText("l_new_space");
        setTopicButtonText("");
        getUserSpaceList();
        break; 
      case "space_invoice":
        setButtonText("");
        setTopicButtonText("");
        getSpaceInvoices();
        break;
      case "billing":
        setButtonText("");
        setTopicButtonText("");
        if(userType === "operator")
        {
          setButtonText("l_add_new");
          getProviderWalletList();
        }
        else {
          setSpaceBillingTab();
        }
        break;
      case "edit_user_profile":
        setButtonText("l_save");
        break;
      case "ai_context":
        setButtonText("l_save");
        break;
      case "tags":
        setForMeTags();
        setTopicButtonText("l_add_tag");
        break;
      case "upload_document":
        setButtonText("");
        setTopicButtonText("");
        // if(userType === "operator" && isSolarHubPlatform())
        // {
        //   navigate("/provider-documents");
        //   break;
        // }
      default:
        setButtonText("");
        setListData({});
        break;
    }
  };

  const cardClickHandle = (key, row) => {
    let externalURL = "";
    switch (key) {
      case "providers":
        let categoryName = "";
        if (row.category.length) {
          const categoryData = categoriesList.find(
            (w) => w.filter_key == row.category[0]
          );
          categoryName = categoryData.title;
        }
        externalURL = `/providers?space=${projectId}&actionType=add-contact&invitee=provider&provider=${
          row.userid
        }&category=${row.category[0]}&categoryName=${encodeURIComponent(
          categoryName
        )}`;
        break;
      case "schedules":
        if(data.main_key === "equipments")
        {
          externalURL = `/equipments?space=${row.project_id}&action=equipment-list&equipment=${row.equipment_id}&id=${row.id}`;
        }
        else {
          externalURL = `/connect-provider?space=${row.project_id}&provider-type=schedule&id=${row.id}`;
        }
        break;
      case "benchmarks":
        externalURL = `/connect-provider?space=${row.project_id}&provider-type=benchmark&id=${row.id}`;
        break;
      case "contracts":
      case "proposals":
        externalURL = `/connect-provider?space=${row.project_id}&provider-type=proposal&id=${row.id}`;
        break;
      case "equipments":
        if (selectedCategory.id === -1) {
          setSelectedCategory({
            id: row.equipment_id,
            title: row.title,
            is_active: row.is_active,
            main_sub_key: row.main_sub_key,
          });
          break;
        }
        externalURL = `/equipments?space=${projectId}&equipment=${row.equipment_id}&item=${row.id}`;
        break;
      case "people":
      case "myr_support":
        if (selectedCategory.id === -1 && row.main_sub_key === "team") {
          setSelectedCategory({
            id: row.filter_key,
            title: row.title,
            is_active: row.is_active,
            main_sub_key: row.main_sub_key,
          });
          break;
        } else if (
          row.staff_id &&
          row.staff_id != null &&
          row.staff_id > 0 &&
          ((userType != "staff" && loginUserRole <= row.role_detail.role_id ) || (userType === "staff" && admin === "true"))
        ) {
          // externalURL = `/myprofile?user=${row.staff_id}`;
          navigate(`/myprofile?user=${row.staff_id}`)
        } else {
          showError("l_unauthorized");
        }
        break;
      case "wallet":
        setPaymentSetupModalData(row);
        break;
      default:
        break;
    }

    if (externalURL !== "") {
      window.open(externalURL, "_blank");
      return;
    }
  };

  const addNewEquipmentItem = async (id) => {
    try {
      setAddOperationOnGoing(true);
      const response = await EquipmentsServices.addEquipmentQuantity(
        projectId,
        id,
        1
      );
      if (response.status) {
        let newEquipment = response.data.pop();
        newEquipment = {...newEquipment, 
          equipment_id: id,
          title: newEquipment.quantity_name,
          people: [],
        }
        setListData({...listData, l_equipments: listData["l_equipments"].concat([newEquipment])});
        dispatch(getDefaultTopics(projectId));
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    } finally {
      setAddOperationOnGoing(false);
    }
  };

  const addNewWalletForProvider = () => {
    setOverWriteMainModal(true);
    setPaymentSetupModalData({show: true});
  };

  const closeNewWalletSetupModal = () => {
    setPaymentSetupModalData({});
    setOverWriteMainModal(true);
    getProviderWalletList(false);
  };

  const saveAIContext = () => {
    aiContextRef.current.updateContextValue();
  };

  const handleAddAction = () => {
    switch (selectedTopic.key) {
      case "providers":
        if (selectedCategory.id === -1) {
          setShowBulkCategoryUpload(true);
          setOverWriteMainModal(true);
        } else {
          window.open(
            `/connect-provider?space=${projectId}&provider-type=invite_provider&categoryName=${encodeURIComponent(
              selectedCategory.title
            )}&categoryId=${selectedCategory.id}&email=${encodeURIComponent(
              providerEmail
            )}`,
            "_blank"
          );
        }
        break;
      case "teams":
        window.open(
          userType === "operator"
            ? `/employees?space=${projectId}&actionType=create-team`
            : `/tenants?space=${projectId}&actionType=create-team`
        );
        break;
      case "people":
        if (selectedCategory.main_sub_key == "team") {
          window.open(
            userType === "operator"
              ? `/employees?space=${projectId}&actionType=create-team&edit=${Math.abs(
                  selectedCategory.id
                )}`
              : `/tenants?space=${projectId}&actionType=create-team&edit=${Math.abs(
                  selectedCategory.id
                )}`
          );
        } else {
          window.open(
            userType === "operator"
              ? `/employees?space=${projectId}&actionType=add-employee`
              : `/tenants?space=${projectId}&actionType=add-contact`
          );
        }
        break;
      case "benchmarks":
        window.open(
          `/connect-provider?space=${projectId}&provider-type=benchmark&categoryName=${encodeURIComponent(
            selectedCategory.title
          )}&categoryId=${selectedCategory.id}`,
          "_blank"
        );
        break;
      case "schedules":
        window.open(
          `/connect-provider?space=${projectId}&provider-type=schedule&categoryName=${encodeURIComponent(
            selectedCategory.title
          )}&categoryId=${selectedCategory.id}`,
          "_blank"
        );
        break;
      case "equipments":
        if (selectedCategory.id === -1) {
          setBulkEquipmentsImport(true);
          setOverWriteMainModal(true);
        } else {
          addNewEquipmentItem(selectedCategory.id);
        }
        break;
      case "manage_roles":
        setOverWriteMainModal(true);
        setShowEditRole(true);
        setSelectedRole("");
        break;
      case "manage_user":
        navigate(
          userType === "operator"
            ? `/employees?space=${project_id > 0 ? spaces[0].project_id : 0}&actionType=add-employee`
            : `/tenants?space=${project_id > 0 ? spaces[0].project_id : 0}&actionType=add-contact`,
          { state: { from: location.pathname } }
        );
        if (project_id == 0) {
          removeExpandedClassFromIntroOfficeList()
        }
        // closeSettingModal()
        break;
      case "manage_company":
        navigate("/space-onboarding");
        closeSettingModal()
        break;
      case "third_party_email":
        window.open(`/MyEmail`);
        break;
      case "wallet-settings":
      case "payment-step-1":
      case "payment-step-2":
      case "payment-step-3":
      case "postal-fields":
      case "info-modal":
        walletSetupRef.current.moveToNextStep(selectedTopic.key);
        break;
      case "my_spaces":
        navigate("/space-onboarding");
        break;
      case "billing":
        addNewWalletForProvider();
        break;
      case "ai_context":
        saveAIContext();
      default:
        break;
    }
  };

  const viewAll = (key) => {
    const totalPages = Math.ceil((listData[key].length - 3) / 10) + 1;
    setPaginationState({ ...paginationState, [key]: totalPages });
  };

  const openDocumentDetailView = async (documentData, key) => {
    try {
      switch (key) {
        case "l_current":
        case "l_past":
        case "l_future":
        case "l_subscriptions":
          setOverWriteMainModal(true);
          setDocumentMiniModalData({
            id: documentData.id,
            hash: documentData.hash,
            type: "subscription",
          });
          break;
        case "l_estimates":
          setOverWriteMainModal(true);
          setDocumentMiniModalData({
            id: documentData.id,
            hash: documentData.hash,
            type: "estimate",
          });
          break;
          case "premiumEstimate":
          setOverWriteMainModal(true);
          setDocumentMiniModalData({
            id: documentData.project_id,
            hash: documentData.hash,
            type: "premiumEstimate",
          });
          break;
        case "l_space_document":
          setOverWriteMainModal(true);
          setDocumentMiniModalData({
            id: documentData.id,
            hash: documentData.hash,
            type: "spaceDocument",
            floor_id: documentData.floor_id,
          });
          break;
        case "l_expenses":
        case "l_invoices":
        case "l_invoice":
          setOverWriteMainModal(true);
          setDocumentMiniModalData({
            id: documentData.id,
            hash: documentData.hash,
            type: "invoice",
            is_app_invoice:
              key === "l_expenses" || documentData.is_app_invoice === 1 ? 1 : 0,
          });
          break;
        case "l_income":
        case "l_credit_note":
          setOverWriteMainModal(true);
          setDocumentMiniModalData({
            id: documentData.id,
            hash: documentData.hash,
            type: "credit_note",
            is_app_credit_note:  documentData.is_app_credit_note === 1 ? 1 : 0,
          });
          break;
        case "l_documents":
          setOverWriteMainModal(true);
          setDocumentMiniModalData({
            id: documentData.id,
            type: "task",
            ...documentData,
          });
          break;
        default:
          break;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleTopic = async (
    main_key,
    filter_key,
    is_active,
    main_sub_key,
    key
  ) => {
    const paylaodTopicData = {
      main_key,
      filter_key,
      main_sub_key,
      status: is_active ? 0 : 1,
    };
    try {
      const response = updateTopicStatusInBulk([paylaodTopicData]);
      dispatch(getDefaultTopics());
      if (response) {
        setListData({
          ...listData,
          [key]: listData[key].map((w) => {
            if (w.filter_key == filter_key) {
              w.is_active = is_active ? 0 : 1;
            }
            return w;
          }),
        });
      }
    } catch (e) {}
  };

  const updateTopicStatusInBulk = async (topicData) => {
    try {
      const response = await CustomerServices.changeTopicStatusBulk(
        projectId,
        topicData
      );
      if (response.status > 0) {
        return true;
      } else {
        showError(response.message);
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const handleBulkActiveInActiveTopics = async (key, checked) => {
    let topicArray = [];
    listData[key].forEach((topic) => {
      topicArray.push({
        main_key: topic.main_key || data.main_key,
        filter_key: topic.filter_key,
        main_sub_key: topic.main_sub_key,
        status: checked ? 1 : 0,
      });
    });
    if (!topicArray.length) {
      return false;
    }
    try {
      const flag = await updateTopicStatusInBulk(topicArray);
      dispatch(getDefaultTopics());
      if (flag) {
        setListData({
          ...listData,
          [key]: listData[key].map((w) => {
            return { ...w, is_active: checked ? 1 : 0 };
          }),
        });
      }
    } catch (e) {
      return false;
    }
  };

  const getCategoriesPill = (categoriesArray) => {
    let pills = [];
    let titles = categoriesList
      .filter((w) => categoriesArray.includes(w.filter_key))
      .map((w) => w.title);

    titles = [...new Set(titles)];

    titles.forEach((title) => {
      pills.push({ color: "color-green", content: `#${title}` });
    });

    return pills;
  };

  const editEquipment = (equipment) => {
    if (equipment.filter_key > 0) {
      window.open(
        `/equipments?space=${projectId}&equipment=${equipment.filter_key}`,
        "_blank"
      );
    } else if (equipment.equipment_id > 0 && equipment.id) {
      window.open(
        `/equipments?space=${projectId}&equipment=${equipment.equipment_id}&item=${equipment.id}`,
        "_blank"
      );
    }
  };

  const deleteEquipment = (main_key, filter_key) => {
    dispatch(
      showConfirmation(
        _l("l_delete"),
        _l("l_delete_equipment_confirmation"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
          dispatch(toastCloseAction());
          try {
            if (filter_key > 0) {
              const response = await EquipmentsServices.deleteEquipmentQuantity(
                main_key,
                filter_key,
                projectId
              );
              if (response.status) {
                setListData({
                  ...listData,
                  l_equipments: listData["l_equipments"].filter(
                    (w) => w.id != filter_key
                  ),
                });
                let updatedEquipmentsArray = categoriesList.map((w) => {
                  if(w.filter_key == main_key )
                  {
                    w.sub_category = w.sub_category.filter((w) => w.id != filter_key);
                  }
                  return w;
                });
                if(updatedEquipmentsArray.find((w) => w.filter_key == main_key && !w.sub_category.length))
                {
                  setSelectedCategory(updatedEquipmentsArray);
                }
                dispatch(getDefaultTopics(projectId));
              } else {
                showError(response.message);
              }
            } else {
              const response = await EquipmentsServices.deleteEquipment(
                main_key,
                projectId
              );
              if (response.status) {
                setListData({
                  ...listData,
                  l_equipments: listData["l_equipments"].filter(
                    (w) => w.filter_key != main_key
                  ),
                });
                setCategoriesList(categoriesList.filter((w) => w.filter_key != main_key));
                dispatch(getDefaultTopics(projectId));
              } else {
                showError(response.message);
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      )
    );
  };

  const setupCategoryMenu = (isInit) => {
    if (defaultTopics && defaultTopics.length) {
      const allCategoriesList = defaultTopics.find(
        (w) =>
          (w.main_key == data.main_key &&
          ((TOPIC_LIST_BY_USER_TYPE.find(
            (z) =>
              z.userType === userType && z.allowedTopics.includes(w.main_key)
          )) || ["my_task"].includes(data.main_key)))
      );
      if (
        allCategoriesList &&
        ["my_staff", "my_contact", "my_operator", "my_task"].includes(
          allCategoriesList.main_key
        )
      ) {
        if(allCategoriesList.main_key !== "my_task")
        {
          allCategoriesList.categories = allCategoriesList.categories.filter(
            (w) => w.filter_key != staff_id
          );
        }
      }
      if (allCategoriesList && allCategoriesList.categories.length) {
        setCategoriesList(
          [{ title: "l_all", filter_key: -1 }].concat(
            allCategoriesList.categories.filter((w) => allCategoriesList.main_key == "my_task" || !isNaN(w.filter_key))
          )
        );
      }

      let menuOptions = [];
      defaultTopics.forEach((topic) => {
        if (
          topic.main_key !== "my_task" &&
          TOPIC_LIST_BY_USER_TYPE.find(
            (z) =>
              z.userType === userType &&
              z.allowedTopics.includes(topic.main_key)
          )
        ) {
          menuOptions.push({
            id: topic.main_key,
            label: topic.title,
            numberOfSelectedSubMenuItems:
              isInit && topic.main_key == data.main_key ? 1 : 0,
            subMenuOptions: topic.categories
              .filter((w) => !isNaN(w.filter_key))
              .map((category) => {
                let subMenuOption = { ...category };
                subMenuOption.id = category.filter_key;
                subMenuOption.label = category.title;
                subMenuOption.profile_image = category.image;
                subMenuOption.is_selected =
                  isInit &&
                  topic.main_key == data.main_key &&
                  category.filter_key == data.id
                    ? 1
                    : 0;
                if (
                  !["my_contact", "my_operator", "my_staff"].includes(
                    topic.main_key
                  )
                ) {
                  subMenuOption.showHashIcon = true;
                }
                return subMenuOption;
              }),
          });
        }
      });
      setCategoryMenuOptions(
        [{ id: -1, label: "l_all", subMenuOptions: [] }].concat(menuOptions)
      );
    }
    if (data.main_key == "company-profile" || data.main_key == "edit_profile") {
      setCategoryMenuOptions([
        { id: -1, label: "l_all", subMenuOptions: [], is_selected: false },
        {
          id: 1,
          label: "l_team",
          subMenuOptions: userTeams.map((s) => {
            return { id: s.id, label: s.name, is_selected: false };
          }),
        },
        {
          id: 2,
          label: "l_space",
          subMenuOptions: spaces.map((s) => {
            return {
              id: s.project_id,
              label: s.office_title,
              is_selected: false,
            };
          }),
        },
        {
          id: 3,
          label: "l_role",
          subMenuOptions: roleList.map((s) => {
            return { id: s.id, label: s.name, is_selected: false };
          }),
        },
      ]);
    }
  };

  const applyFilterActive = (newOptionsList, newSelected, selectedIds) => {
    newOptionsList[newSelected].subMenuOptions = newOptionsList[
      newSelected
    ].subMenuOptions.map((ss) => {
      return { ...ss, is_selected: selectedIds.includes(ss.id) ? true : false };
    });
    newOptionsList[0].is_selected = false;
    setCategoryMenuOptions(newOptionsList.slice());
  };

  const applyFilterForUser = (selectedFilter) => {
    let newOptionsList = categoryMenuOptions;
    let newSelected = newOptionsList.findIndex(
      (n) => n.label == selectedFilter.outerData.label
    );
    switch (selectedFilter.outerData.label) {
      case "l_all":
        setSelectedRoleIds([]);
        setSelectedSPacesIds([]);
        setSelectedTeamIds([]);
        setCategoryMenuOptions([
          { id: -1, label: "l_all", subMenuOptions: [], is_selected: true },
          {
            id: 1,
            label: "l_team",
            subMenuOptions: userTeams.map((s) => {
              return { id: s.id, label: s.name, is_selected: false };
            }),
          },
          {
            id: 2,
            label: "l_space",
            subMenuOptions: spaces.map((s) => {
              return {
                id: s.project_id,
                label: s.office_title,
                is_selected: false,
              };
            }),
          },
          {
            id: 3,
            label: "l_role",
            subMenuOptions: roleList.map((s) => {
              return { id: s.id, label: s.name, is_selected: false };
            }),
          },
        ]);
        break;
      case "l_team":
        let teamsIds = selectedTeamIds;
        if (teamsIds.includes(selectedFilter.innerData.id)) {
          teamsIds = teamsIds.filter((t) => t != selectedFilter.innerData.id);
        } else if (selectedFilter.innerData.id) {
          teamsIds.push(selectedFilter.innerData.id);
        }
        setSelectedTeamIds(teamsIds.slice());
        applyFilterActive(newOptionsList, newSelected, teamsIds);
        break;
      case "l_space":
        let spacesIds = selectedSPacesIds;
        if (spacesIds.includes(selectedFilter.innerData.id)) {
          spacesIds = spacesIds.filter((t) => t != selectedFilter.innerData.id);
        } else {
          spacesIds.push(selectedFilter.innerData.id);
        }
        setSelectedSPacesIds(spacesIds.slice());
        applyFilterActive(newOptionsList, newSelected, spacesIds);
        break;
      case "l_role":
        let rolesIds = selectedRoleIds;
        if (rolesIds.includes(selectedFilter.innerData.id)) {
          rolesIds = rolesIds.filter((t) => t != selectedFilter.innerData.id);
        } else {
          rolesIds.push(selectedFilter.innerData.id);
        }
        setSelectedRoleIds(rolesIds.slice());
        applyFilterActive(newOptionsList, newSelected, rolesIds);
        break;
      default:
        break;
    }
  };

  const deleteBenchmark = (id) => {
    dispatch(
      showConfirmation(
        _l("l_delete_benchmark"),
        _l("l_delete_benchmark_confirmation"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
          try {
            dispatch(toastCloseAction());
            const response = await BenchmarkServices.deleteBenchmark(
              id,
              projectId
            );
            if (response.status) {
              await getSchedules(selectedTopic.key);
            } else {
              showError(response.message);
            }
          } catch (e) {
            showError("l_something_went_wrong");
            console.error(e);
          }
        }
      )
    );
  };

  const stopBenchmark = (benchmark) => {
    setBenchmarkToStop(benchmark);
    setOverWriteMainModal(true);
  };

  const applyFilter = (selectedFilter) => {
    if (selectedFilter.outerData.id === -1) {
      // setFilterArray(defaultTopics.map((topic) => {
      //   let filter = {main_key: topic.main_key, filter_keys: []};
      //   filter.filter_keys = topic.categories && topic.categories.length ? topic.categories.map((w) => w.filter_key) : [];
      //   return filter;
      // }));
      setFilterArray([]);
      setupCategoryMenu();
    } else {
      const filterCategoryIndex = filterArray.findIndex(
        (w) => w.main_key == selectedFilter.outerData.id
      );
      const menuItemIndex = categoryMenuOptions.findIndex(
        (w) => w.id == selectedFilter.outerData.id
      );
      const subMenuItemIndex = categoryMenuOptions[
        menuItemIndex
      ].subMenuOptions.findIndex((w) => w.id == selectedFilter.innerData.id);

      if (filterCategoryIndex === -1) {
        setFilterArray(
          filterArray.concat({
            main_key: selectedFilter.outerData.id,
            filter_keys: [
              {
                filter_key: selectedFilter.innerData.id,
                main_sub_key: selectedFilter.innerData.main_sub_key,
              },
            ],
          })
        );
        setCategoryMenuOptions(
          Object.values({
            ...categoryMenuOptions,
            [menuItemIndex]: {
              ...categoryMenuOptions[menuItemIndex],
              numberOfSelectedSubMenuItems: 1,
              subMenuOptions:
                subMenuItemIndex === -1
                  ? []
                  : Object.values({
                      ...categoryMenuOptions[menuItemIndex].subMenuOptions,
                      [subMenuItemIndex]: {
                        ...categoryMenuOptions[menuItemIndex].subMenuOptions[
                          subMenuItemIndex
                        ],
                        is_selected: true,
                      },
                    }),
            },
          })
        );
      } else {
        if (
          filterArray[filterCategoryIndex].filter_keys.find(
            (w) =>
              w.main_sub_key === selectedFilter.innerData.main_sub_key &&
              w.filter_key === selectedFilter.innerData.filter_key
          )
        ) {
          let updatedFilterArray = filterArray;
          updatedFilterArray[filterCategoryIndex].filter_keys =
            updatedFilterArray[filterCategoryIndex].filter_keys.filter(
              (w) => w.filter_key != selectedFilter.innerData.id
            );
          setFilterArray(updatedFilterArray);
          setCategoryMenuOptions(
            Object.values({
              ...categoryMenuOptions,
              [menuItemIndex]: {
                ...categoryMenuOptions[menuItemIndex],
                numberOfSelectedSubMenuItems:
                  categoryMenuOptions[menuItemIndex]
                    .numberOfSelectedSubMenuItems - 1,
                subMenuOptions:
                  subMenuItemIndex === -1
                    ? []
                    : Object.values({
                        ...categoryMenuOptions[menuItemIndex].subMenuOptions,
                        [subMenuItemIndex]: {
                          ...categoryMenuOptions[menuItemIndex].subMenuOptions[
                            subMenuItemIndex
                          ],
                          is_selected: false,
                        },
                      }),
              },
            })
          );
        } else {
          let updatedFilterArray = filterArray;
          updatedFilterArray[filterCategoryIndex].filter_keys.push({
            filter_key: selectedFilter.innerData.id,
            main_sub_key: selectedFilter.innerData.main_sub_key,
          });
          setCategoryMenuOptions(
            Object.values({
              ...categoryMenuOptions,
              [menuItemIndex]: {
                ...categoryMenuOptions[menuItemIndex],
                numberOfSelectedSubMenuItems:
                  categoryMenuOptions[menuItemIndex]
                    .numberOfSelectedSubMenuItems + 1,
                subMenuOptions:
                  subMenuItemIndex === -1
                    ? []
                    : Object.values({
                        ...categoryMenuOptions[menuItemIndex].subMenuOptions,
                        [subMenuItemIndex]: {
                          ...categoryMenuOptions[menuItemIndex].subMenuOptions[
                            subMenuItemIndex
                          ],
                          is_selected: true,
                        },
                      }),
              },
            })
          );
        }
      }
    }
  };

  const updateProposalBadgeCount = (count) => {
    if(count > 0)
    {
      let updatedCount;
      switch(selectedTopic.key)
      {
        case "proposals":
          const currentProposalCount = localStorage.getItem("unread_proposal_comment");
          updatedCount = Number(currentProposalCount) - Number(count);
          localStorage.setItem("unread_proposal_comment", updatedCount);
          break;
        case "contracts":
          const currentContractCount = localStorage.getItem("unread_contract_comment");
          updatedCount =  Number(currentContractCount) - Number(count);
          localStorage.setItem("unread_proposal_comment", updatedCount);
          break;
        case "leads":
          break;
        default:
          break
      }
      const topicIndex = sectionList.findIndex((w) => w.key === selectedTopic.key);
      if(topicIndex > -1)
      {
        setSectionList(sectionList.map((w) => {
          if(w.key === selectedTopic.key)
          {
            w.notificationCounts = updatedCount;
          }
          return w;
        }));
      }
    }
  };

  const getTopicNotificationCounts = (categoryName) => {
    if(["proposals", "contracts"].includes(categoryName))
    {
      switch(categoryName)
      {
        case "proposals":
          return localStorage.getItem("unread_proposal_comment");
        case "contracts":
          return localStorage.getItem("unread_contract_comment");
        default:
          return 0;
      }
    }
    else if (!defaultTopics || !defaultTopics.length) {
      return 0;
    }
    const mainCategoryIndex = defaultTopics.findIndex(
      (w) => w.main_key == data.main_key
    );
    if (
      mainCategoryIndex > -1 &&
      defaultTopics[mainCategoryIndex].categories.find(
        (w) =>
          w.filter_key == selectedCategory.id &&
          w.main_sub_key == selectedCategory.main_sub_key
      )
    ) {
      let currentCategory = defaultTopics[mainCategoryIndex].categories.find(
        (w) => w.filter_key == selectedCategory.id
      );

      if (
        !currentCategory ||
        !currentCategory.total_notification_count ||
        !Object.keys(currentCategory.total_notification_count).length
      ) {
        return 0;
      }

      switch (categoryName) {
        case "schedules":
          if(data.main_key === "myr_support")
          {
            return 0;
          }
          return currentCategory.total_notification_count.schedule_count + currentCategory.total_notification_count.benchmark_count;
        case "proposals":
        case "benchmarks":
          return currentCategory.total_notification_count.benchmark_count;
        default:
          return 0;
      }
    }
  };

  const prepareTopicList = () => {
    let topicList = [];
    let benchmarkTabs = [
      {
        key: "schedules",
        name: "l_schedules",
        icon: AddScheduleIcon,
        notificationCounts: getTopicNotificationCounts("schedules"),
      }
    ];

    switch (data.main_key) {
      case "proposals":
        topicList = [
          {
            key: "proposals",
            name: "l_proposals",
            icon: CurrencyEur,
            notificationCounts: getTopicNotificationCounts("proposals"),
          },
          {
            key: "leads",
            name: "l_leads",
            icon: BezierCurve,
            notificationCounts: 0,
          },
          {
            key: "contracts",
            name: "l_contracts",
            icon: Buildings,
            notificationCounts: getTopicNotificationCounts("contracts"),
          },
          {
            key: "dpgf",
            name: "l_dpgf",
            icon: Receipt,
            notificationCounts: getTopicNotificationCounts(0),
          },
        ];
        break;
      case "providers":
        topicList = [
          {
            key: "providers",
            name: "l_providers",
            icon: UserGear,
            notificationCounts: 0,
          },
          {
            key: "schedules",
            name: "l_schedules",
            icon: AddScheduleIcon,
            notificationCounts: getTopicNotificationCounts("schedules"),
          },
          {
            key: ["contact", "staff"].includes(userType)
              ? "benchmarks"
              : "proposals",
            name: ["contact", "staff"].includes(userType)
              ? "l_benchmark"
              : "l_proposals",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts(
              ["contact", "staff"].includes(userType)
                ? "benchmarks"
                : "proposals"
            ),
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("documents"),
          },
        ];
        if(((userType === "contact" && loginUserRole == 1) && selectedCategory.id > 0 && isAdminAsStaff && Object.keys(isAdminAsStaff).length > 0))
        {
          topicList.push({
            key: "ai_context",
            name: "l_ai_context",
            icon: Cpu,
            notificationCounts: getTopicNotificationCounts("ai_context"),
          });
        }
        break;
      case "my_operator":
      case "my_staff":
      case "my_contact":
        topicList = [
          {
            key: "people",
            name: "l_people",
            icon: UsersThree,
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("documents"),
          },
        ];
        if(userType !== "operator")
        {
          benchmarkTabs.push({
            key:  "benchmarks",
            name:"l_benchmarks",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts("benchmarks"),
          });
        }
        Array.prototype.splice.apply(topicList, [1, 0].concat(benchmarkTabs)); //Insert elemets at index 1
        break;
      case "myr_support":
        topicList = [
          {
            key: "myr_support",
            name: "l_myr_support",
            icon: UsersThree,
          },
          {
            key: "schedules",
            name: "l_schedules",
            icon: AddScheduleIcon,
            notificationCounts: getTopicNotificationCounts("schedules"),
          },
          {
            key: "benchmarks",
            name: "l_benchmarks",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts("benchmarks"),
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: 0,
          }
        ];
        break;
      case "equipments":
        topicList = [
          {
            key: "equipments",
            name: "l_equipments",
            icon: Wrench,
            notificationCounts: getTopicNotificationCounts("equipments"),
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("documents"),
          },
        ];
        if(userType !== "operator")
        {
          benchmarkTabs.push({
            key:  "benchmarks",
            name:"l_benchmarks",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts("benchmarks"),
          });
        }
        Array.prototype.splice.apply(topicList, [1, 0].concat(benchmarkTabs)); //Insert elemets at index 1
        break;
      case "documents":
        topicList = [
          {
            key: "solar_document",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("documents"),
          },
          // {
          //   key: "google_drive",
          //   name: "l_documents",
          //   icon: File,
          //   notificationCounts: getTopicNotificationCounts("documents"),
          // },
          {
            key: "estimate",
            name: "l_estimates",
            icon: EstimateIcon,
            notificationCounts: getTopicNotificationCounts("estimate"),
          },
          // {
          //   key: "space",
          //   name: "l_space_document",
          //   icon: FileCloud,
          //   notificationCounts: getTopicNotificationCounts("space"),
          // },
          // {
          //   key: "subscription",
          //   name: "l_subscriptions",
          //   icon: ArrowClockwise,
          //   notificationCounts: getTopicNotificationCounts("subscription"),
          // },
          {
            key: "invoice",
            name: "l_invoices",
            icon: FileText,
            notificationCounts: getTopicNotificationCounts("invoice"),
          },
          // {
          //   key: "credit_notes",
          //   name: "l_credit_note",
          //   icon: File,
          //   notificationCounts: getTopicNotificationCounts("credit_notes"),
          // },
        ];
        break;
      case "third_party_integrations":
        topicList = [
          // {
          //   key: "third_party_email",
          //   name: "l_email",
          //   icon: EnvelopeSimple,
          //   notificationCounts: 0,
          // },
          // {
          //   key: "third_party_calendar",
          //   name: "l_calendar",
          //   icon: Calendar,
          //   notificationCounts: 0,
          // },
          // {
          //   key: "third_party_drive",
          //   name: "l_drive",
          //   icon: CloudArrowUp,
          //   notificationCounts: 0,
          // },
          {
            key: "wallet",
            name: ["contact", "operator"].includes(userType)
              ? "l_wallet"
              : "l_wallet",
            icon: Wallet,
            notificationCounts: 0,
          },
        ];
        if (userType === "operator") {
          topicList.push({
            key: "payout",
            name: "l_payout",
            icon: CurrencyCircleDollar,
            notificationCounts: 0,
          });
        }
        break;
      case "operator_wallet_setup":
        topicList = [
          {
            key: "wallet-settings",
            name: "l_wallet_setting",
            icon: Wallet,
            notificationCounts: 0,
          },
          {
            key: "payment-step-1",
            name: "l_business",
            icon: Buildings,
            notificationCounts: 0,
          },
          {
            key: "payment-step-2",
            name: "l_benificiaries",
            icon: UserCircle,
            notificationCounts: 0,
          },
          {
            key: "payment-step-3",
            name: "l_documents",
            icon: File,
            notificationCounts: 0,
          },
          {
            key: "postal-fields",
            name: "l_postal_details",
            icon: Note,
            notificationCounts: 0,
          },
        ];
        if (data.autoSubScribe) {
          topicList = [
            {
              key: "info-modal",
              name: "l_information",
              icon: Check,
              notificationCounts: 0,
            },
          ].concat(topicList);
        }
        if(data.walletId > 0)
        {
          topicList = topicList.filter((w) => w.key === "wallet-settings");
        }
        break;
      case "premium_estimate":
        topicList = [
          {
            key: "my_spaces",
            name: "l_my_space",
            icon: Wallet,
            notificationCounts: 0,
          },
          {
            key: "space_subscription",
            name: "l_subscriptions", 
            icon: Buildings,
            notificationCounts: 0,
          },
          {
            key: "space_invoice",
            name: "l_invoices",
            icon: UserCircle,
            notificationCounts: 0,
          },
          {
            key: "billing",
            name: "l_blling",
            icon: File,
            notificationCounts: 0,
          },
        ];
        if(isSolarHubPlatform() && userType === "operator")
        {
          topicList = topicList.filter((topic) => topic.key !== "my_spaces"); //For Provider setting menu, hide the space section as per the reporting feedback
        }

        break;
      case "edit_user":
        topicList = [
          {
            key: "edit_user_profile",
            name: "l_edit_profile",
            icon: UserGear,
            notificationCounts: 0,
          },
          {
            key: "user_email_notification",
            name: "l_email_notifications",
            icon: EnvelopeSimple,
            notificationCounts: 0,
          }
        ];
        break;
      case "edit_profile":
        topicList = [
          {
            key: "basic_details",
            name: "l_basic_details",
            icon: UserList,
            notificationCounts: 0,
          },
          // {
          //   key: "availablity_setup",
          //   name: "l_setup_avilability",
          //   icon: UserGear,
          //   notificationCounts: 0,
          // },
          {
            key: "upload_document",
            name: "l_upload_documents",
            icon: CloudArrowUp,
            notificationCounts: 0,
          },
          {
            key: "notification_preference",
            name: "l_notification_preference",
            icon: Bell,
            notificationCounts: 0,
          }
        ];
        if(["contact", "staff"].includes(userType))
        {
          topicList = topicList.filter((w) => w.key !== "upload_document");
        }
        if(userType == "operator")
        {
          topicList.push({
            key: "company_profile",
            name: "l_company_profile_label",
            icon: UserList,
            notificationCounts: 0,
          },
          {
            key: "manage_user",
            name: "l_manage_user_label",
            icon: Sparkle,
            notificationCounts: 0,
          },
          {
            key: "price_catalog",
            name: "l_price_catalog ",
            icon: CurrencyCircleDollar,
            notificationCounts: 0,
          }
          );
        }
        if(isCurrentUserAI() && (isAdminAsStaff && Object.keys(isAdminAsStaff).length && userType === "contact" && loginUserRole == 1))
        {
          topicList.push({
            key: "ai_context",
            name: "l_ai_context",
            icon: Cpu,
            notificationCounts: getTopicNotificationCounts("ai_context"),
          });
        }
        break;
      case "my_task":
        topicList = [
          {
            key: "tags",
            name: "l_tags",
            icon: UserList,
            notificationCounts: 0,
          },
          {
            key: "schedules",
            name: "l_schedules",
            icon: AddScheduleIcon,
            notificationCounts: getTopicNotificationCounts("schedules"),
          },
          {
            key: userType === "operator" ? "proposals" : "benchmarks",
            name: userType === "operator" ? "l_proposals" : "l_benchmarks",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts(userType === "operator" ? "proposals" : "benchmarks"),
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: 0,
          }
        ];
        break;
      default:
        break;
    }
    return topicList;
  };

  const connectToThirdPartyTool = async (tool, type) => {
    const left = (window.screen.width - 600) / 2;
    const top = (window.screen.height - 600) / 4;
    switch (type) {
      case "mail":
        if (tool === "Outlook") {
          window.open(
            OUTLOOK_OAUTH_URL,
            "center window",
            "resizable = yes, width=600, height=600, top=" +
              top +
              ", left=" +
              left
          );
        } else {
          try {
            const response = await CustomerServices.getMailSettings();
            if (response.data) {
              window.open(
                response.data,
                "center window",
                "resizable = yes, width=600, height=600, top=" +
                  top +
                  ", left=" +
                  left
              );
            }
          } catch (e) {
            console.error(e);
            showError("l_something_went_wrong");
          }
        }
        break;
      case "drive":
        if (tool === "Outlook") {
          OnedriveServices.oauthUrl()
            .then((res) => {
              if (res.status == 1) {
                window.open(
                  res.data,
                  "center window",
                  "resizable = yes, width=600, height=600, top=" +
                    top +
                    ", left=" +
                    left
                );
              }
            })
            .catch((e) => {
              console.error(e);
              showError("l_something_went_wrong");
            });
        } else {
          const client = new OAuth2Client(
            GOOGLE_CLIENT_ID,
            GOOGLE_CLIENT_SECRET
          );
          const url = client.generateAuthUrl({
            access_type: "offline",
            redirect_uri: window.location.origin,
            scope: GOOGLE_SERVICES_SCOPE,
            state: "google_drive",
          });

          window.open(
            url,
            "center window",
            "resizable = yes, width=600, height=600, top=" +
              top +
              ", left=" +
              left
          );
        }
        break;
      case "calendar":
        if (tool === "Outlook") {
          OutlookCalendar.oauthUrl("outlook_calendar", "", projectId, 0).then(
            (res) => {
              if (res.message == "oauth_url") {
                localStorage.removeItem("calendar_type");
                window.open(
                  res.data,
                  "center window",
                  "resizable = yes, width=600, height=600, top=" +
                    top +
                    ", left=" +
                    left
                );
              }
            }
          );
        } else {
          GoogleCalendar.createAuthUrl().then((res) => {
            if (res.status == 1) {
              var left = (window.screen.width - 600) / 2;
              var top = (window.screen.height - 600) / 4;
              window.open(
                res.data,
                "center window",
                "resizable = yes, width=600, height=600, top=" +
                  top +
                  ", left=" +
                  left
              );
            }
          });
        }
        break;
      default:
        break;
    }
  };

  const handleSelectedSpace = (e, employee_id, staff_id) => {
    let dataNew = listData;
    let index = dataNew.l_manage_user_label.findIndex(
      (emplyee) => emplyee.id == employee_id
    );
    let projectsIds = dataNew.l_manage_user_label[index].projectsIds;
    if (projectsIds.includes(e)) {
      projectsIds = projectsIds.filter((p) => p != e);
    } else {
      projectsIds.push(e);
    }
    CustomerServices.projectAssignTopeople(
      projectsIds,
      employee_id,
      staff_id
    ).then((res) => {
      if (res.status === 1) {
        dataNew.l_manage_user_label[index].projectsIds = projectsIds;
        dataNew.l_manage_user_label[index].projects = spaces.filter((p) =>
          projectsIds.includes(p.project_id)
        );
        setListData(dataNew);
        dispatch(showMessage("sucess", _l("l_succuss"), res.message));
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const getCountPerPage = (pageCount, keyName) => {
    switch (pageCount) {
      case 1:
        return keyName * 10;
      default:
        return keyName === 1 ? keyName * 3 : keyName * 10;
    }
  };

  const getTotalCountPerPage = (totalLength, pageCount, keyName) => {
    switch (totalLength) {
      case 1:
        return pageCount - ((keyName - 1) * 10 + 10);
      default:
        return pageCount - ((keyName - 1) * 10 + 3);
    }
  };

  const hideViewMoreBtn = (totalLength, pageCount, keyName) => {
    switch (totalLength) {
      case 1:
        return (
          pageCount >= (keyName - 1) * 10 + 10 &&
          pageCount != (keyName - 1) * 10 + 10
        );
      default:
        return (
          pageCount >= (keyName - 1) * 10 + 3 &&
          pageCount != (keyName - 1) * 10 + 3
        );
    }
  };

  const revokeToolAccessKey = async (item) => {
    switch (item.type) {
      case "calendar":
        if (item.tool === "Google") {
          try {
            const response = await GoogleCalendar.revoke_Googlecalendar({
              provider: "google_calendar",
              project_id: projectId,
            });
            if (response.status) {
              dispatch(showMessage("l_success"));
            } else {
              showError("l_something_went_wrong");
            }
          } catch (e) {
            showError("l_something_went_wrong");
            console.error(e);
          }
        } else {
          try {
            const response = await OutlookCalendar.revoke(projectId);
            if (response.status) {
              dispatch(showMessage("l_success"));
            } else {
              showError("l_something_went_wrong");
            }
          } catch (e) {
            showError("l_something_went_wrong");
            console.error(e);
          }
        }
        break;
      case "drive":
        if (item.tool === "Google") {
          try {
            const response = await GoogleDriveServices.revoke(projectId);
            if (response.status) {
              dispatch(showMessage("l_success"));
            } else {
              showError("l_something_went_wrong");
            }
          } catch (e) {
            showError("l_something_went_wrong");
            console.error(e);
          }
        } else {
          try {
            const response = await OnedriveServices.revoke(projectId);
            if (response.status) {
              dispatch(showMessage("l_success"));
            } else {
              showError("l_something_went_wrong");
            }
          } catch (e) {
            showError("l_something_went_wrong");
            console.error(e);
          }
        }
        break;
    }
  };

  const removeEmployee = async (id) => {
    dispatch(
      showConfirmation(
        _l("l_delete"),
        _l("l_delete_tenant_confirmation"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
          dispatch(toastCloseAction());
          try {
            const response = await CustomerServices.deleteCompanyProfileUser(
              projectId,
              id
            );
            if (response.status) {
              getPeopleList(true);
            }
          } catch (e) {
            console.error(e);
          }
        }
      )
    );
  };

  const deleteTeam = async (id) => {
    dispatch(
      showConfirmation(
        _l("l_delete"),
        _l("l_are_you_sure_you_want_to_delete_team"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
          dispatch(toastCloseAction());
          try {
            const response = await CustomerServices.deleteTeam(
              id
            );
            if (response.status) {
              setListData({...listData, l_people: listData.l_people.filter((w) => w.filter_key != id && w.main_key != "team")});
            }
          } catch (e) {
            console.error(e);
          }
        }
      )
    );
  };

  const deleteProvider = async (provider) => {
    try {
      setIsFetchingData(true);
      const response = await BenchmarkServices.removeProvider(
        projectId,
        provider.employees[0].staffid,
        provider.userid
      );
      if (response.status) {
        getProviderList();
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShowRemoveProviderConfirmation({});
      setIsFetchingData(false);
    }
  };

  const deleteProviderConfirmation = (provider) => {
    if (provider.is_existing == 2) {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_remove_provider_confirmation"),
          _l("l_delete"),
          _l("l_cancel"),
          () => {},
          () => {
            dispatch(toastCloseAction());
            deleteProvider(provider);
          }
        )
      );
    } else {
      setShowRemoveProviderConfirmation(provider);
      setOverWriteMainModal(true);
    }
  };

  const handleAddTopic = () => {
    switch (selectedTopic.key) {
      case "providers":
        window.open(
          `/connect-provider?space=${projectId}&provider-type=invite_provider`
        );
        break;
      case "people":
        window.open(
          userType === "operator"
            ? `/employees?space=${projectId}&actionType=create-team`
            : `/tenants?space=${projectId}&actionType=create-team`
        );
        break;
      case "equipments":
        window.open(
          `/equipments?space=${projectId}&action=equipment-list`,
          "_blank"
        );
        break;
      case "wallet-settings":
        walletSetupRef.current.changeSelectedTabFromParent("info-modal");
        break;
      case "payment-step-1":
        walletSetupRef.current.changeSelectedTabFromParent("wallet-settings");
        break;
      case "payment-step-2":
        walletSetupRef.current.changeSelectedTabFromParent("payment-step-1");
        break;
      case "payment-step-3":
        walletSetupRef.current.changeSelectedTabFromParent("payment-step-2");
        break;
      case "postal-fields":
        walletSetupRef.current.changeSelectedTabFromParent("payment-step-3");
        break;
      case "my_spaces":
        premiumEstimateRef.current.acceptEstimate();
        break;
      case "tags":
        setAddCustomTopicModal(true)
        break;
      default:
        break;
    }
  };
  const deleteTopicConfirmation = async (item) => {
    setDeleteCategoryData(item);
    setOverWriteMainModal(true);
  };

  const deleteTopic = async () => {
    try {
      const response = await BenchmarkServices.removeProviderCategory(
        projectId,
        deleteCategoryData.filter_key
      );
      if (response.status) {
        dispatch(getDefaultTopics());
      } else {
        showError(response.message);
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    } finally {
      setDeleteCategoryData({});
      setOverWriteMainModal(false);
    }
  };

  const prepareLightboxData = (attachmentsData, attachmentType, position) => {
    let openLightBox = true;
    let lightBoxData = attachmentsData.map((file) => ({
      src: file.image_url,
      type: getFileType(file.file_name),
    }));

    if (["l_gallery", "l_files"].includes(attachmentType)) {
      let selectedAttachment = attachmentsData[position];
      if (selectedAttachment.task_id > 0) {
        getTaskDetailsForAttachments(selectedAttachment);
        return;
      }
    }

    setOpenLightBox(openLightBox);
    setlightBoxData(lightBoxData);
  };

  const changeDocumentActivityStatus = async (
    user_id,
    document_type,
    document_id,
    category,
    position,
    checked
  ) => {
    try {
      const response = await DocumentsServices.updateDocumentActivityStatus(
        projectId,
        user_id,
        document_type,
        document_id,
        checked ? 1 : 0
      );
      if (response.status) {
        setListData({
          ...listData,
          [category]: listData[category].map((w, i) => {
            if (i === position) {
              w = { ...w, is_active: checked ? 1 : 0 };
            } else {
              w = { ...w, is_active: 0 };
            }
            return w;
          }),
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddFileInput = async (event) => {
    let filedata = {};
    if (!event.target.files || !Array(event.target.files).length) {
      return;
    }
    switch (fileUploadSectionInfo.type) {
      case "l_id_card":
        filedata = { document_type: "id_card", files: event.target.files };
        break;
      case "l_company_id":
        filedata = { document_type: "company_id", files: event.target.files };
        break;
      case "l_payment_documents":
        filedata = { document_type: "payment", files: event.target.files };
        break;
      case "l_insurance_documents":
        filedata = { document_type: "insurance", files: event.target.files };
        break;
      default:
        break;
    }
    if (filedata.document_type) {
      try {
        const response = await DocumentsServices.uploadEmployeeDocuments({
          ...filedata,
          user_staff_id: selectedCategory.id,
        });
        if (response.status) {
          setListData({
            ...listData,
            [fileUploadSectionInfo.type]: response.data.concat(
              listData[fileUploadSectionInfo.type]
            ),
          });
        } else {
          showError(response.message);
        }
      } catch (e) {
        showError("l_something_went_wrong");
      } finally {
        setFileUploadSectionInfo({});
      }
    }
  };

  const getTaskDetailsForAttachments = async (selectedAttachment) => {
    try {
      setIsFetchingData(true);

      const taskDetail = await CustomerServices.getTaskData(
        projectId,
        selectedAttachment.task_id
      );
      const taskAttachments = await CustomerServices.getAttachments(
        selectedAttachment.task_id,
        projectId
      );
      const taskComments = await DocumentsServices.getComments(
        selectedAttachment.id,
        "task"
      );

      const currentAttachment = {
        id: selectedAttachment.id,
        task_id: selectedAttachment.task_id,
        task_comment_id: selectedAttachment.task_comment_id,
        image_url: selectedAttachment.image_url,
        dateadded: selectedAttachment.dateadded,
      };

      const attachmentAdditionalDetails = taskAttachments.data.find(
        (w) => w.id == selectedAttachment.id
      );

      setAttachmentDetail({
        id: selectedAttachment.id,
        taskId: selectedAttachment.task_id,
        projectId,
        attachmentDetails: {
          ...currentAttachment,
          ...attachmentAdditionalDetails,
        },
        taskDetails: taskDetail.data,
        allAttachments: taskAttachments.data,
        comments: taskComments.data,
      });
      setOverWriteMainModal(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingData(false);
    }
  };

  const showViewAllButton = (key) => {
    const totalResults = listData[key].length;
    const remainingResults = getTotalCountPerPage(
      Object.keys(listData).length,
      searchString.trim().length
        ? listData[key].filter((item) => searchObjects(item, searchString))
            .length
        : listData[key].length,
      paginationState[key]
    );
    return totalResults !== remainingResults;
  };

  const confirmDiscardWalletSetup = () => {
    if (unsavedWalletSettings) {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_confirm_discard_wallet_setting"),
          _l("l_yes"),
          _l("l_no"),
          () => {},
          () => {
            dispatch(toastCloseAction());
            handleClose();
          }
        )
      );
    } else {
      handleClose();
    }
  };

  const closeSettingModal = () => {
    switch (data.main_key) {
      case "operator_wallet_setup":
        confirmDiscardWalletSetup();
        break;
      default:
        handleClose();
    }
  };

  const getProviderpayoutReport = async (project_ids) => {
    try {
      setIsFetchingData(true);
      let payoutProjectIds = project_ids;
      if (!payoutProjectIds.length) {
        payoutProjectIds = spaceList.map((project) => {
          return {
            label: project.office_title || project.postal_address,
            value: project.project_id,
          };
        });
      }
      const response = await DocumentsServices.getOperatorPayoutReport(
        payoutProjectIds.map((w) => w.value)
      );
      if (response.status) {
        payoutData = response.data.map((w) => {
          const space = spaceList.find((space) => space.project_id == w.payout.project_id);
          return {
            ...w,
            companyName: space ? space.company : "",
            spaceName: space ? space.office_title || space.postal_address : ""
          }
        });
        setListData({
          l_payout: payoutData,
        });
        setPaginationState({
          l_payout: 1,
        });
        setPayoutSpace(payoutProjectIds);
      } else {
        setListData({
          l_payout: [],
        });
        setPaginationState({
          l_payout: 1,
        });
        // showError(response.message);
      }
    } catch (e) {
      showError("l_something_went_wrong");
    } finally {
      setIsFetchingData(false);
    }
  };

  const cancelSubscription = (subscription, type) => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_cancel_subscription"), _l("l_yes"), _l("l_no"), () => {}, async() => {
      try {
        setIsFetchingData(true);
        dispatch(toastCloseAction());
        const response = await DocumentsServices.cancelCustomerSubscription(subscription.id, type);
        if(response.status)
        {
          if(type !== "at_period_end")
          {
            setListData({
              ...listData,
              ["l_subscriptions"]: listData["l_subscriptions"].map((w) => {
                if (w.id == subscription.id) {
                  w.status = "canceled";
                }
                return w;
              }),
            });
            showMessage("l_subscription_cancelled");
          }
          else {
            showMessage("l_subscription_cancellation_scheduled");
          }
        }
      }
      catch(e)
      {
        console.error(e);
      }
      finally {
        setIsFetchingData(false);
      }
    }))
  }

  const filterDatedRange = (dateArr) => {
    setDateRange(dateArr);
    setShowDateRangeDropDown(false);
  };

  const updatePayoutListByStatus = (statuses) => {
    if (statuses.length) {
      setPayoutStatus(statuses);
      setListData({
        ...listData,
        ["l_payout"]: payoutData.filter((w) =>
          statuses.find((s) => s.value === w.payout.status)
        ),
      });
    } else {
      setPayoutStatus([]);
      setListData({ ...listData, ["l_payout"]: payoutData });
    }
  };

  const handlerDeleteOrCancelSpace = async() => {
    try {
      dispatch(getCustomerSpaces(client_id, contact_id));
      const response = await CustomerServices.premiumEstimatePlan();
      if (response.status && response.data) {
        setPremiumEstimateData(response.data);
      }
    }
    catch(e)
    {
      console.error(e);
    }
  };

  const getProviderWalletList = async(showLoader = true) => {
    try {
      setIsFetchingData(showLoader);
      
      const response = await CustomerServices.getProviderCompanyAccounts();
      const categoryResponse = await CustomerServices.getProviderCategoriesForWallet();
      const regionsResponse = await CustomerServices.getRegionList()

      if(response.status && categoryResponse.status && regionsResponse.status)
      {
        const categories = categoryResponse.data.filter((category, index, self) => self.findIndex((w) => w.name == category.name) === index).map((w) => {
          return {value: w.provider_id , label: w.name}
        });
        const regions = regionsResponse.data.map((data) => {
          return {
            label: data.name,
            value: data.id,
          };
        });

        categories.sort((a, b) => a.label.localeCompare(b.label));

        let spaceArr = spaceList  .map((space) => {
          return {
            label: space.office_title || space.postal_address,
            value: space.project_id,
          };
        })
        setListData({
          l_wallets: response.data.map((w) => {
            return {
              id: w.id,
              title: w.title,
              is_active: w.is_active,
              acc_iban: w.account_iban,
              company_name: w.company_name,
              billing_address: w.company_address,
              invoice_tax: w.invoice_tax,
              selected_category: categories.filter((category) =>
                w.stripe_category_id.includes(category.value)
              ),
              selected_space: spaceArr.filter((space) =>
                w.stripe_project_id.includes(space.value)
              ),
              selected_region: regions.filter((region) =>
                w.stripe_region_id.includes(region.value)
              ),
            };
          }),
        });
        setPaginationState({l_wallets: 1});
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      setIsFetchingData(false);
    }
  };

  const toggleWalletStatus = async(item, checked) => {
    try {
      const response = await CustomerServices.activeInactivateWallet(item.id);
      if(response.status)
      {
        setListData({...listData, l_wallets: listData.l_wallets.map((w) => {
          if(w.id == item.id)
          {
            w.is_active = checked ? 1 : 0;
          }
          return w;
        })})
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      console.error(e);
    }
  };

  const changeNotificationPreference = async(slug, value) => {
    const setting_type = data.main_key === "company-profile" ? "company_profile" : "user_profile"
    try {
      const response = await CustomerServices.changeNotificationPreference(slug, value, setting_type);
      if(response.status > 0)
      {
        if(slug === "push_notification")
        {
          setPushNotificationPreference(value);
        }
        else {
          setEmailNotificationPreference(value);
        }
        showSuccess("l_preference_updated");
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };

  const isCurrentUserAI = (staff_id) =>{
    let flag = false;
    if(defaultTopics && defaultTopics.length > 0){
      if(userType == "contact"){
        const peopleList = defaultTopics.find((topic)=> topic.main_key == "my_contact")
        if (peopleList && peopleList.categories && peopleList.categories.find((person)=> ((person.filter_key == userId) || (staff_id > 0 && person.filter_key == staff_id)) && person.contact_role == 4)) {
          flag = true;
        }

      }

    }
    return flag;
  };

  const handleUserActiveInactive = async(check, data) => {
    try {
      const isActive = check ? 1 : 0;
      const response = await CustomerServices.handleUserActiveInactive(data.id, data.staff_id, isActive);
      if(!response.status)
      {
        setListData({...listData, ["l_manage_user_label"]: listData.l_manage_user_label.map((w) => {
          if(w.staff_id == data.staff_id)
          {
            w.active = isActive;
          }
          return w;
        })});
      }
    }
    catch(e)
    {
      console.error(e);
    }
  };

  const updateUserTags = (topic) => {
    let currentCategories = JSON.parse(JSON.stringify(categoriesList));
    currentCategories.push({
      main_key: data.main_key,
      filter_key: topic.id,
      name: _l(topic.name),
      title: _l(topic.name),
      full_name: _l(topic.name),
      main_sub_key: "custom_tag",
      profile_image_url: "",
      is_active: 1
    });
    setCategoriesList(currentCategories);
    setListData({...listData, l_tags: currentCategories});
  };

  const deleteCustomTopic = (topic) => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_delete_tag_confirmation"), _l("l_delete"), _l("l_cancel"), () => {
      dispatch(toastCloseAction());
    }, async() => {
      try {
        const response = await CustomerServices.removeTopicTag(topic.filter_key);
        if(response.status)
        {
          setListData({...listData, l_tags: listData["l_tags"].filter((tag) => tag.filter_key != topic.filter_key)});
          setCategoriesList(categoriesList.filter((category) => category.filter_key != topic.filter_key));
        }
      }
      catch(e)
      {
        console.error(e);
      }
      finally {
        dispatch(toastCloseAction());
      }
    }))
  };

  const openDPGFBenchmark = (benchmarkData) => {
    if(userType === "contact")
    {
      navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${benchmarkData.id}`); 
      handleClose();
    }
    else {
      navigate(`/view-dpgf-proposal?dpgf-proposal-id=${benchmarkData.group_id}`); 
    }
  }

  useEffect(() => {
    let searchResults = [];
    if (
      selectedTeamIds.length != 0 &&
      selectedRoleIds.length != 0 &&
      selectedSPacesIds.length != 0
    ) {
      // team role and spaces filter apply
      userList.forEach((user) => {
        user.teams.forEach((team) => {
          user.projectsIds.forEach((project) => {
            if (
              selectedTeamIds.includes(team.team_id) &&
              selectedSPacesIds.includes(project) &&
              selectedRoleIds.includes(user.role_id)
            ) {
              searchResults.push(user);
            }
          });
        });
      });
    } else if (
      selectedTeamIds.length != 0 &&
      selectedRoleIds.length != 0 &&
      selectedSPacesIds.length == 0
    ) {
      // team and role  filter apply
      userList.forEach((user) => {
        user.teams.forEach((id) => {
          if (
            selectedTeamIds.includes(id.team_id) &&
            selectedRoleIds.includes(user.role_id)
          ) {
            searchResults.push(user);
          }
        });
      });
    } else if (
      selectedTeamIds.length != 0 &&
      selectedRoleIds.length == 0 &&
      selectedSPacesIds.length != 0
    ) {
      // team and spaces filter apply
      userList.forEach((user) => {
        user.teams.forEach((team) => {
          user.projectsIds.forEach((project) => {
            if (
              selectedTeamIds.includes(team.team_id) &&
              selectedSPacesIds.includes(project)
            ) {
              searchResults.push(user);
            }
          });
        });
      });
    } else if (
      selectedTeamIds.length == 0 &&
      selectedRoleIds.length != 0 &&
      selectedSPacesIds.length != 0
    ) {
      // role and spaces filter apply
      userList.forEach((user) => {
        user.projectsIds.forEach((id) => {
          if (
            selectedSPacesIds.includes(id) &&
            selectedRoleIds.includes(user.role_id)
          ) {
            searchResults.push(user);
          }
        });
      });
    } else if (
      selectedRoleIds.length != 0 &&
      selectedSPacesIds.length == 0 &&
      selectedTeamIds.length == 0
    ) {
      // role filter apply
      userList.forEach((row) => {
        if (row && selectedRoleIds.includes(row.role_id)) {
          searchResults.push(row);
        }
      });
      setListData({ l_manage_user_label: searchResults });
    } else if (
      selectedTeamIds.length != 0 &&
      selectedRoleIds.length == 0 &&
      selectedSPacesIds.length == 0
    ) {
      // team filter apply
      userList.forEach((user) => {
        user.teams.forEach((id) => {
          if (selectedTeamIds.includes(id.team_id)) {
            searchResults.push(user);
          }
        });
      });
    } else if (
      selectedSPacesIds.length != 0 &&
      selectedRoleIds.length == 0 &&
      selectedTeamIds.length == 0
    ) {
      // spaces filter apply
      userList.forEach((user) => {
        user.projectsIds.forEach((id) => {
          if (selectedSPacesIds.includes(id)) {
            searchResults.push(user);
          }
        });
      });
    }
    setListData({ l_manage_user_label: removeDuplicateObjects(searchResults) });

    if (
      selectedTeamIds.length == 0 &&
      selectedRoleIds.length == 0 &&
      selectedSPacesIds.length == 0
    ) {
      setListData({ l_manage_user_label: userList });
    }
  }, [selectedRoleIds, selectedTeamIds, selectedSPacesIds]);

  useEffect(() => {
    if (defaultTopics && !categoriesList.length) {
      setupCategoryMenu(true);
      const topics = defaultTopics;
      const userType = localStorage.getItem("user_type");
      let providerTasksIndex = -1;
      providerTasksIndex = topics.findIndex(
        (x) => x.main_key === "providers"
      );

      if (providerTasksIndex !== -1) {
        setCategoryListing(
          topics[providerTasksIndex].categories.map((x) => {
            return {
              provider_id: parseInt(x.filter_key),
              name: x.title,
            };
          })
        );
      }
    }

    if(data.main_key === "edit_profile"){
      setSectionList(prepareTopicList())
    }
  }, [defaultTopics]);

  useEffect(() => {
    if (sectionList.length) {
      changeTopic(
        Object.keys(selectedTopic).length ? selectedTopic : sectionList[0]
      );
      setSectionList(prepareTopicList());
    }
  }, [categoriesList, JSON.stringify(filterArray), selectedCategory]);

  useEffect(() => {
    if(["my_operator", "my_contact", "my_staff"].includes(data.main_key) && sectionList.length && !categoriesList.length)
    {
      changeTopic(sectionList[0]);
    }
  }, [sectionList])
  

  useEffect(() => {
    if (sectionList.length && selectedTopic.notificationCounts > 0) {
      const category = topicData.find((w) => w.main_key == data.main_key);
      if (!category) {
        return;
      } else {
        const subTopic = category.categories.find(
          (w) => w.filter_key == selectedCategory.id
        );
        let updatedTopicList = sectionList;
        const currentSectionIndex = updatedTopicList.findIndex(
          (w) => w.key === selectedTopic.key
        );
        if (!subTopic || currentSectionIndex === -1) {
          return;
        }
        switch (selectedTopic.key) {
          case "schedules":
            updatedTopicList[currentSectionIndex] = {
              ...updatedTopicList[currentSectionIndex],
              notificationCounts:
                subTopic.total_notification_count["schedule_count"],
            };
            break;
          case "benchmarks":
          case "proposals":
            updatedTopicList[currentSectionIndex] = {
              ...updatedTopicList[currentSectionIndex],
              notificationCounts:
                subTopic.total_notification_count["schedule_count"],
            };
            break;
        }
        setSectionList(updatedTopicList);
      }
    }
  }, [JSON.stringify(topicData)]);

  useEffect(() => {

    const fetchPremiumEstimateInfo = async() => {
      try {
        setIsFetchingData(true);

        const response = await CustomerServices.premiumEstimatePlan();
        if (response.status && response.data) {
          const estimateDetails = response.data;
          setPremiumEstimateData(estimateDetails);
        }

        const contactInfoResponse = await CustomerServices.getContactsByClient();
        if (contactInfoResponse.status && contactInfoResponse.data) {
          setContactList(contactInfoResponse.data);
        }

        const estimateResponse = await CustomerServices.getPremiumEstimateDetails();
        if (estimateResponse.status && estimateResponse.data) {
          setEstimateData(estimateResponse.data);
        }

        //setProviderCategoryList
        if (defaultTopics && defaultTopics.length > 0) {
          var main_key = userType == "contact" ? "provider_task" : "proposals";
          defaultTopics.map((item) => {
            if (item.main_key == main_key) {
              setProviderCategoryList(
                item.categories.map((item1) => {
                  return {
                    value: parseInt(item1.filter_key),
                    label: item1.title,
                    is_default: item1.is_default,
                  };
                })
              );
            }
          });
        }
      }
      catch(e)
      {
        console.error(e);
      }
      finally {
        setIsFetchingData(false);
      }
    };

    let topicList = prepareTopicList();
    switch (data.main_key) {
      case "providers":
        topicList = [
          {
            key: "providers",
            name: "l_providers",
            icon: UserGear,
            notificationCounts: 0,
          },
          {
            key: "schedules",
            name: "l_schedules",
            icon: AddScheduleIcon,
            notificationCounts: getTopicNotificationCounts("schedules"),
          },
          {
            key: ["contact", "staff"].includes(userType)
              ? "benchmarks"
              : "proposals",
            name: ["contact", "staff"].includes(userType)
              ? "l_benchmarks"
              : "l_proposals",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts(
              ["contact", "staff"].includes(userType)
                ? "benchmarks"
                : "proposals"
            ),
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("documents"),
          },
        ];
        break;
      case "my_operator":
      case "my_staff":
      case "my_contact":
        topicList = [
          {
            key: "people",
            name: "l_people",
            icon: UsersThree,
          },
          {
            key: ["contact", "staff"].includes(userType)
              ? "benchmarks"
              : "proposals",
            name: ["contact", "staff"].includes(userType)
              ? "l_benchmarks"
              : "l_proposals",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts(
              ["contact", "staff"].includes(userType)
                ? "benchmarks"
                : "proposals"
            ),
          },
          {
            key: "schedules",
            name: "l_schedules",
            icon: AddScheduleIcon,
            notificationCounts: getTopicNotificationCounts("schedules"),
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("documents"),
          },
        ];
        break;
      case "equipments":
        topicList = [
          {
            key: "equipments",
            name: "l_equipments",
            icon: Wrench,
            notificationCounts: getTopicNotificationCounts("equipments"),
          },
          {
            key: ["contact", "staff"].includes(userType)
              ? "benchmarks"
              : "proposals",
            name: ["contact", "staff"].includes(userType)
              ? "l_benchmarks"
              : "l_proposals",
            icon: CreateBenchmarkIcon,
            notificationCounts: getTopicNotificationCounts(
              ["contact", "staff"].includes(userType)
                ? "benchmarks"
                : "proposals"
            ),
          },
          {
            key: "schedules",
            name: "l_schedules",
            icon: AddScheduleIcon,
            notificationCounts: getTopicNotificationCounts("schedules"),
          },
          {
            key: "documents",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("documents"),
          },
        ];
        break;
      case "documents":
        topicList = [
          {
            key: "solar_document",
            name: "l_documents",
            icon: File,
            notificationCounts: getTopicNotificationCounts("solar_document"),
          },
          {
            key: "estimate",
            name: "l_estimates",
            icon: EstimateIcon,
            notificationCounts: getTopicNotificationCounts("estimate"),
          },
          {
            key: "space",
            name: "l_space_document",
            icon: FileCloud,
            notificationCounts: getTopicNotificationCounts("space"),
          },
          {
            key: "subscription",
            name: "l_subscriptions",
            icon: ArrowClockwise,
            notificationCounts: getTopicNotificationCounts("subscription"),
          },
          {
            key: "invoice",
            name: "l_invoices",
            icon: FileText,
            notificationCounts: getTopicNotificationCounts("invoice"),
          },
          {
            key: "credit_notes",
            name: "l_credit_note",
            icon: File,
            notificationCounts: getTopicNotificationCounts("credit_notes"),
          },
        ];
        if(userType == "contact")
        {
          topicList = topicList.filter((w) => ["solar_document", "estimate", "invoice"].includes(w.key));
        }
        break;
      case "company-profile":
        topicList = [
          {
            key: "company_profile",
            name: "l_company_profile_label",
            icon: UserList,
            notificationCounts: 0,
          },
          {
            key: "manage_user",
            name: "l_manage_user_label",
            icon: Sparkle,
            notificationCounts: 0,
          },
          {
            key: "manage_company",
            name: "l_manage_companies_label",
            icon: Globe,
            notificationCounts: 0,
          },
          {
            key: "manage_roles",
            name: "l_manage_roles_label",
            icon: Sun,
            notificationCounts: 0,
          },
          {
            key: "teams",
            name: "l_manage_teams_label",
            icon: Users,
            notificationCounts: 0,
          },
          {
            key: "manage_checklist",
            name: "l_manage_checklist_label",
            icon: Check,
            notificationCounts: 0,
          },
          {
            key: "notification_preference",
            name: "l_notification_preference",
            icon: Bell,
            notificationCounts: 0,
          },
        ];
        break;
      case "operator_wallet_setup":
        topicList = [
          {
            key: "wallet-settings",
            name: "l_wallet_setting",
            icon: Wallet,
            notificationCounts: 0,
          },
          {
            key: "payment-step-1",
            name: "l_business",
            icon: Buildings,
            notificationCounts: 0,
          },
          {
            key: "payment-step-2",
            name: "l_benificiaries",
            icon: UserCircle,
            notificationCounts: 0,
          },
          {
            key: "payment-step-3",
            name: "l_documents",
            icon: File,
            notificationCounts: 0,
          },
          {
            key: "postal-fields",
            name: "l_postal_details",
            icon: Note,
            notificationCounts: 0,
          },
        ];
        if (data.autoSubScribe) {
          topicList = [
            {
              key: "info-modal",
              name: "l_information",
              icon: Check,
              notificationCounts: 0,
            },
          ].concat(topicList);
        }
        if(data.walletId > 0)
        {
          topicList = topicList.filter((w) => w.key === "wallet-settings");
        }
        break;
      case "premium_estimate":
        fetchPremiumEstimateInfo()
        break;
      default:
        break;
    }
    setSectionList(topicList);

    //If the modal is opened as part of redirection, select the topic from it

    const topicName = searchParams.get("section");

    let selectedTopicIndex = 0;

    if (topicName && topicName.length) {
      switch (topicName) {
        //For Provider Setting Modal
        case "my-providers":
          selectedTopicIndex = topicList.findIndex(
            (w) => w.key === "providers"
          );
          break;
        case "proposal":
          selectedTopicIndex = topicList.findIndex(
            (w) => w.key === "proposals"
          );
          break;
        case "benchmark":
          selectedTopicIndex = topicList.findIndex(
            (w) => w.key === "benchmarks"
          );
          break;
        case "schedule":
          selectedTopicIndex = topicList.findIndex(
            (w) => w.key === "schedules"
          );
          break;
        case "people-list":
          selectedTopicIndex = topicList.findIndex((w) => w.key === "people");
        //For People Modal
        //For Equipment Modal
        default:
          break;
      }
      changeTopic(topicList[selectedTopicIndex]);
    } else if (SET_FIRST_INDEX_DEFAULT.includes(data.main_key)) {
      if (step && step == "find_job" ) {
        changeTopic(topicList[1]);
      }else if(step && step == "document_upload"){
        changeTopic(topicList[2]);
      }else{
        changeTopic(topicList[selectedTopicIndex]);
      }
    }
    if (data.main_key == "company-profile" || data.main_key == "edit_profile") {
      CustomerServices.getAllRoles().then((res) => {
        if (res.status == 1) {
          setRoleList(res.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    switch (selectedTopic.key) {
      case "payout":
        if (dateRange.length > 1) {
          const dateFrom = moment(dateRange[0]);
          const dateTo = moment(dateRange[1]);
          const fitleredPayouts = payoutData.filter((item) => {
            const createdAt = moment(item.payout.created);
            return createdAt.isBetween(dateFrom, dateTo, null, "[]"); // '[]' includes the boundaries
          });
          setListData({ ...listData, ["l_payout"]: fitleredPayouts });
        }
        break;
      default:
        break;
    }
  }, [dateRange]);
  
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style provider-screen-modal c-modal settingModal"
        fullscreen
        centered
        backdrop={false}
        onShow={() => {
          tabindexFix();
        }}
      >
        <Modal.Body className="p-0 calendar-box-bg">
          {data.autoSubScribe ? (
            <React.Fragment></React.Fragment>
          ) : (
            <a href="#/"
              className="d-flex position-absolute ModalCloseIcon z-index-15 p-15"
              onClick={() => {
                closeSettingModal();
                dispatch(setShowIsChatScreenDisplay(false));
              }}
            >
              <XCircle
                size={45}
                weight="light"
                className="c-icons opacity-50 stroke-width-4px"
              />
            </a>
          )}
          <div className="with-modified-width m-auto w-100 h-100">
            <div className="d-flex wrapper-main h-100">
              <div className="left-part TopicModalLeftPart ModalLeftPart-BgWithShadow p-20 pe-30px d-flex z-index-2 flex-column">
                <div className="c-font f-14 fw-semibold p-10 px-0 radius_3 title-fonts w-100">
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="h20w20 d-flex align-items-cente justify-content-center me-1">
                      <GearSix size={16} weight="light" className="c-icons" />
                    </div>
                    <div className=" flex-grow-1 w-0 text-truncate c-font f-15">
                      {_l(title)} {_l(titlePrefix)}
                    </div>
                  </div>
                </div>
                <div className="border-bottom mt-10"></div>
                <div className=" flex-grow-1  overflow-auto pt-15 c-font f-15">
                  <div className="c-font f-12 color-white-60 mb-2 floor-filter">{_l("l_lists")}</div>
                  <ul className="w-100 d-flex flex-column left-part overflow-hiiden-auto comman-vertical-tabs z-index-2 list-view-toggle pe-0 mt-0 pt-1">
                    {/* <div className="base-body-color c-font color-white-80 end-0 f-12 pt-20px pb-10px position-sticky start-0 title-fonts top-0">
                      {_l("l_list")}
                    </div> */}
                    {sectionList.map((topic, key) => {
                      return (
                        <li className="comman_action_icon">
                          <a
                            key={key}
                            className={`position-relative task-sub-list w-100 p-10-15 mb-2 ${
                              topic.key === selectedTopic.key ? "active" : ""
                            }`}
                            onClick={() => {
                              if (
                                !["operator_wallet_setup"].includes(
                                  data.main_key
                                )
                              ) {
                                changeTopic(topic);
                              }
                            }}
                          >
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center flex-grow-1 w100minus35 text-truncate">
                                <div className="h32w32 d-flex align-items-center justify-content-center action_icon with_bg">
                                  <topic.icon
                                    size={18}
                                    weight={`${
                                      topic.name === "l_credit_note"
                                        ? "fill"
                                        : "light"
                                    }`}
                                    className="c-icons active-svg"
                                  />
                                </div>
                                <div className=" flex-grow-1 w-0 text-truncate ps-10px">
                                  {_l(`${topic.name}`)}
                                </div>
                              </div>
                              {topic.notificationCounts > 0 ? (
                                <div className="badge-green d-flex justify-content-center h18w18 rounded-circle">
                                  <span className="m-auto p-0">
                                    {topic.notificationCounts}
                                  </span>
                                </div>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              {/* <a href="#/" className="action_icon with_bg"><Plus size={16} weight="light" className="c-icons" /></a> */}
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="border-bottom pt-2"></div>
                  {/* Commenting as per the feedbacks of 30th october 2023 */}
                  {/* {data.main_key != "company-profile" &&
                  selectedCategory.id !== -1 &&
                  !SECTIONS_FOR_NO_ACTIVE_DEACTIVE_TOGGLE.includes(
                    data.main_key
                  ) ? (
                    <ul className="w-100 d-flex flex-column left-part overflow-hiiden-auto comman-vertical-tabs z-index-2 list-view-toggle pe-0 mt-0">
                      <div className="base-body-color c-font color-white-80 end-0 f-12 pt-20px pb-10px position-sticky  start-0 title-fonts top-0">
                        {_l("l_action")}
                      </div>
                      <div
                        className="w-100 p-10 pe-0 radius_3 activet-bg-white-03 c-font color-white-80 f-14 lh-1 comman_action_icon"
                        onClick={() =>
                          toggleTopic(
                            data.main_key,
                            selectedCategory.id,
                            selectedCategory.is_active,
                            selectedCategory.main_sub_key
                          )
                        }
                      >
                        <div className="w-100 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center flex-grow-1 w100minus35 text-truncate justify-content-between">
                            <div className="form-check-label p-0 title-font c-font f-12 color-white-60">
                              {selectedCategory.is_active > 0
                                ? `${_l("l_disable_topic")}`
                                : _l("l_activate_topic")}
                            </div>
                            <div className="form-check form-check-inline m-0 ps-2">
                              <Form.Check
                                className=" with-no-label-space"
                                type="switch"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                checked={selectedCategory.is_active > 0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )} */}
                </div>
              </div>
              <div className="right-part TopicModalRightPart ps-40px flex-grow-1 overflow-hiiden-auto d-flex flex-column">
                <div className="d-flex flex-column flex-grow-1 h-100">
                  <div className={"pe10per z-index-12"}>
                    <div className="p-20 pb-0 px-0">
                      <div
                        className={`d-flex bgpatternCommanImg ${
                          (["company-profile", "edit_profile"].includes(data.main_key) &&
                          !["manage_user", "price_catalog"].includes(selectedTopic.key) || hideFilterArea)
                            ? "justify-content-between"
                            : "justify-content-between"
                        } ${["company-profile", "edit_profile"].includes(data.main_key) && ["manage_user"].includes(selectedTopic.key) ? "comman_top_header " : ""} align-items-center w-100 bgpatternCommanImg`}
                      >
                        {(data.main_key == "company-profile" || data.main_key === "edit_profile") &&
                        selectedTopic.key == "manage_user" ? (
                          <>
                            <a href="#/" className="dropdown c-font f-14 fw-semibold title-fonts">
                              <div
                                className=" d-flex m-auto"
                                type="button"
                                id="GeneralListing"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-auto-close="outside"
                              >
                                <div className="w-100 d-flex align-items-center justify-content-between me-2 pt-1">
                                  <React.Fragment>
                                    <div className="h16w16 d-flex align-items-cente justify-content-center me-1">
                                      <Funnel
                                        size={16}
                                        weight="bold"
                                        className="c-icons"
                                      />
                                    </div>
                                    <div className=" flex-grow-1 w-0 text-truncate fw-semibold ">
                                      {_l("l_filters")}
                                    </div>
                                  </React.Fragment>
                                  <React.Fragment>
                                    <div className="h16w16 d-flex align-items-cente justify-content-center me-1">
                                      <Hash
                                        size={16}
                                        weight="bold"
                                        className="c-icons"
                                      />
                                    </div>
                                  </React.Fragment>
                                </div>
                                <CaretDown
                                  size={16}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                              <GeneralTwoLevelDropdown
                                menuOptions={categoryMenuOptions}
                                onSelectOption={applyFilterForUser}
                              />
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                        {hideFilterArea  ||
                        data.main_key == "company-profile" ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <div className=" d-flex align-items-center ">
                            <a href="#/" className="dropdown c-font f-14 fw-semibold title-fonts">
                              <div
                                className=" d-flex m-auto"
                                type="button"
                                id="GeneralListing"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-auto-close="outside"
                              >
                                <div className="w-100 d-flex align-items-center justify-content-between me-2 pt-1">
                                  {TOPICS_WITH_ALL_FILTERS.includes(
                                    data.main_key
                                  ) ? (
                                    <React.Fragment>
                                      <div className="h16w16 d-flex align-items-cente justify-content-center me-1">
                                        <Funnel
                                          size={16}
                                          weight="bold"
                                          className="c-icons"
                                        />
                                      </div>
                                      <div className=" flex-grow-1 w-0 text-truncate fw-semibold ">
                                        {_l("l_filters")}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <div className="h16w16 d-flex align-items-cente justify-content-center me-1">
                                        {TOPICS_WITH_USER_PLACEHOLDER.includes(
                                          selectedCategory.main_sub_key
                                        ) ? (
                                          <User
                                            size={16}
                                            weight="bold"
                                            className="c-icons"
                                          />
                                        ) : TOPICS_WITH_TEAM_PLACEHOLDER.includes(
                                            selectedCategory.main_sub_key
                                          ) ? (
                                          <UsersThree
                                            size={16}
                                            weight="bold"
                                            className="c-icons"
                                          />
                                        ) : (
                                          <Hash
                                            size={16}
                                            weight="bold"
                                            className="c-icons"
                                          />
                                        )}
                                      </div>
                                      <div className=" flex-grow-1 w-0 text-truncate fw-semibold ">
                                        {_l(selectedCategory.title)}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                                <CaretDown
                                  size={16}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                              {TOPICS_WITH_ALL_FILTERS.includes(
                                data.main_key
                              ) ? (
                                <GeneralTwoLevelDropdown
                                  menuOptions={categoryMenuOptions}
                                  onSelectOption={applyFilter}
                                />
                              ) : (
                                <UncontrolledGeneralListing
                                  list={categoriesList.map((category) => {
                                    if(!TOPIC_WITH_NO_FILTERS.includes(selectedTopic.key) || category.filter_key != -1)
                                    {
                                      return {
                                        name: _l(category.title),
                                        id: category.filter_key,
                                        is_active: category.is_active,
                                        main_sub_key: category.main_sub_key,
                                      };
                                    }
                                  })}
                                  handler={(e) => {
                                    setSelectedCategory({
                                      id: e.id,
                                      title: e.name,
                                      is_active: e.is_active,
                                      main_sub_key: e.main_sub_key,
                                    });
                                  }}
                                  sortList={false}
                                />
                              )}
                            </a>
                            
                          </div>
                        )}
                        {(hideSearchArea && !buttonText && !topicButtonText && !["ai_context", "notification_preference"].includes(selectedTopic.key)) 
                         ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <div
                            className={`global-search-main ${
                              ["operator_wallet_setup", "premium_estimate"].includes(data.main_key) || (userType == 'operator' && data.main_key == "edit_profile" && ["space_subscription", "space_invoice", "billing"].includes(selectedTopic.key))
                                ? ""
                                : "w_500px"
                            }`}
                          >
                            {
                              buttonText !== "" || topicButtonText !== "" ? (
                                <Form.Group className="c-input-box d-flex justify-content-end p-10 position-relative pe-0 align-items-center">
                                  {loginUserRole == 1 && contactRoleId && contactRoleId.role_Id != 4 && contactRoleId.payment_Status == 1 && adminAsStaff ? (
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      className="text-nowrap me-2"
                                      onClick={() => setViewAIContext(true)}
                                      disabled={loadingNextStep}
                                    >
                                      {_l("l_create_ai_assistant")}
                                    </Button>
                                  ) : <></>}
                                  {topicButtonText && (
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      className="text-nowrap me-2"
                                      onClick={handleAddTopic}
                                      disabled={loadingNextStep}
                                    >
                                      {_l(topicButtonText)}
                                    </Button>
                                  )}
                                  {buttonText && (
                                    <Button
                                      variant={selectedCategory.id !== -1 || ["schedules", "benchmarks", "manage_user"].includes(selectedTopic.key) ? "primary" : "secondary"} 
                                      size="sm"
                                      className="text-nowrap"
                                      onClick={handleAddAction}
                                      disabled={loadingNextStep}
                                    >
                                      {loadingNextStep
                                        ? 
                                        <>
                                          {_l("l_please_wait")}
                                          <Spinner as="span" animation="border" size="sm" role="status" className="ms-2"/>
                                        </>
                                        : _l(buttonText)}
                                    </Button>
                                  )}
                                </Form.Group>
                              ) : (
                                <></>
                              )
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {(
                    (hideSearchArea && selectedTopic.key !== 'manage_user') || 
                    TOPIC_WITH_NO_SEARCH.includes(selectedTopic.key) || 
                    (data.main_key === "premium_estimate" && selectedTopic.key === "billing" && ["contact", "staff"].includes(userType)))  ? (
                    <React.Fragment></React.Fragment>
                  ) : (
                    <div className="modal-body overflow-hidden px-0 d-flex flex-column pt-20 bg-transparent">
                      {/* <Calendar projectId={projectId}/> */}
                      {
                        ["company_profile", "notification_preference", "my_spaces" , "space_subscription" , "space_invoice"].includes(selectedTopic.key)
                        ?
                        <React.Fragment></React.Fragment>
                        :
                        <div className="position-sticky top-0 pe10per mb-15px">
                          <div
                            className={`commn-search topicsearch d-flex align-items-center justify-content-between radius_3 position-relative z-index-25 rounded-0 p-10`}
                          >
                            <div className="m-auto d-none">
                              <MagnifyingGlass
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                              
                            </div>
                            <div className="comment-input-box  flex-grow-1">
                              <input
                                type="text"
                                value={searchString}
                                onChange={(e) => {
                                  setSearchString(e.target.value);
                                }}
                                className="form-control  d-flex align-items-center border-0 comman_action_icon p-0 h-42px shadow-none"
                                placeholder={`${_l(
                                  "l_search"
                                )}`}
                              />
                            </div>
                            <a href="#/" className="comman_action_icon d-flex ">
                              <div className="d-flex action_icon h32w32 with_bg align-items-center m-auto shadow-none commn-search-bg light-theme-grey-light-bg-imp light-theme-border">
                                <MagnifyingGlass
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      }
                      {["payout"].includes(selectedTopic.key) ? (
                        <div className="row pe10per mb-3">
                          <div className="col-lg-4 c-input-box position-relative">
                            <Form.Label className="input-label no-asterisk">
                              {_l("l_select_date_range")}
                            </Form.Label>
                            <Dropdown
                              isOpen={showDateRangeDropDown}
                              className="dropdown dropdown-center z-index-3 Cleftbox"
                            >
                              <DropdownToggle
                                tag={"a"}
                                className={`custom-datepicker d-flex datepicker-hidden-input flex-column on-hover-active-toggle-img position-relative comman_action_icon form-control h_53px`}
                                data-tip={`${_l("l_date_range_filter")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <DropdownItem
                                  className="p-0 d-flex align-items-center bg-transparent"
                                >
                                  {showDateRangeDropDown ? (
                                    <DateRangeDropdown
                                      show={showDateRangeDropDown}
                                      setShow={setShowDateRangeDropDown}
                                      pageName={""}
                                      setDatesFrom={filterDatedRange}
                                      initialdates={dateRange}
                                      handleclose={() => {
                                        setShowDateRangeDropDown(false);
                                      }}
                                    />
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}

                                  <a href="#/"
                                    className="h32w32 action_icon with_bg d-flex justify-content-center align-items-cente"
                                    onClick={() => {
                                      setShowDateRangeDropDown(
                                        !showDateRangeDropDown
                                      );
                                    }}
                                  >
                                    <CalendarWithFilterIcon className="fill-transparent HW18 pe-auto c-icons" />
                                  </a>
                                  {/* <div className="color-white-60">{_l('l_range')}</div> */}
                                  <div className="d-flex align-items-center ps-10px">
                                    {dateRange.length > 1 ? (
                                      <React.Fragment>
                                        <span>{`${_l("l_from")} ${moment(
                                          dateRange[0]
                                        ).format("DD/MM/YYYY")}`}</span>
                                        <span>{`${_l("l_to")} ${moment(
                                          dateRange[1]
                                        ).format("DD/MM/YYYY")}`}</span>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </div>
                                </DropdownItem>
                              </DropdownToggle>
                            </Dropdown>
                          </div>
                          <div className="col-lg-4">
                            <Form.Group className="c-input-box position-relative">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_spaces")}
                              </Form.Label>
                              <Select
                                className="custom-select-menu"
                                classNamePrefix="react-select"
                                placeholder={`${_l("l_projects")}`}
                                options={spaceList.map((w) => {
                                  {
                                    return {
                                      label: w.office_title || w.postal_address,
                                      value: w.project_id,
                                    };
                                  }
                                })}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={true}
                                value={payoutSpace}
                                isMulti
                                // menuPlacement="top"
                                onChange={getProviderpayoutReport}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-lg-4">
                            <Form.Group className="c-input-box position-relative">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_status")}
                              </Form.Label>
                              <Select
                                className="custom-select-menu"
                                classNamePrefix="react-select"
                                placeholder={`${_l("l_status")}`}
                                isMulti
                                options={PAYOUT_STATUS}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                value={payoutStatus}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={true}
                                // menuPlacement="top"
                                onChange={updatePayoutListByStatus}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <div className="flex-grow-1 overflow-hiiden-auto pe10per">
                        {/* <PlatformList /> */}
                          <>
                            {Object.keys(listData).map((key, index) => {
                              return (
                                <div className="mb-3" key={index}>
                                  <div className=" d-flex  align-items-center bgpatternCommanImg justify-content-between position-sticky top-0 z-index-7 calendar-box-bg list-view p-10 rounded-0 shadow-none">
                                    <div className="c-font color-white-80 f-12 title-fonts">
                                      {key ==
                                        "l_manage_companies_profile" ||
                                        key == "l_manage_checklist_label"
                                        ? ""
                                        : _l(key)}
                                      {key !=
                                        "l_manage_companies_profile" &&
                                        key != "l_manage_checklist_label" &&
                                        listData[key].length > 0
                                        ? `(${searchString.trim().length
                                          ? listData[key].filter(
                                            (item) =>
                                              searchObjects(
                                                item,
                                                searchString
                                              )
                                          ).length
                                          : listData[key].length
                                        })`
                                        : ""}
                                    </div>
                                    {allowToAddNewRow.includes(key) &&
                                      selectedCategory.id > -1 ? (
                                      <a href="#/"
                                        onClick={() => {
                                          fileInputRef.current.click();
                                          setFileUploadSectionInfo({
                                            type: key,
                                          });
                                        }}
                                      >
                                        <Plus />
                                      </a>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                    {TOGGLE_BULK_TOPICS.includes(key) && selectedCategory.id == -1 ? (
                                      <div className="d-flex align-items-center flex-grow-1 w100minus35 text-truncate justify-content-end">
                                        <div className="form-check form-check-inline m-0 ps-2">
                                          <Form.Check
                                            className=" with-no-label-space"
                                            type="switch"
                                            role="switch"
                                            checked={listData[key].every(
                                              (w) => w.is_active > 0
                                            )}
                                            onChange={(e) =>
                                              handleBulkActiveInActiveTopics(
                                                key,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </div>
                                  {key != "l_manage_companies_profile" &&
                                  key != "l_manage_checklist_label" &&
                                  listData[key].length > 0 ? (
                                    <>
                                      
                                    </>
                                  ) : (
                                    <>
                                    </>
                                  )}
                                  <div className="h-100 res-padding-right0 pe-0">
                                    {["l_gallery", "l_files"].includes(key) && listData[key].length > 0 ? (
                                      <DocumentGalleryView
                                        clickHandler={(data, position) => {
                                          prepareLightboxData(
                                            listData[key],
                                            key,
                                            position
                                          );
                                        }}
                                        documents={listData[key]}
                                        key={index}
                                        previewHeightClass={"h85w115"}
                                        imagesPerPage={10}
                                      />
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                    {listData[key]
                                      .filter(
                                        (i) =>
                                          searchObjects(i, searchString) &&
                                          !["l_gallery", "l_files"].includes(
                                            key
                                          )
                                      )
                                      .slice(
                                        0,
                                        getCountPerPage(
                                          Object.keys(listData).length,
                                          paginationState[key]
                                        )
                                      )
                                      .map((item, index2) => {
                                        let pills = [];
                                        let people = [];
                                        // if (
                                        //   searchString.trim().length &&
                                        //   !searchObjects(item, searchString)
                                        // ) {
                                        //   return;
                                        // }
                                        switch (selectedTopic.key) {
                                          case "providers":
                                            if (selectedCategory.id === -1) {
                                              return (
                                                <GeneralCardView
                                                  key={index2}
                                                  hideProgressBarTooltip={true}
                                                  cardTitle={item.name}
                                                  onClick={() => {
                                                    if (
                                                      userType !== "operator"
                                                    ) {
                                                      setSelectedCategory({
                                                        id: item.filter_key,
                                                        title: item.name,
                                                        is_active:
                                                          item.is_active,
                                                        main_sub_key:
                                                          item.main_sub_key,
                                                      });
                                                    }
                                                  }}
                                                  ownerDetails={{
                                                    name: item.full_name,
                                                    profile_image:
                                                      item.profile_image_url,
                                                  }}
                                                  peopleListTitle={
                                                    "l_providers"
                                                  }
                                                  hideChatAndCall
                                                  people={
                                                    userType !== "operator" &&
                                                    item.providers
                                                      ? item.providers.map(
                                                          (w) => {
                                                            return {
                                                              ...w,
                                                              profile_image:
                                                                w.profile_image_url,
                                                            };
                                                          }
                                                        )
                                                      : []
                                                  }
                                                  ActionButton={
                                                    <div className="form-check form-check-inline m-0 ps-2">
                                                      <Form.Check
                                                        className=" with-no-label-space"
                                                        type="switch"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        checked={
                                                          item.is_active > 0
                                                        }
                                                        onChange={async () => {
                                                          await toggleTopic(
                                                            item.main_key,
                                                            item.filter_key,
                                                            item.is_active,
                                                            item.main_sub_key,
                                                            key
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  }
                                                  //setEditCategoryModalData
                                                  actionItems={
                                                    item.is_default == 1
                                                      ? []
                                                      : [
                                                          {
                                                            actionName:
                                                              _l("l_edit"),
                                                            actionHandler:
                                                              () => {
                                                                setOverWriteMainModal(
                                                                  true
                                                                );
                                                                setEditCategoryModalData(
                                                                  item
                                                                );
                                                              },
                                                            actionIcon:
                                                              "PencilSimpleLineIcon",
                                                          },
                                                          {
                                                            actionName:
                                                              _l("l_delete"),
                                                            actionHandler:
                                                              () => {
                                                                deleteTopicConfirmation(
                                                                  item
                                                                );
                                                              },
                                                            actionIcon:
                                                              "TrashIcon",
                                                          },
                                                        ]
                                                  }
                                                />
                                              );
                                            }
                                            return (
                                              <GeneralCardView
                                                key={index2}
                                                hideChatAndCall
                                                hideProgressBarTooltip={true}
                                                cardTitle={item.company}
                                                ownerDetails={{
                                                  name: item.full_name,
                                                  profile_image:
                                                    item.profile_image_url,
                                                }}
                                                pills={item.pills}
                                                peopleListTitle={"l_members"}
                                                people={
                                                  item.employees
                                                    ? item.employees.map(
                                                        (w) => {
                                                          return {
                                                            ...w,
                                                            profile_image:
                                                              w.profile_image_url,
                                                          };
                                                        }
                                                      )
                                                     : []
                                                }
                                                progress={item.score}
                                                category={data.title}
                                                showShieldIcon={
                                                  item.is_verified == 1
                                                }
                                                onClick={() => {
                                                  cardClickHandle(
                                                    selectedTopic.key,
                                                    item
                                                  );
                                                }}
                                                actionItems={[
                                                  {
                                                    actionName: _l("l_delete"),
                                                    actionHandler: () => {
                                                      deleteProviderConfirmation(
                                                        item
                                                      );
                                                    },
                                                    actionIcon: "TrashIcon",
                                                  },
                                                ]}
                                              />
                                            );
                                          case "teams":
                                            people.push({
                                              full_name: item.manager_name,
                                              profile_image:
                                                item.manager_profile_image,
                                            });
                                            item.team.forEach((member) => {
                                              people.push({
                                                full_name: `${member.firstname} ${member.lastname}`,
                                                profile_image:
                                                  member.profile_image,
                                              });
                                            });
                                            return (
                                              <GeneralCardView
                                                hideChatAndCall
                                                key={index2}
                                                cardTitle={item.name}
                                                ownerDetails={{
                                                  name: item.name,
                                                  profile_image:
                                                    item.manager_profile_image,
                                                }}
                                                pills={
                                                  item.manager_name
                                                    ? [
                                                        {
                                                          color: "color-green",
                                                          content: `${_l(
                                                            "l_manager"
                                                          )}: ${
                                                            item.manager_name
                                                          }`,
                                                        },
                                                      ]
                                                    : []
                                                }
                                                people={people}
                                                // progress={item.score}
                                                // category={data.title}
                                                // showShieldIcon={
                                                //   item.is_verified == 1
                                                // }
                                                onClick={() => {
                                                  // getSelectedTeamDetail(item.id);
                                                  navigate(
                                                    userType === "operator"
                                                      ? `/employees?space=${item.project_id}&actionType=create-team&edit=${item.id}`
                                                      : `/tenants?space=${item.project_id}&actionType=create-team&edit=${item.id}`
                                                  );
                                                }}
                                              />
                                            );
                                          case "people":
                                            if (
                                              item.role_detail &&
                                              item.role_detail.role_name
                                            ) {
                                              pills = [
                                                {
                                                  color: "color-green",
                                                  content:
                                                    item.role_detail.role_name,
                                                },
                                              ];
                                            }
                                            return (
                                              <GeneralCardView
                                                peopleListTitle={
                                                  item.people &&
                                                  item.people.length
                                                    ? "l_members"
                                                    : ""
                                                }
                                                key={index2}
                                                cardTitle={item.full_name}
                                                ownerDetails={{
                                                  name: item.full_name,
                                                  profile_image:
                                                    item.profile_image,
                                                }}
                                                pills={pills}
                                                onClick={() => {
                                                  cardClickHandle(
                                                    selectedTopic.key,
                                                    item
                                                  );
                                                }}
                                                people={
                                                  item.people &&
                                                  item.people.length
                                                    ? item.people
                                                    : []
                                                }
                                                hideChatAndCall
                                                ActionButton={
                                                  selectedCategory.id == -1 ? (
                                                    <div className="form-check form-check-inline m-0 ps-2">
                                                      <Form.Check
                                                        className=" with-no-label-space"
                                                        type="switch"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        checked={
                                                          item.is_active > 0
                                                        }
                                                        onChange={() => {
                                                          toggleTopic(
                                                            item.main_key,
                                                            item.filter_key,
                                                            item.is_active,
                                                            item.main_sub_key,
                                                            key
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <React.Fragment></React.Fragment>
                                                  )
                                                }
                                                actionItems={
                                                  contact_id == item.id ||
                                                  selectedCategory.id === -1
                                                    ? []
                                                    : [
                                                        {
                                                          actionName:
                                                            _l("l_delete"),
                                                          actionHandler: () => {
                                                            removeEmployee(
                                                              item.id
                                                            );
                                                          },
                                                          actionIcon:
                                                            "TrashIcon",
                                                        },
                                                      ]
                                                }
                                              />
                                            );
                                          case "equipments":
                                            return (
                                              <GeneralCardView
                                                key={index2}
                                                cardTitle={item.title}
                                                ownerDetails={{
                                                  name: item.title,
                                                  profile_image:
                                                    item.equipment_url,
                                                }}
                                                pills={pills}
                                                onClick={() => {
                                                  cardClickHandle(
                                                    selectedTopic.key,
                                                    item
                                                  );
                                                }}
                                                peopleListTitle={"l_members"}
                                                people={item.people.filter(
                                                  (w) =>
                                                    typeof w !== "undefined"
                                                )}
                                                actionItems={[
                                                  {
                                                    actionName: _l("l_edit"),
                                                    actionHandler: () =>
                                                      editEquipment(item),
                                                    actionIcon:
                                                      "PencilSimpleLineIcon",
                                                  },
                                                  {
                                                    actionName: _l("l_delete"),
                                                    actionHandler: () => {
                                                      deleteEquipment(
                                                        item.equipment_id,
                                                        item.id
                                                      );
                                                    },
                                                    actionIcon: "TrashIcon",
                                                  },
                                                ]}
                                                hideChatAndCall
                                                ActionButton={
                                                  selectedCategory.id ===
                                                    -1 && (
                                                    <div className="form-check form-check-inline m-0 ps-2">
                                                      <Form.Check
                                                        className=" with-no-label-space"
                                                        type="switch"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        checked={
                                                          item.is_active > 0
                                                        }
                                                        onChange={async () => {
                                                          await toggleTopic(
                                                            item.main_key,
                                                            item.filter_key,
                                                            item.is_active,
                                                            item.main_sub_key,
                                                            key
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                }
                                              />
                                            );
                                          case "schedules":
                                          case "benchmarks":
                                          case "proposals":
                                          case "dpgf":
                                          case "contracts":
                                            if(location.pathname === "/dashboard" || selectedTopic.key === "dpgf")
                                            {
                                              return (
                                                <GeneralCardView
                                                  cardTitle={`#${_l("l_dpgf")} - ${item.id}`}
                                                  ownerDetails={{
                                                    name: `#${_l("l_dpgf")} - ${item.id}`
                                                  }}
                                                  hideChatAndCall={true}
                                                  onClick={() => openDPGFBenchmark(item)}
                                                  ActionButton={
                                                    <div className="custom-datepicker w-100">
                                                      <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100    react-datepicker-wrapper-w-100 comman_action_icon p-0">
                                                        <div className="action_icon h32w32 with_bg">
                                                          <CalendarBlank
                                                            size={18}
                                                            weight="light"
                                                            className="c-icons opacity-60"
                                                          />
                                                        </div>
                                                        <DatePicker
                                                          className="ps-2 w-100"
                                                          disabledKeyboardNavigation
                                                          dateFormat="dd/MM/yyyy"
                                                          selectsStart
                                                          defaultMenuIsOpen
                                                          calendarStartDay={1}
                                                          portalId="custom_datepicker"
                                                          calendarClassName="custom-datepicker"
                                                          readOnly={true}
                                                          selected={item.created_on ? new Date(item.created_on) : new Date()}
                                                        />
                                                      </div>
                                                    </div>
                                                    }
                                                />
                                              )
                                            }
                                            else {
                                              return (
                                                <ScheduleCard
                                                  key={index2}
                                                  aiChatAssistURL={`/aichat?rel_type=${selectedTopic.key}&rel_id=${item.id}&project_id=${projectId}`}
                                                  active={false}
                                                  benchmark={item}
                                                  handleBenchmarkModal={() => {
                                                    cardClickHandle(
                                                      selectedTopic.key,
                                                      item,
                                                    );
                                                  }}
                                                  reduceBadgeCount={updateProposalBadgeCount}
                                                  handleDeleteBenchmark={
                                                    deleteBenchmark
                                                  }
                                                  handleStopBenchmark={
                                                    stopBenchmark
                                                  }
                                                  handler={() => {}}
                                                />
                                              );
                                            }
                                          case "google_drive":
                                          case "solar_document":
                                            return (
                                              <TaskCard
                                                key={index2}
                                                task={item}
                                                project_id={projectId}
                                                handler={() =>
                                                  // openDocumentDetailView(
                                                  //   item,
                                                  //   key
                                                  // )
                                                  {
                                                    window.open( item.file_preview_url ? item.file_preview_url : item.file_url , "_blank")
                                                  }
                                                }
                                                pageName="task"
                                                driveDocument={true}
                                              />
                                            );
                                          case "estimate":
                                          case "space":
                                          case "space_subscription":
                                          case "subscription":
                                          case "space_invoice":
                                          case "invoice":
                                          case "credit_notes":
                                          case "documents":
                                            let options = [];
                                            if (
                                              key.replace("l_", "") ===
                                              "documents"
                                            ) {
                                              return (
                                                <TaskCard
                                                  key={index2}
                                                  task={item}
                                                  project_id={projectId}
                                                  handler={() => {
                                                    setOverWriteMainModal(true);
                                                  }}
                                                  pageName="task"
                                                />
                                              );
                                            } else if (
                                              [
                                                "l_id_card",
                                                "l_company_id",
                                                "l_payment_documents",
                                                "l_insurance_documents",
                                              ].includes(key)
                                            ) {
                                              return (
                                                <GeneralCardView
                                                  key={index2}
                                                  cardTitle={`${
                                                    item.task_name ||
                                                    item.file_name
                                                  }`}
                                                  ownerDetails={{
                                                    name:
                                                      item.task_name ||
                                                      item.file_name,
                                                    profile_image:
                                                      getPreviewFromURL(
                                                        item.image_url
                                                      ),
                                                  }}
                                                  pills={[
                                                    {
                                                      color: "color-green",
                                                      content: `${moment(
                                                        item.dateadded
                                                      ).format("ll")}`,
                                                    },
                                                  ]}
                                                  onClick={() => {
                                                    prepareLightboxData(
                                                      listData[key],
                                                      key,
                                                      index2
                                                    );
                                                  }}
                                                  actionItems={[]}
                                                  hideChatAndCall={true}
                                                  ActionButton={
                                                    [
                                                      "l_id_card",
                                                      "l_company_id",
                                                      "l_payment_documents",
                                                      "l_insurance_documents",
                                                    ].includes(key) ? (
                                                      <div className="form-check form-check-inline m-0 ps-2">
                                                        <Form.Check
                                                          className=" with-no-label-space"
                                                          type="switch"
                                                          role="switch"
                                                          id="flexSwitchCheckChecked"
                                                          checked={
                                                            item.is_active > 0
                                                          }
                                                          onChange={(e) => {
                                                            changeDocumentActivityStatus(
                                                              item.staffid,
                                                              item.rel_type,
                                                              item.id,
                                                              key,
                                                              index2,
                                                              e.target.checked
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <React.Fragment></React.Fragment>
                                                    )
                                                  }
                                                />
                                              );
                                            } else if (
                                              ["l_gallery", "l_files"].includes(
                                                key
                                              )
                                            ) {
                                              return <></>;
                                            }
                                            if(userType === "contact" && (selectedTopic.key === "subscription" || key === "l_subscriptions") && item.status == "active" && [1, 2].includes(Number(loginUserRole)))
                                            {
                                              if(item.payment_mode == "stripe")
                                              {
                                                options = [
                                                  {
                                                    actionName: _l("l_cancel_immediately"),
                                                    actionHandler: () => {
                                                      cancelSubscription(item, "immediately");
                                                    },
                                                    actionIcon: "ProhibitIcon",
                                                  },
                                                  {
                                                    actionName: _l("l_cancel__at_period_end"),
                                                    actionHandler: () => {
                                                      cancelSubscription(item, "at_period_end");
                                                    },
                                                    actionIcon: "FIleXicon",
                                                  },
                                                ]
                                              }
                                              else {
                                                options = [
                                                  {
                                                    actionName: _l("l_cancel"),
                                                    actionHandler: () => {
                                                      cancelSubscription(item);
                                                    },
                                                    actionIcon: "ProhibitIcon",
                                                  },
                                                ]
                                              }
                                            }

                                            return (
                                              <CardView
                                                hideShow={() => {
                                                  openDocumentDetailView(
                                                    item,
                                                    key
                                                  );
                                                }}
                                                docType={
                                                  key === "l_space_document"
                                                    ? "spaceDocument"
                                                    : key.replace("l_", "")
                                                }
                                                setDocType={() => {}}
                                                pinHandler={() => {}}
                                                User_2={""}
                                                data={item}
                                                selectedId={0}
                                                key={index2}
                                                setSelectedId={() => {}}
                                                aiChatAssistURL={`/aichat?rel_type=${selectedTopic.key}&rel_id=${item.id}&project_id=${projectId}`}
                                                actionItems={options}
                                              />
                                            );
                                          case "third_party_calendar":
                                          case "third_party_email":
                                          case "third_party_drive":
                                            return (
                                              <GeneralCardView
                                                key={index2}
                                                cardTitle={item.name}
                                                ownerDetails={{
                                                  name: item.name,
                                                  profile_image: item.logo,
                                                }}
                                                pills={[]}
                                                onClick={() => {}}
                                                people={[]}
                                                hideChatAndCall
                                                ActionButton={
                                                  item.connected ? (
                                                    <React.Fragment>
                                                      {item.type == "" ? (
                                                        <></>
                                                      ) : (
                                                        <Button
                                                          variant="danger"
                                                          size="sm"
                                                          onClick={() => {
                                                            revokeToolAccessKey(
                                                              item
                                                            );
                                                          }}
                                                        >
                                                          {_l("l_revoke")}
                                                        </Button>
                                                      )}
                                                    </React.Fragment>
                                                  ) : (
                                                    <div className="d-flex">
                                                      {item.type ===
                                                      "calendar" ? (
                                                        <div class="form-check with_separator_10 d-none">
                                                          <input
                                                            id="isAllowAllSpaces"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              item.all_spaces
                                                            }
                                                            onChange={(e) => {
                                                              setListData({
                                                                ...listData,
                                                                [key]:
                                                                  Object.values(
                                                                    {
                                                                      ...listData[
                                                                        key
                                                                      ],
                                                                      [index2]:
                                                                        {
                                                                          ...listData[
                                                                            key
                                                                          ][
                                                                            index2
                                                                          ],
                                                                          all_spaces:
                                                                            e
                                                                              .target
                                                                              .checked,
                                                                        },
                                                                    }
                                                                  ),
                                                              });
                                                            }}
                                                          />
                                                          <label
                                                            class="form-check-label"
                                                            for="flexCheckDefault"
                                                          >
                                                            {_l(
                                                              "l_is_allow_for_all_spaces"
                                                            )}
                                                          </label>
                                                        </div>
                                                      ) : (
                                                        <React.Fragment></React.Fragment>
                                                      )}
                                                      <div className="with_separator2_10 ps-0 me-10px"></div>
                                                      <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        className=""
                                                        onClick={() => {
                                                          connectToThirdPartyTool(
                                                            item.tool,
                                                            item.type
                                                          );
                                                        }}
                                                      >
                                                        {_l("l_connect")}
                                                      </Button>
                                                    </div>
                                                  )
                                                }
                                              />
                                            );
                                          case "wallet":
                                            return (
                                              <TransactionCard
                                                setDocumentMiniModalData={
                                                  openDocumentDetailView
                                                }
                                                getTransacitonDetails={() => {}}
                                                handleShow={() => {
                                                  openDocumentDetailView(
                                                    item,
                                                    key
                                                  );
                                                }}
                                                isManualInvoices={false}
                                                transaction={item}
                                                uploadInvoice={() => {}}
                                                key={index2}
                                                aiChatAssistURL={`/aichat?rel_type=${selectedTopic.key}&rel_id=${item.id}&project_id=${projectId}`}
                                              />
                                            );
                                          case "manage_user":
                                            let _actionItems;
                                            if(item.role_id == 4)
                                            { 
                                              _actionItems = [];
                                            }
                                            else {
                                              _actionItems =
                                              item.password_set_link &&
                                              item.password_set_link.length
                                                ? [
                                                    {
                                                      actionName: _l("l_copy"),
                                                      actionIcon: "CopyIcon",
                                                      actionHandler: () =>
                                                        copyToClipboard(
                                                          item.password_set_link
                                                        ),
                                                    },
                                                    {
                                                      actionName:
                                                        _l("l_delete"),
                                                      actionHandler: () => {
                                                        dispatch(
                                                          showConfirmation(
                                                            _l(
                                                              "l_are_you_sure"
                                                            ),
                                                            _l(
                                                              "l_you_want_to_delete_this_user"
                                                            ),
                                                            _l("l_yes"),
                                                            _l("l_no"),
                                                            undefined,
                                                            () => {
                                                              deleteUser(
                                                                item.id
                                                              );
                                                            }
                                                          )
                                                        );
                                                      },
                                                      actionIcon: "TrashIcon",
                                                    },
                                                  ]
                                                : [
                                                    {
                                                      actionName:
                                                        _l("l_delete"),
                                                      actionHandler: () => {
                                                        dispatch(
                                                          showConfirmation(
                                                            _l(
                                                              "l_are_you_sure"
                                                            ),
                                                            _l(
                                                              "l_you_want_to_delete_this_user"
                                                            ),
                                                            _l("l_yes"),
                                                            _l("l_no"),
                                                            undefined,
                                                            () => {
                                                              deleteUser(
                                                                item.id
                                                              );
                                                            }
                                                          )
                                                        );
                                                      },
                                                      actionIcon: "TrashIcon",
                                                    },
                                                  ];
                                            }
                                            return (
                                              <GeneralCardView
                                                selectedTopic={
                                                  selectedTopic.key
                                                }
                                                key={index2}
                                                cardTitle={item.full_name}
                                                ownerDetails={{
                                                  name: "",
                                                  profile_image:
                                                    item.profile_image,
                                                }}
                                                teamName={item.teams}
                                                roleDetail={item.roleDetail}
                                                pills={
                                                  item.is_psw_set == 0
                                                    ? [
                                                        {
                                                          color: "color-green",
                                                          content:
                                                            _l("l_pending"),
                                                        },
                                                      ]
                                                    : []
                                                }
                                                projects={
                                                  item.projects &&
                                                  item.projects.map((p) => {
                                                    return {
                                                      content: p.office_title,
                                                      color: "",
                                                    };
                                                  })
                                                }
                                                onClick={() => {
                                                  if (
                                                    item.staff_id &&
                                                    item.staff_id != null &&
                                                    item.staff_id > 0 &&
                                                    (loginUserRole <= item &&
                                                    item.role_detail
                                                      ? item.role_detail.role_id
                                                      : -1 &&
                                                        userType != "staff")
                                                  ) {
                                                    navigate(
                                                      `/myprofile?user=${item.staff_id}`
                                                    );
                                                    handleClose();
                                                  } else {
                                                    showError(
                                                      _l(
                                                        "l_staff_id_missing_for_profile_cant_edit_this_user"
                                                      )
                                                    );
                                                  }
                                                }}
                                                people={[]}
                                                hideChatAndCall
                                                ActionSelection={
                                                  <>
                                                    <div>
                                                         <UncontrolledDropdown
                                                      key={index2}
                                                      isOpen={
                                                        openSpaceListDropdown
                                                      }
                                                      className={`comman_action_icon ${
                                                        item.roleDetail && item.roleDetail.role_id == 1
                                                          ? "for-disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      <DropdownToggle
                                                        className="action_icon with_bg h32w32 justify-content-center align-items-center p-0"
                                                        type="button"
                                                        data-tip={`${_l(
                                                          "l_all_spaces"
                                                        )}`}
                                                        data-effect="solid"
                                                        data-delay-show='1000'
                                                        data-class="tooltip-main"
                                                        id="SapceListMainDropdown"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                        // data-bs-auto-close="true"
                                                        onClick={() => {
                                                          setOpenSpaceListDropdown(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        <div
                                                          className={`on-hover-active-toggle-img text-on-hover-active-green d-flex w-100 h-100  ${
                                                            item.projects && item.projects.length > 0
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          <MapPin
                                                            size={18}
                                                            className="c-icons"
                                                            weight="light"
                                                          />
                                                        </div>
                                                      </DropdownToggle>
                                                      <SpaceListDropdown
                                                        openSpaceListDropdown={
                                                          openSpaceListDropdown
                                                        }
                                                        SpaceList={spaces}
                                                        setOpenSpaceListDropdown={
                                                          setOpenSpaceListDropdown
                                                        }
                                                        handler={(space) => {
                                                          handleSelectedSpace(
                                                            space.project_id,
                                                            item.id,
                                                            item.staff_id
                                                          );
                                                        }}
                                                        selectedSpace={
                                                          item.projectsIds
                                                        }
                                                      />
                                                    </UncontrolledDropdown>
                                                    </div>
                                                    <div>
                                                      <Form.Check
                                                        className=" with-no-label-space mx-2"
                                                        type="switch"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        checked={
                                                          item.active > 0
                                                        }
                                                        disabled={staff_id == item.staff_id}
                                                        onChange={(e) => {handleUserActiveInactive(e.target.checked, item)}}
                                                      />
                                                    </div>
                                                  </>
                                                }
                                                actionItems={_actionItems}
                                                active={item.active > 0}
                                              />
                                            );
                                          case "manage_company":
                                            return (
                                              <GeneralCardView
                                                selectedTopic={
                                                  selectedTopic.key
                                                }
                                                key={index2}
                                                cardTitle={item.office_title}
                                                ownerDetails={{
                                                  name: "",
                                                  profile_image: "",
                                                }}
                                                managerSelection={
                                                  <a href="#/" className="before-dnone comman_action_icon with_separator_10 card-view-none pe-0" onClick={(e) => e.stopPropagation()}>
                                                    <div className="d-flex align-items-center">
                                                      <div
                                                        className="action_icon with_bg h32w32 rounded-circle on-hover-active-toggle-img"
                                                        onClick={() => {
                                                          setShowManagerModal(
                                                            true
                                                          );
                                                          setOverWriteMainModal(
                                                            true
                                                          );
                                                          setSelectedProjectId(
                                                            item.projectId
                                                          );
                                                        }}
                                                        data-tip={`${_l(
                                                          "l_add_assignee_manage_company"
                                                        )}`}
                                                        data-effect="solid"
                                                        data-delay-show='1000'
                                                        data-class="tooltip-main"
                                                      >
                                                        <Plus
                                                          size={18}
                                                          weight="light"
                                                          className="c-icons"
                                                        />
                                                      </div>
                                                    </div>
                                                  </a>
                                                }
                                                onClick={() => {
                                                  navigate(
                                                    `/explore-features?space=${item.projectId}`
                                                  );
                                                  dispatch(
                                                    setProject(item.projectId)
                                                  );
                                                  localStorage.setItem(
                                                    "selectedOffice",
                                                    item.projectId
                                                  );
                                                  handleClose();
                                                }}
                                                people={item.members}
                                                managers={item.managers}
                                                hideChatAndCall
                                                providerList={item.my_providers}
                                                peopleListTitle={"members"}
                                                actionItems={[
                                                  {
                                                    actionName: _l(
                                                      "l_invoice_separately"
                                                    ),
                                                    actionHandler: () => {
                                                      navigate(
                                                        "/premiumsubscription?selectedTab=billing"
                                                      );
                                                    },
                                                    actionIcon:
                                                      "PencilSimpleLineIcon",
                                                  },
                                                ]}
                                              />
                                            );
                                          case "manage_roles":
                                            return (
                                              <GeneralCardView
                                                key={index2}
                                                cardTitle={item.name}
                                                ownerDetails={{
                                                  name: "",
                                                  profile_image: "",
                                                }}
                                                pills={[]}
                                                onClick={() => {
                                                  setSelectedRole(item);
                                                  setOverWriteMainModal(true);
                                                  setShowEditRole(true);
                                                }}
                                                people={item.employees}
                                                ActionButton={
                                                  item.is_default > 0 ? (
                                                    <div className="comman_action_icon with_separator_10 pe-0">
                                                      <a href="#/"
                                                        className="action_icon with_bg h32w32  on-hover-active-toggle-img light-theme-img"
                                                        onClick={() => {
                                                          setSelectedRole(item);
                                                          setViewPermissions(
                                                            true
                                                          );
                                                          setOverWriteMainModal(
                                                            true
                                                          );
                                                        }}
                                                        data-tip={_l(
                                                          "l_view_permissions"
                                                        )}
                                                        data-effect="solid"
                                                        data-delay-show='1000'
                                                        data-class="tooltip-main"
                                                      >
                                                        <Eye
                                                          size={18}
                                                          weight="light"
                                                          className="c-icons m-auto"
                                                        />
                                                      </a>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )
                                                }
                                                hideChatAndCall
                                                actionItems={[
                                                  {
                                                    actionName: _l("l_delete"),
                                                    actionHandler: () => {
                                                      dispatch(
                                                        showConfirmation(
                                                          _l("l_are_you_sure"),
                                                          _l(
                                                            "l_are_you_sure_you_want_to_delete_role"
                                                          ),
                                                          _l("l_yes"),
                                                          _l("l_no"),
                                                          undefined,
                                                          () => {
                                                            handleDeleteRole(
                                                              item
                                                            );
                                                          }
                                                        )
                                                      );
                                                    },
                                                    actionIcon: "TrashIcon",
                                                  },
                                                ]}
                                              />
                                            );
                                          case "company_profile":
                                            return (
                                              <Companyprofilestep setShowInfoModal="" setPushNotificationPreference={setPushNotificationPreference} setEmailNotificationPreference={setEmailNotificationPreference} />
                                            );
                                          case "manage_checklist":
                                            return (
                                              <ManageChecklistStep
                                                categoryList={categoryListing}
                                                checklists={checklists}
                                                setSelectCategory={(
                                                  provider_id
                                                ) => {
                                                  getChecklist(provider_id);
                                                }}
                                                setChecklists={setChecklists}
                                              />
                                            );
                                          case "myr_support":
                                            return (
                                              <GeneralCardView
                                                peopleListTitle={"l_members"}
                                                key={index2}
                                                cardTitle={item.title}
                                                ownerDetails={{
                                                  name: item.title,
                                                  profile_image: item.image,
                                                  userType: item.main_sub_key,
                                                  isAdmin: item.role,
                                                }}
                                                pills={[
                                                  {
                                                    color:
                                                      item.main_sub_key ==
                                                      "contact"
                                                        ? "color-blue"
                                                        : "color-green",
                                                    content: item.main_sub_key,
                                                  },
                                                ]}
                                                onClick={() => {
                                                  cardClickHandle(
                                                    selectedTopic.key,
                                                    item
                                                  );
                                                }}
                                                ActionButton={
                                                  selectedCategory.id == -1 ? (
                                                    <div className="form-check form-check-inline m-0 ps-2">
                                                      <Form.Check
                                                        className=" with-no-label-space"
                                                        type="switch"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        checked={
                                                          item.is_active > 0
                                                        }
                                                        onChange={() => {
                                                          toggleTopic(
                                                            item.main_key,
                                                            item.filter_key,
                                                            item.is_active,
                                                            item.main_sub_key,
                                                            key
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <React.Fragment></React.Fragment>
                                                  )
                                                }
                                                actionItems={
                                                  contact_id == item.id ||
                                                  selectedCategory.id === -1
                                                    ? []
                                                    : [
                                                        {
                                                          actionName:
                                                            _l("l_delete"),
                                                          actionHandler: () => {
                                                            removeEmployee(
                                                              item.id
                                                            );
                                                          },
                                                          actionIcon:
                                                            "TrashIcon",
                                                        },
                                                      ]
                                                }
                                              />
                                            );
                                          case "payout":
                                            pills = [
                                              {
                                                color: "color-green",
                                                content: `€${item.payout.db_amount}`,
                                              },
                                              {
                                                color: "color-green",
                                                content: moment(
                                                  item.payout.created
                                                ).format("DD/MM/YYYY"),
                                                tooltip: `${_l(
                                                  "l_creation_date"
                                                )}: ${moment(
                                                  item.payout.created
                                                ).format("DD/MM/YYYY")}`,
                                              },
                                              {
                                                color: "color-green",
                                                content: item.payout.status,
                                              },
                                              {
                                                color: "color-blue",
                                                content: moment(
                                                  item.payout.arrival_date
                                                ).format("DD/MM/YYYY"),
                                                tooltip: `${_l(
                                                  "l_arrival_date"
                                                )}: ${moment(
                                                  item.payout.arrival_date
                                                ).format("DD/MM/YYYY")}`,
                                              },
                                            ];
                                            return (
                                              <GeneralCardView
                                                key={index2}
                                                cardTitle={`#${index2 + 1} - ${
                                                  item.companyName
                                                } - ${item.spaceName}`}
                                                ownerDetails={{
                                                  name: `${item.payout.id} ${item.payout.description}`,
                                                  profile_image: "",
                                                }}
                                                pills={pills}
                                                onClick={() => {
                                                  cardClickHandle();
                                                }}
                                                aiChatAssistURL={`/aichat?rel_type=${selectedTopic.key}&rel_id=${item.id}&project_id=${projectId}`}
                                                hideChatAndCall
                                              />
                                            );
                                          case "my_spaces":
                                            return (
                                              <PremiumSpaceCard
                                                premiumPlanInfo={premiumEstimateData && premiumEstimateData.estimate && premiumEstimateData.estimate.items ? premiumEstimateData.estimate.items : []}
                                                space={item}
                                                handlerDeleteOrCancelSpace={() => {}}
                                                estimateId={0}
                                                setSpaceDetails={() => {}}
                                                hideShow={() => {
                                                  openDocumentDetailView(
                                                    item,
                                                    "premiumEstimate"
                                                  );
                                                }}

                                              />
                                            );
                                          case "billing":
                                            return (
                                              <GeneralCardView
                                                hideChatAndCall
                                                key={index2}
                                                ownerDetails={{
                                                  name: item.title,
                                                  profile_image: ""
                                                }}
                                                cardSubTitleText={item.company_address}
                                                cardTitle={`#${item.title} - ${item.acc_iban}`}
                                                pills={[
                                                  {
                                                    color: "color-blue",
                                                    content: `${_l("l_spaces")}: ${item && item.selected_space && item.selected_space.map((w) => w.label).join(",")}`,
                                                    tooltip: `${item.selected_space.map((w) => w.label).join(",")}`
                                                  },
                                                  {
                                                    color: "color-green",
                                                    content: `${_l("l_categories")}: ${item.selected_category.map((w) => w.label).join(",")}`,
                                                    tooltip: `${item.selected_category.map((w) => w.label).join(",")}`
                                                  },
                                                  {
                                                    color: "color-blue",
                                                    content: `${_l("l_regions")}: ${item.selected_region.map((w) => w.label).join(",")}`,
                                                    tooltip: `${item.selected_region.map((w) => w.label).join(",")}`
                                                  },
                                                ]}
                                                onClick={() => {
                                                  cardClickHandle("wallet", item);
                                                }}
                                                ActionButton={
                                                 (
                                                  <div className="form-check form-check-inline m-0 ps-2">
                                                    <Form.Check
                                                      className=" with-no-label-space"
                                                      type="switch"
                                                      role="switch"
                                                      id="flexSwitchCheckChecked"
                                                      checked={
                                                        item.is_active > 0
                                                      }
                                                      onChange={(e) => {toggleWalletStatus(item, e.target.checked)}}
                                                    />
                                                  </div>
                                                )}
                                              />
                                            );
                                          case "tags":
                                            return (
                                              <GeneralCardView
                                                key={index2}
                                                cardTitle={item.full_name}
                                                ownerDetails={{
                                                  name: item.full_name
                                                }}
                                                pills={[]}
                                                onClick={() => {}}
                                                hideChatAndCall
                                                ActionButton={
                                                    <div className="form-check form-check-inline m-0 ps-2">
                                                      <Form.Check
                                                        className=" with-no-label-space"
                                                        type="switch"
                                                        role="switch"
                                                        disabled={item.disabled}
                                                        checked={item.is_active > 0}
                                                        onChange={() => {
                                                          toggleTopic(
                                                            item.main_key,
                                                            item.filter_key,
                                                            item.is_active,
                                                            item.main_sub_key,
                                                            key
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                }
                                                actionItems={
                                                  item.disabled
                                                    ? []
                                                    : [
                                                        {
                                                          actionName:
                                                            _l("l_delete"),
                                                          actionHandler:
                                                            () => {deleteCustomTopic(item)},
                                                          actionIcon:
                                                            "TrashIcon",
                                                        },
                                                      ]
                                                }
                                              />
                                            );
                                        }
                                      })}
                                    {!listData[key].length &&
                                    (![
                                      "company_profile",
                                      "manage_checklist",
                                    ].includes(selectedTopic.key) ||
                                      !["l_gallery", "l_files"].includes(
                                        key
                                      )) ? (
                                      <CommanPlaceholder
                                        mainSpacing="bg-white-03 p-0"
                                        imgWidth="big-image h95 d-none"
                                        isImageOpacity="opacity-50"
                                        placeholderTextClass="c-font f-16 pt-0 opacity30"
                                        imgType="no-benchmark"
                                        placeholderText={_l(
                                          `l_no_${selectedTopic.key}`
                                        )}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {!["l_gallery", "l_files"].includes(key) &&
                                    hideViewMoreBtn(
                                      Object.keys(listData).length,
                                      searchString.trim().length
                                        ? listData[key].filter((item) =>
                                            searchObjects(item, searchString)
                                          ).length
                                        : listData[key].length,
                                      paginationState[key]
                                    ) ? (
                                      <div className="d-flex justify-content-center">
                                        <a href="#/"
                                          className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                                          onClick={() => {
                                            setPaginationState({
                                              ...paginationState,
                                              [key]: paginationState[key] + 1,
                                            });
                                          }}
                                        >
                                          <span className="title-fonts d-flex justify-content-center align-item-center ">
                                            <div className="d-flex align-item-center justify-content-center">
                                              {_l("l_view_more_btn")} (
                                              {getTotalCountPerPage(
                                                Object.keys(listData).length,
                                                searchString.trim().length
                                                  ? listData[key].filter(
                                                      (item) =>
                                                        searchObjects(
                                                          item,
                                                          searchString
                                                        )
                                                    ).length
                                                  : listData[key].length,
                                                paginationState[key]
                                              )}
                                              )
                                              <CaretDoubleDown
                                                size={14}
                                                weight="light"
                                                className="c-icons mx-2"
                                              />
                                              <span
                                                role="status"
                                                aria-hidden="true"
                                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                              ></span>
                                            </div>
                                          </span>
                                        </a>
                                        {showViewAllButton(key) ? (
                                          <a href="#/"
                                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                                            onClick={() => {
                                              viewAll(key);
                                            }}
                                          >
                                            <span className="title-fonts d-flex justify-content-center align-item-center ">
                                              <div className="d-flex align-item-center justify-content-center">
                                                {_l("l_view_all")}
                                              </div>
                                            </span>
                                          </a>
                                        ) : (
                                          <React.Fragment></React.Fragment>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {/* Gallery View */}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                      </div>
                    </div>
                  )}
                  {
                    data.main_key === "premium_estimate" && selectedTopic.key === "billing" && ["contact", "staff"].includes(userType)
                    ?
                    <div className="comman-data-table h-100 d-flex flex-column mt-3">
                        <PremiumBillingTable
                          BillingsData={ibanList}
                          IBANStatus={ibanStatus}
                          handlerIBAN={() => {}}
                        />
                    </div>
                    :
                    <React.Fragment></React.Fragment>
                  }
                  {
                    data.main_key === "premium_estimate" && selectedTopic.key === "my_spaces"
                    ? <></>
                      // <PremiumMyspace
                      //   PremiumEstimatDetails={premiumEstimateData}
                      //   setPremiumEstimatDetails={setPremiumEstimateData}
                      //   contacts={contactList}
                      //   estimate={estimateData}
                      //   setEstimate={setEstimateData}
                      //   handler={getUserSpaceList}
                      //   handlerDeleteOrCancelSpace={handlerDeleteOrCancelSpace}
                      //   projectId={projectId}
                      //   categoryListing={providerCategoryList}
                      //   fromSettingModal={true}
                      //   ref={premiumEstimateRef}
                      // />
                    :
                    <React.Fragment></React.Fragment>
                  }
                  {
                    selectedTopic.key === "ai_context" && ((data.main_key === "providers" && selectedCategory.id > 0) || (data.main_key === "edit_profile"))
                    ?
                      <AITopicContext ref={aiContextRef} category={selectedCategory} setCategory={setSelectedCategory} setLoadingNextStep={setLoadingNextStep}  projectId={projectId} is_general_context={data.main_key === "providers" ? 0 : 1} ai_assistant_id={aiAssistantId} main_key = {data.main_key}/>
                    :
                      <React.Fragment></React.Fragment>
                  }
                  {data.main_key === "operator_wallet_setup" ? (
                    <PaymentPageMain
                      handleClose={handleClose}
                      insideModal={true}
                      autoSubScribe={data.autoSubScribe}
                      categoryId={data.categoryId}
                      regionId={data.regionId}
                      ref={walletSetupRef}
                      fromTopicSettingModal={true}
                      callBack={(key) => changeTopic({ key })}
                      setLoadingNextStep={setLoadingNextStep}
                      setUnsavedWalletSettings={setUnsavedWalletSettings}
                      walletData={data.paymentSetupModalData}
                    />
                  ) : (
                    <></>
                  )}
                  {data.main_key === "edit_profile" && ["upload_document"].includes(selectedTopic.key) ? (
                    <ProviderDocuments isfromTopSetEditProf = {true}/>
                  ) : (
                    <></>
                  )}
                  {data.main_key === "edit_profile" && !["ai_context", "notification_preference", "company_profile", "manage_user", "upload_document", "price_catalog"].includes(selectedTopic.key) ? (
                    <MyProfileSteps selectedSection={selectedTopic.key} setContactId={setAIAssistantId} setPushNotificationPreference={setPushNotificationPreference} setEmailNotificationPreference={setEmailNotificationPreference}/>
                  ) : (
                    <></>
                  )}
                  { data.main_key === "edit_profile" && ["company_profile"].includes(selectedTopic.key) ?
                    <Companyprofilestep setShowInfoModal="" setPushNotificationPreference={setPushNotificationPreference} setEmailNotificationPreference={setEmailNotificationPreference} isfromTopSetEditProf={true}/>
                    :<></>
                  }
                  {
                    ["edit_profile", "company-profile"].includes(data.main_key) && selectedTopic.key === "notification_preference"
                    ?
                    <NotificationPreference emailNotification={emailNotificationPreference} handleNotificationPreference={changeNotificationPreference}  mobileNotification={pushNotificationPreference} />
                    :
                    <></>
                  }
                  {
                    selectedTopic.key === "price_catalog"
                    ?
                    <ProviderPricingCatalog />
                    :
                    <></>
                  }
                </div>
              </div>
            </div>
          </div>
          <input
            type="file"
            accept="image/*,video/*,.pdf"
            className="d-none"
            name="file-input"
            onInput={handleAddFileInput}
            ref={fileInputRef}
            multiple
          />
        </Modal.Body>
      </Modal>
      {overWriteMainModal ? (
        <React.Fragment>
          {addOperatorModal ? (
            <AddEmployee
              handleClose={() => {
                setAddOperatorModal(false);
                setOverWriteMainModal(false);
                setPeopleDetail({});
              }}
              selectedRecord={peopleDetail.id > 0 ? peopleDetail : ""}
              projectId={projectId}
              show={addOperatorModal}
              updateDataSource={() => {
                getPeopleList(false);
                viewAll("l_people");
              }}
            />
          ) : documentMiniModalData.id > 0 ||
            (documentMiniModalData.id && documentMiniModalData.id.length) ? (
            <DocumentModal
              handleClose={() => {
                setDocumentMiniModalData({});
                setOverWriteMainModal(false);
              }}
              fromTopicSetting={true}
              documentData={documentMiniModalData}
              show={
                documentMiniModalData.id > 0 ||
                (documentMiniModalData.id && documentMiniModalData.id.length)
              }
              type={documentMiniModalData.type}
            />
          ) : addTeamModal.id > 0 ? (
            <CreateTeam
              dataSource={[]}
              setDataSource={() => {}}
              handleClose={() => {
                setOverWriteMainModal(false);
                setAddTeamModal({});
              }}
              setTeamsData={() => {}}
              show={addTeamModal.id > 0}
              team={addTeamModal}
              teamsData={[]}
              handleAddedTeam={() => {}}
              projectId={projectId}
            />
          ) : viewPermissions ? (
            <ManageRolePermissionModal
              show={viewPermissions}
              handleClose={() => {
                setViewPermissions(false);
                setOverWriteMainModal(false);
              }}
              handleShow={() => {
                setViewPermissions(true);
                setOverWriteMainModal(true);
              }}
              role={selectedRole}
            />
          ) : showEditRole ? (
            <AddRole
              show={show}
              handleClose={() => {
                setOverWriteMainModal(false);
                setShowEditRole(false);
              }}
              handleShow={() => {
                setOverWriteMainModal(true);
                setShowEditRole(true);
              }}
              role={selectedRole}
              handleDataRole={handleDataRole}
            />
          ) : showEditUser ? (
            <AddEmployee
              show={showEditUser}
              handleClose={() => {
                setOverWriteMainModal(false);
                setShowEditUSer(false);
              }}
              updateDataSource={handleDataSourceChange}
              selectedRecord={selectedEmployeeData}
            />
          ) : showManagerModal ? (
            <AddAssigneeList
              show={showManagerModal}
              handleClose={() => {
                setOverWriteMainModal(false);
                setShowManagerModal(false);
              }}
              handleShow={() => {
                setOverWriteMainModal(true);
                setShowManagerModal(true);
              }}
              callBack={() => {}}
              projectId={selectedProjectId}
            />
          ) : showRemoveProviderConfirmation.userid > 0 ? (
            <RemoveProviderConfirmation
              header={"l_you_want_to_delete_provider"}
              show={showRemoveProviderConfirmation.userid > 0}
              handleConfirm={() => {
                deleteProvider(showRemoveProviderConfirmation);
              }}
              handleClose={() => {
                setShowRemoveProviderConfirmation({});
                setOverWriteMainModal(false);
              }}
              message={"l_remove_provider_data_confirmation"}
            />
          ) : deleteCategoryData.filter_key > 0 ? (
            <RemoveProviderConfirmation
              header={"l_you_want_to_delete_provider"}
              show={deleteCategoryData.filter_key > 0}
              handleConfirm={() => {
                deleteTopic(deleteCategoryData);
              }}
              handleClose={() => {
                setDeleteCategoryData({});
                setOverWriteMainModal(false);
              }}
              message={"l_remove_category_data_confirmation"}
            />
          ) : benchmarkToStop.id > 0 ? (
            <StopBenchnark
              benchmarkId={benchmarkToStop.id}
              callback={async () => {
                await getSchedules(selectedTopic.key);
                setBenchmarkToStop({});
                setOverWriteMainModal(false);
              }}
              handleClose={() => {
                setBenchmarkToStop({});
                setOverWriteMainModal(false);
              }}
              projectId={projectId}
              show={benchmarkToStop.id > 0}
              status={benchmarkToStop.status}
              startDate={benchmarkToStop.start_date}
            />
          ) : editCategoryModalData.filter_key > 0 ? (
            <CategoryProviderList
              handleClose={() => {
                setOverWriteMainModal(false);
                setEditCategoryModalData({});
              }}
              projectId={projectId}
              show={editCategoryModalData.filter_key > 0}
              handleAddCategory={() => dispatch(getDefaultTopics())}
              editTopicDetails={{
                ...editCategoryModalData,
                id: editCategoryModalData.filter_key,
              }}
            />
          ) : showBulkCategoryUpload ? (
            <CategoryAndProviderBulkImport
              handleClose={() => {
                setShowBulkCategoryUpload(false);
                setOverWriteMainModal(false);
              }}
              redirect={false}
              selectedOffice={projectId}
              setLoader={setIsFetchingData}
              setShow={setShowBulkCategoryUpload}
              show={showBulkCategoryUpload}
            />
          ) : bulkEquipmentsImport ? (
            <AddBulkEquipments
              handleClose={() => {
                setOverWriteMainModal(false);
                setBulkEquipmentsImport(false);
              }}
              projectId={projectId}
              setShow={setBulkEquipmentsImport}
              show={bulkEquipmentsImport}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {openLightBox && lightBoxData ? (
        <DocumentPreview
          open={openLightBox}
          setOpen={setOpenLightBox}
          slides={lightBoxData}
        />
      ) : (
        <></>
      )}
      {attachmentDetail.id > 0 ? (
        <CommanOffCanvas
          show={attachmentDetail.id > 0}
          handleClose={() => {
            setAttachmentDetail({});
            setOverWriteMainModal(false);
          }}
          data={attachmentDetail}
          docType={"imageview"}
          setSelectedId={() => {}}
          commentList={attachmentDetail.comments}
          selectedTaskId={attachmentDetail.taskId}
          handleParentComponentAction={() => {}}
          CloseCommentModal={() => {}}
          isChat={true}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {
        paymentSetupModalData && Object.keys(paymentSetupModalData).length
        ?
        <PaymentSetupModal
          handleClose={closeNewWalletSetupModal}
          show={paymentSetupModalData && Object.keys(paymentSetupModalData).length}
          autoSubScribe={false}
          walletId={paymentSetupModalData.id}
          data={paymentSetupModalData}
        />
        :
        <React.Fragment></React.Fragment>
      }
      {
        addCustomTopicModal ? (
          <AddNewCategory
            projectId={projectId}
            activeTopicKey={data.main_key}
            show={addCustomTopicModal}
            handleClose={() => {setAddCustomTopicModal(false)}}
            handler={updateUserTags}
          />
        ) : (
          <></>
        )
      }
      {
        viewAIContext ?
          <AIContextModal relType={"aiChat"} show={viewAIContext} handleClose={()=> {setViewAIContext(false)}} handelSave={saveCreatAIAssistant} />
        :
          <></>
      }
    </React.Fragment>
  );
};

export default TopicSettingModal;
