import React, { useEffect, useState } from 'react'
import { MentionsInput, Mention } from "react-mentions";
import { X, Hash, ArrowRight, At, Paperclip, ChatCircle, } from "phosphor-react";
import StepIcon from "../../assets/icons/StepIcon";
import jQuery from "jquery";
import {
    TaskCommentModalOpen,
    _l,
    TaskCommentModalToggle,
    TaskModalClose,
    CloseCommentModal,
    tabindexFix,
    getImagePreviewIcon,
} from "../../hooks/utilities";
import { getComments } from '../../actions/documents-actions/documents-actions';
import { useDispatch } from 'react-redux';
import { getLeadActivities } from '../../actions/leads-actions/leads-actions';
import { useParams, useNavigate } from "react-router-dom";
import FloorFilterDropdown from '../Dropdowns/FloorFilterDropdown';
import {
    UsersThree,
} from "phosphor-react";
import CommanFooterPlus from '../CommanFooterPlus';
import { commanoffcanvasisOpen } from '../../actions/customer';
let fileUplods = []
function CommonFooter({ mentionList, count, type, handleAction, data, isAllowToEdit, docId, setShowCommentModal, ShowCommentModal, setShowCommentSection, setType, isExternal, spaceData,showFullscreen=false, setCommentSkeleton }) {
    const dispatch = useDispatch();
    const contact_image = localStorage.getItem("contact_image");
    // const [ShowCommentModal, setShowCommentModal] = useState(false);
    const [commentContent, setCommentContent] = useState("");
    const [imagePreviews, setImagePreviews] = useState([]);
    const [unreadCommentCount, setUnreadCommentCount] = useState(0);
    const [isMentionListShow, setIsMentionListShow] = useState(false);
    const { id: hash_id, page } = useParams();
    useEffect(() => {
        if (data) {
            setCommentContent(data.comment)
            // setImagePreviews(data.preview)
            setUnreadCommentCount(data.unreadCommentCount)
        }
    }, [JSON.stringify(data), type])
    useEffect(() => {
        // jQuery(".office-list-icon, #openCommentModal,#left-office-list").on("click", function () {
        //     if (ShowCommentModal) {
        //         setShowCommentModal(false)
        //         CloseCommentModal()
        //     } else {
        //         setShowCommentModal(true)
        //     }
        // });
        jQuery("#comment_close").on("hide", function () {
            setShowCommentModal(false)
        });
    }, [])

    useEffect(() => {
        if (type == 'lead') {
            dispatch(getLeadActivities(docId));
        }
        else {
            setCommentSkeleton(true);
            dispatch(getComments(docId, type == "request-task" ? "task" : type, 0, hash_id))
            .then((res) => {
                setCommentSkeleton(false);
            })            
        }
    }, [docId]) 
    const preparePreview = (files) => {
        let previrewArray = [];
        Array.from(files).map((img) => {
            let preview_url = getImagePreviewIcon(img);
            previrewArray.push({ name: img.name, url: preview_url });
        });
        fileUplods = files
        setImagePreviews(previrewArray);
    };
    const removeImageSelection = (name) => {
        imagePreviews.forEach((thumbnail) => {
            URL.revokeObjectURL(thumbnail.url);
        });
        const updatedFileList = Array.from(fileUplods).filter(
            (img) => img.name != name
        );
        // setImageBuffer(updatedFileList);
        preparePreview(updatedFileList);
    };
    const mentionListToggle = () => {
        if (!isMentionListShow) {
            setCommentContent("@");
            jQuery("#commentInputOuter").focus();
        } else {
            setCommentContent("");
        }
        setIsMentionListShow(!isMentionListShow);
    };
    useEffect(()=>{
        if(['task', 'benchmark', 'proposal', 'schedule','lead','request-task'].includes(type)){
            if (!ShowCommentModal) {
                TaskCommentModalOpen();
                handleAction('getComment', '')
                setShowCommentModal(true);
            } 
        }else if(['pilotonboarding','estimate','subscription','invoice','credit_note'].includes(type)){
            if (ShowCommentModal) {
                CloseCommentModal();
                setShowCommentSection(false);
                setShowCommentModal(false);
            }
        }
    },[type]);
    return (
        <div className={`d-flex justify-content-end align-items-end w-100 mt-auto position-relative ${isExternal && type == "spaceDocument" ? "" : ["mandate", "reference", "dp"].includes(type) ? '' : "ps-3 z-index-minus-1"} ${commanoffcanvasisOpen ? ["mandate", "reference", "dp"].includes(type) ? '' : "z-index-minus-1 d-none" : ""}`}>
            {isExternal && type == "spaceDocument"  ? (
                <div className={`comman_action_icon btn-group dropup me-auto ${isExternal && type == "spaceDocument" ? "z-index-11" : "z-index-5"}`}>
                    <a href="#/"
                        className="action_icon with_bg position-relative w55 h46 box-shadow-2 on-hover-active-toggle-img radius_3 "
                        type="button"
                        id="FloorFilterDropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        data-tip={_l("l_floor_filter")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="right"
                    >
                        <StepIcon className="HW20" />
                    </a>
                    <FloorFilterDropdown isExternal={isExternal} data={spaceData} />
                </div>) : <></>}
            {ShowCommentModal ? (
                <></>
            ) : (
                <>
                    <div className="chat-main-box with-bg flex-grow-1 me-0 w100minus40 d-none">
                        {
                            isExternal ?
                                ""
                                :
                                <div className={`chat-comment-box`} >
                                    <div className="comment-input-box">
                                        <div className="d-flex align-items-center position-relative">
                                            <div
                                                className="h25w25 comman-image-box rounded-circle"
                                                data-bs-toggle="tooltip"
                                                title={localStorage.getItem("full_name")}
                                                aria-expanded="false"
                                                data-tip={localStorage.getItem("full_name")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                            >
                                                <div
                                                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                    style={{
                                                        backgroundImage: `url('${contact_image}')`,
                                                    }}
                                                ></div>
                                            </div>

                                            <MentionsInput
                                                value={commentContent}
                                                className="mension-box flex-grow-1"
                                                placeholder={`${_l("l_write_comment")}`}
                                                onChange={(e) => {

                                                    handleAction('commentValueChange', e.target.value)
                                                    setCommentContent(e.target.value)
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 && isAllowToEdit && !e.ctrlKey && !e.shiftKey) {
                                                        e.preventDefault();
                                                        handleAction('addComment', {
                                                            content: commentContent,
                                                            files: fileUplods,
                                                            editCommentId: 0,
                                                        }, setCommentContent)
                                                        fileUplods = []
                                                        setImagePreviews([])
                                                        setCommentContent('')

                                                    }
                                                    if (e.key === 'Enter') {
                                                    }
                                                }}
                                                id="commentInputOuter"
                                                disabled={!isAllowToEdit}
                                            >
                                                <Mention
                                                    markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                                                    trigger="@"
                                                    data={mentionList}
                                                    renderSuggestion={(
                                                        suggestion,
                                                        search,
                                                        highlightedDisplay
                                                    ) => {
                                                        return (
                                                            <div className="user d-flex align-items-center">
                                                                <div className="comman-image-box h25w25 rounded-circle mx-2">
                                                                    {
                                                                        suggestion.isteam == 1 ? <div
                                                                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex"> <UsersThree className="m-auto opacity-60" /> </div> : <div
                                                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                                                style={{
                                                                                    backgroundImage: `url('${suggestion.image}')`,
                                                                                }}
                                                                            ></div>
                                                                    }
                                                                </div>

                                                                {highlightedDisplay}
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </MentionsInput>
                                            <React.Fragment>
                                                {imagePreviews && imagePreviews.length ? (
                                                    <div className="upload-image-preview ">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            {imagePreviews.map((preview, index) => {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="comman-image-box h25w25 radius_3 upload-image with-margin"
                                                                    >
                                                                        <div
                                                                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                                                            style={{
                                                                                backgroundImage: `url(
                                            '${preview.url}'
                                          )`,
                                                                            }}
                                                                        ></div>
                                                                        <a href="#/"
                                                                            className="upload-close"
                                                                            onClick={() => {
                                                                                handleAction('removePreview', preview.name)
                                                                                removeImageSelection(preview.name)

                                                                            }}
                                                                        >
                                                                            <X size={10} className="c-icons" weight="light" />
                                                                        </a>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </React.Fragment>
                                            <div className="d-flex align-items-center me-4 pe-1">
                                                <div className="comman_action_icon pe-2 ps-0" onClick={() => {
                                                    mentionListToggle();

                                                }}>
                                                    <div className="d-flex align-items-center">
                                                        <a href="#/"
                                                            className="action_icon white-border-box opacity-60"
                                                            aria-expanded="false"
                                                            data-tip={`${_l("l_mention_list")}`}
                                                            data-effect="solid"
                                                            data-delay-show='1000'
                                                            data-class="tooltip-main"
                                                        >
                                                            <At size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="comman_action_icon  pe-2 ps-1">
                                                    <div className="d-flex align-items-center position-relative">
                                                        <a href="#/"
                                                            className="action_icon with_bg"
                                                            aria-expanded="false"
                                                            data-tip={`${_l("l_add_attachment")}`}
                                                            data-effect="solid"
                                                            data-delay-show='1000'
                                                            data-class="tooltip-main"
                                                        >
                                                            <input
                                                                type="file"
                                                                className="absolute-input"
                                                                multiple
                                                                accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                                                                onChange={(event) => {
                                                                    if (isAllowToEdit) {
                                                                        handleAction('fileUpload', event)
                                                                        if (event.target.files.length <= 5) {
                                                                            preparePreview(event.target.files);
                                                                        }

                                                                    }


                                                                    // CustomerServices.addLinkComment(
                                                                    //   taskId,
                                                                    //   projectId,
                                                                    //   "",
                                                                    //   undefined,
                                                                    //   undefined,
                                                                    //   0,
                                                                    //   0,
                                                                    //   event.target.files[0]
                                                                    // );
                                                                }}
                                                                onClick={(e) => {
                                                                    e.target.value = "";
                                                                }}
                                                                disabled={!isAllowToEdit}
                                                            />
                                                            <Paperclip size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="comman_action_icon  pe-2 ps-1 d-none">
                                                    <div className="d-flex align-items-center">
                                                        <a href="#/"
                                                            className="action_icon with_bg"
                                                            aria-expanded="false"
                                                            data-tip={`${_l("l_task_list")}`}
                                                            data-effect="solid"
                                                            data-delay-show='1000'
                                                            data-class="tooltip-main"
                                                        >
                                                            <Hash size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="#/"
                                                className="send-arrow-btn with_position_right_zero comman_action_icon"
                                                onClick={() => {
                                                    if (isAllowToEdit) {
                                                        handleAction('addComment', {
                                                            content: commentContent,
                                                            files: fileUplods,
                                                            editCommentId: 0,
                                                        })
                                                        fileUplods = []
                                                        setImagePreviews([])
                                                        setCommentContent('')
                                                    }
                                                }}>
                                                <div className="action_icon with_bg">
                                                    <ArrowRight size={18} className="c-icons" weight="light" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </>
            )}
            { showFullscreen ?
            <CommanFooterPlus docType ={type} MainClass="with-position" showFullscreen={showFullscreen} id="commanFooterHandle" toggleId='commanFooterHandleShow' data={spaceData} /> : <></>}
            {ShowCommentModal ? "" : 
            <div
                className={`comman_action_icon paper-clip-file ps-3 h-100  ${type == "task" || type == "request-task"  ? "with-absolute-bottom-160" :""}`}
            >
                <a href="#/"
                    className="action_icon with_bg position-relative w55 h46 radius_3 with-comment-close" id={`comment_close`}
                    onClick={() => {
                        const proposalId = localStorage.getItem("proposalId");
                        localStorage.setItem("proposalId", "")
                        setType("")
                        if (!ShowCommentModal || proposalId) {
                            if(proposalId)
                            {
                                localStorage.setItem("preventCommentSectionToggle", true);
                            }
                            TaskCommentModalOpen();
                            handleAction('getComment', '')
                            setShowCommentModal(true);
                        } else {
                            CloseCommentModal();
                            setShowCommentSection(false);
                            setShowCommentModal(false);
                        }

                    }}
                    data-tip={_l("l_add_comments")}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                >
                    {unreadCommentCount > 0 ? (
                        <span
                            className=" rounded-pill badge-custom d-flex"
                            style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-10px",
                                height: "20px",
                                width: "20px",
                                padding: "0px",
                            }}
                        >
                            {unreadCommentCount}
                        </span>
                    ) : (
                        <></>
                    )}

                    <div className="d-flex align-items-center justify-content-center w-100">
                        <ChatCircle size={18} className="c-icons m-0" weight="light" />
                        <span className="c-font f-12 title-fonts fw-semibold">{count}{" "}</span>
                    </div>
                </a>
            </div>
            }
        </div>
    )
}

export default CommonFooter