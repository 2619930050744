import React, { useEffect, useState } from "react";
import ProviderUploadDocument from "../components/SolarExternalPages/ProviderUploadDocument";
import CustomerServices from "../services/customer-services";
import StepperHeader from "../components/SolarExternalPages/StepperHeader";
import { _l, hideLeftPanelNew, showError, showSuccessMessage } from "../hooks/utilities";
import Loader from "../components/SolarExternalPages/Loader";
import { useNavigate } from "react-router";
import DocumentsServices from "../services/documents-services";
import { Button, Spinner } from "react-bootstrap";
import customerServices from "../services/customer-services";
import { showMessage } from "../actions/messages";
import { useDispatch } from "react-redux";
import { setSelectedTabLeftPanel } from "../actions/customer";
import { useSearchParams } from "react-router-dom";

const ProviderDocuments = ({isfromTopSetEditProf = false}) => {

  const dispatch = useDispatch();


  const staffId = localStorage.getItem("staff_id");
  const userType = localStorage.getItem("user_type");

  const [documents, setDocuments] = useState();
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [updatedDocuments, setUpdatedDocuments] = useState({
    companyLogo: "", 
    companyId: "", 
    companyRGE: "", 
    companyQC: "", 
    companyInsurance: "", 
    companymandate:"",
    companyPI : ""
  });
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page");

  const updateDocuments = async() => {
    setLoader(true);
    try {

      const formattedDocumentObject = {
        l_company_id_upload: {
          files: updatedDocuments.companyLogo,
          key: "logo",
        },
        l_id_card_upload: {
          files: updatedDocuments.companyId,
          key: "company_id",
        },
        l_insurance_upload: {
          files: updatedDocuments.companyInsurance,
          key: "insurance",
        },
        l_rge_certificate_upload: {
          files: updatedDocuments.companyRGE,
          key: "rge_certificate",
        },
        l_skill_certificate_upload: {
          files: updatedDocuments.companyQC,
          key: "skill",
        },
        l_mandate_upload: {
          files: updatedDocuments.companymandate,
          key: "mandate",
        },
        l_pi_upload: {
          files: updatedDocuments.companyPI,
          key: "pi",
        },
      }

      await DocumentsServices.uploadEmployeeDocuments(formattedDocumentObject, staffId).then((response) => {
      if (response && response.status) {
        showSuccessMessage("l_documents_uploaded")
        if (userType == "operator") {
          customerServices.getSelectedEmployeeDetails(staffId).then(
            (res) => {
            if (res.status == 1) {
              localStorage.setItem("company_logo", res.data.company_logo);

              const { company_id, id_card, rge_certificate, skill, pi, mandate} = res.data
              if (company_id != "" && id_card != ""  && rge_certificate != ""  && skill != "" && mandate != ""  && pi != "") {
                  localStorage.setItem("isCompanyDocumentsUploaded", true)
              } else {
                localStorage.setItem("isCompanyDocumentsUploaded", false)
              }
            } else {
              showMessage(
                "unsucess",
                _l("l_error"),
                res.message ? res.message : ""
              )
            }
            }
          )
        }
        setLoader(false);
      } else {
        showError("l_documents_uploaded_failed")
        setLoader(false);
      }
      })
    }
    catch(e)
    {
      console.error(e);
      showError("");
    }
    finally {
      navigate(-1);
    }
  };

  useEffect(() => {
    const loadDocuments = async () => {
      try {
       if (!staffId || staffId <= 0) {
          navigate("/?page=proposals");
          return [];
        } else {
          const response = await CustomerServices.getSelectedEmployeeDetails(
            staffId
          );
          if (response && response.data) {
            setDocuments({
              companyLogoPreview: response.data.company_logo,
              companyIdPreview: response.data.company_id || "",
              companyRGEPreview: response.data.rge_certificate || "",
              companyQCPreview: response.data.skill || "",
              companyInsurancePreview: response.data.insurance || "",
              companymandatePreview: response.data.mandate || "",
              companyPIPreview: response.data.pi || "", 
              mandateID : response.data.mandate_id,
              cordinates : response.data.cordinates
            });
          } else {
            navigate("/?page=proposals");
            return [];
          }
        }
      } catch (e) {
        navigate("/?page=proposals");
        console.error(e);
        return [];
      } finally {
        setIsDocumentLoaded(true);
      }
    };

    loadDocuments();
    // hideLeftPanelNew();
    // dispatch(setSelectedTabLeftPanel("my-documents"));
  }, []);

  return (
    <div className="RegNewFlow pe10per">
      {/* <StepperHeader
        backButtonHandler={() => {navigate(-1)}}
        headerClass={""}
        stepName={""}
        textContent={{
          backButtonText: "l_back",
          headerTitle: _l("l_upload_your_company_logo_and_documents"),
        }}
        hideStepSpan={true}
        isfromTopSetEditProf ={isfromTopSetEditProf}
      /> */}
      <div className={`comman_top_header  position-sticky top-0 z-index-4 p-0 bgpatternCommanImg ${page == "document" ? "" :  ""}`}> 
          <div className="d-flex justify-content-between align-items-center">
            <div className="comman-tab-wrapper pb-0 d-flex justify-content-between">
              <div className="tab-wrapper d-inline-flex align-items-center">
                <div className="tab-name title-fonts fw-semibold m-0 color-white-80 me-1">
                  {_l("l_upload_your_company_logo_and_documents")}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center pe-10px">
            <Button className={`btn btn-primary btn-sm mx-auto text-nowrap ${loader ? "for-disabled" : ""}`}onClick={updateDocuments}>
              {loader ? _l("l_please_wait") : _l("l_save")}
            </Button>
            </div>
          </div>
        </div> 
      {isDocumentLoaded && (
        <ProviderUploadDocument
          value={[]}
          onChange={(documents) => {
            setUpdatedDocuments(documents)
          }}
          handleNextStep={updateDocuments}
          isDocumentPage={true}
          previewObject={documents}
          loader={loader}
          setDocuments={setDocuments}
        />
      )}
    </div>
  );
};

export default ProviderDocuments;
