import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import $ from "jquery";
import { _l, adjustDynamicInputHeight, formateDate, HideDropdown, showError, addActiveBorderforWeekCalendar, isSolarHubPlatform } from "../../hooks/utilities";
import MsgCard from "./MsgCard";
import { ArrowRight, X, FileDoc, FilePdf, PlayCircle, Paperclip, Smiley, ArrowBendUpLeft, Cpu, Export } from "phosphor-react";
import Lightbox from "yet-another-react-lightbox";
import { showMessage, showConfirmation, toastCloseAction } from "../../actions/messages";
import ChatServices from "../../services/chat-services";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { MentionsInput, Mention } from "react-mentions";
import CustomerServices from "../../services/customer-services";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import Spinner from "react-bootstrap/Spinner";
import jquery from "jquery";
import EmojiPicker from "emoji-picker-react";
import ReactHtmlParser from "react-html-parser";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from 'react-bootstrap/Button';

import {
    sendMessage,
    uploadChatMedia,
    updateUploadedFileName,
    initCall,
    sendTyping,
    fetchMessages,
    setIDofFirstMessage,
    getGroupDetails,
    updateExternalGroupListMessages,
    setShowIsChatScreenDisplay
} from "../../actions/chat";

import {
    ATTACH_BASE_URL,
    DELETE_MESSAGE,
    EDIT_MESSAGE,
    USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";

import { 
    setDaterangeFilter, 
    setDaterangeFilterApplied, 
    setLastMessageOrTaskDate, 
    setSelectedDateForChatai, 
    setSelectedDateView, 
    setShowWeekcalendardays, 
    setSpaceNotificationCount,
    setVirtualSpaceNotificationCount,
    chatUnreadMessageCount,
    getTasksDetailsForChat,
    getBenchmarkDetail,
    chatMessageLoader
} from "../../actions/customer"

import {
    getSelectedTask, 
    openChatAiInput
} from "../../actions/customer";

import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { mentionStyle } from "../../assets/mention/mentionStyle";
import TaskCard from "../TaskCard";
import customerServices from "../../services/customer-services";
import ScheduleCard from "../BenchmarkNew/ScheduleCard";
import Mapcard from "../Mapcard";
import AIContextModal from "../Modals/AIContextModal";
import Typing from "../Typing/Typing";
import CommanLoader from "../Loader/CommanLoader";
import { useSearchParams } from "react-router-dom";
import { mentionInputStyle } from "../../assets/mention/mentionInputStyle";
import FullScreenChatBoxSkeleton from "../Skeleton/FullScreenChatBoxSkeleton";

let selectedTopic = localStorage.getItem("selectedTopic") && JSON.parse(localStorage.getItem("selectedTopic"));

const RightChatBoard = ({
    otherUserStatus,
    isVirtualSpace,
    shareContacts,
    chatUserId,
    loader = false,
    pageName,
    chatAIgroupdeatils,
    msgloader
}) => {  
    const { uploadFileName,groupDetails, typingMembers, disableInputChat, groupListExternal, messageList } = useSelector((state) => state.chat);
    const { 
        defaultTopics, 
        selectedUserForChat, 
        taskList, 
        globalSearchString, 
        commanoffcanvasIsOpen, 
        ChatAiDate, 
        daterangeAppliedFilters,
        taskFilters,
        globalView,
        chatTaskList,
        firstIdofMessage,
        preDataAvaliable,
        lastAppliedFilter,
        getSelectedCalendarDate,
        isAppliedUnreadComment,
        benchmarkDetail,
        chatMessagesLoader
    } = useSelector((state) => state.customer);
    const selectedTask = useSelector((state) => state.customer.selectedTask);
    const spaceState = useSelector((state) => state.customer.spaces);
    const dispatch = useDispatch();
    const [editMessageId, setEditMessageId] = useState("");
    const [convertMessageId, setConvertMessageId] = useState("");
    const [replyMessageId, setReplyMessageId] = useState("");
    const [slug, setSlug] = useState("");
    const [replyTaskId, setReplyTaskId] = useState("");
    const [replyScheduleId, setReplyScheduleId] = useState("");
    const [messageText, setMessageText] = useState("");
    const [groupMembers, setGroupMembers] = useState({});
    const [openGallery, setOpenGallery] = useState(false);

    const [fileUploadLoader, setFileUploadLoader] = useState(false);
    const [imagePreviewSrc, setImagePreviewSrc] = useState("");
    const [selectedFile, setSelectedFile] = useState({});
    const [selectedFileSrc, setSelectedFileSrc] = useState("");
    const [selectedFileType, setSelectedFileType] = useState("");
    
    const [taskLoader, setTaskLoader] = useState(false);
    const [assigneeOptions, setAssigneeOptions] = useState([]);
    const [showTaskInfo, setShowTaskInfo] = useState(false);
    const [selectedTaskInfo, setSelectedTaskInfo] = useState({});
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [showConvertDropdown, setShowConvertDropdown] = useState(false);
    const [todoTasksList, setTodoTasksList] = useState([]);
    const [assigneeDropdownList, setAssigneeDropdownList] = useState([]);
    const [loadPageNo, setLoadPageNo] = useState(1);
    const [selectedId, setSelectedId] = useState(0)
    const [customIndex, setCustomIndex] = useState(10);
    const [chatAiTasks, setChatAiTasks] = useState([]);
    const [chatAndTasks, setchatAndTasks] = useState([]);
    const [task_type, setTask_type] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(0);
    const [commonModalData, setCommonModalData] = useState();
    const [showEmoji, setShowEmoji] = useState(false);
    const [chatTaskPage, setchatTaskPage] = useState(-20);
    const [replyMsgTask, setReplyMsgTask] = useState({});
    const [viewAIContext, setViewAIContext] = useState(false);
    var tomorrowDate  = new Date();
    var yesterdayDate = new Date();
    const [runId, setRunId] = useState('');
    let fromDate =localStorage.getItem("fromDate")
    let endDate =localStorage.getItem("toDate")
    const localGroupDetails = JSON.parse(localStorage.getItem("getGroupDetails"));
    const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;
    const [showOptions, setshowOptions] = useState(0);
    const [stopScroll, setStopScroll] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [taskListForChat, setTaskListForChat] = useState([]);
    const [chatmessageList, setChatmessageList] = useState([]);
    const [prevPos, setprevPos] = useState(0);
    const [messageLoader, setMessageLoader] = useState(false);
    const [messageTaskIds,setMessageTaskIds] = useState([])
    const [aiTyping, setAiTyping] = useState(0)
    let activeGroupId = localStorage.getItem("activeGroupId");

    const [searchParams, setSearchParams] = useSearchParams();
    const [proposalList, setProposalList] = useState([])
    const [isVerifiedUser, setIsVerifiedUser] = useState(true);
    const [scheduleID, setscheduleID] = useState(0);
    const proposalId = searchParams.get("proposal_id");

    // Increase the date by 1 day
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    // Decrease the date by 1 day
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);

    var projectId = localStorage.getItem("selectedOffice");
    const convertMenuFilters = {
        status: 0,
        categories: [],
        date: "",
    };
    const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
    let userType = localStorage.getItem("user_type");
    var userImg = "url(" + localStorage.getItem("contact_image") + ")";
    if (userType == "staff" && !isVirtualSpace) {
        userImg = "url(" + localStorage.getItem("am_image") + ")";
    }
    var groupType = localStorage.getItem("chatGroupType");

    var media = {};

    var fileTypes = {
        image: ["jpg", "jpeg", "png", "gif", "svg", "ico"],
        video: ["mp4", "3gp", "ts", "mov", "avi", "wmv", "mkv", "webm", "flv", "avchd"],
        audio: ["mp3", "aac", "wav", "aiff", "ogg", "wma", "flac", "alac", "m4a"]
    }

    // ------------------------ Helper functions start ------------------------

    // ---------------- know page is at the bottom ------------------------
    //   var msgListDiv = document.getElementById("message-list");
    //   if(msgListDiv){
    //      console.log( (msgListDiv.scrollTop + msgListDiv.clientHeight) >=  msgListDiv.scrollHeight)
    //   }
    // ---------------- know page is at the bottom ------------------------

    // ------------------------ Image compression code - start ------------------------
    function getImageDimensions(image) {
        return new Promise((resolve, reject) => {
            image.onload = function (e) {
                const width = this.width;
                const height = this.height;
                resolve({ height, width });
            };
        });
    }

    function compressImage(image, scale, initalWidth, initalHeight) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");

            canvas.width = scale * initalWidth;
            canvas.height = scale * initalHeight;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            ctx.canvas.toBlob((blob) => {
                resolve(blob);
            }, "image/jpg");
        });
    }

    async function previewImages(uploadedImage) {
        media = {};
        // const imageInput = document.getElementById(input_id);
        // const uploadedImage = imageInput.files[0];
        if (!uploadedImage) {
            return;
        }
        var name = uploadedImage.name;
        if (!/\.(jpe?g|png|jpg)$/.test(name)) {
            return dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_valid_file")));
        }

        const inputPreview = document.getElementById("input-preview");
        inputPreview.src = URL.createObjectURL(uploadedImage);
        const { height, width } = await getImageDimensions(inputPreview);

        const MAX_WIDTH = 500;
        const MAX_HEIGHT = 500;
        const widthRatioBlob = await compressImage(inputPreview, MAX_WIDTH / width, width, height);
        const heightRatioBlob = await compressImage(inputPreview, MAX_HEIGHT / height, width, height);
        const compressedBlob = widthRatioBlob.size > heightRatioBlob.size ? heightRatioBlob : widthRatioBlob;

        const outputPreview = document.getElementById("output-preview");
        outputPreview.src = URL.createObjectURL(compressedBlob);
        const optimalBlob = compressedBlob.size < uploadedImage.size ? compressedBlob : uploadedImage;
        compressedBlob.name = name;
        URL.revokeObjectURL(inputPreview);
        URL.revokeObjectURL(outputPreview);
        readAndPreview(compressedBlob);
    }

    function readAndPreview(file) {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        var newName = timestamp + ".png";
        file.name = newName;
        var file = new File([file], file.name, { type: file.type });
        media = file;
    }
    // ------------------------ Image compression code - end ------------------------

    function isValidDateFormat(inputDate) {
        var dateFormatRegex = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-9]|3[01])$/;  
        return dateFormatRegex.test(inputDate);
    }

    // ------------------------ Helper functions end ------------------------

    useEffect(() => {
        let selectedTopic = localStorage.getItem("selectedTopic") && JSON.parse(localStorage.getItem("selectedTopic"));
        dispatch(setSelectedDateView("dayView"));
        addActiveBorderforWeekCalendar();
        if ((pageName == "ChatAi" || globalView == "AichatView")) {
            var messageInput = $('#chatAIFeedInput');
            messageInput.focus();
        }
        let temp = spaceState
        let index = spaceState.findIndex((space) => space.project_id == projectId)
        if (index > -1) {
            let tempdata = temp[index]
            if (tempdata) {
                // dispatch(setSpaceNotificationCount(projectId, -tempdata.chat_notification_count, 1, "chat")); 
            }
        }
        defaultTopics && defaultTopics.map((top) => {
            if (selectedTopic && selectedTopic.main_key == top.main_key) {
                top.categories.map((category) => {
                if (category.filter_key == selectedTopic.id && category.main_sub_key == selectedTopic.main_sub_key) {
                    if (category.total_notification_count.chat_count > 0) {
                        dispatch(setSpaceNotificationCount(projectId, -category.total_notification_count.chat_count, 1, "chat"));
                        dispatch(setVirtualSpaceNotificationCount(projectId, -category.total_notification_count.chat_count, 1, "chat"));
                        dispatch(chatUnreadMessageCount(category.chat_data && category.chat_data.groupId, 'remove', "chatMSG"))
                    }
                }
                })
            }
        })
        // Set message list div height as per user screen
        var messageList = document.getElementById('message-list');
        // setCurrrentDateHieght(messageList.clientHeight - 50);

        if (proposalId && proposalId > 0) {
            const scheduleData = JSON.parse(localStorage.getItem("currentProposal"))
            if(scheduleData && scheduleData.proposal_id){
                // setSlug("schedule");
                // setReplyScheduleId(scheduleData.proposal_id)
                // setReplyMsgTask({
                //     id: scheduleData.proposal_id,
                //     title: `# ${scheduleData.proposal_id} - Solaire`,
                //     slug: `schedule`,
                //     start_date: scheduleData.date,
                //     max_budget: scheduleData.amount,
                //     link: "",
                //     total_item: 0,
                //     recursive: 0,
                //     is_schedule: 0
                // })
            }
        }

        if (isSolarHubPlatform() &&!benchmarkDetail) {
            let scheduleData = localStorage.getItem("schedule_data");
        scheduleData = scheduleData ? JSON.parse(scheduleData) : {};
        if(Object.keys(scheduleData).length)
        {
            // dispatch(setProject(scheduleData.project_id))
            dispatch(getBenchmarkDetail(scheduleData.benchmark_id,"","","",0,scheduleData.project_id));
        }}
    }, []);

    useEffect(() => {
        if (benchmarkDetail) {
            let proposalList =
            benchmarkDetail && benchmarkDetail.proposal_list &&
            benchmarkDetail.proposal_list.length &&
            benchmarkDetail.proposal_list[0].proposals
              ? benchmarkDetail.proposal_list[0].proposals
              : [];
              setProposalList(proposalList)
        }
        // setIsVerifiedUser
    }, [benchmarkDetail])
    
    useEffect(() => {
        if (proposalList && proposalList.length > 0) {
            let checkIsverified = false;
            let proposalDetails = proposalList.filter((proposal) => proposal.proposal_id == proposalId);
            checkIsverified = proposalDetails && proposalDetails[0] ? proposalDetails[0].is_verified_user == 0 ? false : true : true
            setIsVerifiedUser(checkIsverified)
            setscheduleID(proposalDetails && proposalDetails[0] ?  proposalDetails[0].schedule_id : 0)
        }
      
    }, [proposalList])
    
 
    async function uploadMedia(uploadedFile) {
        setFileUploadLoader(true);
        dispatch(uploadChatMedia(uploadedFile));
        if (replyTaskId) {
            var msgData = {
                groupId: localStorage.getItem("activeGroupId"),
                userId: localStorage.getItem("chatUserId"),
                message: "",
                type: "image",
            };
            msgData.extraData = { task_id: replyTaskId, slug: "task_reply" }
            // handleAddMessageAsComment()        
        }
        if (replyMessageId) {
            var msgData = {
                groupId: localStorage.getItem("activeGroupId"),
                userId: localStorage.getItem("chatUserId"),
                message: "",
                type: "image",
            };
            msgData.replyMsgId = replyMessageId
            // handleAddMessageAsComment()        
        }

    }
    
    const sendChatMessage = (aiMsg = '', fromAi= 0) => {
        setshowOptions(0);
        let aiUserId = '';
        if (fromAi == 1) {
            let aiuser = localGroupDetails &&  localGroupDetails.members.filter((t) => t._id !== localStorage.getItem("chatUserId"))
            aiUserId = aiuser && aiuser[0] && aiuser[0]._id;
        }
        var textMessage = fromAi == 1 ? aiMsg :  messageText;
        textMessage = textMessage.trim();
        // find contact role proccess --- Start ---
        let mentionIdArry = [];
        let contact_roleArray = [];
        let regex = /data-mention-id="([^"]*)"/g;
        const mentionedId = [...messageText.matchAll(regex)];
        for (const match of mentionedId) {
            mentionIdArry.push(match[1]);
        }
        groupDetails.members && groupDetails.members.forEach((item) => {
            if (item._id && mentionIdArry && mentionIdArry.includes(item._id)){
                contact_roleArray.push(item.myrRole)
            }
        })
        // Find contact role proccess --- End ---
        if (textMessage != "") {
            var msgData = {
                groupId: localStorage.getItem("activeGroupId"),
                userId: fromAi == 1 ? aiUserId :localStorage.getItem("chatUserId"),
                message: textMessage,
                type: "text",
            };
            if (replyMessageId != "" && (slug == "" || slug == "message")) {
                msgData.replyMsgId = replyMessageId;
            }
            if (replyTaskId != undefined && replyTaskId != "" && slug == "task") {
                msgData.extraData = { task_id: replyTaskId, slug: "task_reply" }
            } else if (replyScheduleId != undefined && replyScheduleId != "" && slug == "schedule") {
                msgData.extraData = replyMsgTask;
                msgData.extraData.slug = "schedule_reply"
            }
            if((contact_roleArray && contact_roleArray.includes(4)) || selectedTopic && selectedTopic.contact_role == 4 && fromAi == 0){
                msgData.disableInput  = 1;
            }
            dispatch(sendMessage(msgData));
            if (isSolarHubPlatform() && isVerifiedUser == false) {
                let firstlastmesage = [];
                let secondlastmesage = [];
                let thirdlastmesage = [];
                let lastchatmessageList = []
                let staffID = localStorage.getItem("staff_id")
                switch (messageList.length ? messageList.length : 0 ) {
                    case 0:
                        firstlastmesage = [{
                            content : textMessage,
                            staff_id : staffID,
                            date : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        lastchatmessageList = firstlastmesage
                        break;

                    case 1:
                        firstlastmesage = [{
                            content : textMessage,
                            staff_id : staffID,
                            date : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        secondlastmesage = [{
                            content : messageList[messageList.length - 1] ? messageList[messageList.length - 1].message : "",
                            staff_id : staffID,
                            date: messageList[messageList.length - 1] ? moment(messageList[messageList.length - 1].createdAt).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        lastchatmessageList = secondlastmesage.concat(firstlastmesage)
                        break;
                    case 3 :
                        firstlastmesage = [{
                            content : textMessage,
                            staff_id : staffID,
                            date : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        secondlastmesage = [{
                            content : messageList[messageList.length - 1] ? messageList[messageList.length - 1].message : "",
                            staff_id : staffID,
                            date: messageList[messageList.length - 1] ? moment(messageList[messageList.length - 1].createdAt).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        thirdlastmesage = [{
                            content : messageList[messageList.length - 2] ? messageList[messageList.length - 2].message : "",
                            staff_id : staffID,
                            date: messageList[messageList.length - 2] ? moment(messageList[messageList.length - 2].createdAt).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                    default:
                        firstlastmesage = [{
                            content : textMessage,
                            staff_id : staffID,
                            date : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        secondlastmesage = [{
                            content : messageList[messageList.length - 1] ? messageList[messageList.length - 1].message : "",
                            staff_id : staffID,
                            date: messageList[messageList.length - 1] ? moment(messageList[messageList.length - 1].createdAt).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        thirdlastmesage = [{
                            content : messageList[messageList.length - 2] ? messageList[messageList.length - 2].message : "",
                            staff_id : staffID,
                            date: messageList[messageList.length - 2] ? moment(messageList[messageList.length - 2].createdAt).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }];
                        lastchatmessageList = thirdlastmesage.concat(secondlastmesage,firstlastmesage)
                        break;
                }
                customerServices.sendMailtoProvider(proposalId,scheduleID,lastchatmessageList)
            }
            if (isSolarHubPlatform()) {
                let unreadindex = groupListExternal.findIndex((gd) => gd.groupId == localStorage.getItem("activeGroupId"))
                if (unreadindex > -1) {
                    let tempData = groupListExternal[unreadindex]
                    tempData.lastMessage = textMessage
                    tempData.lastMsgUserName = localStorage.getItem("full_name")
                    tempData.lastMessageDate = new Date()
                    let finalData = Object.values({ ...groupListExternal, [unreadindex]: tempData })
                    dispatch(updateExternalGroupListMessages(finalData))

                }    
                
            }
            setStopScroll(true);
            if (replyTaskId) {
                handleAddMessageAsComment(textMessage)
            }
            scrollToBottom();
            setMessageText("");
            setReplyMessageId("");
            jquery("#chatAIFeedInput").val("")
            handleCancleReply()
            if (formateDate(ChatAiDate) != formateDate(new Date())) {                
                dispatch(setSelectedDateForChatai(new Date()));
                dispatch(setLastMessageOrTaskDate(new Date()));
                dispatch(setDaterangeFilter("today"));
                dispatch(setShowWeekcalendardays(true));
                dispatch(setIDofFirstMessage(""))

            }
            // jquery(".ai-chat-header").removeClass("mt-4")
            setTimeout(() => {
                // For open AI chat
                if ((pageName == "ChatAi" || globalView == "AichatView") && selectedTopic && selectedTopic.contact_role == 4 && fromAi == 0) {
                    messageIntoThread(textMessage, "contact_role");
                    document.getElementById("chatAIFeedInput").blur();
                }
                // for thread Topics
                if ((pageName == "ChatAi" || globalView == "AichatView") && selectedTopic && selectedTopic.thread_id && selectedTopic.thread_id !== '' && adminAsStaff && selectedTopic.main_key != "my_contact") {
                    
                    if (fromAi == 2) {
                        messageIntoThread(textMessage);
                    }else if(fromAi == 0){
                        if (contact_roleArray && contact_roleArray.includes(4)) {
                            messageIntoThread(textMessage, "contact_role")
                            // document.getElementById("chatAIFeedInput").blur();
                        }else{
                            messageIntoThread(textMessage, "threadWithEnter")
                        }
                    }
                }
            }, 500);
        }
    };

    // For open AI chat
    const messageIntoThread = (textMessage, threadWithEnter = '') =>{
        // if (threadWithEnter == '') {
        //     setchatInputDisabled(true)
        // }
        customerServices.messageIntoThread(projectId, textMessage, threadWithEnter, taskFilters).then((res) =>{
            if (res.status) {
                const newRunId = res.data.run_id;
                const thread_data_id = res.data.thread_data_id
                setAiTyping(res.data.is_analyzing);
                setRunId(newRunId)
                if (threadWithEnter == 'contact_role') {
                    // setTimeout(() => {
                    //     checkRunStatus(newRunId);
                    //     // checkAiResponseStatus(projectId, thread_data_id)
                    // }, 3000);
                    addMessageIntoTreadRunId(thread_data_id) 
                }
            }else{
                dispatch(showMessage("unsucess", _l("l_error"), res.message));
                // setchatInputDisabled(false)
            }
        })
    }
    // For Open AI chat
    const addMessageIntoTreadRunId = (threadDataId) => {
        customerServices.addMessageIntoTreadRunId(threadDataId);
    }
    // For open AI chat
    const checkRunStatus = (newRunId) =>{
        customerServices.checkRunStatus(newRunId).then((res)=>{
            if (res.message == 'l_success') {
                sendChatMessage(res.data[0] && res.data[0].content[0] && res.data[0].content[0].text && res.data[0].content[0].text.value, 1)
            }else{
                setTimeout(() => {
                    checkRunStatus(newRunId);
                }, 5000);
            }
        })
    }

    // For Open Ai Chat
    const checkAiResponseStatus = (projectId, thread_id) => {
        customerServices.checkAiResponseStatus(projectId, thread_id).then((res) => {
            if(res.status){
                // document.getElementById("chatAIFeedInput").focus();
            }else{
                setTimeout(() => {
                    checkAiResponseStatus(projectId, thread_id)
                }, 5000);
            }
        })
    }

    useEffect(() => {
        if (disableInputChat == 1 && groupDetails._id == activeGroupId) {
            document.getElementById("chatAIFeedInput").blur();
        }else{
            document.getElementById("chatAIFeedInput").focus();
            setAiTyping(0)
        }
    }, [disableInputChat])

    useEffect(() => {
        var groupId = localStorage.getItem("activeGroupId");
        var isViewGroup = localStorage.getItem("isViewGroup");
        if (isViewGroup == 1) {
            $('#group_name').text($('#group_member_name_1_' + groupId).text() + ', ' + $('#group_member_name_2_' + groupId).text());
        } else {
            var otherUserImg = $("#group_img_" + groupId).css("background-image");
            $("#group_img").css("background-image", !otherUserImg ? "url(" + USR_IMG_PLACEHOLDER + ")" : otherUserImg);
            $("#group_name").text($("#group_name_" + groupId).text());
            $("#text-message").focus();
        }
        var chatMembers = {};
        if ((pageName == "ChatAi" || globalView == "AichatView")) {
            chatAIgroupdeatils && chatAIgroupdeatils.members && chatAIgroupdeatils.members.map((item) => {
                chatMembers[item._id] = item;
            });
        } else {
            groupDetails.members && groupDetails.members.map((item) => {
                chatMembers[item._id] = item;
            });
        }
        setGroupMembers(chatMembers);
        setMessageText("");
        setEditMessageId("");
        // setMeetLink(groupDetails.meetLink);
        setAssigneeOptions([]);
        setShowTaskInfo(false);
        setLoadPageNo(1);
        if (defaultTopics && defaultTopics.length > 0) {
            defaultTopics.map((item) => {
                if (item.main_key == "my_task") {
                    if (item.categories && item.categories.length > 0) {
                        item.categories.map((item2) => {
                            if (item2.filter_key > 0) {
                                setAssigneeOptions((assigneeOptions) => [
                                ...assigneeOptions,
                                {
                                    name: item2.title,
                                    id: item2.filter_key,
                                    is_tag: 1,
                                    is_assigned: 0
                                }
                                ]);
                            }
                        })
                    }
                }
            });
        }
    }, [groupDetails, chatAIgroupdeatils]);

    const editMessageText = (messageId) => {
        if ((pageName == "ChatAi" || globalView == "AichatView")) {
            const targetMessage = messageList && messageList.filter((msg) => msg._id == messageId);
            dispatch(openChatAiInput(true));
            jquery("#chatAIFeedInput").val(targetMessage[0].message)
            setMessageText(targetMessage[0].message)
        }
        setEditMessageId(messageId);
        const targetMessage = messageList && messageList.filter((msg) => msg._id == messageId);
        setMessageText(targetMessage[0].message);
        setTimeout(() => {
            if ((pageName == "ChatAi" || globalView == "AichatView")) {
                var messageInput = $('#chatAIFeedInput');
                messageInput.focus();
            } else {
                var messageInput = $('#text-message');
                messageInput.focus();
            }
        }, 300);
    };

    const editMessage = () => {
        ChatServices.editMessage(localStorage.getItem("chatUserId"), localStorage.getItem("activeGroupId"), editMessageId, messageText).then((res) => {
            if (res.success) {
                if (pageName = "ChatAi") {
                    let index = chatmessageList.findIndex((msg) => msg._id === editMessageId)
                    let tempdata = chatmessageList[index]
                    tempdata.message = messageText
                    let data = (
                        Object.values({ ...chatmessageList, [index]: tempdata })
                    )
                    dispatch({
                        type: EDIT_MESSAGE,
                        payload: { data },
                    });
                }
                setMessageText("");
                setEditMessageId("");
            } else {
                dispatch(showMessage("unsucess", _l("l_error"), res.message));
            }
        });
    };

    const deleteMessage = (messageId, isConfirmed = false) => {
        if (!isConfirmed) {
            dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_you_want_to_delete_message"), _l("l_yes"), _l("l_no"), undefined, () => { deleteMessage(messageId, true); }));
        } else {
            dispatch(toastCloseAction());
            ChatServices.deleteMessage(localStorage.getItem("chatUserId"), localStorage.getItem("activeGroupId"), messageId).then((res) => {
                if (res.success) {
                    if ((pageName == "ChatAi" || globalView == "AichatView")) {
                        let newList = chatmessageList.filter((message) => message._id != messageId)
                        setChatmessageList(newList)
                        let data = {data : newList}
                        setTimeout(() => {
                            dispatch({
                                type: DELETE_MESSAGE,
                                payload: data ,
                            });
                        }, 500);
                        // dispatch(updateMessageList(newList.length > 0 ? newList : []));
                    }
                } else {
                    dispatch(showMessage("unsucess", _l("l_error"), res.message));
                }
            });
        }
    };

    const setCopiedFiles = (e) => {
        const fileObject = e.clipboardData.files[0];
        if (!$.isEmptyObject(fileObject)) {
            selectFile(fileObject);
        }
    };

    const selectFile = (file) => {
        if (!file) {
            return;
        }
        var size = file.size;
        if (size == 0 || size == undefined) {
            return dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_valid_file")));
        } else if (size / (1024 ** 2) > 30) {
            return dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_file_below_30MB")));
        } else {
            var name = file.name;
            var type = "other_file";
            if (fileTypes.image.includes(getFileExt(name))) {
                type = "image";
            } else if (fileTypes.video.includes(getFileExt(name))) {
                type = "video";
            } else if (fileTypes.audio.includes(getFileExt(name))) {
                type = "audio";
            } else if (getFileExt(name) == "pdf") {
                type = "pdf";
            }
            setSelectedFile(file);
            setSelectedFileSrc(URL.createObjectURL(file));
            setSelectedFileType(type);
        }
    };

    const dragOverHandler = (e) => {
        // Prevent default behavior (Prevent file from being opened)
        e.preventDefault();
    }

    const dropHandler = (e) => {
        // Prevent default behavior (Prevent file from being opened)
        e.preventDefault();
        if (e.dataTransfer.items) {
            if (e.dataTransfer.items[0].kind == "file") {
                selectFile(e.dataTransfer.items[0].getAsFile());
            }
        }
    }

    function getFileExt(fileName) {
        const lastDot = fileName.lastIndexOf('.');
        const ext = fileName.substring(lastDot + 1).toLowerCase();
        return ext;
    }

    const createTask = (messageId, selectedId, isRequest = 0) => {
        var msgContent =messageList && messageList.filter((msg) => msg._id == messageId)[0].message;
        setTaskLoader(true);
        CustomerServices.createNewTask(localStorage.getItem("selectedOffice"), isRequest, 1, selectedId, "", msgContent).then((res) => {
            setTaskLoader(false);
            if (res.status == 1 && res.data.task_id) {
                var taskLink = process.env.REACT_APP_MYR_WEB_URL + "/task/" + res.data.hash;
                var taskId = res.data.task_id;
                var messageContent = (isRequest ? "Request" : "Task") + " #" + taskId + " - " + msgContent + " \n" + taskLink;
                var extraData = {
                    dataType: isRequest ? "request" : "task",
                    taskId: taskId,
                    hash: res.data.hash,
                };
                ChatServices.editMessage(localStorage.getItem("chatUserId"), localStorage.getItem("activeGroupId"), messageId, messageContent, extraData).then((res) => {
                    if (res.success) {
                        if (pageName = "ChatAi") {
                            let index = chatAndTasks.findIndex((msg) => msg._id === messageId)
                            let tempdata = chatAndTasks[index]

                            tempdata.message = messageContent
                            tempdata.extraData = extraData
                            tempdata.isEdited = 1
                            let data = (
                            Object.values({ ...chatAndTasks, [index]: tempdata })
                            )
                            dispatch({
                            type: EDIT_MESSAGE,
                            payload: { data },
                            });
                        }
                    } else {
                        dispatch(showMessage("unsucess", _l("l_error"), res.message));
                    }
                });
                HideDropdown();
                return dispatch(showMessage("sucess", _l("l_success"), _l("l_task_created")));
            } else {
            return dispatch(showMessage("unsucess", _l("l_error"), res.message));
            }
        });
    }

    const handleAddMessageAsComment = (textMessage) => {
        customerServices.addLinkComment(replyTaskId, projectId, $.isEmptyObject(selectedFile) ? textMessage : "", undefined, undefined, undefined, 0, !$.isEmptyObject(selectedFile) ? [selectedFile] : undefined, 0, "", 0, 0, "", "", 0, false).then((res) => {
            if (res.status) {
                let tempData = chatAiTasks
                let index = tempData.findIndex((task) => task.id == replyTaskId)
                if (index > -1) {
                    tempData[index].comments.unshift({ dateadded: new Date().toLocaleString(), content: textMessage })
                }
                setChatAiTasks(tempData.slice())
            }
        })
    }

    useEffect(() => {
        HideDropdown();
        if (!$.isEmptyObject(selectedTaskInfo)) {
            dispatch(getSelectedTask(localStorage.getItem("selectedOffice"), selectedTaskInfo.taskId, 0, 1));
        }
        setSelectedId(selectedTaskInfo.taskId)
    }, [selectedTaskInfo]);

    const handleSelectedTask = (rel_id, taskId = 0, show = "", isRequestedTask = null, convertRequestTaskId = 0) => {
        dispatch(getSelectedTask(rel_id, taskId, isRequestedTask == 1 ? 1 : null));
        setShowTaskModal(false);
        localStorage.setItem("SelectedTask", taskId);
        localStorage.setItem("TaskType", isRequestedTask && isRequestedTask != null ? isRequestedTask : 0);
        if (isRequestedTask || taskId) {
            setShowTaskModal(show);
        }
    };

    useEffect(() => {
        if (selectedTask) {
            setTask_type(selectedTask && selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task');
            setSelectedTaskId(selectedTask.task_id || selectedTask.id)
            setCommonModalData(selectedTask)
        }
    }, [JSON.stringify(selectedTask)])

    const getTaskList = (filter) => {
        switch (filter.type) {
        case "date":
            convertMenuFilters[filter.type] = moment(filter.value).format(
            "YYYY-MM-DD"
            );
            break;
        case "status":
            convertMenuFilters[filter.type] = filter.value;
            break;
        case "categories":
            convertMenuFilters[filter.type] = [filter.value];
            break;
        default:
            break;
        }
        CustomerServices.getTodoTaskList(0, convertMenuFilters).then((res) => {
            if (res.status && res.data) {
                setTodoTasksList(res.data);
            }
        });
    };

    const handleConvertTask = (slug = "", task_id = 0) => {
        dispatch(
            showConfirmation(_l("l_confirm"), _l("l_only_one_action_perform_at_a_time"), _l("l_convert"), _l("l_cancel"), () => { }, () => {
                const targetMessage = messageList.filter((msg) => msg._id == convertMessageId);
                if (targetMessage && targetMessage.length > 0) {
                    CustomerServices.convertMessage(slug, task_id, targetMessage[0].message).then((res) => {
                        dispatch(toastCloseAction());
                        if (res.status) {
                            var extraData = {
                                dataType: slug,
                                taskId: task_id
                            };
                            ChatServices.editMessage(localStorage.getItem("chatUserId"), localStorage.getItem("activeGroupId"), convertMessageId, targetMessage[0].message, extraData).then((res) => {
                                if (res.success) {

                                } else {
                                    dispatch(showMessage("unsucess", _l("l_error"), res.message));
                                }
                            });
                            setConvertMessageId("");
                            setShowConvertDropdown(false);
                            dispatch(getSelectedTask(localStorage.getItem("selectedOffice"), task_id, 0, 1));
                            setShowTaskInfo(true);
                            dispatch(showMessage("sucess", _l("l_success"), res.message));
                        } else {
                            dispatch(showMessage("sucess", _l("l_success"), res.message));
                        }
                    });
                }
            })
        );
    };

    useEffect(() => {
        if (defaultTopics && defaultTopics.length) {
            selectedTopic = localStorage.getItem("selectedTopic") && JSON.parse(localStorage.getItem("selectedTopic"));
            const providerTopics = defaultTopics.filter((x) => x.main_key === (userType == "contact" ? "provider_task" : "proposals"));
            let requestAssigneeList = [];
            defaultTopics.forEach((topic) => {
                switch (topic.main_key) {
                    case "my_task":
                        topic.categories.forEach((my_task) => {
                            if (!isNaN(parseInt(my_task.filter_key))) {
                                requestAssigneeList.push({
                                    main_key: topic.main_key,
                                    type: requestAssigneeList.findIndex((x) => x.type == topic.title) === -1 ? topic.title : "",
                                    name: my_task.title,
                                    id: my_task.filter_key,
                                    is_assigned: my_task.is_assigned || 0,
                                    image: my_task.image || "",
                                });
                            }
                        });
                        break;
                    case "provider_task":
                        topic.categories.forEach((provider) => {
                            requestAssigneeList.push({
                                main_key: topic.main_key,
                                type: requestAssigneeList.findIndex((x) => x.type == topic.title) === -1 ? topic.title : "",
                                name: provider.title,
                                id: provider.filter_key,
                                is_assigned: provider.is_assigned || 0,
                                image: provider.image || "",
                            });
                        });
                        break;
                    case "my_people":
                        topic.categories.forEach((provider) => {
                            requestAssigneeList.push({
                                main_key: topic.main_key,
                                type: requestAssigneeList.findIndex((x) => x.type == topic.title) === -1 ? topic.title : "",
                                name: provider.title,
                                id: provider.filter_key,
                                is_assigned: provider.is_assigned || 0,
                                image: provider.image || "",
                            });
                        });
                        break;
                    case "proposals":
                        topic.categories.forEach((proposal) => {
                            requestAssigneeList.push({
                                main_key: topic.main_key,
                                type:
                                requestAssigneeList.findIndex((x) => x.type == topic.title) === -1 ? topic.title : "",
                                name: proposal.title,
                                id: proposal.filter_key,
                                is_assigned: proposal.is_assigned || 0,
                                image: proposal.image || "",
                            });
                        });
                        break;
                    case "user_journey":
                        topic.categories.forEach((proposal) => {
                            requestAssigneeList.push({
                                main_key: topic.main_key,
                                type:
                                requestAssigneeList.findIndex((x) => x.type == topic.title) === -1 ? topic.title : "",
                                name: proposal.title,
                                id: proposal.filter_key,
                                is_assigned: proposal.is_assigned || 0,
                                image: proposal.image || "",
                            });
                        });
                        break;
                    case "myr_staff_task":
                        topic.categories.forEach((proposal) => {
                            requestAssigneeList.push({
                                main_key: topic.main_key,
                                type: requestAssigneeList.findIndex((x) => x.type == topic.title) === -1 ? topic.title : "",
                                name: proposal.title,
                                id: proposal.filter_key,
                                is_assigned: proposal.is_assigned || 0,
                                image: proposal.image || "",
                            });
                        });
                        break;
                    case "task_topic":
                        if (virtualSpaces && Object.keys(virtualSpaces).length) {
                            topic.categories.forEach((proposal) => {
                                requestAssigneeList.push({
                                    main_key: topic.main_key,
                                    type: requestAssigneeList.findIndex((x) => x.type == topic.title) === -1 ? topic.title : "",
                                    name: proposal.title,
                                    id: proposal.filter_key,
                                    is_assigned: proposal.is_assigned || 0,
                                    image: proposal.image || "",
                                });
                            });
                        }
                        break;
                    case "my_contact":
                    case "providers":
                        if(topic && topic.categories && topic.categories.length)
                        {
                            topic.categories.forEach((w) => {
                                if(w && selectedTopic && w.filter_key == selectedTopic.filter_key)
                                {
                                    selectedTopic.thread_id = w.thread_id;
                                    localStorage.setItem("selectedTopic", JSON.stringify(selectedTopic))
                                }
                            })
                        }
                        break;
                    default:
                        break;
                }
            });
            setAssigneeDropdownList(requestAssigneeList);
        }
    }, [defaultTopics]);

    const initReply = (messageId, slug = "message") => {
        setSlug(slug);
        if (slug == "schedule") {
            setReplyMessageId("")
            const targetMessage = messageList && messageList.filter((msg) => msg._id == messageId);
            if (targetMessage && targetMessage.length > 0) {
                        setReplyScheduleId(targetMessage[0].extraData.id);
                        setReplyMsgTask(targetMessage[0].extraData);
            }
            
        } else if (slug == "task") {
            setReplyMessageId("")
            const targetMessage = messageList && messageList.filter((msg) => msg._id == messageId);
            if (targetMessage && targetMessage.length > 0) {
                let task_id = targetMessage[0].extraData && targetMessage[0].extraData.id
                if (task_id > 0) {
                    setReplyTaskId(task_id);
                    let replyTask = chatTaskList.filter((task) => task.id == task_id)
                    if (replyTask.length > 0) {
                        setReplyMsgTask(replyTask[0]);
                    }
                } 
            }
        } else {
            setReplyMessageId(messageId);
            setReplyTaskId("");
            const targetMessage = messageList && messageList.filter((msg) => msg._id == messageId);
            if (targetMessage && targetMessage.length > 0) {
                setReplyMsgTask(targetMessage[0]);
            }
        }
        $("#text-message").focus();
    };

    const makeCall = (receiverId = "") => {
        dispatch(initCall({
            userId: localStorage.getItem("orgChatUserId"),
            receiverId: receiverId,
            extraData: {
                groupId: localStorage.getItem("activeGroupId")
            }
        }));
    };

    useEffect(() => {
        setCustomIndex(10)
    }, [chatUserId, loader])

    // ------------------------------------- chatAi start  ----------------------------------------------------------

    const [taskPinned, setTaskPinned] = useState(false);
    const [checkedTask, setCheckedTask] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [totalUnreadCount, SetTotalUnreadCount] = useState(0)
    const inputRef = useRef(null);
    const inputHeight = useRef();
    const messageListDiv = useRef();
    const [aichatMentionList, setAichatMentionList] = useState([]);
    let futureTaskCount = localStorage.getItem("futureTaskCount");
    let currentUnreadCount = localStorage.getItem("unread_chat_notification_count") ? localStorage.getItem("unread_chat_notification_count") : 1;
    let scrollHeight  = -1

    const getAiContextForChat = async() => {
        let contextDescription = "";
        let projectId = chatAIgroupdeatils.projectId;
        let clientId = chatAIgroupdeatils.clientId ? chatAIgroupdeatils.clientId : 0;
        let selectedDate = moment(localStorage.getItem("selectedDate")).format('YYYY-MM-DD');
        try {
            const response = await CustomerServices.getAiContextForChat(clientId, projectId, selectedDate);
            if(response.status){
                contextDescription = response.data;
            }
        }catch(e){
            console.error(e);
        }finally {
            return contextDescription;
        }
    };

    const taskOpenAiRequest = async(content) => {
        let projectId = chatAIgroupdeatils.projectId;
        try{
            const response = await CustomerServices.taskOpenAiRequest(content, projectId);
            if(response.status){
                // showSuccess("l_content_updated");
            }else if(response.message) {
                showError(response.message);
            }
        }catch(e){
            showError("l_something_went_wrong");
            console.error(e);
        }finally{
            setViewAIContext(false);
            localStorage.removeItem("firstDate");
            localStorage.removeItem("lastDate");
            dispatch(setDaterangeFilterApplied(false));
            dispatch(setSelectedDateForChatai(new Date()));
            dispatch(setShowWeekcalendardays(true));
            dispatch(setDaterangeFilter("today"));
            localStorage.setItem("selectedDate", new Date());
        }
    };


    const removeDuplicates = (arr) => {
        const uniqueIds = new Set(); // Create a Set to store unique ids
        return arr && arr.filter((obj) => {
            if (obj) {               
            
            if (!uniqueIds.has(obj.id || obj._id)) {
                uniqueIds.add(obj.id || obj._id); // Add the id to the Set if it's not already there
                return true; // Keep the object in the filtered array
            }
            return false; 
        }// Discard the object (it's a duplicate)
        });
    };

    useEffect(() => {
        if (chatAIgroupdeatils && chatAIgroupdeatils.members) {
            setAichatMentionList(
                chatAIgroupdeatils.members.filter((item) => item._id != localStorage.getItem("orgChatUserId")).map((item) => {
                    return {
                        id: item._id,
                        display: item.fname + " " + item.lname,
                        image: item.displayPicture != "" ? ATTACH_BASE_URL + item.displayPicture : USR_IMG_PLACEHOLDER
                    };
                })
            );
        }
    }, [chatAIgroupdeatils]);

    function urlify(text) {
        var urlRegex = /(?<!")(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url) {
            return '<a href="' + url + '">' + url + '</a>';
        }).replace(/<a[\s]+/gm, `<a class="color-green" target="_blank"`);
    } 

    const handleCancleReply = () => {
        if (!proposalId){
            setReplyMsgTask({});
        }
        setReplyMessageId("");
        setReplyTaskId("");
    }

    const onClick = (emojiData) => {
        setMessageText((text) => text + emojiData.emoji)
        setShowEmoji(false);
        var messageInput = $('#chatAIFeedInput');
        messageInput.focus();
    }

    useEffect(() => {
        if (groupDetails && groupDetails.firstUnreadMsgDate) {
            // dispatch(setSelectedDateForChatai(groupDetails.firstUnreadMsgDate))            
        }      
        // setprevPos(0);
        // setcurrentPos(0);
        if(groupDetails && Object.keys(groupDetails).length == 0){
            messageListDiv.current = null;
        }else{
            let el = document.getElementById("message-list")
            messageListDiv.current = el;

        }
    }, [JSON.stringify(groupDetails)])

    useEffect(() => {
    if (daterangeAppliedFilters == "date") {
        setChatmessageList([])    
    }
    }, [daterangeAppliedFilters, getSelectedCalendarDate])

    useEffect(() => {
        dispatch(setShowIsChatScreenDisplay(false));

        const messageList = document.getElementById('scrollableDiv'); // Replace 'messageList' with your actual message list element ID or ref
        // const mainDiv = document.getElementById('msg-main-div'); // Replace '.message' with your message element selector
    
        const handleScroll = () => {
   
          const scrollTop = messageList.scrollTop;
          const messages = [...messageList.querySelectorAll('.message')]; // Replace '.message' with your message element selector
          let currentDate = null;
          let visibleDate = null;
          let screenHeight =  (messageList.clientHeight + 130 )

          // Iterate through each message and check if it's in the viewport
          messages.forEach((message) => {
            const rect = message.getBoundingClientRect();
        
            // Check if the message is at least partially visible
            if ((rect.top + screenHeight) < (window.innerHeight + screenHeight) && rect.bottom - screenHeight >= 0) {
              // Get the date associated with the visible message
              visibleDate = message.getAttribute('date');
            }
          });
          if (visibleDate && ["today","date"].includes(daterangeAppliedFilters)) {
            dispatch(setSelectedDateForChatai(visibleDate));
          } else if (chatmessageList.length > 0 && ["today","date"].includes(daterangeAppliedFilters)) {
              dispatch(setSelectedDateForChatai(chatmessageList[chatmessageList.length - 1].createdAt));
          }{
            // dispatch(setSelectedDateForChatai(new Date()));
          }
    
          for (let i = messages.length - 1; i >= 0; i--) {
            const message = messages[i];
            const messageTop = message.offsetTop;
    
            if (messageTop <= Math.abs(scrollTop)) {
              currentDate = message.getAttribute("date")
              break;
            }
        }
    }

        messageList.addEventListener('scroll', handleScroll);
    
        return () => {
          messageList.removeEventListener('scroll', handleScroll);
        };
    
      }, []);
      useEffect(() => {
        let ids = [];
        if (messageList && messageList.length > 0) {
            messageList.map((msg)=>{
                if (msg && msg.extraData && (msg.extraData.slug == "task" || msg.extraData.slug == "schedule")) {
                    ids.push(msg.extraData.id)                    
                }
            })    
            let msgList = chatmessageList.concat(messageList)
            if (localStorage.getItem("space_setup_pending") == 1) {
                setChatmessageList(removeDuplicates(msgList.reverse()))
            } else {
                setChatmessageList(removeDuplicates(msgList.reverse().filter(msg => msg.group == activeGroupId)))
            }
                   
        }
        if (ids.length > 0) {
            setMessageTaskIds(ids)
        }
        // scrollToBottom()
        if (messageListDiv && messageListDiv.current) {
            let tempHeight = messageListDiv.current.scrollHeight - messageListDiv.current.clientHeight
            setprevPos(tempHeight)
        }
        setMessageLoader(false)
        dispatch(chatMessageLoader(false));
    }, [messageList])
    
    useEffect(() => {
        if (messageTaskIds && messageTaskIds.length > 0) {
            dispatch(getTasksDetailsForChat(projectId, messageTaskIds))
        }
    }, [JSON.stringify(messageTaskIds)])

    useEffect(() => {
        if (chatTaskList) {
            let tempArr = chatTaskList.concat(taskListForChat)
            setTaskListForChat(taskListForChat.concat(chatTaskList))            
        }
      }, [chatTaskList])
      
      useEffect(() => {
        let el =document.getElementById("unread-message-seperator")
        if (el && firstIdofMessage == "") {
            el.scrollIntoView({behavior: "instant", block:"center",inline:"start"})
        }
        if (firstIdofMessage == "" && messageList.length > 0) {
            dispatch(setSelectedDateForChatai(messageList[messageList.length - 1].createdAt));
            dispatch(setIDofFirstMessage(messageList && messageList[0] ? messageList[0].createdAt : "")) 
        }else if(messageList.length > 0 && messageList.length < 20){
            dispatch(setSelectedDateForChatai(messageList[messageList.length - 1].createdAt));
            
        }
        dispatch(setIDofFirstMessage(messageList && messageList[0] ? messageList[0].createdAt : "")) 
      }, [chatmessageList,messageList])
      
      const scrollToBottom = () => {
        var msgListDiv = document.getElementById("message-list");
        if(msgListDiv){
          msgListDiv.scrollTop = msgListDiv.scrollHeight;
        }
      };
      const handleChatScroll = (e) =>{
        scrollHeight = (messageListDiv.current.scrollHeight - messageListDiv.current.clientHeight);
        if (messageListDiv && messageListDiv.current && messageListDiv.current && messageListDiv.current.scrollTop == 0 && preDataAvaliable) {
            // scroll to top of message list
            setMessageLoader(true)
            dispatch(fetchMessages( 
                localStorage.getItem("chatUserId"), 
                localStorage.getItem("activeGroupId"),
                0,
                ()=>{},
                1,
                firstIdofMessage
                ))
        }
        if (messageListDiv.current.scrollTop == scrollHeight && firstIdofMessage != "") {
            // scroll to bottom of message List
            // console.log("scroll at the bottom")
            
        }
        // dispatch(setIDofFirstMessage(messageList && messageList[0] ? messageList[0].createdAt : ""))
      }

      useEffect(() => {

        if (groupDetails && groupDetails.firstUnreadMsgId && groupDetails.firstUnreadMsgId != "" || currentUnreadCount > 0) {
            setTimeout(() => {
                currentUnreadCount = 0;
                localStorage.setItem("unread_chat_notification_count",0)        
            }, 2500);
        }
      }, [groupDetails,currentUnreadCount])

      useEffect(() => {
        if (isSolarHubPlatform() ) {
            setChatmessageList(messageList.reverse());
        } else if (lastAppliedFilter.main_key != "date_range_filter" && Number(localStorage.getItem("space_setup_pending")) != 1 && Number(localStorage.getItem("is_registered")) != 1) {
            setChatmessageList([])
        } else if(Number(localStorage.getItem("space_setup_pending")) == 1){
            setChatmessageList(messageList.reverse())
        }else if(localStorage.getItem("is_registered") == 1){
            setChatmessageList(messageList.reverse())
        }else{
            setChatmessageList(chatmessageList.filter((msg) => moment(msg.createdAt).isSameOrAfter(fromDate) && moment(msg.createdAt).isSameOrBefore(endDate)))
        }
    }, [lastAppliedFilter])

    useEffect(() => {
        if (isAppliedUnreadComment && taskList.length > 0) {
            let lastTask = taskList.sort((a,b)=>{
                let event_dateA = "";
                if ((a.schedule_id > 0 || a.rel_type == "equipment" || a.is_requested == 1)) {
                    event_dateA = a.startdate
                } else if (a.startdate != null && a.startdate != "") {
                    event_dateA = a.startdate
                  } else if (a.duedate != null && a.duedate != "") {
                    event_dateA = a.duedate
                  }
                let event_dateB = "";
                if ((b.schedule_id > 0 || b.rel_type == "equipment" || b.is_requested == 1)) {
                    event_dateB = b.startdate
                } else if (b.startdate != null && b.startdate != "") {
                    event_dateB = b.startdate
                  } else if (b.duedate != null && b.duedate != "") {
                    event_dateB = b.duedate
                  }
                // return moment(event_dateA, 'hh:mm A').diff(moment(event_dateB, 'hh:mm A'));
                return event_dateA.localeCompare(event_dateB) ;
            })
            lastTask = lastTask[lastTask.length - 1]
            let date = lastTask.startdate != "" && lastTask.startdate ? lastTask.startdate : lastTask.duedate != "" && lastTask.duedate ? lastTask.duedate : lastTask.converted_date
            dispatch(setSelectedDateForChatai(formateDate(date)));
        } else  if(messageList.length > 0 ){
            // dispatch(setSelectedDateForChatai(messageList[messageList.length - 1].createdAt));
            // scrollToBottom()
            
        }
    }, [isAppliedUnreadComment, taskList])          
    const fetchMoreData = () => {
        if (preDataAvaliable) {
            dispatch(fetchMessages(         
            localStorage.getItem("chatUserId"), 
            localStorage.getItem("activeGroupId"),
            0,
            ()=>{},
            1,
            firstIdofMessage,
            // ["today","date"].includes(daterangeAppliedFilters) ? "" : fromDate ,
            // ["today","date"].includes(daterangeAppliedFilters) ? "" : endDate
            ))
        }
    };
      /* ------------------------------------------------------------------------------- */
      if (uploadFileName != "" && uploadFileName != "undefined") {
        setFileUploadLoader(false);
        var msgData = {
            groupId: localStorage.getItem("activeGroupId"),
            userId: localStorage.getItem("chatUserId"),
            message: uploadFileName,
            type: selectedFileType,
        };
        var textMessage = messageText;
        textMessage = textMessage.trim();
        if (textMessage != "") {
            msgData.caption = textMessage;
        }
        if (replyMessageId != "") {
            msgData.replyMsgId = replyMessageId;
        }
        dispatch(sendMessage(msgData));
        dispatch(updateUploadedFileName());
        setSelectedFile({});
        setSelectedFileSrc("");
        setMessageText("");
        setReplyMessageId("");
        setSelectedFile({});
        jquery("#chatAIFeedInput").val("")
        handleCancleReply()
    }

    const setUnreadMessage =(id)=>{
        ChatServices.setCustomUnreadMessage(id).then((res)=>{
            if (res && res.success == true) {
                let tempGroup = groupDetails
                    tempGroup.firstUnreadMsgId = id
                    dispatch(getGroupDetails(tempGroup));
                    let unreadCount = res && res.result && res.result.unreadCount ? res.result.unreadCount : 0;
                    localStorage.setItem("unread_chat_notification_count",unreadCount)
                    dispatch(setSpaceNotificationCount(projectId, unreadCount, 1, "chat"));
                    dispatch(setVirtualSpaceNotificationCount(projectId, unreadCount, 1, "chat"));
                    dispatch(chatUnreadMessageCount(activeGroupId, 'add', "chatMSG", unreadCount)) /// burger menu
                    setshowOptions(0)
            }else{
                dispatch(showMessage("unsucess", _l("l_error"),_l(res.message)));                 
            }
        });
    }
    let el = document.getElementById("unread-message-seperator")
    useEffect(() => {
        if (el && stopScroll == false) {
            el.scrollIntoView({ behavior: "instant", block: "center", inline: "start" })
            el = null
        }
    },[activeGroupId,el])
    
  /* ------------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log("msgloader", msgloader);
}, [msgloader])

    return (
        msgloader ? <><div id="scrollableDiv">
            <CommanLoader className="position-absolute start-0" />
            {/* <FullScreenChatBoxSkeleton /> */}
            </div> </> :
        <div
        id="msg-main-div"
            className={`${(pageName == "ChatAi" || globalView == "AichatView") ? "h-100" : ""} right-content-part width-calc-300 chat-main-box position-relative d-flex flex-column`}
            onPaste={(e) => setCopiedFiles(e)}
            onDragOver={(e) => dragOverHandler(e)}
            onDrop={(e) => dropHandler(e)}
            onKeyUp={(e) => {
                if (e.key === "Escape") {
                    setReplyMessageId("");
                }
            }}
        >
            <Lightbox
                open={openGallery}
                close={() => setOpenGallery(false)}
                slides={[{ src: imagePreviewSrc }]}
            />        
            <div className={`${(pageName == "ChatAi" || globalView == "AichatView") ? "h-100" : "comman_vertical_btn_h scroll_60"} position-relative`}>
                <>
                    {showEmoji ?
                    <EmojiPicker
                        onEmojiClick={onClick}
                        autoFocusSearch={false}
                        theme="dark"
                        lazyLoadEmojis={true}
                        previewConfig={{
                            // defaultEmoji: string; // defaults to: "1f60a"
                            // defaultCaption: string; // defaults to: "What's your mood?"
                            showPreview: false // defaults to: true
                        }}
                    />
                    : <></>
                    }   
                    <div ref={inputRef} className={`aicommentbox radius_3 ms-0 bg-transparent ${commanoffcanvasIsOpen ? "aiinputrelative" : ""}`} id="input-box">{/*${aiFooterUpdate ? "with-toggle-input":""}${aiChatInput ? "":"d-none z-index-12"}*/}
                    <div className="stepper-wrapper d-none">
                            <div className="stepper-item completed">
                                <div className="step-counter">1</div>
                                <div className="step-name">Demande</div>
                            </div>
                            <div className="stepper-item completed">
                                <div className="step-counter">2</div>
                                <div className="step-name">Manque document</div>
                            </div>
                            <div className="stepper-item active">
                                <div className="step-counter">3</div>
                                <div className="step-name">En cours</div>
                            </div>
                            <div className="stepper-item">
                                <div className="step-counter">4</div>
                                <div className="step-name">Complétée</div>
                            </div>
                        </div>
                        {Object.keys(replyMsgTask).length > 0 && !proposalId?
                        <div className="aicommentbox-reply p-2" id="aicommentbox-reply">
                        { proposalId > 0 ? <></> :
                            <a href="#/" className="aicomment-reply-close"onClick={() => { handleCancleReply() }}>
                                <X weight="light" size="14" className="c-icons" />
                            </a>
                        }
                            <div className="d-flex flex-wrap align-items-center">
                                <ArrowBendUpLeft weight="light" size="18" className="c-icons" />
                                <div className="overflowscroll w100minus30">
                                    {slug == "task" ?
                                    <div className="m-w40">
                                        <TaskCard
                                            key={replyMsgTask.id}
                                            task={replyMsgTask}
                                            project_id={projectId}
                                            handler={() => { }}
                                            pageName='ChatAi'
                                            taskCheckUncheck={() => { }}
                                            checkedTask={""}
                                            setTaskPinned={() => { }}
                                            setTaskId={() => { }}
                                            setCallUserList={() => { }}
                                            fromReply={true}
                                            showOptions={showOptions}
                                            setshowOptions={setshowOptions}
                                        />
                                    </div> 
                                    : slug == "schedule" ? 
                                    <div className="m-w40">
                                       <ScheduleCard
                                         benchmark={replyMsgTask}
                                         fromChat={true} 
                                         fromReply={true}
                                         proposal_id={proposalId}
                                         />
                                    </div> 
                                    : <div className={`d-flex justify-content-start base-body-color radius_3 overflow-hidden p-1 ${replyMsgTask.type == "audio" ? "flex-column" :"align-items-center"}`}>
                                        <div className="color-white-60">
                                            <i className="font-italic d-none">{replyMsgTask.senderName}</i>
                                            { replyMsgTask.type == "text" ?
                                            ReactHtmlParser(urlify(replyMsgTask.message.replace(/(?:\r\n|\r|\n)/g, '<br>')))
                                            : <></>}
                                            {replyMsgTask.caption != "" ?
                                            ReactHtmlParser(urlify(replyMsgTask.caption.replace(/(?:\r\n|\r|\n)/g, '<br>')))
                                            : <></>}
                                        </div>
                                        {replyMsgTask.type != "text" && replyMsgTask.type != "audio" ?
                                        <div className="h50w50 with-bg d-flex align-items-center bg-white-05">
                                            <div className="comman-bg-img h-100 w-100 bg-style-cover " style={{ backgroundImage: `url('${encodeURI(ATTACH_BASE_URL + replyMsgTask.message)}')` }}></div>
                                        </div>
                                        : <>
                                        {replyMsgTask.type == "audio" ?
                                        <div className="chat-audio">
                                        <AudioPlayer
                                          style={{width: '300px'}}
                                          layout="horizontal"
                                          // autoPlay
                                          className="audio_player"
                                          showJumpControls={false}
                                          showSkipControls={false}
                                          customAdditionalControls={[]}
                                          customVolumeControls={[]}
                                          src={encodeURI(ATTACH_BASE_URL + replyMsgTask.message)}
                                          autoPlay={false}
                                          autoPlayAfterSrcChange={false}
                                        />
                                      </div> : <></>}</>}
                                    </div>
                                    }
                                </div>
                                <div className="c-font color-white-60 f-11 ms-auto mt-2 w100minus30">
                                    <span className="with_separator_10 ps-0">{replyMsgTask.creator_name ? replyMsgTask.creator_name : replyMsgTask.senderName}</span>
                                    <span className="with_separator_10">{moment(replyMsgTask.createdAt ? replyMsgTask.createdAt : replyMsgTask.startdate != null ? replyMsgTask.startdate : replyMsgTask.duedate != null ? replyMsgTask.duedate : replyMsgTask.dateadded).format("DD/MM/YYYY HH:mm")}</span>
                                </div>
                            </div>
                        </div> 
                        : <></>}
                        {selectedFileSrc != "" &&
                        <div className="upload-image-preview py-2">
                            <div className="d-flex align-items-center justify-content-start">
                                <div className={`${["image", "pdf", "other_file", "video"].includes(selectedFileType) ? "h70w70" : "bg-transparent mb-2"} comman-image-box radius_3 upload-image with-margin position-relative`}>
                                    {["image", "pdf", "other_file"].includes(selectedFileType) ?
                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={selectedFileType == "image" ? { backgroundImage: `url('${selectedFileSrc}')` } : {}}>
                                        {selectedFileType == "pdf"
                                            ? <FilePdf color="#00ff6a" weight="light" className="h-100 w-100 " />
                                            : selectedFileType == "other_file"
                                                ? <FileDoc color="#00ff6a" weight="light" className="h-100 w-100 " />
                                                : <></>
                                        }
                                    </div>
                                    : selectedFileType == "audio" ? 
                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 chat-audio">
                                        <AudioPlayer
                                            style={{ width: '300px' }}
                                            layout="horizontal"
                                            // autoPlay
                                            className="audio_player"
                                            showJumpControls={false}
                                            showSkipControls={false}
                                            customAdditionalControls={[]}
                                            customVolumeControls={[]}
                                            src={selectedFileSrc}
                                            autoPlay={false}
                                            autoPlayAfterSrcChange={false}
                                        />
                                    </div>
                                    : selectedFileType == "video" ? 
                                    <>
                                        <video controls className="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex" src={selectedFileSrc} ></video>
                                        <PlayCircle size={20} color="#00ff6a" weight="light" className=" play-icon" />
                                    </>
                                    : <></>
                                    }
                                    <a href="#/"
                                        className="upload-close"
                                        onClick={() => {
                                            setSelectedFile({});
                                            setSelectedFileSrc("");
                                        }}
                                    >
                                        <X size={10} weight="light" className="c-icons" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="d-flex m-auto chat-main-box gap-3">
                            <div className={`comment-input-box d-flex flex-grow-1 align-items-center chat-comment-box ${disableInputChat ? 'for-disabled' : ''}`}>
                                <div class="h32w32 comman-image-box with-bg rounded-circle bg-black-op5">
                                    <div class="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                                        backgroundImage: `url('${localStorage.getItem("contact_image")}')`,
                                    }} >
                                    </div>
                                </div>
                                <div className="mension-box mension-box--multiLine custom-width withpm4px" style={{position: "relative", overflowY: "visible"}}>
                                    <MentionsInput
                                        id="chatAIFeedInput"
                                        // ref={inputHeight}
                                        value={messageText}
                                        className="mension-box"
                                        placeholder={`${_l("l_write_message_placeholder")}`}
                                        alwaysOpen={true}
                                        style={mentionInputStyle}
                                        onInput={(e) => { adjustDynamicInputHeight(inputHeight, e) }}
                                        onChange={(e) => {
                                            setMessageText(e.target.value);
                                            if(!isTyping){
                                                setIsTyping(true);
                                                setTimeout(() => {
                                                    setIsTyping(false);
                                                }, 3000);
                                                dispatch(sendTyping({userId: localStorage.getItem("chatUserId"), groupId: localStorage.getItem("activeGroupId")}));
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && !e.ctrlKey && !e.shiftKey) {
                                                e.preventDefault();
                                                if (!$.isEmptyObject(selectedFile)) {
                                                    uploadMedia(selectedFile);
                                                } else {
                                                    if (editMessageId != "") {
                                                        editMessage();
                                                    } else {
                                                        sendChatMessage();
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <Mention
                                            markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                                            trigger="@"
                                            data={aichatMentionList}
                                            displayTransform={(id, display) => `@${display}`}
                                            style={mentionStyle}
                                            renderSuggestion={(
                                                suggestion,
                                                search,
                                                highlightedDisplay
                                            ) => {
                                                return (
                                                    <div className="user d-flex align-items-center">
                                                        <div className="comman-image-box h25w25 rounded-circle mx-2">
                                                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{
                                                                backgroundImage: `url('${suggestion.image}')`,
                                                            }}></div>
                                                        </div>
                                                        {highlightedDisplay}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </MentionsInput>
                                </div>
                                <div className={`d-flex align-items-center ${disableInputChat ?  'disabledElenent' : ''}`} >
                                    {selectedTopic && selectedTopic.thread_id && selectedTopic.thread_id !== '' && selectedTopic.main_key  && selectedTopic.main_key  != "my_contact" && adminAsStaff ?
                                    <div className="comman_action_icon me-2">
                                        <a href="#/" className={`action_icon with_bg position-relative h32w32 `}
                                        data-tip={_l("l_ai_context")}
                                        onClick={() => {sendChatMessage('', 2)}}
                                        >
                                        <Cpu
                                            size={18}
                                            weight="light"
                                            className="c-icons"
                                        />
                                        </a>
                                    </div> 
                                    : <></>}
                                    <div className="comman_action_icon me-2">
                                        <a href="#/"
                                            className="action_icon with_bg position-relative h32w32"
                                            data-tip={_l("l_choose_file")}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                        >
                                            <input
                                                type="file"
                                                // accept="image/png, image/jpeg, image/jpg"
                                                id="chat-file"
                                                className="absolute-input"
                                                onChange={(e) => selectFile(e.target.files[0])}
                                            />
                                            <Paperclip size={16} weight="light" className="c-icons" />
                                        </a>
                                    </div>
                                    <div className="comman_action_icon me-2">
                                        <a href="#/" className={`action_icon with_bg position-relative h32w32 `} onClick={() => { 
                                            setShowEmoji(!showEmoji) 
                                        }}>
                                            <Smiley size={18} className="c-icons" weight="light" />
                                        </a>
                                    </div>
                                    <div className="">
                                        <a href="#/" className={`btn btn-primary h32wauto lh-sm imginvert`} >
                                            <div alt={"White arrow"} className="">
                                            {fileUploadLoader ? 
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            : <div
                                                className=" d-flex align-items-center"
                                                data-tip={_l("l_send")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                                onClick={() => {
                                                    if (!$.isEmptyObject(selectedFile)) {
                                                        uploadMedia(selectedFile);
                                                    } else {
                                                        if (editMessageId != "") {
                                                            editMessage();
                                                        } else {
                                                            sendChatMessage();
                                                        }
                                                    }
                                                }}
                                            >
                                                <span className="me-10px c-font f-12">{_l("l_send")}</span>
                                                <ArrowRight size={16} className="c-icons" />
                                            </div>
                                            }
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <Button variant="white-05" size="sm" 
                             data-tip={_l("l_export_pdf")}
                             data-effect="solid"
                             data-class="tooltip-main"
                             data-place="top"
                             className="d-none"
                            >
                                {/* <span className="me-10px">{_l("l_export_pdf")}</span> */}
                                <Export size="20" className="c-icons" />
                            </Button>
                        </div>
                    </div>
                </>
                <div
                className={`comman-content-scroll-wrapper chat-wrapper  position-relative  ${isSolarHubPlatform() ? "sloar-scrollheight" : "scrollheight"} ${proposalId ? "h130px" : ""}`}
                    id="scrollableDiv"
                    style={{
                        // height: 800,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    {/*Put the scroll bar always on the bottom*/}<>
                    {isAppliedUnreadComment ? <>
                    {
                       taskList && taskList.length > 0  ?
                             <InfiniteScroll
                              className={`comman-content-scroll scroller ${pageName === "ChatAi" ? "comman-main-box pb-0 position-relative" : ""} ${messageLoader ? "overflow-hidden" : "overflow-hiiden-auto"}`}
                              dataLength={taskList.length}
                              next={()=>{}}
                              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                              inverse={true} //
                              hasMore={true} 
                              scrollableTarget="scrollableDiv"
                              initialScrollY={60}                      
                             >
                                        {taskList &&taskList.sort((a,b)=>{
                                            let event_dateA = "";
                                            if ((a.schedule_id > 0 || a.rel_type == "equipment" || a.is_requested == 1)) {
                                                event_dateA = a.startdate
                                            } else if (a.startdate != null && a.startdate != "") {
                                                event_dateA = a.startdate
                                            } else if (a.duedate != null && a.duedate != "") {
                                                event_dateA = a.duedate
                                            }
                                            let event_dateB = "";
                                            if ((b.schedule_id > 0 || b.rel_type == "equipment" || b.is_requested == 1)) {
                                                event_dateB = b.startdate
                                            } else if (b.startdate != null && b.startdate != "") {
                                                event_dateB = b.startdate
                                            } else if (b.duedate != null && b.duedate != "") {
                                                event_dateB = b.duedate
                                            }
                                            // return moment(event_dateA, 'hh:mm A').diff(moment(event_dateB, 'hh:mm A'));
                                            return event_dateB.localeCompare(event_dateA) ;
                                        }).map((task, index) => {
                                            let taskdateSeperator = ""
                                            var dateId = ""
                                            if (task.schedule_id > 0 || task.rel_type == "equipment" || task.is_requested == 1) {
                                                 if (task.startdate != null && task.startdate != "") {
                                                  taskdateSeperator = (moment(task.startdate)).format("DD/MM/YYYY");
                                                  dateId = (formateDate(task.startdate));
                                                } else if (task.duedate != null && task.duedate != "") {
                                                  taskdateSeperator = (moment(task.duedate)).format("DD/MM/YYYY");
                                                  dateId = (formateDate(task.duedate));
                                                } 
                                              }else{  
                                                if (task.duedate != null && task.duedate != "") {
                                                  taskdateSeperator = (moment(task.duedate)).format("DD/MM/YYYY");
                                                  dateId = (formateDate(task.duedate));
                                                } else if (task.startdate != null && task.startdate != "") {
                                                  taskdateSeperator = (moment(task.startdate)).format("DD/MM/YYYY");
                                                  dateId = (formateDate(task.startdate));
                                                } 
                                              }
                                            var prevMsgdate = localStorage.getItem("prevMsgdateoftask");
                                            if (index == [taskList.length - 1]) {
                                                prevMsgdate = "";
                                            }
                                            localStorage.setItem("prevMsgdateoftask", taskdateSeperator);


                                            return (<>
                                                {prevMsgdate != taskdateSeperator && index != 0 && prevMsgdate != ""?
                                                    <div className="chat-date-seperator" date={dateId} id={`seperator-${prevMsgdate}`}>
                                                        <span className="seperator-line"></span>
                                                        <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                            {moment(yesterdayDate).format("DD/MM/YYYY") == prevMsgdate ? _l("l_yesterday")
                                                                : moment(new Date()).format("DD/MM/YYYY") == prevMsgdate ? _l("l_today")
                                                                    : moment(tomorrowDate).format("DD/MM/YYYY") == prevMsgdate ? _l("l_tomorrow")
                                                                        : prevMsgdate
                                                            }
                                                        </span>
                                                        <span className="seperator-line"></span>
                                                    </div>
                                                    : <></>
                                                }
                                                <div className={`message`} date={dateId}>
                                                    <div className={`d-flex chat-box with-padding width-custom ${commanoffcanvasIsOpen ? "" : ""} ${task.creator_name != "" && task.creator_name != undefined && task.creator_name == localStorage.getItem('full_name') ? "right-side-msg" : ""} ${task.id == selectedTask.id ? "z-index-7" : ""}`} id={`task_${task.id}`}>
                                                        <div className="d-flex w-100">

                                                            <TaskCard
                                                                key={task.id}
                                                                task={task}
                                                                active={selectedTaskId == task.id}
                                                                project_id={projectId}
                                                                handler={handleSelectedTask}
                                                                pageName='ChatAi'
                                                                taskCheckUncheck={() => { }}
                                                                checkedTask={checkedTask}
                                                                taskPinned={taskPinned}
                                                                setTaskPinned={setTaskPinned}
                                                                contacts={contacts}
                                                                setTaskId={() => { }}
                                                                setCallUserList={() => { }}
                                                                initReply={initReply}
                                                                replyTaskId={replyMessageId}
                                                                showOptions={showOptions}
                                                                setshowOptions={setshowOptions}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {taskdateSeperator !=  prevMsgdate && prevMsgdate == "" &&  (index == 0 || index == (taskList.length - 1))? 
                                        <div className="chat-date-seperator" date={dateId} id={`seperator-${taskdateSeperator}`}>
                                            <span className="seperator-line"></span>
                                            <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                {moment(yesterdayDate).format("DD/MM/YYYY") == taskdateSeperator ?  _l("l_yesterday") 
                                                : moment(new Date()).format("DD/MM/YYYY") == taskdateSeperator ?  _l("l_today")
                                                : moment(tomorrowDate).format("DD/MM/YYYY") == taskdateSeperator ? _l("l_tomorrow") 
                                                : taskdateSeperator
                                                }
                                            </span>
                                            <span className="seperator-line"></span>
                                        </div>
                                        :                                     
                                         prevMsgdate != taskdateSeperator && prevMsgdate == ""  ? 
                                        <div className="chat-date-seperator" date={dateId} id={`seperator-${taskdateSeperator}`}>
                                            {/* <span className="seperator-line"></span>
                                            <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                {moment(yesterdayDate).format("DD/MM/YYYY") == taskdateSeperator ?  _l("l_yesterday") 
                                                : moment(new Date()).format("DD/MM/YYYY") == taskdateSeperator ?  _l("l_today")
                                                : moment(tomorrowDate).format("DD/MM/YYYY") == taskdateSeperator ? _l("l_tomorrow") 
                                                : taskdateSeperator
                                                }
                                            </span>
                                            <span className="seperator-line"></span> */}
                                        </div>
                                        : <></>
                                        }

                                            </>)
                                        })}

                             </InfiniteScroll>
                             :
                             <CommanPlaceholder mainSpacing="h-100" imgType="no-chat" placeholderText={_l("l_no_data")} />
                    }
                    </> : <>
                    { chatMessagesLoader ? 
                        <FullScreenChatBoxSkeleton /> :  
                    chatmessageList && chatmessageList.length > 0?
                    <InfiniteScroll
                    className={`comman-content-scroll scroller comman-main-box pb-0 position-relative ${messageLoader ? "overflow-hidden" : "overflow-hiiden-auto"}`}
                        dataLength={chatmessageList.length}
                        next={fetchMoreData}
                        style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.

                        inverse={true} //
                        hasMore={true} 
                        loader={<><div className={`d-flex align-item-center justify-content-center mt-10 ${preDataAvaliable ? "" : "d-none"}`}><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`opacity-1`}
                        aria-hidden="true"
                      /></div></>}
                        scrollableTarget="scrollableDiv"
                        initialScrollY={60}
                        
                    >
                        {/* <div className={`comman-content-scroll-wrapper chat-wrapper position-relative`}>
                    <div data-offset="50" data-target=".c-gradinet-active-border" className={`comman-content-scroll scroller ${pageName === "ChatAi" ? "comman-main-box pb-0 position-relative" : ""} ${messageLoader ? "overflow-hidden" : "overflow-hiiden-auto"}`} id="message-list" ref={messageListDiv}
                    > */}
                    <div className="d-flex flex-column-reverse" id="message-group">
                        {chatMessagesLoader ? <FullScreenChatBoxSkeleton />  
                        : chatmessageList && chatmessageList.length > 0  ? chatmessageList.sort((a,b)=> {
                        const timeA = a.createdAt ? moment(a.createdAt) : moment(new Date())
                        const timeB = b.createdAt ? moment(b.createdAt) : moment(new Date())

                        if (timeA.isBefore(timeB)) {
                            return 1;
                          }
                          if (timeA.isAfter(timeB)) {
                            return -1;
                          }
                          return 0;
                    }
                        ).filter((m) => m.message ? m.message.toLowerCase().includes(globalSearchString.toLowerCase()) : m.name ? m.name.toLowerCase().includes(globalSearchString.toLowerCase()) : "")
                            .map((msg, index) => {
                                var dateSeperator = moment(msg.createdAt).format("DD/MM/YYYY");
                                var dateId = moment(msg.createdAt).format("YYYY-MM-DD");
                                var prevMsgdate = localStorage.getItem("prevMsgdate");
                                if (index == [chatmessageList.length - 1]) {
                                    prevMsgdate = "";
                                }
                                localStorage.setItem("prevMsgdate", dateSeperator);
                                var senderName = groupMembers[msg.user] ? groupMembers[msg.user].fname + ' ' + groupMembers[msg.user].lname : "";
                                var senderImage = groupMembers[msg.user] ? groupMembers[msg.user].displayPicture : "";
                                msg.senderName = senderName;
                                msg.senderImage = senderImage;
                                let msgId = msg && msg.extraData && msg.extraData.id 
                                let taskDetails = taskListForChat && taskListForChat.length > 0 && taskListForChat.filter((t) => t.id ==  msgId) && taskListForChat.filter((t) => t.id == msgId)[0]
                                let element = chatmessageList && chatmessageList.length > 0 && chatmessageList.filter((t) => moment(t.createdAt).format("DD/MM/YYYY") == dateSeperator)
                                return (
                                    <>
                                        {/* {currentUnreadCount && currentUnreadCount > 0 ? */}
                                     {prevMsgdate != dateSeperator && prevMsgdate != "" &&  msg.group ==  activeGroupId && index != 0 ?  
                                        <div className="chat-date-seperator" date={prevMsgdate} id={`seperator-${prevMsgdate}`}>
                                            <span className="seperator-line"></span>
                                            <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                {moment(yesterdayDate).format("DD/MM/YYYY") == prevMsgdate ?  _l("l_yesterday") 
                                                : moment(new Date()).format("DD/MM/YYYY") == prevMsgdate ?  _l("l_today")
                                                : moment(tomorrowDate).format("DD/MM/YYYY") == prevMsgdate ? _l("l_tomorrow") 
                                                : prevMsgdate
                                                }
                                            </span>
                                            <span className="seperator-line"></span>
                                        </div>
                                        : <></>
                                        }
                                     
                                        {msg.type == "info" &&  msg.group ==  activeGroupId ?
                                        <div className="chat-date-seperator message" date={dateId}>
                                            <span className="date-text color-white-80 c-font f-12 title-fonts fw-bolder">
                                                <div className="chat-send-name c-font f-10 opacity-50 text-center">
                                                    <div className="align-items-center">
                                                        <span>{moment(msg.createdAt).format("HH:mm")}</span>
                                                    </div>
                                                </div>
                                                <span className="color-red">{msg.message}</span>
                                            </span>
                                        </div>
                                        : msg.extraData && msg.extraData && msg.extraData.slug == "schedule" && msg.group ==  activeGroupId
                                        ? <ScheduleCard
                                         benchmark={msg.extraData}
                                         fromChat={true} 
                                         msgid={msg._id}
                                         initReply={initReply}
                                         proposal_id={proposalId}
                                         />
                                        :  msg.extraData && msg.extraData && msg.extraData.slug == "new_space" && msg.group ==  activeGroupId
                                        ? <Mapcard data={msg} />
                                        :msg.extraData && msg.extraData.slug == "task" && msg.group ==  activeGroupId ? /* extraData && msg.extraData.slug == "task" */ 
                                        <div className={`message`} date={dateId}> 
                                            <div className={`d-flex chat-box with-padding width-custom ${commanoffcanvasIsOpen ? "" : ""} ${msg.creator_name != "" && msg.creator_name != undefined && msg.creator_name == localStorage.getItem('full_name') ? "right-side-msg" : ""} ${msg.extraData.id && selectedTask && selectedTask.id ? msg.extraData.id == selectedTask.id ? "z-index-7" :"" :"" }`} id={`task_${msg.extraData.id}`}>
                                                    <div className="d-flex w-100">
                                                        <TaskCard
                                                        key={msg._id}
                                                        task={taskDetails && msg.group ==  activeGroupId ? taskDetails : {}}
                                                        active={selectedTask && msg && msg.extraData && msg.extraData.id == selectedTask.id}
                                                        project_id={projectId}
                                                        handler={handleSelectedTask}
                                                        pageName='ChatAi'
                                                        taskCheckUncheck={() => { }}
                                                        checkedTask={checkedTask}
                                                        taskPinned={taskPinned}
                                                        setTaskPinned={setTaskPinned}
                                                        contacts={contacts}
                                                        setTaskId={() => { }}
                                                        setCallUserList={() => { }}
                                                        initReply={initReply}
                                                        replyTaskId={replyMessageId}
                                                        showOptions={showOptions}
                                                        setshowOptions={setshowOptions}
                                                        setUnreadMessage={setUnreadMessage}
                                                        msgid={msg._id}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                        : msg.group ==  activeGroupId ?
                                        <div className="message" date={dateId}>
                                            <MsgCard
                                                msg={msg}
                                                setOpenGallery={setOpenGallery}
                                                setImagePreviewSrc={setImagePreviewSrc}
                                                editMessage={editMessageText}
                                                deleteMessage={deleteMessage}
                                                createTask={createTask}
                                                taskLoader={taskLoader}
                                                assigneeOptions={assigneeOptions}
                                                initReply={initReply}
                                                replyMessageId={replyMessageId}
                                                assigneeDropdownList={assigneeDropdownList}
                                                todoTasksList={todoTasksList}
                                                shareContacts={shareContacts}
                                                handleSelectedTask={handleSelectedTask}
                                                getTaskList={getTaskList}
                                                messageList={messageList}
                                                pageName={"ChatAi"}
                                                chatAndTasks={chatTaskList}
                                                fromReply={true}
                                                showOptions={showOptions}
                                                setshowOptions={setshowOptions}
                                                setUnreadMessage={setUnreadMessage}
                                            />
                                            </div>
                                            : <></>
                                        }
                                         {groupDetails && groupDetails.firstUnreadMsgId == msg._id && currentUnreadCount && currentUnreadCount > 0 && msg.group ==  activeGroupId ?
                                            <div className="chat-date-seperator message" id="unread-message-seperator" on>
                                                <span className="bg-blue seperator-line"></span>
                                                <span className="c-font color-blue date-text f-10 light-theme-color-black minw230px fw-semibold text-center">
                                                    {`${ _l('l_unred_message')}`}
                                                    {/* {`${(localStorage.getItem("unread_chat_notification_count")) + " " + _l('l_unred_message')}`} */}
                                                </span>
                                                <span className="bg-blue seperator-line"></span>
                                            </div>
                                            : <></>
                                        }
                                        {dateSeperator !=  prevMsgdate &&  chatmessageList.length > 20 && prevMsgdate == "" && msg.group ==  activeGroupId && (index == 0 || index == (chatmessageList.length - 1))? 
                                        <div className="chat-date-seperator" date={dateSeperator} id={`seperator-${dateSeperator}`}>
                                            <span className="seperator-line"></span>
                                            <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                {moment(yesterdayDate).format("DD/MM/YYYY") == dateSeperator ?  _l("l_yesterday") 
                                                : moment(new Date()).format("DD/MM/YYYY") == dateSeperator ?  _l("l_today")
                                                : moment(tomorrowDate).format("DD/MM/YYYY") == dateSeperator ? _l("l_tomorrow") 
                                                : dateSeperator
                                                }
                                            </span>
                                            <span className="seperator-line"></span>
                                        </div>
                                        : dateSeperator !=  prevMsgdate &&  chatmessageList.length < 20 && prevMsgdate == "" && msg.group ==  activeGroupId ? 
                                        <div className="chat-date-seperator" date={dateSeperator} id={`seperator-${dateSeperator}`}>
                                            <span className="seperator-line"></span>
                                            <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                {moment(yesterdayDate).format("DD/MM/YYYY") == dateSeperator ?  _l("l_yesterday") 
                                                : moment(new Date()).format("DD/MM/YYYY") == dateSeperator ?  _l("l_today")
                                                : moment(tomorrowDate).format("DD/MM/YYYY") == dateSeperator ? _l("l_tomorrow") 
                                                : dateSeperator
                                                }
                                            </span>
                                            <span className="seperator-line"></span>
                                        </div> :                                    
                                         prevMsgdate != dateSeperator && chatmessageList.length < 20 && prevMsgdate == ""  && msg.group ==  activeGroupId && element.length == messageList.length ? 
                                        <div className="chat-date-seperator" date={dateSeperator} id={`seperator-${dateSeperator}`}>
                                            <span className="seperator-line"></span>
                                            <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                {moment(yesterdayDate).format("DD/MM/YYYY") == dateSeperator ?  _l("l_yesterday") 
                                                : moment(new Date()).format("DD/MM/YYYY") == dateSeperator ?  _l("l_today")
                                                : moment(tomorrowDate).format("DD/MM/YYYY") == dateSeperator ? _l("l_tomorrow") 
                                                : dateSeperator
                                                }
                                            </span>
                                            <span className="seperator-line"></span>
                                        </div>
                                        : <></>
                                        }
                                        
                                          
                                    </>
                                );
                                // }
                            })
                        : <CommanPlaceholder mainSpacing="h-100" imgType="no-chat" placeholderText={_l("l_no_chat")} />  
                           
                        }
                          {/* </div>
                    </div> */}
                    </div>
                    </InfiniteScroll>
                    : 
                    <> <CommanPlaceholder mainSpacing="h-100" imgType="no-chat" placeholderText={_l("l_no_chat")} /> </>}
                    </>}</>
                  
                </div>
                <div id="message-list" ref={messageListDiv}></div>
                 {/* <div className={`comman-content-scroll-wrapper chat-wrapper position-relative`}>
                    <div data-offset="50" data-target=".c-gradinet-active-border" className={`comman-content-scroll scroller ${pageName === "ChatAi" ? "comman-main-box pb-0 position-relative" : ""} ${messageLoader ? "overflow-hidden" : "overflow-hiiden-auto"}`} id="message-list" ref={messageListDiv}
                    onScroll={(e)=>{
                        handleChatScroll(e)
                    }}
                    >
                        {messageLoader ? <><div className="d-flex align-item-center justify-content-center mt-10"><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`opacity-1`}
                            aria-hidden="true"
                          /></div></> : <></>}                        
                        {chatmessageList && chatmessageList.length > 0  ? chatmessageList.sort((a,b)=> {
                        const timeA = a.createdAt ? moment(a.createdAt) : moment(new Date())
                        const timeB = b.createdAt ? moment(b.createdAt) : moment(new Date())

                        if (timeA.isBefore(timeB)) {
                            return -1;
                          }
                          if (timeA.isAfter(timeB)) {
                            return 1;
                          }
                          return 0;
                    }
                        )
                            // .slice((messageList.length - (loadPageNo * itemsPerPage)) > 0 ? messageList.length - (loadPageNo * itemsPerPage) : 0, messageList.length)
                            .map((msg, index) => {
                                // if(moment(msg.createdAt).format('YYYY-MM-DD') == moment(localStorage.getItem('selectedDate')).format('YYYY-MM-DD')){
                                var dateSeperator = moment(msg.createdAt).format("DD/MM/YYYY");
                                var dateId = moment(msg.createdAt).format("YYYY-MM-DD");
                                var prevMsgdate = localStorage.getItem("prevMsgdate");
                                if (index == 0) {
                                    prevMsgdate = "";
                                }
                                localStorage.setItem("prevMsgdate", dateSeperator);
                                var senderName = groupMembers[msg.user] ? groupMembers[msg.user].fname + ' ' + groupMembers[msg.user].lname : "";
                                var senderImage = groupMembers[msg.user] ? groupMembers[msg.user].displayPicture : "";
                                msg.senderName = senderName;
                                msg.senderImage = senderImage;
                                return (
                                    <>
                                        {prevMsgdate != dateSeperator ? 
                                        <div className="chat-date-seperator message" date={dateId} id={`seperator-${dateSeperator}`}>
                                            <span className="seperator-line"></span>
                                            <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                {moment(yesterdayDate).format("DD/MM/YYYY") == dateSeperator ?  _l("l_yesterday") 
                                                : moment(new Date()).format("DD/MM/YYYY") == dateSeperator ?  _l("l_today")
                                                : moment(tomorrowDate).format("DD/MM/YYYY") == dateSeperator ? _l("l_tomorrow") 
                                                : dateSeperator
                                                }
                                            </span>
                                            <span className="seperator-line"></span>
                                        </div>
                                        : <></>
                                        }

                                        {msg.type == "info" ?
                                        <div className="chat-date-seperator message" date={dateId}>
                                            <span className="date-text color-white-80 c-font f-12 title-fonts fw-bolder">
                                                <div className="chat-send-name c-font f-10 opacity-50 text-center">
                                                    <div className="align-items-center">
                                                        <span>{moment(msg.createdAt).format("HH:mm")}</span>
                                                    </div>
                                                </div>
                                                <span className="color-red">{msg.message}</span>
                                            </span>
                                        </div>
                                        : msg.extraData && msg.extraData && msg.extraData.slug == "schedule" 
                                        ? <ScheduleCard benchmark={msg.extraData} fromChat={true} />
                                        :  msg.extraData && msg.extraData && msg.extraData.slug == "new_space" 
                                        ? <Mapcard data={msg} />
                                        :msg.extraData && msg.extraData.slug == "task" ? /* extraData && msg.extraData.slug == "task" * / 
                                        <div className={`message`} date={dateId}> 
                                            <div className={`d-flex chat-box with-padding width-custom ${commanoffcanvasIsOpen ? "" : ""} ${msg.creator_name != "" && msg.creator_name != undefined && msg.creator_name == localStorage.getItem('full_name') ? "right-side-msg" : ""}`} id={`task_${msg.id}`}>
                                                    <div className="d-flex w-100">
                                                        <TaskCard
                                                        key={msg.id}
                                                        task={taskListForChat && taskListForChat.filter((t) => t.id == msg.extraData.id) && taskListForChat.filter((t) => t.id == msg.extraData.id)[0] ? taskListForChat.filter((t) => t.id == msg.extraData.id)[0] : {}}
                                                        active={selectedTaskId == msg.id}
                                                        project_id={projectId}
                                                        handler={handleSelectedTask}
                                                        pageName='ChatAi'
                                                        taskCheckUncheck={() => { }}
                                                        checkedTask={checkedTask}
                                                        taskPinned={taskPinned}
                                                        setTaskPinned={setTaskPinned}
                                                        contacts={contacts}
                                                        setTaskId={() => { }}
                                                        setCallUserList={() => { }}
                                                        initReply={initReply}
                                                        replyTaskId={replyMessageId}
                                                        showOptions={showOptions}
                                                        setshowOptions={setshowOptions}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                        : 
                                        <div className="message" date={dateId}>
                                            <MsgCard
                                                msg={msg}
                                                setOpenGallery={setOpenGallery}
                                                setImagePreviewSrc={setImagePreviewSrc}
                                                editMessage={editMessageText}
                                                deleteMessage={deleteMessage}
                                                createTask={createTask}
                                                taskLoader={taskLoader}
                                                assigneeOptions={assigneeOptions}
                                                initReply={initReply}
                                                replyMessageId={replyMessageId}
                                                assigneeDropdownList={assigneeDropdownList}
                                                todoTasksList={todoTasksList}
                                                shareContacts={shareContacts}
                                                handleSelectedTask={handleSelectedTask}
                                                getTaskList={getTaskList}
                                                messageList={messageList}
                                                pageName={"ChatAi"}
                                                chatAndTasks={taskandMessages}
                                                fromReply={true}
                                                showOptions={showOptions}
                                                setshowOptions={setshowOptions}
                                            />
                                            </div>
                                        }
                                    </>
                                );
                                // }
                            })
                        : pageName != "ChatAi" 
                            ? <CommanPlaceholder mainSpacing="h-100" imgType="no-chat" placeholderText={_l("l_no_chat")} /> 
                            : <></>
                        }
                    </div>
                    
                    
                    {chatInputDisabled || (typingMembers && typingMembers.length > 0) ? 
                    <div className="typing-main ms-4 ps-20pximp">
                        <Typing chatInput={chatInputDisabled ? false : true} />
                    </div>
                    :<></>}
                </div>  */}
                {(disableInputChat == 1 && groupDetails._id == activeGroupId) || (typingMembers && typingMembers.length > 0) ?
                    <div className="typing-main ms-4 ps-20pximp">
                        <Typing
                            chatInput={aiTyping ? false : true}
                            disableInputChat={disableInputChat}
                        />
                    </div>
                    : 
                    <></>}
            </div>
            {showTaskModal && !$.isEmptyObject(selectedTask) ?
            <CommanOffCanvas
                show={showTaskModal}
                handleClose={() => {
                    setShowTaskModal(false);
                }}
                pinHandler={(id, type, flag) => {
                    // handlePinUnPin(id, type, flag);
                }}
                data={commonModalData ?commonModalData : selectedTask }
                docType={selectedTask.is_requested == 1 ? "request-task" :"task"}
                // setSelectedId={selectedTaskInfo.taskId}
                selectedTaskId={selectedTaskInfo.taskId}
                IsKanbanView={false}
                meetingUrl={selectedTask.meeting_link}
                canvasfullHeightwithHeaderFooter={true}
                handleParentComponentAction={() => {
                }}
                setSelectedId={setSelectedId}
                pageName={"ChatAi"}
            />
            : <></>
            }
            {viewAIContext ?
            <AIContextModal 
            relType={"aiChat"}
            show={viewAIContext} 
            handleClose={()=> {setViewAIContext(false)}} 
            loadDescription={getAiContextForChat}
            handelSave={taskOpenAiRequest}
            />
            : <></>
            }
        </div>
    );
};

export default RightChatBoard;

{/* <div className="comman-content-bottom-btn margin-15 position-relative" id="send_message_div">
                        {selectedFileSrc != "" &&
                        <div className="upload-image-preview py-2">
                            <div className="d-flex align-items-center justify-content-start">
                                <div className={`${["image", "pdf", "other_file", "video"].includes(selectedFileType) ? "h70w70" : "bg-transparent mb-2"} comman-image-box radius_3 upload-image with-margin position-relative`}>
                                    {["image", "pdf", "other_file"].includes(selectedFileType) ?
                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                                        style={selectedFileType == "image" ? { backgroundImage: `url('${selectedFileSrc}')` } : {}}
                                    >
                                        { selectedFileType == "pdf"
                                            ? <FilePdf color="#00ff6a" weight="light" className="h-100 w-100 " />
                                            : selectedFileType == "other_file"
                                                ? <FileDoc color="#00ff6a" weight="light" className="h-100 w-100 " />
                                                : <></>
                                        }
                                    </div>
                                    : selectedFileType == "audio"
                                        ? <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 chat-audio">
                                            <AudioPlayer
                                                style={{ width: '300px' }}
                                                layout="horizontal"
                                                // autoPlay
                                                className="audio_player"
                                                showJumpControls={false}
                                                showSkipControls={false}
                                                customAdditionalControls={[]}
                                                customVolumeControls={[]}
                                                src={selectedFileSrc}
                                                // other props here
                                            />
                                        </div>
                                        : selectedFileType == "video"
                                            ? <>
                                                <video controls className="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex" src={selectedFileSrc} ></video>
                                                <PlayCircle size={20} color="#00ff6a" weight="light" className=" play-icon" />
                                            </>
                                            : <></>
                                    }
                                    <a href="#/" className="upload-close" onClick={() => {
                                        setSelectedFile({});
                                        setSelectedFileSrc("");
                                    }}>
                                        <X size={10} weight="light" className="c-icons" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        }
                        {showEmoji ?
                        <EmojiPicker
                            onEmojiClick={onClick}
                            autoFocusSearch={false}
                            lazyLoadEmojis={true}
                            previewConfig={{
                            // defaultEmoji: string; // defaults to: "1f60a"
                            // defaultCaption: string; // defaults to: "What's your mood?"
                            showPreview: false // defaults to: true
                            }}
                        />
                        : <></>
                        }
                        <div className="chat-comment-box ">
                            <div className="comment-input-box p-0">
                                <div className="d-flex position-relative">
                                    <div className="comman-image-box h30w30 rounded-circle">
                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" id="chat-user-image" style={{
                                            backgroundImage: userImg,
                                        }}>
                                        </div>
                                    </div>
                                    <div className="w100minus120 ps-1">
                                        <div className="mension-box mension-box--multiLine pt-1" style={{ position: "relative", overflowY: "visible"}}>
                                            <MentionsInput
                                                id="text-message"
                                                value={messageText}
                                                className="mension-box custom-width w-100"
                                                placeholder={`${_l("l_write_message_placeholder")}`}
                                                alwaysOpen={true}
                                                // style={mentionInputStyle}
                                                onChange={(e) => {
                                                    setMessageText(e.target.value);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter" && !e.ctrlKey && !e.shiftKey) {
                                                        e.preventDefault();
                                                        if (!$.isEmptyObject(selectedFile)) {
                                                            uploadMedia(selectedFile);
                                                        } else {
                                                            if (editMessageId != "") {
                                                                editMessage();
                                                            } else {
                                                                sendChatMessage();
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                <Mention
                                                    markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                                                    trigger="@"
                                                    data={mentionList}
                                                    displayTransform={(id, display) => `@${display}`}
                                                    style={mentionStyle}
                                                    renderSuggestion={(
                                                        suggestion,
                                                        search,
                                                        highlightedDisplay
                                                    ) => {
                                                        return (
                                                            <div className="user d-flex align-items-center">
                                                                <div className="comman-image-box h25w25 rounded-circle mx-2">
                                                                <div
                                                                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                                    style={{
                                                                    backgroundImage: `url('${suggestion.image}')`,
                                                                    }}
                                                                ></div>
                                                                </div>
                                                                {highlightedDisplay}
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </MentionsInput>
                                        </div>
                                    </div>
                                    <div className="pt2px d-flex align-items-start">
                                        <div className="comman_action_icon with_separator_10">
                                            <a href="#/"
                                                className="action_icon with_bg position-relative"
                                                data-tip={_l("l_mention")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                                onClick={() => {
                                                    setMessageText(messageText != "" ? messageText + " @" : "@");
                                                    $("#text-message").focus();
                                                }}
                                            >
                                                <At size={18} weight="light" className="c-icons" />
                                            </a>
                                        </div>
                                        <div className="comman_action_icon with_separator_10">
                                            <a href="#/"
                                                className="action_icon with_bg position-relative"
                                                data-tip={_l("l_choose_file")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                            >
                                                <input
                                                    type="file"
                                                    // accept="image/png, image/jpeg, image/jpg"
                                                    id="chat-file"
                                                    className="absolute-input"
                                                    onChange={(e) => selectFile(e.target.files[0])}
                                                />
                                                <Paperclip size={18} weight="light" className="c-icons" />
                                            </a>
                                        </div>
                                        <div className="comman_action_icon with_separator_10">
                                            <a href="#/" className={`action_icon with_bg`}
                                                onClick={() => { setShowEmoji(!showEmoji) }}
                                            >
                                                <Smiley size={18} className="c-icons" weight="light" />
                                            </a>
                                        </div>
                                        <div className="comman_action_icon with_separator_10 pe-0">
                                            {fileUploadLoader ? 
                                            <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true" />
                                            : <a href="#/"
                                                className="action_icon with_bg"
                                                data-tip={_l("l_send")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                                onClick={() => {
                                                    if (!$.isEmptyObject(selectedFile)) {
                                                        uploadMedia(selectedFile);
                                                    } else {
                                                        if (editMessageId != "") {
                                                            editMessage();
                                                        } else {
                                                            sendChatMessage();
                                                        }
                                                    }
                                                }}
                                            >
                                                <ArrowRight size={18} weight="light" className="c-icons" />
                                            </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

{/* <div className="chat-trigger" id="chat-fixed" style={{"height": "1px", "width": "100%", "background": "red", "position": "fixed"}}></div> */}
                        {/* {(pageName == "ChatAi" || globalView == "AichatView") ?
                            chatcalendarData && chatcalendarData.length > 0 && chatcalendarData.filter((date) => moment(date.date).isSameOrAfter(initialScrollDate) && moment(date.date).isSameOrBefore(finalScrollDate)).length == 0 ?
                            <CommanPlaceholder mainSpacing="py-4" imgType="no-chat" placeholderText={_l("l_no_data")} />
                            : chatcalendarData && chatcalendarData.length > 0 && chatcalendarData.filter((date) => moment(date.date).isSameOrAfter(initialScrollDate) && moment(date.date).isSameOrBefore(finalScrollDate)).map((date) => {
                                return (
                                    <>
                                        <div className="box1" style={{minHeight :  currrentDateHieght }}>
                                            <div className="box2">
                                                <div className="chat-date-seperator message" data-id={date.date} id={date.date} >
                                                    <span className="seperator-line"></span>
                                                    <span className="date-text color-white-80 c-font f-12 color-blacl-70">
                                                        { date.date == formateDate(yesterdayDate) ?  _l("l_yesterday")  : date.date == formateDate(new Date()) ?  _l("l_today") : date.date == formateDate(tomorrowDate) ?  _l("l_tomorrow") : moment(date.date).format("DD/MM/YYYY") }
                                                    </span>
                                                    <span className="seperator-line"></span>
                                                </div>
                                                {taskandMessages.filter((tasks) => formateDate(tasks.createdAt) == date.date).length == 0 ?
                                                <CommanPlaceholder mainSpacing="py-4" imgType="no-chat" placeholderText={_l("l_no_data")} />
                                                : taskandMessages && taskandMessages.length > 0 && taskandMessages.filter((m, key) => key > (msgListLength - customIndex) &&
                                                    m.message
                                                        ? m.message.toLowerCase().includes(globalSearchString.toLowerCase())
                                                        : m.name
                                                            ? m.name.toLowerCase().includes(globalSearchString.toLowerCase())
                                                            : ""
                                                ).length > 0 
                                                    ? taskandMessages.filter((m) => m.message
                                                        ? m.message.toLowerCase().includes(globalSearchString.toLowerCase())
                                                        : m.name
                                                            ? m.name.toLowerCase().includes(globalSearchString.toLowerCase())
                                                            : "")
                                                // .slice(chatTaskPage)
                                                .map((chatTask, index) => {
                                                    if (taskandMessages.filter((m) => m.message ? m.message.toLowerCase().includes(globalSearchString.toLowerCase()) : m.name ? m.name.toLowerCase().includes(globalSearchString.toLowerCase()) : "").length == (index + 1)) {
                                                        setLoder(false);
                                                    } 
                                                    var currentDate = new Date();
                                                    if (formateDate(date.date) == formateDate(msg.createdAt)) {
                                                        localStorage.setItem("chatTaskcreatedAt", chatTask.createdAt)
                                                        var dateSeperator = moment(chatTask.createdAt).format("DD/MM/YYYY");
                                                        var dateforID = formateDate(chatTask.createdAt);
                                                        var prevMsgdate = localStorage.getItem("prevMsgdate");
                                                        if (index == 0) {
                                                            prevMsgdate = "";
                                                        }
                                                        localStorage.setItem("prevMsgdate", dateSeperator);
                                                        let event_date = "";
                                                        if (chatTask.startdate != null) {
                                                            event_date = moment(chatTask.startdate).format("YYYY-MM-DD HH:mm:ss");
                                                        } else if (chatTask.duedate != null) {
                                                            event_date = moment(chatTask.duedate).format("YYYY-MM-DD HH:mm:ss");
                                                        } else {
                                                            event_date = moment(chatTask.dateadded).format("YYYY-MM-DD HH:mm:ss");
                                                        }

                                                        var senderName = groupMembers[chatTask.user] ? groupMembers[chatTask.user].fname + ' ' + groupMembers[chatTask.user].lname : "";
                                                        var senderImage = groupMembers[chatTask.user] ? groupMembers[chatTask.user].displayPicture : "";
                                                        chatTask.senderName = senderName;
                                                        chatTask.senderImage = senderImage;

                                                        let ChatTasklenght = taskandMessages.filter((m) => m.message ? m.message.toLowerCase().includes(globalSearchString.toLowerCase()) : m.name ? m.name.toLowerCase().includes(globalSearchString.toLowerCase()) : "").length

                                                        return (
                                                            <>
                                                                {groupDetails && groupDetails.firstUnreadMsgId == chatTask._id && currentUnreadCount ? 
                                                                <div className="chat-date-seperator message" id="unread-message-seperator">
                                                                    <span className="bg-blue seperator-line"></span>
                                                                    <span className="c-font color-blue date-text f-10 light-theme-color-black minw230px fw-semibold text-center">
                                                                        {`${(localStorage.getItem("unread_chat_notification_count")) + " " + _l('l_unred_message')}`}
                                                                    </span>
                                                                    <span className="bg-blue seperator-line"></span>
                                                                </div>
                                                                : <></>
                                                                }
                                                            
                                                                {chatTask.extraData && chatTask.extraData && chatTask.extraData.slug == "schedule" 
                                                                    ? <ScheduleCard benchmark={chatTask.extraData} fromChat={true} />
                                                                    : chatTask.extraData && chatTask.extraData && chatTask.extraData.slug == "new_space" 
                                                                        ? <Mapcard data={chatTask} />
                                                                        : chatTask.id 
                                                                            ? <div data-id={dateforID} className={`message`}> 
                                                                                <div className={`d-flex chat-box with-padding width-custom ${commanoffcanvasIsOpen ? "" : ""} ${chatTask.creator_name != "" && chatTask.creator_name != undefined && chatTask.creator_name == localStorage.getItem('full_name') ? "right-side-msg" : ""}`} id={`task_${chatTask.id}`}>
                                                                                    <div className="d-flex w-100">
                                                                                        <TaskCard
                                                                                            key={chatTask.id}
                                                                                            task={chatTask}
                                                                                            active={selectedTaskId == chatTask.id}
                                                                                            project_id={projectId}
                                                                                            handler={handleSelectedTask}
                                                                                            pageName='ChatAi'
                                                                                            taskCheckUncheck={() => { }}
                                                                                            checkedTask={checkedTask}
                                                                                            taskPinned={taskPinned}
                                                                                            setTaskPinned={setTaskPinned}
                                                                                            contacts={contacts}
                                                                                            setTaskId={() => { }}
                                                                                            setCallUserList={() => { }}
                                                                                            initReply={initReply}
                                                                                            replyTaskId={replyMessageId}
                                                                                            showOptions={showOptions}
                                                                                            setshowOptions={setshowOptions}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : <div className={`message ${chatTask.extraData && chatTask.extraData.taskId && chatTask.extraData.taskId == selectedTaskId ? "z-index-7" : ""}`} data-id={dateforID}>
                                                                                {chatTask.type == "info" ?
                                                                                <div className="chat-date-seperator" id={`${formateDate(dateSeperator)}`}>
                                                                                    <span className="date-text color-white-80 c-font f-12 title-fonts fw-bolder">
                                                                                        <div className="chat-send-name c-font f-10 opacity-50 text-center">
                                                                                            <div className="align-items-center">
                                                                                                <span>{moment(chatTask.createdAt).format("HH:mm")}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <span className="color-red">{chatTask.message}</span>
                                                                                    </span>
                                                                                </div>
                                                                                : <MsgCard
                                                                                    msg={chatTask}
                                                                                    setOpenGallery={setOpenGallery}
                                                                                    setImagePreviewSrc={setImagePreviewSrc}
                                                                                    editMessage={editMessageText}
                                                                                    deleteMessage={deleteMessage}
                                                                                    createTask={createTask}
                                                                                    taskLoader={taskLoader}
                                                                                    assigneeOptions={assigneeOptions}
                                                                                    initReply={initReply}
                                                                                    replyMessageId={replyMessageId}
                                                                                    assigneeDropdownList={assigneeDropdownList}
                                                                                    todoTasksList={todoTasksList}
                                                                                    shareContacts={shareContacts}
                                                                                    handleSelectedTask={handleSelectedTask}
                                                                                    getTaskList={getTaskList}
                                                                                    messageList={messageList}
                                                                                    pageName={"ChatAi"}
                                                                                    chatAndTasks={taskandMessages}
                                                                                    fromReply={true}
                                                                                    showOptions={showOptions}
                                                                                    setshowOptions={setshowOptions}
                                                                                />
                                                                                }
                                                                            </div>
                                                            
                                                                }
                                                            </>
                                                        )
                                                    }

                                                    if (index == Math.abs(taskandMessages.length - 1)) {
                                                        // scrollToBottom();
                                                    }
                                                })
                                                :<></>}
                                                {/* { formateDate(new Date()) == date.date ?  * /}
                                                <div className="seperator-line border-top-0 w-100" id={`end-${formateDate(date.date)}`}> </div> 
                                                {/* : <></> } * /}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        : <></>
                        } */}
