import React from 'react'
const CheckListSkeletons = () => {
  return (
    <div className='task-checklist position-relative SkeletonMain'>
        <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center flex-grow-1'>
                <div className='h32w32 rounded-5 skeleton-animation'></div>
                <div className='h20w20 ms-10px skeleton-animation radius_3'></div>
                <div className='ms-10px flex-grow-1'>
                    <div className='skeleton-animation radius_3' style={{width:'40%', height:'12px'}}></div>
                </div>
            </div>
            <div className='d-flex align-items-cente gap10'>
                <div className='h32w32 skeleton-animation radius_3'></div>
                <div className='h32w32 skeleton-animation radius_3'></div>
                <div className='h32w32 skeleton-animation radius_3'></div>
            </div>
        </div>
    </div>
  )
}
export default CheckListSkeletons
