import React, { useState, useEffect } from "react";
import { _l } from "../hooks/utilities";
import CustomerServices from "./../services/customer-services";
import { showMessage } from "../actions/messages";
import { useDispatch } from "react-redux";
import UserProfile from "./SpaceOnBoarding/UserProfile";
import { useSearchParams } from "react-router-dom";
let empolyeesDetail = {
  admin: '0',
  firstName: "",
  lastName: "",
  role: [],
  position: "",
  email: "",
  countryCode: "",
  password: "",
  confirmPassword: "",
  selectedSpaces: [],
};
const MyProfileSteps = ({ pageName, selectedSection, setContactId, setPushNotificationPreference, setEmailNotificationPreference}) => {
  const staff_id = localStorage.getItem("staff_id");
  const dispatch = useDispatch();
  const employeeRoleListing = [
    {
      value: 1,
      label: _l("l_admin"),
    },
    {
      value: 2,
      label: _l("l_manager"),
    },
    {
      value: 3,
      label: _l("l_employee"),
    },
  ];

  const [searchParams] = useSearchParams();
  let userId = searchParams.get("user");

  const [countryCodes, setCountryCodes] = useState([]);
  const [empolyeesDetails, setEmployeesDetails] = useState(empolyeesDetail);
  const [employeeRoleList, setEmployeeRoleList] = useState([])

  useEffect(() => {
    let tempEmployeeRoleList = []
    CustomerServices.getCountryCodes(true).then((res) => {
      if (res && res.status == "1") {
        setCountryCodes(
          res.data.map((code) => {
            return {
              value: code.code_value,
              label: code.code_value,
            };
          })
        );
      }
    });
    CustomerServices.getRoles().then((res) => {
      if (res.status == 1) {
          tempEmployeeRoleList = (
            res.data.map((role) => {
              return {
                value: role.roleid,
                label: role.name,
  
              };
            })
          );
          setEmployeeRoleList(tempEmployeeRoleList);
      } else {
        dispatch(
          showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
        );
        return false;
      }
    });
    CustomerServices.getSelectedEmployeeDetails(userId || staff_id).then(
      (res) => {
        if (res && res.status == 1) {
          if(setContactId)
          {
            setContactId(res.data.id);
          }
          setPushNotificationPreference(Number(res.data.push_notification));
          setEmailNotificationPreference(Number(res.data.email_notification_preference_type));
          setEmployeesDetails({
            admin : res.data.admin ? res.data.admin : 0,
            firstname: res.data.first_name,
            lastname: res.data.last_name,
            profile_image_url: res.data.profile_image,
            role: tempEmployeeRoleList.find(
              (employee) => employee.value == res.data.role_detail.role_id
            ),
            position: res.data.title,
            email: res.data.email,
            password: "",
            confirmPassword: "",
            countryCode: res.data.countrycode ? res.data.countrycode : "+33",
            phonenumber: res.data.countrycode ? `${res.data.countrycode}${res.data.phonenumber}`: `"+33"${res.data.phonenumber}`,
            staff_id: res.data.staff_id,
            contact_id: res.data.id,
            selectedSpaces: res.data.assigned_project_ids,
            is_profile_image_exist: res.data.is_profile_image_exist,
            urls: {
              l_id_card_upload: { previewArray: [{ url: res.data.id_card }] },
              l_company_id_upload: {
                previewArray: [{ url: res.data.company_id }],
              },
              l_payment_upload: { previewArray: [{ url: res.data.payment }] },
              l_insurance_upload: {
                previewArray: [{ url: res.data.insurance }],
              },
              l_skill_certificate_upload: { previewArray: [{ url: res.data.skill }] }
            },
            contact_role : res.data.role_detail.role_id,
            regions : res.data.regions
          });
        } else {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              res && res.message ? res.message : _l("l_something_went_wrong")
            )
          );
          return false;
        }
      }
    );
  }, []);

  // useEffect(() => {
  //   CustomerServices.getRoles().then((res) => {
  //     console.log("123", employeeRoleList);
  //     if (res.status == 1) {
  //         setEmployeeRoleList(
  //           res.data.map((role) => {
  //             return {
  //               value: role.roleid,
  //               label: role.name,
  
  //             };
  //           })
  //         );
  //         console.log("then", employeeRoleList);
  //     } else {
  //       dispatch(
  //         showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
  //       );
  //       return false;
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   let tempEmployeeRoleList = []
  //   CustomerServices.getSelectedEmployeeDetails(userId || staff_id).then(
  //     (res) => {
  //       if (res.status == 1) {
  //         console.log('employeeRoleList', employeeRoleList);
  //         if(setContactId)
  //         {
  //           setContactId(res.data.id);
  //         }
  //         setEmployeesDetails({
  //           admin : res.data.admin,
  //           firstname: res.data.first_name,
  //           lastname: res.data.last_name,
  //           profile_image_url: res.data.profile_image,
  //           role: employeeRoleList.find(
  //             (employee) => employee.value == res.data.role_detail.role_id
  //           ),
  //           role_id : res.data.role_detail.role_id,
  //           position: res.data.title,
  //           email: res.data.email,
  //           password: "",
  //           confirmPassword: "",
  //           countryCode: res.data.countrycode ? res.data.countrycode : "",
  //           phonenumber: res.data.phonenumber,
  //           staff_id: res.data.staff_id,
  //           contact_id: res.data.id,
  //           selectedSpaces: res.data.assigned_project_ids,
  //           is_profile_image_exist: res.data.is_profile_image_exist,
  //           urls: {
  //             l_id_card_upload: { previewArray: [{ url: res.data.id_card }] },
  //             l_company_id_upload: {
  //               previewArray: [{ url: res.data.company_id }],
  //             },
  //             l_payment_upload: { previewArray: [{ url: res.data.payment }] },
  //             l_insurance_upload: {
  //               previewArray: [{ url: res.data.insurance }],
  //             },
  //           },
  //         });
  //       } else {
  //         dispatch(
  //           showMessage(
  //             "unsucess",
  //             _l("l_error"),
  //             res.message ? res.message : ""
  //           )
  //         );
  //         return false;
  //       }
  //     }
  //   );
  // }, [userId, staff_id]);

  return (
    <>
      <div className="comman-contnet-wrapper res-border-0 row h-100 border-0 d-flex justify-content-center overflow-hidden ">
          <div className={`${selectedSection !== "" ? "pe10per h-100 pb-20pximp" : "col-xl-12 d-flex flex-column h-100" }`}>
            <UserProfile
              setOnboardingStep={() => {}}
              profileData={empolyeesDetails}
              pageName={pageName}
              setEmployeesDetails={setEmployeesDetails}
              isMyprofileStep={true}
              userId={userId}
              countryCodes={countryCodes}
              selectedSection={selectedSection}
              employeeRoleList={employeeRoleList}
            />
          </div>
      </div>
    </>
  );
};

export default MyProfileSteps;
