import React, { useEffect, useState } from "react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import "./assets/css/customer_main.css";
import "./assets/js/app";
import Login from "./views/Login";
import EmpolyeeTimeSheets from "./views/EmployeeTimeSheet";
import MyLeads from "./views/MyLeads";
import LiteVersion from "./views/LiteVersion";
import MapPage from "./views/MapPage";
import { fetchAuthToken } from "./actions/chat-auth";
import { connectToSocket, fetchUnreadMsgCount, setShowAccordionVisibility, setShowIsChatScreenDisplay, setactiveChatScreenId } from "./actions/chat";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomerServices from "./services/customer-services";
import { SetSelectedTask } from "./actions/customer";

// import Needle from "./views/Needle";
import {
  getCountryCodes,
  getDefaultTopicList,
  getTeams,
  setProjectTheme,
  setSelectedLanguage,
} from "./actions/customer";
import Redirect from "./components/Redirect/Redirect";
import { autoLogin } from "./actions/auth";
import {userAutoLogin} from "./actions/auth"
import Main from "./components/StandAlonePages/Main";
// import LogRocket from "logrocket";
import { DefaultListView, _l, isMinimumUpdateVersion, isSolarHubPlatform, pad, refreshCacheAndReload, showError } from "./hooks/utilities";
import { ErrorBoundary } from "./components/ErrorBoundry/ErrorBoundry";
import ProviderReport from "./views/ProviderReport";
import Client from "./views/Client";
import Staff from "./views/Staff";
import SetPassword from "./views/SetPassword";
import EquipmentTimeSheet from "./views/EquipmentTimeSheet";
import CreditNotesView from "./views/CreditNotesView";
import SpaceOnboarding from "./views/SpaceOnboarding";
import EquipmentView from "./views/EquipmentView";
import ExploreFeatures from "./views/ExploreFeatures";
import ConnectProviderView from "./views/ConnectProviderView";
import SpaceInvitation from "./views/SpaceInvitation";
import Benchmarks from "./views/Benchmarks";
import AiChat from "./views/AiChat";
import Tenants from "./views/Tenants";
import CustomFullCalendar from "./views/CustomFullCalendar";
import EquipmentsView from "./views/EquipmentsView";
import DashboardNew from "./views/DashboardNew";
import { loadDefaultMapParams } from "./constants/constants";
import PremiumEstimate from "./views/PremiumEstimate";
import EditProfile from "./views/EditProfile";
import Layout from "./config/Layout";
import AuthenticatedRoutes from "./config/AuthenticatedRoutes";
import ExternalRoutes from "./config/ExternalRoutes";
import LeadsIntegration from "./views/LeadsIntegration";
import Proposals from "./views/Proposals";
import Alerts from "./components/StandAlonePages/Alerts";
import CommanLoader from "./components/Loader/CommanLoader";
import CreateDPGFBenchmark from "./views/CreateDPGFBenchmark";
import ViewDPGFProposal from "./views/ViewDPGFProposal";
import ExternalPageConnector from "./views/ExternalPageConnector";
import Dashboard from "./views/Dashboard";
import InternalPageConnector from "./views/InternalPageConnector";
import customerServices from "./services/customer-services";
import { showMessage } from "./actions/messages";
import ProviderDocuments from "./views/ProviderDocuments";
import ProposalDetails from "./components/ExploreFeatures/ConnectProvider/ProposalDetails";
import SolarFooter from "./components/SolarExternalPages/SolarComponents/SolarFooter";
import { initIDB } from "./hooks/indexed_db_helpers";
import MyDrivePage from "./components/LeftPanelDrive/MyDrivePage";
// LogRocket.init("vo9fzt/myrai-app");

function App() {
  const dispatch = useDispatch();
  const staff_id = localStorage.getItem("staff_id");
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const page = queryParams.get('page');

  // useEffect(() => {
  //   let description = '';
  //   let iconUrl = '';
  //   if (!isSolarHubPlatform()) {
  //     description = "Solairehub | Save time, energy and money automating your workplace repetitive tasks";
  //     iconUrl = "favicon-solarI-con.png";
  //     document.title = "Solairehub";
  //   }else{
  //     description = "Myr.ai | Save time, energy and money automating your workplace repetitive tasks";
  //     iconUrl = "favicon-icon.png";
  //     document.title = "Myr AI";
  //   }
  //   document.querySelector('meta[name="description"]').setAttribute("content", description);
  //   document.querySelector('link[rel="icon"]').setAttribute("href", iconUrl);
  // },[]);

  useEffect(() => {
    if (window.location.pathname === "/myspacenew") {
      setHideClass(true);
    } else {
      setHideClass(false);
    }
    /**Temporary solution to update notification counts from space API */
    //Start
    if (localStorage.getItem("accessToken")) {
      // dispatch(getCustomerSpaces(client_id, staffOrContactId));
    }
    //End
    const accessToken = localStorage.getItem("accessToken");
    const email = localStorage.getItem("email");
    const contact_id = localStorage.getItem("contact_id");
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
  
    // Access individual query parameters
    const appLanguage = queryParams.get('language');
    
    localStorage.setItem("activeGroupId", "");
    if (accessToken != "" && accessToken != null) {
      dispatch(fetchAuthToken(email, contact_id, "client"));
      dispatch(getTeams());
    }
    setTimeout(() => {
      const chatAccessToken = localStorage.getItem("chatAccessToken");
      if (chatAccessToken != "" && chatAccessToken != null) {
        dispatch(connectToSocket());
        var chatUserId = localStorage.getItem("chatUserId");
        var projectId = localStorage.getItem("selectedOffice");
        dispatch(
          fetchUnreadMsgCount(
            chatUserId,
            localStorage.getItem("isLiteVersion") == "true" ? 0 : projectId
          )
        );
      }
    }, 3000);
    DefaultListView();
    switch (window.location.hostname) {
      case "staging.myr.ai":
        document.title += ` - Staging`;
        break;
      case "test.myr.ai":
        document.title += ` - Test`;
        break;
      case "app.myr.ai":
        break;
      case "localhost":
        document.title += ` - Local`;
        break;
      default:
        break;
    }
    dispatch(getDefaultTopicList());
    dispatch(getCountryCodes(true));
    loadDefaultMapParams();

    if(isMinimumUpdateVersion())
    {
      refreshCacheAndReload();
    }

    if(appLanguage)
    {

      let languageValue = "";

      switch(appLanguage)
      {
        case "en":
          languageValue = "english";
          break;
        case "fr":
          languageValue = "french";
          break;
      }

      dispatch(setSelectedLanguage(languageValue));
      localStorage.setItem('language', languageValue);
    }

    if(isSolarHubPlatform())
    {
      document.body.classList.add("glow-shadow");
      dispatch(setProjectTheme("light"));
    }

    if (userType == "operator") {
      customerServices.getSelectedEmployeeDetails(staff_id).then(
        (res) => {
        if (res && res.status == 1) {
          const { company_id, id_card, rge_certificate, skill} = res.data
          if (company_id != "" && id_card != ""  && rge_certificate != ""  && skill != "") {
              localStorage.setItem("isCompanyDocumentsUploaded", true)
          } else {
            localStorage.setItem("isCompanyDocumentsUploaded", false)
          }
        } else {
          showMessage(
            "unsucess",
            _l("l_error"),
            res && res.message ? res.message : ""
          )
        }
        }
      )
    }

    if (!page) {
    dispatch(setShowAccordionVisibility(false));
    dispatch(setactiveChatScreenId(0));
    }

    if(["/aichat", "/login"].includes(window.location.pathname)){

    }else{
      dispatch(setShowIsChatScreenDisplay(true));
    }
    initIDB();

  }, []);

  const [hideClass, setHideClass] = useState(false);
  const language = useSelector((state) => state.customer.get_language);
  const messagesStates = useSelector((state) => state.messages);
  const {isCallRecived, displayChatScreen} = useSelector((state) => state.chat);
  
  const userType = localStorage.getItem("user_type");
  const { calendarCanvasIsOpen, commanoffcanvasIsOpen, global_doc_type,callRecordingActive, globalView, leftPanelTab } =
    useSelector((state) => state.customer);

  useEffect(() => {
    _l();
  }, [language]);

  const handleAutoLogin = (key) => {
    dispatch(autoLogin(key)).then(() => {
      const packageId = localStorage.getItem("membership_package_id");
      const accessToken = localStorage.getItem("accessToken");
      const navigateToExploreFeature =
        sessionStorage.getItem("skip_onboarding");

      if (accessToken) {
        if (Number(navigateToExploreFeature)) {
          sessionStorage.removeItem("skip_onboarding");
          window.location.href = "/";
        } else if (packageId == 1 || userType === "operator") {
          window.location.href = "/space-onboarding";
        } else {
          window.location.href = "/";
        }
      }
    });
  };


  const handleUserAutoLogin = () => {
    const path = window.location.pathname; 
    const pathParts = path.split('/');
    const staffId = pathParts[2]; 
    const taskId = pathParts[3]; 
    // Check if user is already logged in or not
    var exStaffId = localStorage.getItem("staff_id");
    localStorage.setItem("openTaskId",taskId);
    if(exStaffId > 0){
      window.location.href = "/";
    }else{
      dispatch(userAutoLogin(staffId)).then((res) => {
        window.location.href = "/";
      });
    }
  };
  

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (
      !(
        commanoffcanvasIsOpen == true &&
        !["equipment_task", "type_of_space"].includes(global_doc_type)
      )
    ) {
      jquery(document).unbind("mouseup");
    }

    if(accessToken)
    {
      document.body.classList.add("solar-login");
    }else if(localStorage.getItem("is_external") != 1){
      document.body.classList.remove("solar-login")
    }
  });

  // call timer start
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [hoursForRecording, setHoursForRecording] = useState(0);
  const [minutesForRecording, setMinutesForRecording] = useState(0);
  const [secondsForRecording, setSecondsForRecording] = useState(0);


  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (isCallRecived) {
      // Update seconds
      setSeconds((prevSeconds) => (prevSeconds + 1) % 60);

      // Update minutes if seconds reach 60
      if (seconds === 59) {
        setMinutes((prevMinutes) => (prevMinutes + 1) % 60);

        // Update hours if minutes reach 60
        if (minutes === 59) {
          setHours((prevHours) => prevHours + 1);
        }
      }
      updateDisplay()
    }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerInterval);
  }, [seconds, minutes, hours,isCallRecived]);


  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (callRecordingActive) {
      // Update seconds
      setSecondsForRecording((prevSeconds) => (prevSeconds + 1) % 60);

      // Update minutes if seconds reach 60
      if (seconds === 59) {
        setMinutesForRecording((prevMinutes) => (prevMinutes + 1) % 60);

        // Update hours if minutes reach 60
        if (minutes === 59) {
          setHoursForRecording((prevHours) => prevHours + 1);
        }
      }
      updateRecordingTimeDisplay()
    }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerInterval);
  }, [hoursForRecording,minutesForRecording,secondsForRecording,callRecordingActive]);

  useEffect(() => {
    if (!isCallRecived) {
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }
  }, [isCallRecived])

  useEffect(() => {
    if (!callRecordingActive) {
      setHoursForRecording(0)
      setMinutesForRecording(0)
      setSecondsForRecording(0)
    }
  }, [callRecordingActive])
  

  
  function updateDisplay() {
    const formattedTime = (hours > 0 ? pad(hours) + ':' : '') + pad(minutes) + ':' + pad(seconds);
    let el1 = document.getElementById('call-timer-full')
    let el2 = document.getElementById('call-timer')
    if (document.getElementById('call-timer-full')) {
      document.getElementById('call-timer-full').innerText = formattedTime;
    }
    if (document.getElementById('call-timer')) {
      document.getElementById('call-timer').innerText = formattedTime;
    }
  }

  function updateRecordingTimeDisplay() {
    const formattedTime = (hoursForRecording > 0 ? pad(hoursForRecording) + ':' : '') + pad(minutesForRecording) + ':' + pad(secondsForRecording);
    if(document.getElementById("stopwatch")){
      document.getElementById("stopwatch").textContent = formattedTime;
  }
    if (document.getElementById('recording-minute')) {
      document.getElementById('recording-minute').innerText = formattedTime;
    }
    if (document.getElementById('recording-sceonds')) {
      document.getElementById('recording-sceonds').innerText = secondsForRecording;
    }

  }
  // call timer end
  return (
    <ErrorBoundary>
      {
        messagesStates && messagesStates.idbDataLoader
        ?
        <CommanLoader className="main-loader-wrapper full-screen" message={"l_fetching_data"} />
        :
        <div
          id="modified_height"
          className={`App comman-body-wrapper ${
            window.location.pathname === "/spaces-dashboard"
              ? "modified-res-default-height"
              : ""
          } ${hideClass ? "" : "modified-height"}`}
        >
          <div
            className={`${calendarCanvasIsOpen ? "" : "modal-overlay"}`}
            id="modal-overlay-toggle"
          ></div>
          <Router>
            <Alerts />
            {
              ["/aichat", "/login"].includes(window.location.pathname) || 
              !displayChatScreen || 
              userType == "staff" || 
              ["my-request", "my-calendar", "my-drive"].includes(leftPanelTab) || (leftPanelTab == "homePage" && localStorage.getItem("accessToken")) ||
              commanoffcanvasIsOpen
              ? <></> : <SolarFooter/>}
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route exact path="/redirect" element={<Redirect />} />
              <Route
                exact
                path="/autologin/:param"
                element={<Redirect handler={handleAutoLogin} />}
              />
               <Route
                exact
                path="/open-task/:param/:param"
                element={<Redirect handler={handleUserAutoLogin} />}
              />
              <Route exact path="/:page/:id" element={<Main />} />
              <Route
                exact
                path="/set-password/:id/:type"
                element={<SetPassword />}
              />
              <Route exact path="space-invitation/:id" element={<SpaceInvitation />} />
              <Route path="*" element={<Layout />}>
                  <Route element={<AuthenticatedRoutes />}>
                    <Route exact path="dashboard" element={<DashboardNew />} />
                    <Route
                      exact
                      path="provider-reporting"
                      element={<ProviderReport />}
                    />
                    <Route
                      exact
                      path="employee-reporting"
                      element={<EmpolyeeTimeSheets />}
                    />
                    <Route
                      exact
                      path="equipment-reporting"
                      element={<EquipmentTimeSheet />}
                    />
                    <Route exact path="liteversion" element={<LiteVersion />} />
                    <Route
                      exact
                      path="calendar"
                      element={<CustomFullCalendar  pageName="calendar"/> }
                    />
                    <Route exact path="aichat" element={<AiChat />} />
                    <Route path="space-onboarding" element={<SpaceOnboarding />} />
                    <Route
                      exact
                      path="explore-features"
                      element={<ExploreFeatures />}
                    />
                    <Route
                      exact
                      path="connect-provider"
                      element={<ConnectProviderView />}
                    />
                    <Route exact path="tenants" element={<Tenants />} />
                    <Route exact path="employees" element={<Tenants />} />
                    <Route exact path="providers" element={<Tenants />} />
                    <Route exact path="equipments" element={<EquipmentsView />} />
                    <Route exact path="my-drive" element={<MyDrivePage />} />
                    <Route
                      exact
                      path="premiumsubscription"
                      element={<PremiumEstimate />}
                    />
                    <Route exact path="myprofile" element={<EditProfile />} />
                    <Route exact path="provider-documents" element={<ProviderDocuments />} />
                    <Route exact path="leads" element={<MyLeads />} />
                    <Route exact path="dashboardpre" element={<MapPage />} />
                    <Route exact path="clients" element={<Client />} />
                    <Route exact path="proposals" element={<Proposals />} />
                    <Route exact path="staff" element={<Staff />} />
                    <Route exact path="credit_notes" element={<CreditNotesView />} />
                    <Route exact path="benchmarks" element={<Benchmarks />} />
                    <Route exact path="equipmentListing" element={<EquipmentView />} />
                    <Route exact path="lead-form" element={<LeadsIntegration />} />
                    <Route exact path="create-dpgf-benchmark" element={<CreateDPGFBenchmark />} />
                    <Route exact path="view-dpgf-proposal" element={<ViewDPGFProposal />} />
                    <Route exact path="proposal-details" element={<ProposalDetails />} />
                    <Route exact path="*" element={<InternalPageConnector />} />
                  </Route>
                  <Route element={<ExternalRoutes />}>
                    <Route exact path="*" element={<ExternalPageConnector />} />
                  </Route>
              </Route>
            </Routes>
          </Router>
        </div>
      }
    </ErrorBoundary>
  );
}

export default App;
