import React, { useEffect, useRef, useState } from "react";
import LockIcon from "../assets/icons/LockIcon";
import ScheduleDropDown from "./MyPeople/ScheduleDropDown";
import PlusIcon from "../assets/icons/PlusIcon";
import {
  CardListScroll,
  DefaultLeftPanel,
  HideDropdown,
  TaskModalClose,
  _l,
  accessPremiumFeature,
  addActiveBorderforWeekCalendar,
  addexpandedClass,
  favoriteDdashboardSelected,
  formateDate,
  hideLeftPanelNew,
  hideRightPanelNew,
  isUserHaveOnboardingSpace,
  leftPanelMouseUpEvent,
  loadDataToIDB,
  showError,
  showLeftPanel,
  showLeftPanelNew,
  showLeftpanelonSearchHover,
  removeDuplicates,
  isSolarHubPlatform,
  showSolairHubleftPanel,
  addExpandedClasstoProviderList,
  addExpandedClasstoOfficeList,
  addExpandedClasstoIntroOfficeList,
  removeExpandedClasstoIntroOfficeList,
  removeExpandedClasstoOfficeList,
  showSolairHubleftPanelForSearch,
  addExpandedClasstoixedleftpanel,
  fetchTasksFromIDB,
} from "../hooks/utilities";
import CustomerServices from "../services/customer-services";
import GeneralListing from "./TaskModalComponents/GeneralListing";
import WalletSetting from "./Modals/WalletSetting";
import Button from "react-bootstrap/Button";
import ReactHtmlParser from "react-html-parser";
import {
  Funnel,
  Check,
  DotsThreeVertical,
  PushPin,
  MagnifyingGlass,
  CaretDown,
  UserCircleGear,
  Wrench,
  UsersThree,
  EnvelopeSimple,
  Wallet,
  Buildings,
  X,
  CaretUp,
  Rows,
  CurrencyEur,
  Chats,
  Users,
  HardDrives,
  DotsNine,
  BezierCurve,
  GearSix,
  SignIn,
  AddressBook,
  Gauge,
} from "phosphor-react";
import {
  getCustomerSpaces,
  projectTaskCounts,
  setProject,
  getTopicTasks,
  setTaskSearchString,
  leftPanelFilters,
  getTaskCount,
  setActiveTaskStatusFilters,
  setTaskUnreadCmtCntAll,
  getDefaultTopics,
  isIBANModalOpen,
  setCreateScheduledetails,
  setVirtualSpaceList,
  clearDocumentData,
  commanoffcanvasisOpen,
  setAllChatUserList,
  selectedChatUser,
  setSelectedGloablView,
  getTaskList,
  lastSessionOfChat,
  setFetchedTaskandMessages,
  updateTaskList,
  setBulkTaskRating,
  setDaterangeFilterApplied,
  setSelectedDateView,
  dayGridDay,
  setShowWeekcalendardays,
  setDaterangeFilter,
  dayGridMonth,
  dayGridWeek,
  setTopicThreadId,
  clearDefaultTopicsList,
  SetSelectedTask,
  clientTabData,
  setGloablSearchString,
  fullScreenLoaderChangeSpace,
  clearChatTaskList,
  contactDirCount,
  lastMessageSpace,
  setSelectedTabLeftPanel,
  setSpaceNotificationCount,
  chatMessageLoader,
} from "../actions/customer";
import {
  fetchUnreadMsgCount,
  setOpenGeneralChatGroup,
  userOffline,
  setUnreadMsgCount,
  getGroupDetails,
  setClearmessage,
  fetchMessages,
  groupIn,
  setUserDetailsForStaffuser,
  fetchGroupsExternal,
  handleChatScrolltoStop,
  updateNewMessage,
  updateExternalGroupListMessages,
  setShowAccordionVisibility,
  setactiveChatScreenId,
  setShowIsChatScreenDisplay,
} from "../actions/chat";
import { fetchAuthToken } from "../actions/chat-auth";
import { useSelector, useDispatch } from "react-redux";
import AddEditCategory from "./Modals/AddEditCategory";
import jquery from "jquery";
import TwoStepAccordians from "./Accordians/TwoStepAccordians";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CommanLoader from "./Loader/CommanLoader";
import moment from "moment";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import AddTaskIcon from "../assets/icons/AddTaskIcon";
import RequestTaskIcon from "../assets/icons/RequestTaskIcon";
import { loginAsUser } from "../actions/auth";
import {
  showFullScreenLoader,
  showMessage,
  showConfirmation,
  toastCloseAction,
} from "../actions/messages";
import { adminLoginAsStaff } from "../actions/admin-actions";
import ProviderDropdownWithprogressBar from "./Dropdowns/ProviderDropdownWithprogressBar";
import Spinner from "react-bootstrap/esm/Spinner";
import EmailSyncIcon from "../assets/icons/EmailSyncIcon";
import EmailRevokeIcon from "../assets/icons/EmailRevokeIcon";
import { Chat, FileText } from "phosphor-react";
import { is_discord_connected } from "../actions/discord_auth";
import { is_googlecalendar_connected } from "../actions/googlecalendar_auth";
import { is_google_drive_connected } from "../actions/google-drive";
import SettingMenu from "./SettingMenu/SettingMenu";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import User_2 from "../assets/images/users/user_2.jpg";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import UncontrolledGeneralListing from "./TaskModalComponents/UncontrolledGeneralListing";
import MailSetting from "./Modals/MailSetting";
import { getPilotOnboardingDocumentDetail } from "../actions/documents-actions/documents-actions";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import authServices from "../services/auth-services";
import ManagePrjoectMember from "./Modals/ManagePrjoectMember";
import adminServices from "../services/admin-services";
import { USER_LOGOUT } from "../actions/action-type";
import AdminServices from "../services/admin-services";
import SpaceIBANConfiguration from "./Modals/SpaceIBANConfiguration";
import OpenAi from "./Modals/OpenAi";
import Autocomplete from "./Dropdowns/Autocomplete";
import PremiumEstimateIconCrown from "../assets/icons/PremiumEstimateIconCrown";
import ThreeStarIcon from "../assets/icons/ThreeStarIcon";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import TopicSettingModal from "./Modals/TopicSettingModal";
import StaffIbanSetup from "./Modals/StaffIbanSetup";
import Toasts from "./Toasts/Toasts";
import AlertModal from "./Modals/AlertModal";
import CelebrationIcon from "../assets/icons/CelebrationIcon";
import { Form } from "react-bootstrap";
import PaymentModal from "./Modals/PaymentModal";
import DocumentsServices from "../services/documents-services";
import {
  insertCollectionToDB,
  readCollectionFromDB,
} from "../hooks/indexed_db_helpers";
import UserProfileDropdown from "./SolarExternalPages/UserProfileDropdown";
import chatServices from "../services/chat-services";
import SetUserPassword from "./Modals/SetUserPassword";
import {
  ATTACH_BASE_URL,
  USR_IMG_PLACEHOLDER,
} from "../actions/chat-action-type";
import myCallAudio from "../assets/audio/my-call-audio.mp3";
import myCallEndAudio from "../assets/audio/my-call-end-audio.mp3";
import myCallAcceptAudio from "../assets/audio/my-call-accept-audio.mp3";
import myCallRecordingAudio from "../assets/audio/my-call-recording-audio.mp3";
import customerServices from "../services/customer-services";
import FeedChatCallModal from "./Modals/FeedChatCallModal";
import FeedChatAudioCall from "./ChatAi/FeedChatAudioCall";
import LeftMenu from "./SolarExternalPages/SolarComponents/LeftMenu";
import LeftSubMenu from "./SolarExternalPages/SolarComponents/LeftSubMenu";
import LeftPanelSubmenu from "./LeftPanelSubmenu";
import { DEFAULT_MY_TASKS_PAGE_FILTER } from "../constants/constants";

let filterStatus = [];
let projectFilters = {
  my_spaces: false,
  project_status: [],
  account_executive: [],
  account_manager: [],
};

let searchMentionUsers = [];

const CONTACT_MENU_OPTIONS = ["plan", "mail", "company-profile"];
const OPERATOR_MENU_OPTIONS = ["plan", "mail", "company-profile", "proposals"];
const STAFF_MENU_OPTIONS = ["teams", "company-profile", "mail"];
let SPACE_LIST_RESULTS_PER_PAGE = 8;

let VISIBLE_MENUS = [];

const LeftPanel = ({
  setSelectedProject = () => {},
  projectId,
  isStatusCleared,
  clearStatusFilter,
  pageName = null,
  removedFilterStatus,
  setTaskModalClose,
  allMAilMessages,
  allImportantMessages,
  hideMailSyncBtn,
  setShowMailModal,
  synchronizedMail,
  setSynchronizedMail,
  hideDriveSyncBtn = true,
  setIsKanbanView = () => {},
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [selectedOffice, setSelectedOffice] = useState(
    isSolarHubPlatform() && localStorage.getItem("user_type") === "staff"
      ? 0
      : localStorage.getItem("is_registered") == 1
      ? 0
      : localStorage.getItem("selectedOffice")
      ? localStorage.getItem("selectedOffice")
      : 0
  );
  const adminUser = localStorage.getItem("staff_id");
  const startDate = localStorage.getItem("selectedDate");
  const [SettingMenuRender, setSettingMenuRender] = useState(false);
  const [showScheduleDropDown, setShowScheduleDropDown] = useState(false);
  const [selectOperators, setSelectOperators] = useState({});
  const [Modal1show, setModal1show] = useState(false);
  const [activeSpaces, setActiveSpaces] = useState([]);
  const [inActiveSpaces, setInActiveSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState({});
  const [todoTasks, setTodoTasks] = useState(0);
  const [inProgressTasks, setInProgressTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [virtualSpaceUnreadMsg, setVirtualSpaceUnreadMsg] = useState(0);
  const [clientIdLoginAsUser, setClientIdLoginAsUser] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [totalNotificationCounts, setTotalNotificationCounts] = useState(0);
  const [totalCounts, setTotalCounts] = useState(0);
  const [isOfficeSelected, setIsOfficeSelected] = useState(
    localStorage.getItem("selectedOffice")
      ? localStorage.getItem("selectedOffice")
      : -1
  );
  const [teamLeftPanel, setTeamLeftPanel] = useState(false);
  const dispatch = useDispatch();

  const {
    generalMsgCount,
    openGeneralChatGroup,
    groupListExternal,
    userDetails,
    groupDetails,
    callStatus,
    newMessage,    
  } = useSelector((state) => state.chat);
  const customerData = useSelector((state) => state.customer);
  const spaceState = useSelector((state) => state.customer.spaces);
  const proposalCount = useSelector((state) => state.customer.proposalKpiCount);
  const {
    taskFilters,
    taskCounts,
    selectedUserForChat,
    isBackdrop,
    generalUnreadCount,
    globalView,
    selectedProject,
    defaultTopics,
    fullScreenLoadChanSpace,
    leftPanelTab
  } = useSelector((state) => state.customer);
  const {
    importantMessages,
    inboxMessages,
    statusClose,
    virtualSpaceList,
    isAllChatUserlistShow,
    topicLoadedFromIDB,
    isTopicListLoading,
    customerAddress,
  } = useSelector((state) => state.customer);
  virtualSpaceList.forEach((w) => {
    w.is_virtual_space = 1;
  });
  virtualSpaceList.forEach((w) => {
    w.is_project_member = 1;
  });
  virtualSpaceList.forEach((w) => {
    w.project_status = 1;
  });
  virtualSpaceList.forEach((w) => {
    w.project_id = w.id;
  });
  const activeTaskStatusFilters = useSelector(
    (state) => state.customer.activeTaskStatusFilters
  );
  const isPremiumEstimateEdit = useSelector(
    (state) => state.customer.isPremiumEstimateAccess
  );
  const projectAttendancCounts = useSelector(
    (state) => state.customer.projectAttendancCount
  );
  const isIBANModal = useSelector((state) => state.customer.isIBANModalOpen);
  const [searchBarFocus, setSearchBarFocus] = useState(false);
  const [WalletActiveClass, setWalletActiveClass] = useState(true);
  const [MyEmailActiveClass, setMyEmailActiveClass] = useState(true);
  const [MyLeadActiveClass, setMyLeadActiveClass] = useState(true);
  const [PremiumPageActiveClass, setPremiumPageActiveClass] = useState(true);
  const [CompanyProfileActiveClass, setCompanyProfileActiveClass] =
    useState(true);
  const [loder, setLoder] = useState(false);
  const [activeOfficePage, setActiveOfficePage] = useState(1);
  const [project_id, setproject_id] = useState();
  const [settingTopicName, setSettingTopicName] = useState("");
  const [topicMainKey, setTopicMainKey] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [officeId, setOfficeId] = useState(0);
  const [estimateId, setEstimateId] = useState(0);
  const [showProjectStatusList, setshowProjectStatusList] = useState(false);
  const [selectedspaceStatus, setSelectedspaceStatus] = useState(0);
  const disablePages = [
    "companyprofile",
    "mywallet",
    "myspacenew",
    "MyEmail",
    "premiumsubscription",
    "leadPage",
  ];
  const [showBackDropClass, setShowBackDropClass] = useState(false);
  const [spaceSearchOnGoing, setSpaceSearchOnGoing] = useState(false);
  const noLeftPanel = [
    // "dashboard",
    "CompanyProfile",
    "MyProfile",
    "MyWallet",
    "Onboarding",
    "SpaceOnBoardingEquipments",
    "SpaceOnBoardingNew",
    "SpaceOnBoarding",
    "SpaceOnBoardingSchedule",
    "SpaceOnBoardingTeam",
    "SpaceOnBoardingTenet",
    "spaceOnboardingInvite",
    "PaymentPage",
    "paymentsetupnew",
    "PremiumSubscription",
    "SpaceOnBoardingNew",
    "dashboardwithdaterange",
    "dashboardpre",
  ];
  const noLeftPanelPages = [
    // "dashboard",
    "CompanyProfile",
    "MyProfile",
    "MyWallet",
    "Onboarding",
    "SpaceOnBoardingEquipments",
    "SpaceOnBoardingNew",
    "SpaceOnBoarding",
    "SpaceOnBoardingSchedule",
    "SpaceOnBoardingTeam",
    "SpaceOnBoardingTenet",
    "spaceOnboardingInvite",
    "PaymentPage",
    "paymentsetupnew",
    "PremiumSubscription",
    "SpaceOnBoardingNew",
    "dashboardwithdaterange",
    "dashboardpre",
  ];
  const fullScrollPages = [
    "Dashboard",
    "LiteVersion",
    "benchmarkList",
    "documents",
    "MyEmail",
    "Wallet",
    "leadPage",
    "MyPeople",
    "Proposals",
    "schedule",
    "client",
    "AdminTaskPage",
    "SpaceOnBoarding",
    "ChatAi",
    "MyProfile",
    "benchmark",
  ];
  const noActiveSpacePageNames = [
    "invoice",
    "estimate",
    "subscription",
    "staff",
    "benchmark",
    "schedule",
    "CompanyProfile",
    "Proposals",
    "MyProfile",
    "client",
    "fullcalendar",
    "AdminTaskPage",
    "credit_notes",
    "_equipments",
    "PaymentPage",
  ];
  if (!openGeneralChatGroup || !isAllChatUserlistShow) {
    noLeftPanelPages.push("Chat");
    noLeftPanelPages.push("LiteVersion");
  }
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const admin = localStorage.getItem("admin");
  const adminArchieve = localStorage.getItem("adminArchieve");
  var activeGroupId = localStorage.getItem("activeGroupId");

  const rel_id =
    localStorage.getItem("user_type") === "staff"
      ? localStorage.getItem("staff_id")
      : localStorage.getItem("client_id");
  const boss_operator = localStorage.getItem("boss_operator");
  const isProviderHasPlan =
    localStorage.getItem("is_solar_provider_plan_purchased") || "";

  const [officeWorkSpaceScore, setOfficeWorkSpaceScore] = useState(0);
  const [getUser, setUser] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pendingPaymentInfo, setPendingPaymentInfo] = useState({});
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);

  const staffOrContactId =
    localStorage.getItem("user_type") == "contact"
      ? localStorage.getItem("contact_id")
      : localStorage.getItem("staff_id");
  let user_type = localStorage.getItem("user_type");
  user_type =
    user_type === "contact"
      ? "client"
      : user_type === "operator"
      ? "operator"
      : "staff";
  const isOperator = localStorage.getItem("user_type") === "operator";
  const userType = localStorage.getItem("user_type");
  const contact_role = localStorage.getItem("contact_role");
  const isAdmin = localStorage.getItem("admin");
  const spaceArray = localStorage.getItem("spaces")
    ? JSON.parse(localStorage.getItem("spaces"))
    : [];
  const company_name = localStorage.getItem("company_name");
  const company_logo = localStorage.getItem("company_logo");
  const clientWorkspaceScore = localStorage.getItem("workspace_score");
  const savedProject = localStorage.getItem("selectedOffice");
  const disabledThirdPartyToolAccess =
    spaceArray &&
    spaceArray.length <= 1 &&
    localStorage.getItem("space_setup_pending") > 0;

  const [spaces, setSpaces] = useState([]);
  const [clientTeamData, setClientTeamData] = useState([]);
  const [showSearch, setshowSearch] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [preEstiPageDisable, setPreEstiPageDisable] = useState("");
  const [getIbanModlaOpen, setGetIbanModlaOpen] = useState("");
  const [searchTask, setSearchTask] = useState("");
  const [filterTaskActive, setFilterTaskActive] = useState([]);
  const [projectTaskCount, setProjectTaskCount] = useState([]);
  const [estimateDetails, setEstimateDetails] = useState({});
  const [show, setShow] = useState(false);
  const [ibanShow, setIbanShow] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [proposalKpiCount, setProposalKpiCount] = useState({});
  const [randomID, setRandomID] = useState(String(Math.random()));
  const [managePeopleModal, setManagePeopleModal] = useState(false);
  const [showOpenAiModal, setshowOpenAiModal] = useState(false);
  const [spacecontent, setspaeContent] = useState("");
  const [showTaskCanvas, setShowTaskCanvas] = useState(false);
  const [taskDetail, setTaskDetail] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const viewPage = window.location.pathname;
  const email = localStorage.getItem("email");

  let defaultTaskStatus = [0, 1, 4, 5];
  let defaultProposalStatus = [6, 4, 3, 2];
  let defaultInvoiceStatus = [1, 2, 5, 6];
  let defaultBenchmarkStatus = [1, 3, 4, 5];
  let defaultEstimateStatus = [1, 2, 3, 4];
  let defaultStaffStatus = [0, 1];
  let defaulSubscriptionStatus = [
    "active",
    "not_subscribed",
    "future",
    "canceled",
  ];
  let defaultCreditNoteStatus = [1, 2, 3];

  if (
    [
      "Dashboard",
      "calendar",
      "fullcalendar",
      "dashboard",
      "AdminTaskPage",
    ].includes(pageName)
  ) {
    // 0 - for request task
    defaultTaskStatus = [0, 1, 4, 5];
  }
  const [LeftPanelEmailPage, setLeftPanelEmailPage] = useState(false);
  const [officeFilterKey, setOfficeFilterKey] = useState("");
  const [spaceFilter, setSpaceFilter] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [createTeamModal, setCreateTeamModal] = useState(false);
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [showDrviesModal, setShowDrviesModal] = useState(false);
  const handleDriveModalClose = () => setShowDrviesModal(false);
  const handleDriveModalShow = () => setShowDrviesModal(true);
  const [inactiveOfficeShow, setInactiveOfficeShow] = useState(true);
  const [employeeData, setEmployeeData] = useState("");
  const invoiceCounter = useSelector((state) => state.customer.get_counts);
  const staffCounter = useSelector((state) => state.customer.staffCounter);
  const benchmarkCounter = useSelector(
    (state) => state.customer.benchmarkCounter
  );
  const spaceClient = useSelector((state) => state.customer.spaceClient);
  const estimateCounter = useSelector((state) => state.customer.get_counts);
  const subCounter = useSelector((state) => state.customer.subCounter);
  const [invoiceCount, setInvoiceCount] = useState([]);
  const [estimateCount, setEstimateCount] = useState([]);
  const [isEmailSynce, setIsEmailSynce] = useState();
  const [showSyncMailModal, setSyncShowMailModal] = useState(false);
  const [synchronizedMailLeft, setSynchronizedMailLeft] = useState(false);
  const [onboardDocument, setOnboardDocument] = useState("");
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
  const [topicType, setTopicType] = useState("");
  const [selectedTeamForEdit, setSelectedTeamForEdit] = useState();
  const [showAddTeamMember, setshowAddTeamMember] = useState(false);
  const [adminTeamList, setAdminTeamList] = useState([]);
  const [spaceData, setspaceData] = useState([]);
  const [showLeftPanelMenu, setshowLeftPanelMenu] = useState(false);
  const getOnboardDocument = useSelector(
    (state) => state.documentsReducer.getOnboardDocument
  );
  const [overdueTaskCounts, setOverdueTaskCounts] = useState([]);
  const [filterDropDownProps, setFilterDropDownProps] = useState([]);
  const [mentionStaffArray, setMentionStaffArray] = useState([]);
  const [
    selectedStaffMembersForSpaceFilter,
    setSelectedStaffMembersForSpaceFilter,
  ] = useState([]);
  const [defaultTopicsstate, setdefaultTopicsstate] = useState([]);
  const [forMeTopic, setForMeTopic] = useState([]);
  const [showStaffIBANSetup, setShowStaffIBANSetup] = useState(false);
  const [spaceInvitationData, setSpaceInvitationData] = useState({});
  const [visibleOptionIcons, setvisibleOptionIcons] = useState([]);
  const [menuOptionsFloat, setMenuOptionsFloat] = useState(true);
  const [activeMenuOptions, setActiveMenuOptions] = useState([]);
  const [benchmarkDiscardModal, setBenchmarkDiscardModal] = useState({});
  const [searchCategoty, setsearchCategoty] = useState("");
  const [searchTopic, setsearchTopic] = useState("");
  const [showForMeSection, setShowForMeSection] = useState(false);
  const [loginAsUserLoader, setLoginAsUserLoader] = useState(false);
  const [showLoginAsUserList, setShowLoginAsUserList] = useState(false);
  const [isLoginAsUserDataLoaded, setIsLoginAsUserDataLoaded] = useState([]);
  const [contactlistShow, setContactlistShow] = useState(false);
  const [showPasswordSetModal, setShowPasswordSetModal] = useState(
    localStorage.getItem("is_registration_confirmed") == 0
  );

  let space_pending = localStorage.getItem("space_setup_pending");
  const chatUserId = localStorage.getItem("chatUserId");

  const panelRef = useRef();
  const menuButtonRef = useRef();
  const officeListDivRef = useRef();
  const officeSearchboxRef = useRef();
  const topicSearch = useRef();

  const [searchParams, setSearchParams] = useSearchParams();
  const hideLeftPanelFooter = [
    "Proposals",
    "dashboard",
    "estimate",
    "staff",
    "client",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "_equipments",
    "invoice",
    "credit_notes",
    "subscription",
    "schedule",
    "benchmark",
    "leadPage",
    "MyProfile",
    "SpaceOnBoarding",
    // "dashboardnew"
  ];

  const [contactDirLoader, setContactDirLoader] = useState(false);

  const projectStatusArray = [
    {
      id: 1,
      name: _l("l_todo"),
      class: "color-gray",
    },
    {
      id: 2,
      name: _l("l_free_trial"),
      class: "color-blue",
    },
    {
      id: 6,
      name: _l("l_active"),
      class: "color-green",
    },
    {
      id: 7,
      name: _l("l_inactive"),
      class: "color-white",
    },
    // {
    //   id: 3,
    //   name: _l("l_subscription_required"),
    //   class: "color-blue",
    // },
    // {
    //   id: 5,
    //   name: _l("l_ready"),
    //   class: "color-green",
    // },
  ];
  const isLeadPage = pageName == "leadPage" ? 1 : 0;
  const [leftpanelToggleOnLiteversion, setLeftpanelToggleOnLiteversion] =
    useState(false);
  const proposalUnreadComments = localStorage.getItem(
    "unread_schedule_comment"
  );
  let FavView = localStorage.getItem("fav_dashboard");
  const dashboardBenchmarkId =
    !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) &&
    Number(searchParams.get("dashboard-benchmark-id"))
      ? Number(searchParams.get("dashboard-benchmark-id"))
      : 0;
  let spacesArray =
    userType == "staff" ? activeSpaces.concat(virtualSpaceList) : activeSpaces;

  const proposalId = searchParams.get("proposal_id");
  const id = searchParams.get("id");
  const page = searchParams.get("page");
  const accepted_proposal = localStorage.getItem("accepted_proposal")
    ? localStorage.getItem("accepted_proposal")
    : "";
  let scheduleData = localStorage.getItem("schedule_data")
    ? JSON.parse(localStorage.getItem("schedule_data"))
    : {};

  const closeInvitationAlert = async () => {
    try {
      const response = await CustomerServices.readSpaceInvitationAlert(
        spaceInvitationData.project_id
      );
      if (response.status) {
        setSpaceInvitationData({});
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  //  koi pan persone aa useEffect ma kai change no karvo.Hukum ~ State of Gokhalana
  useEffect(() => {
    if (["my-request", "my-calendar"].includes(leftPanelTab)) {
      fetchTasksFromIDB(0,DEFAULT_MY_TASKS_PAGE_FILTER,[],[],dispatch)
    }
    if (localStorage.getItem("leftPanelTab")) {
      switch (localStorage.getItem("leftPanelTab")) {
        case "my-drive":
          navigate('/my-drive');
          break;
        case "homePage":
        case "my-clients" :
        case "my-calendar" :
        case 'my-request' :
        case 'my-staff':
          navigate('/');
          break;
        case "proposal-list":
        case "adminstrative-list" :
        case  "installation-list" :
          navigate('/?page=proposals');
          break;
        case "support":
          navigate('aichat?proposal_id=0');
          break;
        default:
          navigate('/');
          break;
      }
      dispatch(setSelectedTabLeftPanel(localStorage.getItem("leftPanelTab")));
      if (localStorage.getItem("leftPanelTab") == "my-calendar") {
        dispatch(setSelectedGloablView("calendarView"));
      }
    }else{
      if (userType == "operator" && !adminArchieve) {
        localStorage.setItem("leftPanelTab", "proposal-list");
        dispatch(setSelectedTabLeftPanel("proposal-list"));
      }else{
        localStorage.setItem("leftPanelTab", "homePage");
        dispatch(setSelectedTabLeftPanel("homePage"));
      }
    }
    if ("my-calendar" == leftPanelTab) {
      dispatch(dayGridDay(false));
      dispatch(dayGridWeek(false));
      dispatch(dayGridMonth(true));
      dispatch(setDaterangeFilterApplied(true));
      dispatch(setDaterangeFilter("month"));
      dispatch(setSelectedDateView("monthView"));
      dispatch(leftPanelFilters("","","applyForMeAndMonth"));      
    }
  }, [leftPanelTab])
  

  // -------------------------------------------------- solarpage restriction strat--------------------------------------------------
  useEffect(() => {
    if (["/", "/subscription"].includes(window.location.pathname)) {
      if (userType === "operator" && !page) {
        // navigate("/?page=proposals");
      } else if (userType === "contact" && !page) {
        let scheduleData = localStorage.getItem("schedule_data");
        let tempScheduleData = scheduleData ? JSON.parse(scheduleData) : {};
        if (Object.keys(tempScheduleData).length) {
          navigate(
            `/?page=create-benchmark&space=${scheduleData.project_id}&type=benchmark&id=${scheduleData.benchmark_id}`
          );
          return;
        }
      }
    }
  }, [window.location.pathname]);
  // -------------------------------------------------- solarpage restriction end --------------------------------------------------

  useEffect(() => {
    if (noActiveSpacePageNames.indexOf == -1) {
      jquery('.office-box[data-space-div="' + isOfficeSelected + '"]').addClass(
        "active"
      );
    }
    if (
      isSolarHubPlatform() &&
      ![
        "register-provider",
        "proposals",
        "create-benchmark",
        "invite-customer-for-benchmark",
      ].find((x) => window.location.search.toLowerCase().includes(x)) &&
      !["ChatAi"].includes(pageName) &&
      ["MyProfile"].includes(noActiveSpacePageNames)
    ) {
      if (userType === "operator") {
        const accepted_proposal = localStorage.getItem("accepted_proposal");
        if (accepted_proposal <= 0 && !isProviderHasPlan) {
          // navigate("/?page=proposals");
        }
      } else if (userType === "contact") {
        let scheduleData = localStorage.getItem("schedule_data");
        scheduleData = scheduleData ? JSON.parse(scheduleData) : {};
        if (Object.keys(scheduleData).length) {
          navigate(
            `/?page=create-benchmark&space=${scheduleData.project_id}&type=benchmark&id=${scheduleData.benchmark_id}`
          );
          return;
        }
      }
    }
  }, [noActiveSpacePageNames]);
  useEffect(() => {
    if (noLeftPanel.includes(pageName) && showLeftPanelMenu)
      showLeftPanel(true, true);
    if (
      !["operatorListReporting", "equipmentListReporting"].includes(pageName)
    ) {
      CardListScroll();
    }

    return () => {};
  }, [pageName, showLeftPanelMenu]);

  useEffect(() => {
    if (
      selectedTeam &&
      Object.keys(selectedTeam).length > 0 &&
      adminTeamList &&
      adminTeamList.length > 0
    ) {
      let virualspace = adminTeamList.filter(
        (vs) => vs && vs.id == selectedTeam.id
      );
      dispatch(
        setUnreadMsgCount(parseInt(virualspace[0].unread_chat_msg_count))
      );
    } else if (selectedOffice > 0 && activeSpaces && activeSpaces.length > 0) {
      let space = activeSpaces.filter(
        (as) => as && as.project_id == selectedOffice
      );
    }

    if (
      isSolarHubPlatform() &&
      (userType === "staff" || page == "create-benchmark")
    ) {
      setSelectedSpace(0);
      setSelectedProject(0);
    } else if (selectedOffice > 0 && spaceState.length > 0) {
      let tempSelectedSpace = spaceState.filter(
        (as) => as && as.project_id == selectedOffice
      );
      setSelectedSpace(
        tempSelectedSpace.length > 0 ? tempSelectedSpace[0] : {}
      );
    }
    setSelectedProject(selectedOffice);
  }, [selectedOffice, activeSpaces, adminTeamList]);
  useEffect(() => {
    if (
      isAdmin == "true" &&
      selectedUserForChat &&
      Object.keys(selectedUserForChat).length > 0 &&
      !selectedUserForChat.generalChat &&
      pageName == "Chat"
    ) {
      // setShowUserList(true);
      dispatch(setAllChatUserList(true));
    }
  }, [selectedUserForChat, pageName]);
  useEffect(() => {
    jquery("body").removeClass("task-modal-offcanvas-open");
    if (
      pageName == "staff" ||
      pageName == "client" ||
      pageName == "_equipments"
    ) {
      jquery("._staff").attr("style", "width:150px");
    }
    if (pageName == "credit_notes") {
      jquery("._credit_notes").attr("style", "width: 100px");
    }
  }, [pageName]);
  useEffect(() => {
    // if(pageName == 'dashboard' || pageName == "Dashboard"){
    if (
      globalView == "listView" ||
      globalView == "calendarView" ||
      globalView == "kanbanView"
    ) {
      localStorage.removeItem("is_dashboard");
      localStorage.setItem(
        "is_dashboard",
        !isNaN(Number(projectId)) && Number(projectId) > 0 ? 0 : 1
      );
    } else {
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard", 0);
    }

    if (pageName == "LiteVersion") {
      setSelectedOffice();
    } else if (isSolarHubPlatform() && userType === "staff") {
      setSelectedOffice(0);
      setSelectedProject(0);
    } else if (
      (globalView == "listView" &&
        (projectId == null || projectId == 0 || projectId == undefined)) ||
      pageName != "dashboardnew"
    ) {
      const firstProjectId = spaces.find(
        (offices) => offices.status == "1" || offices.status == "2"
      );
      if (pageName != "dashboardnew") {
        // setSelectedOffice(
        //   savedProject
        //     ? savedProject
        //     : firstProjectId && firstProjectId.project_id
        //       ? firstProjectId.project_id
        //       : 0
        // );
      }
    }
  }, [pageName, globalView]);
  useEffect(() => {
    if (
      selectedProject &&
      pageName != "dashboardnew" &&
      localStorage.getItem("is_registered") != 1 &&
      isSolarHubPlatform() &&
      userType !== "staff"
    ) {
      setSelectedOffice(selectedProject);
    }
  }, [selectedProject]);
  useEffect(() => {
    if (getOnboardDocument && Object.keys(getOnboardDocument).length > 0) {
      setOnboardDocument(getOnboardDocument);
    }
  }, [getOnboardDocument]);
  useEffect(() => {
    if (invoiceCounter) {
      setInvoiceCount(invoiceCounter.data);
    }
    if (estimateCounter) {
      setEstimateCount(estimateCounter.data);
    }
  }, [invoiceCounter, estimateCounter]);
  useEffect(() => {
    if (
      pageName == "invoice" ||
      pageName == "estimate" ||
      pageName == "subscription" ||
      pageName == "_equipments" ||
      pageName == "staff" ||
      pageName == "client" ||
      pageName == "AdminTaskPage" ||
      pageName == "credit_notes" ||
      pageName == "fullcalendar" ||
      pageName == "benchmark" ||
      pageName == "schedule"
    ) {
      jquery(".office-box").removeClass("active");
      jquery(".left-panel-company-setting").addClass("active");
    }
  }, [pageName]);

  useEffect(() => {
    const activeSpace = localStorage.getItem("is_active_space");
    const is_registered = localStorage.getItem("is_registered");
    if (
      spaceState &&
      spaceState.length == 0 &&
      virtualSpaceList &&
      virtualSpaceList.length == 0 &&
      is_registered == 1 &&
      ![
        "Proposals",
        "SpaceOnBoarding",
        "SpaceOnBoardingNew",
        "MyProfile",
        "PremiumSubscription",
      ].includes(pageName)
    ) {
      hideLeftPanelNew();
      navigate("/aichat");
      dispatch(setSelectedGloablView("AichatView"));
    } else if (
      (isNaN(activeSpace) || Number(activeSpace) <= 0) &&
      ![
        "Proposals",
        "SpaceOnBoarding",
        "SpaceOnBoardingNew",
        "MyProfile",
        "PremiumSubscription",
      ].includes(pageName) &&
      localStorage.getItem("space_setup_pending") != 1
    ) {
      // navigate("/premiumsubscription");
      // navigate("/myprofile");
    }

    const space_setup_pending = localStorage.getItem("space_setup_pending");

    if (
      !isSolarHubPlatform() &&
      space_setup_pending > 0 &&
      ![
        "ChatAi",
        "SpaceOnBoarding",
        "SpaceOnBoardingNew",
        "MyProfile",
        "PremiumSubscription",
        "Proposals",
      ].includes(pageName) &&
      ["operator", "contact"].includes(userType) &&
      contact_role != 3
    ) {
      if (
        userType === "contact" ||
        (userType === "operator" &&
          (!spaceArray.length ||
            spaceArray.every((w) => w.is_added_from_operator == 1)))
      ) {
        navigate("/");
        dispatch(setSelectedGloablView("AichatView"));
      }
    }

    const invitedProject = spaceArray.find((w) => w.space_invitation_alert);

    if (invitedProject) {
      setSpaceInvitationData({
        project_id: invitedProject.project_id,
        message: invitedProject.space_invitation_alert,
      });
    }

    let currentSpaceDetails = spaceArray.find(
      (w) => w.project_id == selectedOffice && w.project_id != -1
    );
    if (currentSpaceDetails) {
      if (
        currentSpaceDetails.is_external > 0 &&
        !["/connect-provider"].includes(viewPage) &&
        !["MyProfile", "ChatAi"].includes(pageName)
      ) {
        navigate(`/aichat`);
      } else if (
        currentSpaceDetails.pending_benchmarks &&
        currentSpaceDetails.pending_benchmarks.length &&
        ![
          "SpaceOnBoarding",
          "SpaceOnBoardingNew",
          "MyProfile",
          "PremiumSubscription",
          "ChatAi",
        ].includes(pageName)
      ) {
        if (
          currentSpaceDetails.pending_benchmarks.length === 1 &&
          currentSpaceDetails.is_restrict_client != 1
        ) {
          navigate(
            `/connect-provider?space=${currentSpaceDetails.project_id}&provider-type=benchmark&id=${currentSpaceDetails.pending_benchmarks[0].id}`
          );
        } else if (currentSpaceDetails.is_restrict_client != 1) {
          navigate(
            `/explore-features?setting-menu=providers&section=benchmark&category=${currentSpaceDetails.pending_benchmarks[0].provider_id}&space=${currentSpaceDetails.project_id}`
          );
        }
      } else if (
        userType !== "staff" &&
        currentSpaceDetails.status == 0 &&
        ![
          ,
          "SpaceOnBoarding",
          "SpaceOnBoardingNew",
          "MyProfile",
          "PremiumSubscription",
          "ChatAi",
          "Dashboard",
        ].includes(pageName)
      ) {
        if (userType == "operator") {
          // navigate(`/proposals`, { state: { from: viewPage } });
        } else {
          navigate(`/explore-features?space=${currentSpaceDetails.project_id}`);
        }
      } else if (
        currentSpaceDetails.is_external &&
        currentSpaceDetails.schedule_id &&
        !["Chat"].includes(pageName) &&
        userType !== "operator"
      ) {
        dispatch(
          setOnboardingStepData({
            showChat: true,
          })
        );
        // setBenchmarkDiscardModal(currentSpaceDetails);
      } else if (
        userType === "operator" &&
        Number(currentSpaceDetails.is_added_from_operator) &&
        !currentSpaceDetails.is_onboard_completed_operator &&
        ![
          "PremiumSubscription",
          "Proposals",
          "PaymentPage",
          "SpaceOnBoarding",
          "MyProfile",
          "SpaceOnBoardingNew",
          "ChatAi",
        ].includes(pageName)
      ) {
        navigate(`/explore-features?space=${selectedOffice}`);
      } else if (
        userType === "contact" &&
        !Number(currentSpaceDetails.is_added_from_operator) &&
        !currentSpaceDetails.is_onboard_completed_contact &&
        ![
          "PremiumSubscription",
          "PaymentPage",
          "SpaceOnBoarding",
          "MyProfile",
          "SpaceOnBoardingNew",
          "ChatAi",
        ].includes(pageName)
      ) {
        navigate(`/explore-features?space=${selectedOffice}`);
      }
    } else if (
      !isSolarHubPlatform() &&
      spaceArray.length === 1 &&
      ((spaceArray[0].pending_benchmarks &&
        spaceArray[0].pending_benchmarks.length) ||
        spaceArray[0].status == 0) &&
      ![
        "SpaceOnBoarding",
        "SpaceOnBoardingNew",
        "MyProfile",
        "PremiumSubscription",
        "ChatAi",
      ].includes(pageName)
    ) {
      if (spaceArray[0].status == 0) {
        if (userType == "operator") {
          navigate(`/aichat`, { state: { from: viewPage } });
        } else {
          navigate(`/explore-features?space=${spaceArray[0].project_id}`);
        }
      } else if (
        ![
          "SpaceOnBoarding",
          "SpaceOnBoardingNew",
          "MyProfile",
          "PremiumSubscription",
          "ChatAi",
        ].includes(pageName)
      ) {
        if (spaceArray[0].pending_benchmarks.length === 1) {
          navigate(
            `/connect-provider?space=${spaceArray[0].project_id}&provider-type=benchmark&id=${spaceArray[0].pending_benchmarks[0].id}`
          );
        } else {
          navigate(
            `/explore-features?setting-menu=providers&section=benchmark&category=${spaceArray[0].pending_benchmarks[0].provider_id}&space=${spaceArray[0].project_id}`
          );
        }
      }
    } else if (
      spaceArray.length &&
      !currentSpaceDetails &&
      !isUserHaveOnboardingSpace(spaceArray) &&
      ![
        "PremiumSubscription",
        "Proposals",
        "PaymentPage",
        "SpaceOnBoarding",
        "MyProfile",
        "SpaceOnBoardingNew",
      ].includes(pageName)
    ) {
      // navigate("/premiumsubscription");
    }

    if (spaces.length && spaceState.length) {
      let sortedSpaces = spaces.filter(
        (space) =>
          space.status == "1" || space.status == "2" || space.status == "0"
      );
      if (userType == "staff") {
        sortedSpaces = spaces.filter(
          (space) =>
            space.status == "1" || space.status == "2" || space.status == "0"
        );
      }
      if (
        selectedOffice > 0 &&
        sortedSpaces.length &&
        sortedSpaces.find((space) => space.project_id == selectedOffice)
      ) {
        sortedSpaces = [
          sortedSpaces.find((space) => space.project_id == selectedOffice),
          ...sortedSpaces.filter((space) => space.project_id != selectedOffice),
        ];
        //
      }
      setActiveSpaces(sortedSpaces.length ? sortedSpaces : []);
      const firstProjectId = spaces.find(
        (offices) => offices.status == "1" || offices.status == "2"
      );
      // if(globalView == "listView" && (projectId == null || projectId == 0 || projectId == undefined)){
      if (isSolarHubPlatform() && userType === "staff") {
        setSelectedProject(0);
      } else if (pageName != "dashboardnew") {
        if (globalView == "AichatView" && !projectId > 0) {
          setSelectedProject(
            savedProject
              ? savedProject
              : firstProjectId && firstProjectId.project_id
              ? firstProjectId.project_id
              : 0
          );
        } else if (globalView !== "AichatView") {
          setSelectedProject(
            savedProject
              ? savedProject
              : firstProjectId && firstProjectId.project_id
              ? firstProjectId.project_id
              : 0
          );
        }
      }
      // ;}

      if (isSolarHubPlatform() && userType === "staff") {
        setSelectedOffice(0);
      } else if (pageName != "dashboardnew" && !Number(selectedOffice)) {
        setSelectedOffice(
          savedProject
            ? savedProject
            : firstProjectId && firstProjectId.project_id
            ? firstProjectId.project_id
            : 0
        );
      }

      localStorage.removeItem("selectedVirtualSpaceId");
      if (localStorage.getItem("user_type") == "staff") {
        let tempProjectId = savedProject
          ? savedProject
          : firstProjectId && firstProjectId.project_id
          ? firstProjectId.project_id
          : 0;
        let spaceDetails = sortedSpaces.filter(
          (space) => space.project_id == tempProjectId
        );
        if (spaceDetails.length) {
          localStorage.setItem(
            "virtualSpaceClientId",
            spaceDetails[0].client_id ? spaceDetails[0].client_id : ""
          );
        }
      }
    } else if (spaces.length && spaceState.length == 0) {
      setActiveSpaces([]);
    } else {
      setActiveSpaces(spaceState);
    }

    if (
      isOfficeSelected != "" &&
      activeSpaces &&
      activeSpaces.length &&
      localStorage.getItem("selectedOffice") &&
      parseInt(localStorage.getItem("selectedOffice")) > 0
    ) {
      const spaceId = spaces.filter(
        (space) => space.project_id == localStorage.getItem("selectedOffice")
      );
      if (spaceId[0]) {
        setOfficeOnTop(spaceId[0].id);
        setSpaceDetails(spaceId[0]);

        if (selectedOffice == 0 && pageName != "dashboardnew") {
          setSelectedOffice(spaceId[0].project_id);
        }
      }
      setIsOfficeSelected("");
    }

    if (
      activeSpaces &&
      activeSpaces.length &&
      localStorage.getItem("selectedOffice") &&
      parseInt(localStorage.getItem("selectedOffice")) > 0
    ) {
      const spaceId = spaces.filter(
        (space) => space.project_id == localStorage.getItem("selectedOffice")
      );
      if (spaceId[0] && pageName != "dashboardnew") {
        setSelectedSpace(spaceId[0]);
      }
    } else if (userType == "operator" && pageName == "Proposals") {
      dispatch(getDefaultTopics(0, "", "", pageName));
      dispatch(getTopicTasks(0, pageName));
    }

    if (pageName == "MyWallet") {
      setWalletActiveClass(false);
    } else if (pageName == "MyEmail") {
      setMyEmailActiveClass(false);
      setLeftPanelEmailPage(true);
    }
    if (window.location.pathname === "/leads") {
      setMyLeadActiveClass(false);
    } else if (pageName == "PremiumSubscription") {
      setPremiumPageActiveClass(false);
    } else if (pageName == "CompanyProfile") {
      setCompanyProfileActiveClass(false);
    }

    spaces.map((space, index) => {
      if (selectedOffice === space.project_id) {
        dispatch(setTaskUnreadCmtCntAll(space.notification_count));
      }
    });
    if (activeSpaces.length) {
      reorderOffices();
    }
    if (spaceState && spaceState.length) {
      userType == "staff"
        ? setSpaces(spaceState.concat(virtualSpaceList))
        : setSpaces(spaceState);
      let tempSpaces = spaceState
        .concat(userType == "staff" ? virtualSpaceList : spaceState)
        .filter((space) => {
          if (userType != "operator") {
            return space.is_project_member == 1;
          } else {
            return space;
          }
        });
      setSpaces(tempSpaces);
    }

    //Block Operator to access platform in case of pending payment action
    if (userType === "operator") {
      let pendingPayments = localStorage.getItem("pending_payments");
      pendingPayments = pendingPayments ? JSON.parse(pendingPayments) : [];
      if (pendingPayments.length) {
        setPendingPaymentInfo(pendingPayments[0]);
        localStorage.removeItem("pending_payments");
      }
    }
  }, [spaceState, JSON.stringify(spaces), selectedOffice, spaceArray.length]);

  useEffect(() => {
    const is_registered = localStorage.getItem("is_registered");
    if (
      spaceState &&
      spaceState.length == 0 &&
      is_registered == 1 &&
      virtualSpaceList &&
      virtualSpaceList.length == 0 &&
      ![
        "Proposals",
        "SpaceOnBoarding",
        "SpaceOnBoardingNew",
        "MyProfile",
        "PremiumSubscription",
      ].includes(pageName)
    ) {
      hideLeftPanelNew();
      navigate("/aichat");
      dispatch(setSelectedGloablView("AichatView"));
    }
  }, [pageName, globalView]);

  useEffect(() => {
    CustomerServices.getOverDueTaskCOunt(
      staffOrContactId,
      contact_id,
      client_id
    ).then((res) => {
      if (res && res.status) {
        setOverdueTaskCounts(res.data);
      }
    });

    // Open task from link
    var openTaskId = localStorage.getItem("openTaskId");
    if(openTaskId > 0){
      localStorage.removeItem("openTaskId");
      showTaskDetails(openTaskId);
    }
  }, []);

  const showTaskDetails = (taskId) => {
    customerServices.getTaskData(0,taskId).then((res)=>{
      if (res && res.status) {
        setTaskDetail(res.data);
        setShowTaskCanvas(true);
      }
    })
  }

  useEffect(() => {
    if (chatUserId) {
      setContactDirLoader(true);
      dispatch(fetchGroupsExternal(chatUserId && chatUserId != "undefined" ? chatUserId : localStorage.getItem("orgChatUserId") , 0, 1)).then(res => {
        if (res && res.success) {
          setContactDirLoader(false);
        }else {
          setContactDirLoader(false);
        }
      })
    }
  }, [chatUserId]);

  useEffect(() => {
    if (isSolarHubPlatform() && chatUserId && !["my-clients", "my-spaces"].includes(leftPanelTab) && userType !== "staff") {
      dispatch(fetchGroupsExternal(chatUserId, 0, 1));
    }
  }, [chatUserId, leftPanelTab]);

  useEffect(() => {
    if (projectAttendancCounts) {
      setProjectTaskCount(projectAttendancCounts);
    }
  }, [projectAttendancCounts]);

  const handleChangeProjectStatus = (id) => {
    if (id != 7) {
      CustomerServices.changePeojectStatus(project_id, id).then((res) => {
        if (res.status) {
          setSelectedspaceStatus(id);
          dispatch(
            showMessage(
              "sucess",
              _l("l_success"),
              res.message
                ? _l(res.message)
                : _l("l_space") +
                    _l("l_status") +
                    _l("l_change") +
                    _l("l_successfully")
            )
          );
          // dispatch(getCustomerSpaces(client_id, contact_id));
          let index = activeSpaces.findIndex(
            (space) => parseInt(space.project_id) == parseInt(project_id)
          );

          let updatelsit = activeSpaces[index];

          updatelsit.project_status = id;
          // setInActiveSpaces((Object.values({...activeSpaces , [index] :updatelsit})))
        } else {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              res.message ? _l(res.message) : _l("l_something_went_wrong")
            )
          );
        }
      });
    } else {
      handleSpaceDelete();
    }
  };

  useEffect(() => {
    if (
      isAdmin == "true" &&
      userType == "staff" &&
      (globalView == "liteVersionView" || pageName == "Chat") &&
      isAllChatUserlistShow
    ) {
      addExpandedClasstoProviderList();
      showLeftPanelNew();
      dispatch(setAllChatUserList(true));
    } else {
      dispatch(setAllChatUserList(false));
    }
    if (globalView != "liteVersionView" && pageName != "Chat") {
      localStorage.setItem("generalChatForLiteVersion", false);
      localStorage.setItem("isLiteVersion", false);
    }
    if (pageName != "PremiumSubscription") {
      setPremiumPageActiveClass(true);
    }
  }, [pageName, globalView]);

  const OnMouseOverOfficeList = () => {
    // hideRightPanelNew();
    // if((userType == "contact" || userType ==  "operator") && Number(contact_role) == 3){
    //   showLeftPanelNew();
    // }else{
    //   addexpandedClass();
    //   jquery("body").addClass("hover-left-penal");
    // }
    // jquery(".task-comment-modal").removeClass("show-comments");
    // HideDropdown();
  };
  useEffect(() => {
    if (projectId > 0 && spaceState.length > 0) {
      if (
        !["SpaceOnBoarding", "MyProfile", "ConnectProvider"].includes(
          pageName
        ) &&
        contact_role != 3 &&
        localStorage.getItem("is_registered") != 1 &&
        proposalId != 0 &&
        page != "create-benchmark" &&
        page !== "invite-customer-for-benchmark"
      ) {
        showLeftPanelNew();
      }
      const tempSelectedSpace = spaceState.filter(
        (s) => s.project_id == projectId
      )[0];
      setSelectedSpace(tempSelectedSpace ? tempSelectedSpace : {});
    }

    if (
      pageName == "Chat" ||
      pageName == "Proposals" ||
      pageName == "invoice" ||
      pageName == "estimate" ||
      pageName == "subscription" ||
      pageName == "staff" ||
      pageName == "AdminTaskPage" ||
      pageName == "credit_notes" ||
      pageName == "fullcalendar" ||
      pageName == "benchmark" ||
      pageName == "schedule" ||
      pageName == "PremiumSubscription"
    ) {
    } else {
      jquery(".office-list-icon ").on("mouseover", function () {
        OnMouseOverOfficeList();
      });
    }
    jquery(document).mouseup(function (e) {
      var container = jquery(".fixed-left-panel");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setTeamLeftPanel(false);
        setshowLeftPanelMenu(false);
        // HideDropdown();
      }
    });
    jquery(".office-list-icon-search").on("click", function () {
      showLeftPanelNew();
      addexpandedClass();
      setTimeout(function () {
        jquery(".office-list-icon-search-input").focus();
      }, 500);
    });

    jquery(".left_panel_toggle").on("click", function () {});
    jquery(".office-box").on("click", function (event) {});
    if (spaces && spaces.length && projectId) {
      const project = spaces.filter((s) => s.project_id == projectId);
      if (
        projectId &&
        (!customerData.clientTeamData ||
          !customerData.clientTeamData[parseInt(projectId)])
      ) {
        // dispatch(getClientTeamData(projectId));
      }
      if (project.length) {
        // setSelectedSpace(project[0]);
        localStorage.setItem("selected_project_client_id", project.client_id);
        setOfficeWorkSpaceScore(project[0].workspace_score);
        if (localStorage.getItem("user_type") == "staff") {
          localStorage.setItem(
            "virtualSpaceClientId",
            project[0].client_id ? project[0].client_id : ""
          );
        }
      } else {
        // setSelectedSpace(spaces[0]);
        localStorage.setItem("selected_project_client_id", spaces[0].client_id);
        setOfficeWorkSpaceScore(spaces[0].workspace_score);
      }
      if (
        !["operatorListReporting", "equipmentListReporting"].includes(pageName)
      ) {
        dispatch(
          projectTaskCounts(projectId, "", "", isLeadPage, pageName, globalView)
        );
      }
      // dispatch(getTopicTasks(projectId, pageName,0,startDate,taskFilters));
      if (pageName == "dashboardnew") {
        dispatch(getDefaultTopics(null, "", "", pageName, taskFilters));
      } else {
        dispatch(getDefaultTopics(projectId, "", "", pageName, taskFilters));
      }
    } else if (
      adminTeamList &&
      adminTeamList.length > 0 &&
      localStorage.getItem("selectedVirtualSpace") &&
      JSON.parse(localStorage.getItem("selectedVirtualSpace")).id
    ) {
      dispatch(
        getDefaultTopics(adminTeamList[0].id, "", "", pageName, taskFilters)
      );
    }
    if (userType != "staff" && !isSolarHubPlatform()) {
      CustomerServices.getPremiumEstimateDetails().then((res) => {
        if (res && res.status) {
          setEstimateDetails(res.data);
          localStorage.setItem(
            "iban_modal_open",
            res.data && res.data.open_modal
          );
          // localStorage.setItem("membership_package_id",res.data && res.data.package_id);
          dispatch(isIBANModalOpen());
        } else {
          setEstimateDetails({});
        }
      });
    }

    if (isSolarHubPlatform()) {
      if (userType === "operator") {
        const accepted_proposal = localStorage.getItem("accepted_proposal");

        if (
          ((accepted_proposal <= 0 && !isProviderHasPlan) ||
            ["Dashboard"].includes(pageName)) &&
          !["MyProfile", "Dashboard", "ChatAi"].includes(pageName)
        ) {
          navigate("/?page=proposals");
        }
      } else if (userType === "contact") {
        let scheduleData = localStorage.getItem("schedule_data");
        scheduleData = scheduleData ? JSON.parse(scheduleData) : {};
        if (
          Object.keys(scheduleData).length &&
          ![
            "ChatAi",
            "MyProfile",
            "SpaceOnBoarding",
            "proposal_details",
          ].includes(pageName) &&
          window.location.pathname != "/aichat"
        ) {
          navigate(
            `/?page=create-benchmark&space=${scheduleData.project_id}&type=benchmark&id=${scheduleData.benchmark_id}`
          );
          return;
        }
      }
      //  else if (userType == "staff") {
      //   if (!["ChatAi", "MyProfile"].includes(pageName)) {
      //     navigate("/aichat");
      //   }
      // }
    }

    if (isSolarHubPlatform() && userType == "staff") {
      showSolairHubleftPanel();
      if (localStorage.getItem("selectedOffice")) {
        localStorage.setItem("selectedOffice", 0);
        setSelectedOffice(0);
        setSelectedProject(0);
      }
    }
  }, [projectId, pageName]);
  useEffect(() => {
    if (defaultTopics != undefined) {
      setdefaultTopicsstate(defaultTopics);
      defaultTopics.map((dt) => {
        if (dt.main_key == "my_task") {
          if (dt.categories) {
            setForMeTopic(
              dt.categories.map((dd) => {
                return { ...dd, main_key: dt.main_key };
              })
            );
          }
        }
      });
    }
  }, [defaultTopics]);

  // useEffect(() => {
  //   if (globalView == "calendarView" && userType != "staff") {
  //     dispatch(leftPanelFilters("", "", "topicFilterClear"));
  //   }
  // }, [globalView])

  const getAdminTeamList = async () => {
    try {
      const response = await AdminServices.getVirtualSpaceList();

      setAdminTeamList(
        response.data.map((data) => {
          return { ...data, handler: selectAdminTeam };
        })
      );
      dispatch(setVirtualSpaceList(response.data));
    } catch (e) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
      );
    }
  };

  useEffect(() => {
    if (customerData.clientTeamData) {
      setClientTeamData(customerData.clientTeamData[parseInt(projectId)]);
    }
  }, [customerData.clientTeamData]);

  useEffect(() => {
    if (space_pending == 1) {
      hideLeftPanelNew();
    }
  }, [space_pending]);

  useEffect(() => {
    if (removedFilterStatus && removedFilterStatus.flag == true) {
      filterTasksByStatus(removedFilterStatus.status);
    }
  }, [removedFilterStatus]);

  useEffect(() => {
    if (customerData.taskCounts) {
      setProjectTaskCount(customerData.taskCounts);
    }
  }, [customerData.taskCounts]);

  useEffect(() => {
    if (
      customerData.createSchduleDetails &&
      Object.keys(customerData.createSchduleDetails).length > 0 &&
      pageName != "benchmarkList"
    ) {
      dispatch(setCreateScheduledetails({}));
    }
  }, [customerData.createSchduleDetails]);
  const reorderOffices = () => {
    let activeSpaceList = spaceState.filter(
      (x) => x.status === "1" || x.status === "2"
    );

    activeSpaceList = [
      spaceState.find((space) => space.project_id == selectedOffice),
      ...spaceState.filter((space) =>
        userType == "staff"
          ? space.project_id != selectedOffice &&
            (space.status === "1" ||
              space.status === "2" ||
              space.status == "0")
          : space.project_id != selectedOffice &&
            (space.status === "1" ||
              space.status === "2" ||
              space.status == "0")
      ),
    ];
    if (activeSpaceList.length > 0) {
      setActiveSpaces(
        activeSpaceList.sort(function (a, b) {
          if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
          if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
          if (!a.chat_group_message || a.chat_group_message === "") return 1;
          if (!b.chat_group_message || b.chat_group_message === "") return -1;
          const chatA = moment(
            a.chat_group_message_date ? a.chat_group_message_date : ""
          );
          const chatB = moment(
            b.chat_group_message_date ? b.chat_group_message_date : ""
          );
          if (chatA.isBefore(chatB)) return 1;
          if (chatA.isAfter(chatB)) return -1;
          if (
            parseInt(a.notification_count) +
              parseInt(a.chat_notification_count) +
              parseInt(a.unread_notification_count) >
            parseInt(b.notification_count) +
              parseInt(b.chat_notification_count) +
              parseInt(b.unread_notification_count)
          )
            return -1;
          if (
            parseInt(a.notification_count) +
              parseInt(a.chat_notification_count) +
              parseInt(a.unread_notification_count) <
            parseInt(b.notification_count) +
              parseInt(b.chat_notification_count) +
              parseInt(b.unread_notification_count)
          )
            return 1;
          if (a.project_id == selectedOffice) return -1;

          return 0;
        })
      );
    }
  };

  const setOfficeOnTop = (spaceId) => {
    if (activeSpaces.length > 0) {
      const targetSpace = activeSpaces.filter(
        (office) => office && office.id === spaceId
      );
      const otherOffices = activeSpaces.filter(
        (office) => office && office.id !== spaceId
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === "") return 1;
        if (!b.chat_group_message || b.chat_group_message === "") return -1;
        const chatA = moment(
          a.chat_group_message_date ? a.chat_group_message_date : ""
        );
        const chatB = moment(
          b.chat_group_message_date ? b.chat_group_message_date : ""
        );
        if (chatA.isBefore(chatB)) return 1;
        if (chatA.isAfter(chatB)) return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) >
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) <
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return 1;
        if (a.project_id == spaceId) return -1;
        return 0;
      });
      setActiveSpaces(allOffices);
    }
  };

  useEffect(() => {
    let allSpaces = spaces.filter(
      (space) => space.status == "1" || space.status == "2"
    );
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === "") return 1;
        if (!b.chat_group_message || b.chat_group_message === "") return -1;
        const chatA = moment(
          a.chat_group_message_date ? a.chat_group_message_date : ""
        );
        const chatB = moment(
          b.chat_group_message_date ? b.chat_group_message_date : ""
        );
        if (chatA.isBefore(chatB)) return 1;
        if (chatA.isAfter(chatB)) return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) >
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) <
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });
      setActiveSpaces(allOffices);
    }
  }, []);

  useEffect(() => {
    if (spaceClient && spaceClient.project_id) {
      onClickOfficeChange(spaceClient);
      localStorage.setItem("selected_project_client_id", spaceClient.client_id);
      let data = [];
      dispatch(clientTabData(data));
    }
  }, [spaceClient]);
  // useEffect(() => {
  //   // if(pageName != "LiteVersion" && globalView != "liteVersionView"){
  //     if (globalView == "spaceView" && getSelectedFloorId > 0) {
  //       dispatch(getTaskCount(client_id, contact_id, projectId, localStorage.getItem("selectedDate") ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD") : (moment(new Date()).format("YYYY-MM-DD")), taskFilters, '', '', '', '', 0, pageName, (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView,getSelectedFloorId));
  //     }else{
  //       dispatch(getTaskCount(client_id, contact_id, projectId, localStorage.getItem("selectedDate") ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD") : (moment(new Date()).format("YYYY-MM-DD")), taskFilters, '', '', '', '', 0, pageName, (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView));
  //     }
  //   // }
  // },[taskFilters,getSelectedFloorId,globalView])
  const handleOfficeChange = (space, is_virtual_space = false) => {
    // dispatch(leftPanelFilters("","","topicFilterClear"))
    dispatch(lastSessionOfChat([]));

    setSelectedSpace(space);
    dispatch(commanoffcanvasisOpen(false));
    dispatch(SetSelectedTask({}));
    let project_id = space.project_id ? parseInt(space.project_id) : 0;
    if (is_virtual_space) {
      dispatch(
        setUnreadMsgCount(
          +space.unread_chat_msg_count > 0 ? +space.unread_chat_msg_count : 0
        )
      );
    } else {
      dispatch(
        setUnreadMsgCount(
          +space.chat_notification_count > 0
            ? +space.chat_notification_count
            : 0
        )
      );
    }
    dispatch(setTaskUnreadCmtCntAll(parseInt(0)));
    if (is_virtual_space) {
      localStorage.setItem(
        "prevSelectedOffice",
        localStorage.getItem("selectedOffice")
      );
      project_id = space.id;
      setTeamLeftPanel(false);
      dispatch(getTopicTasks(project_id, "", 1));
      dispatch(getDefaultTopics(projectId, "", "", pageName));
      dispatch(
        setTaskUnreadCmtCntAll(parseInt(space.unread_notification_count))
      );
    } else {
      removeVirtualSpace();
      setSelectedTeam();
      setSelectedOffice();
      setOfficeOnTop(space.id);
      setSpaceDetails(space);
      if (project_id != localStorage.getItem("selectedOffice")) {
        setShowLoader(true);
      } else {
        setOfficeOnTop(space.id);
      }
    }

    setshowSearch(false);
    setSearchCategory("");
    if (setTaskModalClose) {
      setTaskModalClose();
    }

    setSelectedOffice(project_id);
    setSelectedProject(project_id);
    dispatch(setProject(project_id));
    if (pageName === "SpaceOnBoarding") {
      let id = searchParams.get("space");
      if (id) {
        searchParams.set("space", project_id);
      } else {
        searchParams.append("space", project_id);
      }
      setSearchParams(searchParams);
    }
    dispatch(is_discord_connected(project_id));
    dispatch(is_google_drive_connected(project_id));
    dispatch(is_googlecalendar_connected(project_id));

    var chatUserId = localStorage.getItem("chatUserId");
    dispatch(
      fetchUnreadMsgCount(
        chatUserId,
        localStorage.getItem("isLiteVersion") == "true" ? 0 : project_id
      )
    );

    dispatch(
      getTaskCount(
        client_id,
        contact_id,
        project_id,
        moment(localStorage.getItem("__selectedDate")).format("YYYY-MM-DD")
          ? moment(localStorage.getItem("__selectedDate")).format("YYYY-MM-DD")
          : moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
        taskFilters,
        activeTaskStatusFilters,
        "",
        "",
        "",
        projectId == null || projectId == 0 || projectId == undefined
          ? "liteVersionView"
          : globalView
      )
    );

    if (
      !customerData.clientTeamData ||
      !customerData.clientTeamData[parseInt(projectId)]
    ) {
      // dispatch(getClientTeamData(project_id));
    } else {
      setClientTeamData(customerData.clientTeamData[project_id]);
    }
    localStorage.setItem("selectedOffice", project_id);
    if (userType == "operator") {
      localStorage.setItem(
        "is_added_from_operator",
        space.is_added_from_operator ? space.is_added_from_operator : 0
      );
      localStorage.setItem(
        "operatorClientId",
        space.client_id ? space.client_id : 0
      );
      // if (space.is_added_from_operator == 1) {
      localStorage.setItem(
        "spaceClientDetails",
        JSON.stringify([
          {
            cp_id: space.client_id ? space.client_id : 0,
            mobile: space.phonenumber ? space.phonenumber : "",
            email: space.email ? space.email : "",
            profile_url: space.profile_image ? space.profile_image : "",
          },
        ])
      );
      // }
    }

    if (pageName == "dashboard") {
    }
    if (pageName != "Chat" && pageName != "Proposals") {
      addExpandedClasstoProviderList();
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    setTeamLeftPanel(false);
    setSearchString("");
  };
  const onPagechangeToggle = () => {};
  const filterTasksByStatus = (statusId) => {
    let otherFilters = customerData.taskFilters ? customerData.taskFilters : [];

    // Remove all status which are not active
    filterStatus.forEach((status) => {
      if (!jquery(`#kpi_task_status_` + status).hasClass("active")) {
        setFilterTaskActive([...filterTaskActive, status]);
        var sIndex = filterStatus.indexOf(status);
        filterStatus.splice(sIndex, 1);
      }
    });
    const index = filterStatus.indexOf(statusId);

    if (index === -1) {
      filterStatus.push(statusId);
      jquery(`#kpi_task_status_` + statusId).addClass("active");
      // jquery("#active_week_date").removeClass("c-gradinet-active-border");
      setFilterTaskActive([...filterTaskActive, statusId]);
    } else {
      filterStatus.splice(index, 1);
      setFilterTaskActive(
        filterTaskActive.filter((status) => status != statusId)
      );
    }

    if (isLeadPage) {
      dispatch(
        getTaskCount(
          0,
          0,
          localStorage.getItem("selectedOffice"),
          moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
          otherFilters,
          "",
          filterStatus,
          "",
          "",
          1,
          projectId == null || projectId == 0 || projectId == undefined
            ? "liteVersionView"
            : globalView
        )
      );
    } else {
      dispatch(
        getTaskCount(
          0,
          0,
          localStorage.getItem("selectedOffice"),
          moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
          otherFilters,
          "",
          filterStatus,
          "",
          "",
          0,
          pageName,
          projectId == null || projectId == 0 || projectId == undefined
            ? "liteVersionView"
            : globalView
        )
      );
    }

    var isDateActive = 0;
    var viewAll = 1;
    if (filterStatus.length > 0) {
      clearStatusFilter(false);
    } else if (globalView == "calendarView") {
      jquery("#active_week_date").removeClass("c-gradinet-active-border");
    } else {
      isDateActive = 1;
      viewAll = 0;
      addActiveBorderforWeekCalendar();
    }
    dispatch(
      setActiveTaskStatusFilters({
        filterStatus,
      })
    );
    // if (
    //   filterStatus.length > 0 &&
    //   otherFilters.findIndex((x) => x.main_key === "date_range_filter") !== -1
    // ) {
    //   dispatch(
    //     leftPanelFilters({
    //       main_key: "date_range_filter",
    //       filter_key: "date_range_filter",
    //       title: "date_range_filter",
    //     })
    //   );
    // } else {
    // }
  };

  const spaceFilterOnStatus = (value, key) => {
    value = value.toString();
    let newArr = [...spaceFilter, value];

    if (spaceFilter.filter((x) => x == value).length) {
      newArr = spaceFilter.filter((c) => c != value);
    }
    setSpaceFilter(newArr);

    if (key === "my_spaces") {
      if (!projectFilters.my_spaces) {
        projectFilters.account_executive.push(adminUser);
        projectFilters.account_manager.push(adminUser);
        projectFilters.my_spaces = true;
      } else {
        projectFilters.account_executive =
          projectFilters.account_executive.filter((w) => w != adminUser);
        projectFilters.account_manager = projectFilters.account_manager.filter(
          (w) => w != adminUser
        );
        projectFilters.my_spaces = false;
      }
    } else if (projectFilters[key]) {
      if (projectFilters[key].includes(value)) {
        projectFilters[key] = projectFilters[key].filter(
          (w) => w.toString() != value.toString()
        );
      } else {
        projectFilters[key].push(value);
      }
      if (projectFilters.my_spaces) {
        projectFilters.account_executive.push(adminUser);
        projectFilters.account_manager.push(adminUser);
      }
    }

    let allSpaces = spaces.filter(
      // (space) => userType == 'staff' ? space.status == "0" || space.status == "1" || space.status == "2" : space.status == "1" || space.status == "2" // only working for admin
      (space) =>
        space.status == "0" || space.status == "1" || space.status == "2"
    );
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === "") return 1;
        if (!b.chat_group_message || b.chat_group_message === "") return -1;
        const chatA = moment(
          a.chat_group_message_date ? a.chat_group_message_date : ""
        );
        const chatB = moment(
          b.chat_group_message_date ? b.chat_group_message_date : ""
        );
        if (chatA.isBefore(chatB)) return 1;
        if (chatA.isAfter(chatB)) return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) >
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) <
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });

      let projectIdArray = [];
      if (searchMentionUsers.length) {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => {
          const projects = obj.projects;
          return commonProjects.filter((project) => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      const myArrayFiltered = allSpaces.filter((el) => {
        let flag = true;
        if (projectIdArray.length && !projectIdArray.includes(el.project_id)) {
          flag = false;
        }

        if (
          projectFilters["project_status"].length &&
          !projectFilters["project_status"].includes(el.project_status)
        ) {
          flag = false;
        }

        if (flag) {
          return el;
        }
      });

      let appliedFilters = 0;
      Object.keys(projectFilters).forEach((key) => {
        if (projectFilters[key].length && !isNaN(projectFilters[key].length)) {
          appliedFilters += projectFilters[key].length;
        }
      });
      if (appliedFilters > 0 || searchMentionUsers.length) {
        setActiveSpaces(myArrayFiltered);
      } else {
        setActiveSpaces(allOffices);
      }
    }
  };

  let onBoardingSpaces = spaceState;
  //This sort is bringing the selected space on top in onboarding page, to maintain the order by notifications, commenting this code
  // onBoardingSpaces.sort((a, b) => a.project_id == selectedOffice ? -1 : b.project_id == selectedOffice ? 1 : 0)
  // console.log("virtualSpaceList",virtualSpaceList ,"selectedStaffMembersForSpaceFilter",selectedStaffMembersForSpaceFilter,"spacesArray",spacesArray)
  let getSearchedOffice = [];

  getSearchedOffice =
    pageName === "SpaceOnBoarding"
      ? onBoardingSpaces
      : spacesArray &&
        (spacesArray.length ||
          projectFilters.project_status.length ||
          searchMentionUsers.length)
      ? removeDuplicates(
          spacesArray.filter((space) => {
            removeDuplicates(spaceArray);
            if (
              searchString !== "" ||
              spaceFilter.length > 1 ||
              userType != "staff"
            ) {
              return (
                (space &&
                  space.office_title &&
                  space.office_title
                    .toLowerCase()
                    .includes(searchString.toLowerCase())) ||
                (space &&
                  space.postal_address &&
                  space.postal_address
                    .toLowerCase()
                    .includes(searchString.toLowerCase())) ||
                (space &&
                  space.name &&
                  space.name
                    .toLowerCase()
                    .includes(searchString.toLowerCase())) ||
                (space &&
                  space.company &&
                  space.company
                    .toLowerCase()
                    .includes(searchString.toLowerCase())) ||
                (space && space.pin_count && space.pin_count == 1)
              );
            } else {
              return selectedStaffMembersForSpaceFilter.length == 0
                ? userType == "staff" &&
                    space != undefined &&
                    (space.is_project_member > 0 ||
                      (space.pin_count && space.pin_count == 1)) &&
                    space.project_status != 7
                : spacesArray && space.project_status != 7;
            }
          })
        )
      : spaceState && spaceState.length > 0 && userType == "staff"
      ? spaceState.filter((space) => {
          return space.members.some(
            (member) =>
              member.staff_id == localStorage.getItem("staff_id") ||
              (space && space.pin_count && space.pin_count == 1)
          );
        })
      : [];

  if (
    getSearchedOffice &&
    getSearchedOffice.length > 0 &&
    Object.keys(selectedSpace).length > 0 &&
    selectedSpace.project_id &&
    !projectFilters.project_status.length /* && !mentionStaffArray.length */
  ) {
    if (
      !getSearchedOffice.some(
        (office) => office.project_id == selectedSpace.project_id
      )
    ) {
      getSearchedOffice = getSearchedOffice.concat(selectedSpace);
    }
  }

  let getinActiveSpaces = inActiveSpaces.filter(
    (space) =>
      (space &&
        space.office_title &&
        space.office_title
          .toLowerCase()
          .includes(searchString.toLowerCase())) ||
      (space &&
        space.postal_address &&
        space.postal_address
          .toLowerCase()
          .includes(searchString.toLowerCase())) ||
      (space &&
        space.company &&
        space.company.toLowerCase().includes(searchString.toLowerCase()))
  );

  useEffect(() => {
    if (isStatusCleared) {
      filterStatus = [];
      setFilterTaskActive([]);
    }
  }, [isStatusCleared]);

  useEffect(() => {
    if (activeTaskStatusFilters && activeTaskStatusFilters.length) {
      setFilterTaskActive(activeTaskStatusFilters);
      if (viewPage == "/" || isLeadPage) {
        setTimeout(() => {
          // jquery("#active_week_date").removeClass("c-gradinet-active-border");
        }, 100);
      }
    }
  }, [activeTaskStatusFilters]);

  useEffect(() => {
    dispatch(getCustomerSpaces(client_id, staffOrContactId));

    // dispatch(getDefaultTopics(projectId, "", "", pageName));
    dispatch(fetchAuthToken(email, contact_id, "client"));
    if (proposalId > 0) {
      const selectedOffice = JSON.parse(localStorage.getItem("spaces"));
      if (selectedOffice && selectedOffice.length > 0) {
        const slectedProjectId = selectedOffice[0].project_id;
        dispatch(getDefaultTopics(slectedProjectId, "", "", pageName));
      }
    } else {
      if (localStorage.getItem("leftPanelTab") == "my-staff") {
        dispatch(getDefaultTopics(0, "", "", "homepage", '', true));
      }else{
        dispatch(getDefaultTopics(0, "", "", pageName));
      }
    }
  }, []);

  const loginHandler = (email, clientId, type, id, projectID, allData) => {
    setSelectedProject();
    const from_admin_side = 1;
    localStorage.setItem("user_client_id", clientId);
    localStorage.setItem(
      "user_project_id",
      project_id == 0 ? projectId : project_id
    );
    let userType =
      allData.user_type.toLowerCase() == "provider" ? "staff" : "contact";
    setLoginAsUserLoader(true);
    authServices
      .getUserInfo(userType.toLowerCase(), id, from_admin_side)
      .then((res) => {
        if (res.status == 1 && res.data) {
          setSelectedProject();
          dispatch(setVirtualSpaceList([]));
          setSelectedTeam();
          localStorage.removeItem("selectedVirtualSpace");
          var chatUserId = localStorage.getItem("chatUserId");
          if (chatUserId != "" && chatUserId != undefined) {
            dispatch(userOffline({ userId: chatUserId }));
          }
          // if (res.data.registration_confirmed == 1) {
          dispatch(showFullScreenLoader());
          dispatch(
            loginAsUser(
              email,
              clientId,
              project_id == 0 ? projectId : project_id
            )
          ).then(async (loginRes) => {
            if (loginRes.status == 1) {
              if (res.data.registration_confirmed == 0) {
                localStorage.setItem(
                  "is_registration_confirmed",
                  res.data.registration_confirmed
                );
                localStorage.setItem("user_type", res.data.type);
                localStorage.setItem("userId", res.data.id);
              }
              if (!localStorage.getItem("accessToken")) {
                dispatch(
                  showMessage(
                    "unsucess",
                    _l("l_error"),
                    _l("l_invalid_credentials")
                  )
                );
              } else {
                localStorage.setItem("password", "12345");
                await loadDataToIDB(allData.staff_id);
                if (localStorage.getItem("membership_package_id") == 1) {
                  // navigate("/premiumsubscription");
                  navigate("/myprofile");
                } else {
                  const client_id = localStorage.getItem("client_id");
                  const contact_id = localStorage.getItem("contact_id");
                  localStorage.setItem(
                    "selectedOffice",
                    project_id == 0 ? projectId : project_id
                  );
                  dispatch(getCustomerSpaces(client_id, contact_id));
                  dispatch(adminLoginAsStaff(true));
                  dispatch(projectTaskCounts(projectId));
                  favoriteDdashboardSelected(loginRes.data.dashboard_name);
                  localStorage.setItem(
                    "fav_dashboard",
                    loginRes.data.dashboard_name
                  );
                  leftPanelFilters("", "", "all");
                  dispatch(getGroupDetails({}));
                  dispatch(lastSessionOfChat([]));
                  dispatch(setSelectedGloablView("AichatView"));
                  dispatch(setSelectedTabLeftPanel("proposal-list"));
                  
                  navigate("/redirect", {
                    state: {
                      // url: "/liteversion",
                      url: "/",
                    },
                  });
                }
              }
              dispatch(leftPanelFilters("", "", "topicFilterClear"));
            } else {
              dispatch(
                showMessage("unsucess", _l("l_error"), loginRes.message)
              );
            }
          });
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      })
      .catch(() => {
        setLoginAsUserLoader(false);
      });
  };
  const loginHandlerforsolarStaff = (userData) => {
    setSelectedProject();
    const from_admin_side = 1;
    localStorage.setItem("user_client_id", userData.clientId);
    localStorage.setItem(
      "user_project_id",
      project_id == 0 ? projectId : project_id
    );
    let userType =
      userData.extraData.mainSubKey.toLowerCase() == "contact"
        ? "contact"
        : "staff";
    setLoginAsUserLoader(true);
    dispatch(showFullScreenLoader());
    dispatch(
      loginAsUser(
        "",
        userData.clientId,
        project_id == 0 ? projectId : project_id,
        userData.extraData.filterKey
      )
    ).then(async (loginRes) => {
      if (loginRes.status == 1) {
        // if (res.data.registration_confirmed == 0) {
        //   localStorage.setItem("is_registration_confirmed", res.data.registration_confirmed);
        //   localStorage.setItem("user_type", res.data.type);
        //   localStorage.setItem("userId", res.data.id);
        // }
        if (!localStorage.getItem("accessToken")) {
          dispatch(
            showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
          );
        } else {
          localStorage.setItem("password", "12345");
          // await loadDataToIDB(allData.staff_id);
          if (localStorage.getItem("membership_package_id") == 1) {
            // navigate("/premiumsubscription");
            navigate("/myprofile");
          } else {
            const client_id = localStorage.getItem("client_id");
            const contact_id = localStorage.getItem("contact_id");
            localStorage.setItem(
              "selectedOffice",
              project_id == 0 ? projectId : project_id
            );
            dispatch(getCustomerSpaces(client_id, contact_id));
            dispatch(adminLoginAsStaff(true));
            dispatch(projectTaskCounts(projectId));
            favoriteDdashboardSelected(loginRes.data.dashboard_name);
            localStorage.setItem("fav_dashboard", loginRes.data.dashboard_name);
            leftPanelFilters("", "", "all");
            dispatch(getGroupDetails({}));
            dispatch(lastSessionOfChat([]));
            dispatch(setSelectedGloablView("AichatView"));
            navigate("/redirect", {
              state: {
                // url: "/liteversion",
                url: "/",
              },
            });
          }
        }
        dispatch(leftPanelFilters("", "", "topicFilterClear"));
      } else {
        // dispatch(showMessage("unsucess", _l("l_error"), loginRes.message));
      }
      setLoginAsUserLoader(false);
    });
  };
  const pinProject = (project_id) => {
    CustomerServices.pinProject(project_id).then((res) => {
      if (res.status && res.data) {
        removeDuplicates(spacesArray);
        const spaceIndex = spacesArray.findIndex(
          (x) => x && x.project_id == project_id
        );
        let spa = Object.values({
          ...spacesArray,
          [spaceIndex]: {
            ...spacesArray[spaceIndex],
            pin_count: spacesArray[spaceIndex].pin_count == "0" ? "1" : "0",
          },
        });
        // if (spa.length > 0) {
        const targetSpace = spa.filter(
          (office) => office && office.id === selectedSpace.id
        );
        const otherOffices = spa.filter(
          (office) => office && office.id !== selectedSpace.id
        );
        spa = targetSpace.concat(otherOffices);
        removeDuplicates(spa);
        spa.sort(function (a, b) {
          if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
          if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
          if (!a.chat_group_message || a.chat_group_message === "") return 1;
          if (!b.chat_group_message || b.chat_group_message === "") return -1;
          const chatA = moment(
            a.chat_group_message_date ? a.chat_group_message_date : ""
          );
          const chatB = moment(
            b.chat_group_message_date ? b.chat_group_message_date : ""
          );
          if (chatA.isBefore(chatB)) return 1;
          if (chatA.isAfter(chatB)) return -1;
          if (
            parseInt(a.notification_count) +
              parseInt(a.chat_notification_count) +
              parseInt(a.unread_notification_count) >
            parseInt(b.notification_count) +
              parseInt(b.chat_notification_count) +
              parseInt(b.unread_notification_count)
          )
            return -1;
          if (
            parseInt(a.notification_count) +
              parseInt(a.chat_notification_count) +
              parseInt(a.unread_notification_count) <
            parseInt(b.notification_count) +
              parseInt(b.chat_notification_count) +
              parseInt(b.unread_notification_count)
          )
            return 1;
          if (a.project_id == selectedSpace.id) return -1;
          return 0;
        });
        //
        setActiveSpaces(spa);
        spaces.find(
          (p) =>
            p.project_id === project_id &&
            ((p.pin_count = p.pin_count == "0" ? "1" : "0"), true)
        );
        setSpaces(spaces);
        // setActiveSpaces(Object.values({...activeSpaces, [spaceIndex]: {...activeSpaces[spaceIndex], pin_count : activeSpaces[spaceIndex].pin_count == "0" ? "1" : "0" } }));
      }
      if (res.status === 0) {
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
      }
    });
  };

  const getUserList = async (client_id, project_id) => {
    const setDataToStateVariable = (data) => {
      if (data) {
        let customers =
          data.customers && data.customers.members
            ? data.customers.members
            : [];
        customers.sort(
          (a, b) =>
            b.is_primary - a.is_primary ||
            a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())
        );
        let providers =
          data.providers && data.providers.members
            ? data.providers.members
            : [];
        providers.sort(
          (a, b) =>
            b.is_boss_operator - a.is_boss_operator ||
            a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())
        );
        if (customers.length) {
          customers.forEach((customer, index) => {
            customer["type"] = data.customers.title;
            customer["user_type"] = data.customers.title;
          });
        }
        if (providers.length) {
          providers.forEach((provider, index) => {
            provider["type"] = data.providers.title;
            provider["user_type"] = data.providers.title;
          });
        }
        setUserList(customers.concat(providers));

        if (providers.length == 0 && customers.length == 0) {
          setUserList([]);
        }
      }
    };

    const fetchListFromAPI = (updateLocalState = true) => {
      setLoder(updateLocalState);
      setIsLoginAsUserDataLoaded(false);
      CustomerServices.getDetailForLoginAsUser(client_id, project_id)
        .then((res) => {
          if (res.status && res.data) {
            if (updateLocalState) {
              setDataToStateVariable(res.data);
            }
            insertCollectionToDB(
              "login_as_user_list",
              [{ data: res.data, project_id }],
              "project_id"
            );
          }
        })
        .catch((e) => {
          console.error("Can not fetch login as users data....", e);
        })
        .finally(() => {
          setLoder(false);
          setIsLoginAsUserDataLoaded(true);
        });
    };

    try {
      setIsLoginAsUserDataLoaded(false);
      const data = await readCollectionFromDB(
        "login_as_user_list",
        "project_id"
      );
      if (!data || !data.length) {
        fetchListFromAPI();
      } else {
        let currentProjectMemberData = data.find(
          (w) => w.project_id == project_id
        );
        if (currentProjectMemberData) {
          setDataToStateVariable(currentProjectMemberData.data);
          fetchListFromAPI(false);
        } else {
          fetchListFromAPI();
        }
      }
    } catch (e) {
      fetchListFromAPI();
      console.error(e);
    } finally {
      setIsLoginAsUserDataLoaded(true);
    }
  };

  const closeSearchArea = () => {
    setShowBackDropClass(false);
    setSpaceSearchOnGoing(false);
    setSearchBarFocus(false);
    setSearchString("");
  };

  useEffect(() => {
    let element = document.getElementById("modified_height");
    let element2 = document.body;

    const handleOfficeListOutsideClick = (e) => {
      if (
        officeListDivRef &&
        officeListDivRef.current &&
        officeSearchboxRef &&
        officeSearchboxRef.current &&
        !officeListDivRef.current.contains(e.target) &&
        !officeSearchboxRef.current.contains(e.target) &&
        officeListDivRef &&
        topicSearch.current &&
        officeSearchboxRef &&
        officeSearchboxRef.current &&
        !topicSearch.current.contains(e.target) &&
        !officeSearchboxRef.current.contains(e.target)
      ) {
        jquery("#intro-office-list").removeClass("searchbutton");
        closeSearchArea();
      }
    };

    document.addEventListener("click", handleOfficeListOutsideClick);
    if (
      noLeftPanel.indexOf(viewPage.replace("/", "")) > -1 ||
      noLeftPanelPages.indexOf(pageName) > -1
    ) {
      if (!["/dashboard", "myprofile"].includes(window.location.pathname)) {
        DefaultLeftPanel();
      }
      document.addEventListener("mouseup", leftPanelMouseUpEvent);
      if (
        pageName != "Chat" &&
        pageName != "AddBenchmark" &&
        pageName != "documents" &&
        projectId > 0 &&
        projectId != "null" &&
        localStorage.getItem("is_dashboard") == 1
      ) {
        jquery(".office-box").removeClass("active");
      }
    }
    if (fullScrollPages.indexOf(pageName) > -1) {
      if (element && element2) {
        element.classList.add("modified-height", "modified-res-default-height");
        element2.classList.add("pb-0");
      }
    }
    if (pageName !== "Dashboard" && pageName !== "Chat") {
      // dispatch(setSelectedGloablView("listView"))
    }
    return () => {
      document.removeEventListener("mouseup", leftPanelMouseUpEvent);
      document.removeEventListener("click", handleOfficeListOutsideClick);
      element = document.getElementById("modified_height");
      if (element && element2) {
        element.classList.remove("modified-res-default-height");
        element2.classList.remove("pb-0");
      }
    };
  }, [pageName, globalView]);

  useEffect(() => {
    setPreEstiPageDisable(false);
    if (isPremiumEstimateEdit == true) {
      // setPreEstiPageDisable(true);
    }
    setGetIbanModlaOpen(false);
    if (isIBANModal == true) {
      setGetIbanModlaOpen(true);
    }
  }, [JSON.stringify(isPremiumEstimateEdit), JSON.stringify(isIBANModal)]);

  useEffect(() => {
    if (pageName == "MyEmail") {
      localStorage.setItem("email_filter_key", "inbox");
      localStorage.removeItem("email_filter_email");
    }
  }, []);
  useEffect(() => {
    // if (pageName == "MyEmail" || pageName == " ") {
    CustomerServices.getMailSettings().then((res) => {
      if (res && res.status) {
        setIsEmailSynce(res.status);
        res.data.map((item) => {
          if (item["provider"] == "gmail" && setSynchronizedMail) {
            setSynchronizedMail("gmail");
          } else if (
            item["provider"] == "outlook_mail" &&
            setSynchronizedMail
          ) {
            setSynchronizedMail("outlook_mail");
          }
        });
      }
    });
    // }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("selectedVirtualSpace")) {
      try {
        let virtualSPace = JSON.parse(
          localStorage.getItem("selectedVirtualSpace")
        );
        setSelectedTeam(
          virtualSPace && Object.keys(virtualSPace).length ? virtualSPace : {}
        );
        dispatch(
          getDefaultTopics(
            localStorage.getItem("selectedOffice"),
            "",
            "",
            pageName
          )
        );
      } catch (error) {
        console.log(error);
      }
      jquery(".link-center-box-toggle").addClass("d-none");
    } else {
      setSelectedTeam();
      jquery(".link-center-box-toggle").removeClass("d-none");
    }
  }, [localStorage.getItem("selectedVirtualSpace")]);

  const revokeMailSync = () => {
    CustomerServices.revokeMailSync(synchronizedMail).then((res) => {
      if (res && res.status) {
        window.dispatchEvent(new Event("mailSyncRevoked"));
        setSynchronizedMail(false);
      }
    });
  };

  const bulkAddCustomTopic = () => {
    window.dispatchEvent(new Event("bulkAddCustomTopic"));
  };

  const selectAdminTeam = (team, is_remove = 0) => {
    dispatch(fullScreenLoaderChangeSpace(true));
    dispatch(setGloablSearchString(""));
    dispatch(lastSessionOfChat([]));
    TaskModalClose();
    dispatch(setSelectedDateView("dayView"));
    dispatch(setDaterangeFilterApplied(false));
    dispatch(selectedChatUser(undefined));
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));
    dispatch(setShowWeekcalendardays(true));
    dispatch(setDaterangeFilter("today"));
    handleChangeViewtoFavView();
    localStorage.setItem("isLiteVersion", false);
    localStorage.setItem("generalChatForLiteVersion", false);
    dispatch(leftPanelFilters("", "", "all"));
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(updateTaskList([]));
    localStorage.setItem("selectedDate", formateDate(new Date()));
    localStorage.removeItem("activeGroupId");
    dispatch(setClearmessage());

    if (is_remove) {
      handleOfficeChange(team);
    } else {
      setSelectedProject(team.id);
      localStorage.setItem("selectedVirtualSpace", JSON.stringify(team));
      localStorage.setItem("selected_project_client_id", team.id);

      setSelectedTeam(team);
      handleOfficeChange(team, true);
      if (pageName != "Chat") {
        navigate("/");
        dispatch(setSelectedGloablView("AichatView"));
      }
    }
    if (contactlistShow) {
      contactDirTopicChange({}, true);
    }
  };
  const removeVirtualSpace = (flag = 0) => {
    if (flag && localStorage.getItem("prevSelectedOffice")) {
      localStorage.setItem(
        "selectedOffice",
        localStorage.getItem("prevSelectedOffice")
      );
      localStorage.removeItem("prevSelectedOffice");
    }
    localStorage.removeItem("selectedVirtualSpace");
  };
  useEffect(() => {
    window.addEventListener("edit_wallet_settings", () => {
      setShow(true);
    });
  }, []);

  const [unreadComments, setUnreadComments] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);
  useEffect(() => {
    const totalMails = allMAilMessages
      ? allMAilMessages
      : [...(importantMessages || []), ...(inboxMessages || [])];
    let unread_comments = 0;
    let unread_messages = 0;

    (totalMails || []).map((email) => {
      unread_comments =
        unread_comments +
        (email["thread"]["mentions"] || []).filter(
          (comment) => comment["is_read"] == 0
        ).length;
      unread_messages =
        unread_messages +
        ((
          email["conversation"]["messages"].filter((reply) =>
            reply["labelIds"].includes("UNREAD")
          ) || []
        ).length > 0
          ? 1
          : 0);
    });
    setUnreadComments(unread_comments);
    setUnreadMessages(unread_messages);
  }, [importantMessages, inboxMessages, statusClose, allMAilMessages]);

  const createChatChannels = (projectId = 0) => {
    dispatch(toastCloseAction());
    setShowLoader(true);
    CustomerServices.createChatChannels(projectId).then((res) => {
      setShowLoader(false);
      if (res.status) {
        dispatch(showMessage("sucess", "Success", res.message));
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const getDataForProjectFilter = async () => {
    try {
      const response = await AdminServices.getMyRStaffList("0");
      if (response.status && response.data.length) {
        const accountExecutiveList = response.data.filter(
          (w) => w.role == 3 && w.staffid != adminUser
        );
        const accountManagerList = response.data.filter(
          (w) => w.role == 12 && w.staffid != adminUser
        );

        let props = [
          {
            key: "l_my_spaces",
            id: -1,
            name: _l("l_my_space"),
            options: [],
            handler: spaceFilterOnStatus,
          },
          {
            key: "l_project_status",
            handler: spaceFilterOnStatus,
            options: projectStatusArray.map((w) => {
              return {
                ...w,
              };
            }),
          },
          {
            key: "l_account_executive",
            handler: spaceFilterOnStatus,
            options: accountExecutiveList.map((w) => {
              return {
                ...w,
                name: w.fullname,
                id: w.staffid,
              };
            }),
          },
          {
            key: "l_account_manager",
            handler: spaceFilterOnStatus,
            options: accountManagerList.map((w) => {
              return {
                ...w,
                name: w.fullname,
                id: w.staffid,
              };
            }),
          },
        ];

        setFilterDropDownProps(props);
        spaceFilterOnStatus("", "l_my_spaces");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    var totalUnreadCount = 0;
    if (virtualSpaceList && virtualSpaceList.length > 0) {
      virtualSpaceList.map((item) => {
        totalUnreadCount +=
          parseInt(item.unread_chat_msg_count) +
          parseInt(item.unread_notification_count);
        if (item.id == projectId) {
          dispatch(
            setTaskUnreadCmtCntAll(parseInt(item.unread_notification_count))
          );
        }
      });
    }
    setVirtualSpaceUnreadMsg(totalUnreadCount);
  }, [virtualSpaceList]);

  useEffect(() => {
    if (proposalCount) {
      setProposalKpiCount(proposalCount);
    }
  }, [proposalCount]);

  useEffect(() => {
    if (
      (userType == "staff" || userType == "operator") &&
      selectedSpace.client_id &&
      selectedSpace.clientId != undefined
    ) {
      localStorage.setItem("virtualSpaceClientId", selectedSpace.client_id);
    } else if (projectId) {
      let tempspace = spaceArray.filter(
        (space) => space.project_id == projectId
      );
      if (tempspace.length > 0) {
        localStorage.setItem("virtualSpaceClientId", tempspace[0].client_id);
      }
      // this code is commneted cause it is creating infinte api call
      //  if(Object.keys(selectedSpace).length > 0){
      //   setSelectedProject(selectedSpace.project_id ? selectedSpace.project_id : selectedSpace.id)
      //   localStorage.setItem("selectedOffice",selectedSpace.project_id ? selectedSpace.project_id : selectedSpace.id)
      //  }
    }

    if (isSolarHubPlatform() && userType === "staff") {
      setSelectedSpace(0);
      setSelectedProject(0);
    } else if (
      userType == "staff" &&
      spaceState.length == 0 &&
      adminTeamList.length > 0
    ) {
      localStorage.setItem("virtualSpaceClientId", adminTeamList[0].id);

      setSelectedProject(adminTeamList[0].id);
    }
  }, [selectedSpace, userType, projectId, adminTeamList, spaceState]);

  useEffect(() => {
    CustomerServices.getCompanyEmployee().then((res) => {
      if (res.status && res.data) {
        setEmployeeData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (userType == "staff") {
      // dispatch(setSelectedTabLeftPanel("my-clients"));
      getDataForProjectFilter();
      if (clientTeamData && clientTeamData.myr_staff) {
        setEmployeeData(clientTeamData.myr_staff);
      }
    }
  }, [clientTeamData]);

  useEffect(() => {
    if (pageName == "dashboard") {
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard", 0);
    } else if (pageName != "LiteVersion" && globalView != "liteVersionView") {
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard", 1);
    }

    if (
      [
        "PremiumSubscription",
        "Proposals",
        "MyEmail",
        "SpaceOnBoarding",
      ].includes(pageName)
    ) {
      let menuName = "";
      switch (pageName) {
        case "PremiumSubscription":
          menuName = "plan";
          break;
        case "Proposals":
          menuName = "proposals";
          break;
        case "SpaceOnBoarding":
          if (window.location.href.includes("proposal")) {
            menuName = "proposals";
          }
          break;
        case "Proposals":
          menuName = "mail";
          break;
      }
      setActiveMenuOptions([menuName]);
    }
  }, []);

  useEffect(() => {
    if (
      FavView == "AichatView" &&
      pageName == "Dashboard" &&
      globalView == "AichatView"
    ) {
      dispatch(fullScreenLoaderChangeSpace(true));
    } else {
      dispatch(fullScreenLoaderChangeSpace(false));
    }
  }, [pageName]);

  useEffect(() => {
    generateThreadId();
  }, [taskFilters, globalView]);

  useEffect(() => {
    if (
      (page == "create-benchmark" || page == "proposals") &&
      !spaceSearchOnGoing
    ) {
      removeExpandedClasstoOfficeList();
      // hideLeftPanelNew();
    } else {
      jquery(document).mouseup(function (e) {
        var container1 = jquery(".LeftPanelSearch");
        if (!container1.is(e.target) && container1.has(e.target).length === 0) {
          var container2 = jquery("#intro-office-list");
          if (
            !container2.is(e.target) &&
            container2.has(e.target).length === 0
          ) {
            setShowBackDropClass(false);
            setSpaceSearchOnGoing(false);
            // addExpandedClasstoOfficeList();
          }
        }
      });
    }
  });
  const mailModalHandleClose = () => setSyncShowMailModal(false);
  const mailModalHandleShow = () => setSyncShowMailModal(true);
  const onClickOfficeChange = (space) => {
    // set default values of global state on space change
    dispatch(setGloablSearchString(""));
    dispatch(clearDefaultTopicsList());
    TaskModalClose();
    dispatch(setSelectedDateView("dayView"));
    dispatch(setDaterangeFilterApplied(false));
    dispatch(selectedChatUser(undefined));
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));
    dispatch(setShowWeekcalendardays(true));
    dispatch(setDaterangeFilter("today"));
    handleChangeViewtoFavView();
    localStorage.setItem("isLiteVersion", false);
    localStorage.setItem("generalChatForLiteVersion", false);
    dispatch(leftPanelFilters("", "", "all"));
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(updateTaskList([]));
    localStorage.setItem("selectedOffice", space.project_id);
    localStorage.setItem("selected_project_client_id", space.client_id);
    localStorage.setItem("selectedDate", formateDate(new Date()));
    dispatch(setClearmessage());
    dispatch(updateTaskList([]));

    if (FavView == "AichatView") {
      dispatch(fullScreenLoaderChangeSpace(true));
    }
    if (
      (user_type == "contact" || user_type == "operator") &&
      Number(contact_role) == 3
    ) {
      navigate("/");
      dispatch(setSelectedGloablView("AichatView"));
      handleChangeViewtoFavView();
    }
    if (globalView != "AichatView") {
      localStorage.removeItem("activeGroupId");
    }
    if (localStorage.getItem("membership_package_id") == 1) {
      return;
    }
    if (projectId != space.project_id) {
      handleOfficeChange(space);
      if (pageName == "Proposals") {
        navigate("/");
      }
    } else {
      jquery(".office-list").removeClass("expanded");
      setTeamLeftPanel(false);
      /*click on Selected space redirect  */
      if (localStorage.getItem("user_type") == "contact") {
        if (pageName == "Chat") {
          navigate("/chat");
        }
      } else {
        navigate("/");
        dispatch(setSelectedGloablView("AichatView"));
      }
    }

    if (pageName != "Chat" && pageName != "LiteVersion") {
      jquery("body").removeClass("with-right-penel");
      showLeftPanelNew();
      jquery(".task-comment-modal").removeClass("show-comments");
      if (
        disablePages.indexOf(viewPage.replace("/", "")) > -1 ||
        noLeftPanelPages.indexOf(pageName) > -1 ||
        isLeadPage
      ) {
        if (localStorage.getItem("user_type") == "contact") {
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));
        } else if (pageName !== "SpaceOnBoarding") {
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));
        }
      }
    } else {
      hideLeftPanelNew();
      jquery(".provider-list").addClass("d-none");
    }
    dispatch(setOpenGeneralChatGroup(false));
    dispatch(clearDocumentData());
    if (contactlistShow) {
      contactDirTopicChange({}, true);
    }
  };

  const handleEditAdminTeam = (team, is_user_journey = "") => {
    if (
      is_user_journey == "user_journey" ||
      is_user_journey == "my_people" ||
      is_user_journey == "task_topic"
    ) {
      CustomerServices.getTeamDetails(Math.abs(team)).then((res) => {
        if (res.status) {
          if (res.data) {
            const teamData = {
              id: res.data.id,
              members: res.data.team.map((x) => {
                return {
                  member_id: x.member_id,
                  firstname: x.firstname,
                  lastname: x.lastname,
                };
              }),
              teamTitle: res.data.name,
              manager_name: res.data.manager_name,
              manager_id: res.data.manager_id,
            };
            setTopicType(is_user_journey);
            setSelectedTeamForEdit(teamData);
            setshowAddTeamMember(true);
          }
        } else {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              res.message ? res.message : ""
            )
          );
        }
      });
    } else {
      const teamData = {
        id: team.id,
        members: team.members.map((x) => {
          return {
            member_id: x.staffid,
            firstname: x.full_name,
            lastname: "",
          };
        }),
        teamTitle: team.name,
      };
      setSelectedTeamForEdit(teamData);
      setshowAddTeamMember(true);
    }
  };

  const handleFavoriteAdminTeam = (team) => {
    adminServices
      .setFavouriteVirtualSpace(team.id, team.is_selected == 0 ? 1 : 0)
      .then((res) => {
        if (res.status) {
          jquery(".dropdown-menu").removeClass("show");
          let Arr = adminTeamList;
          for (let i = 0; i < Arr.length; i++) {
            if (Arr[i].id == team.id) {
              Arr[i].is_selected = team.is_selected == 0 ? 1 : 0;
            } else {
              Arr[i].is_selected = 0;
            }
          }
          const sortArr = Arr.sort(
            (a, b) => parseInt(b.is_selected) - parseInt(a.is_selected)
          );
          dispatch(setVirtualSpaceList(sortArr));
          setAdminTeamList(sortArr);
        } else {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              res.message ? res.message : ""
            )
          );
        }
      });
  };
  const handleDeleteTeam = (data) => {
    CustomerServices.removeVirtualSpace(data.id).then((res) => {
      if (res.status) {
        setAdminTeamList(adminTeamList.filter((team) => team.id != data.id));
        dispatch(
          setVirtualSpaceList(
            adminTeamList.filter((team) => team.id != data.id)
          )
        );
        dispatch(toastCloseAction());
        if (
          localStorage.getItem("selectedVirtualSpace") &&
          JSON.parse(localStorage.getItem("selectedVirtualSpace")).id == data.id
        ) {
          localStorage.setItem(
            "selectedOffice",
            localStorage.getItem("prevSelectedOffice")
          );
          localStorage.removeItem("prevSelectedOffice");
          selectAdminTeam(
            { project_id: localStorage.getItem("prevSelectedOffice") },
            1
          );
          dispatch(
            getTopicTasks(
              localStorage.getItem("prevSelectedOffice"),
              "Dashboard"
            )
          );
          dispatch(
            getDefaultTopics(
              localStorage.getItem("prevSelectedOffice"),
              "",
              "",
              "Dashboard"
            )
          );
        }
        dispatch(
          showMessage("sucess", _l("l_success"), res.message ? res.message : "")
        );
      } else {
        dispatch(
          showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
        );
      }
    });

    // setshowAddTeamMember(true);
  };

  const hideMenuButton = (menuName) => {
    let flag = false;
    let currentSpaceDetails = spaceArray.find(
      (w) => w.project_id == selectedOffice && w.project_id != -1
    );

    switch (menuName) {
      case "searchBar":
      case "premiumPage":
      case "teams":
      case "mail":
      case "wallet":
      case "company-profile":
      case "more-tools":
      case "proposal":
        if (currentSpaceDetails && currentSpaceDetails.is_external > 0) {
          flag = true;
        } else if (
          ["SpaceOnBoardingNew"].includes(pageName) &&
          ["/space-onboarding"].includes(viewPage)
        ) {
          flag = true;
        }
        break;
      default:
        break;
    }
    return flag;
  };

  const getspacedata = (e, project_id) => {
    CustomerServices.getSpaceDataOhover(project_id).then((res) => {
      if (res.status) {
        setspaceData(res.data);
      }
    });
  };

  const getMentionUsersForFilter = async () => {
    if (userType === "staff" && !mentionStaffArray.length) {
      try {
        const response = await AdminServices.getAllProjectMembers();
        let members = [];
        if (response.status && response.data) {
          response.data.forEach((project) => {
            project.members.forEach((member) => {
              let memberIndex = members.findIndex(
                (w) =>
                  w.label &&
                  member.full_name &&
                  member.full_name.trim() == w.label.trim() &&
                  member.staffid == w.value
              );
              if (memberIndex === -1) {
                let showCrown = false;
                if (
                  member.user_type === "operator" &&
                  member.role == 2 &&
                  member.contact_role == 1
                ) {
                  showCrown = true;
                } else if (
                  member.user_type === "contact" &&
                  member.contact_role == 1
                ) {
                  showCrown = true;
                }
                members.push({
                  label: member.full_name,
                  value: member.staffid,
                  profile_image_url: member.profile_image_url,
                  pillContent: `#${member.user_type}`,
                  projects: [
                    {
                      project_id: project.project_id.toString(),
                      title: project.office_title,
                      space_name: project.postal_address,
                    },
                  ],
                  showCrown,
                  user_type: member.user_type,
                  staffid: member.staffid,
                  contactid: member.contactid,
                  email: member.email,
                  full_name: member.full_name,
                });
              } else {
                members[memberIndex] = {
                  ...members[memberIndex],
                  projects: members[memberIndex].projects.concat([
                    {
                      project_id: project.project_id.toString(),
                      title: project.office_title,
                      space_name: project.postal_address,
                    },
                  ]),
                };
              }
            });
          });
        }
        members.forEach((member) => {
          member.label = `@${member.label}`;
        });
        members.sort((a, b) => a.label.localeCompare(b.label));
        setMentionStaffArray(members);
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      }
    }
  };

  const applyStaffUserFilter = (member, isRemove) => {
    let projectIdArray = [];

    if (isRemove) {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.filter(
        (w) => w.value != member.value
      );
      if (searchMentionUsers.length) {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => {
          const projects = obj.projects;
          return commonProjects.filter((project) => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      let updatedMemberList = mentionStaffArray.concat(member);
      updatedMemberList.sort((a, b) => a.label.localeCompare(b.label));
      setMentionStaffArray(updatedMemberList);
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
    } else {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.concat(member);
      if (searchMentionUsers.length == 1) {
        let projectIdArrays = [];
        projectIdArrays = searchMentionUsers.map((w) => w.projects);
        projectIdArrays = projectIdArrays[0];
        projectIdArrays = projectIdArrays.map((project) => project.project_id);
        projectIdArray.push(projectIdArrays);
      } else {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => {
          const projects = obj.projects;
          return commonProjects.filter((project) => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }

      setSearchString("");
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
      setMentionStaffArray(
        mentionStaffArray.filter((w) => w.value != member.value)
      );
    }

    let allSpaces = spaces.filter(
      (space) =>
        space.status == "0" || space.status == "1" || space.status == "2"
    );
    let list = spaceState.filter(
      (space) =>
        space.status == "0" || space.status == "1" || space.status == "2"
    );
    allSpaces = allSpaces.filter((el) => {
      let flag = true;
      Object.keys(projectFilters).forEach((param) => {
        if (
          param !== "my_spaces" &&
          projectFilters[param].length &&
          !projectFilters[param].includes(
            el[param] && !isNaN(el[param]) ? el[param].toString() : el[param]
          )
        ) {
          flag = false;
        }
      });
      if (flag) {
        return el;
      }
    });
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === "") return 1;
        if (!b.chat_group_message || b.chat_group_message === "") return -1;
        const chatA = moment(
          a.chat_group_message_date ? a.chat_group_message_date : ""
        );
        const chatB = moment(
          b.chat_group_message_date ? b.chat_group_message_date : ""
        );
        if (chatA.isBefore(chatB)) return 1;
        if (chatA.isAfter(chatB)) return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) >
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return -1;
        if (
          parseInt(a.notification_count) +
            parseInt(a.chat_notification_count) +
            parseInt(a.unread_notification_count) <
          parseInt(b.notification_count) +
            parseInt(b.chat_notification_count) +
            parseInt(b.unread_notification_count)
        )
          return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });
    }
    const filteredSpaces = spaceArray.filter(
      (w) =>
        projectIdArray.length > 0 && projectIdArray[0].includes(w.project_id)
    );
    setActiveSpaces(searchMentionUsers.length ? filteredSpaces : list);
  };
  const selectLiteVersion = () => {
    DefaultLeftPanel();
    setLeftpanelToggleOnLiteversion(true);
    localStorage.setItem("isLiteVersion", true);
    jquery("body").removeClass("task-modal-offcanvas-open");
    if (pageName != "Dashboard" && pageName != "Chat") {
      navigate("/");
      dispatch(setSelectedGloablView("liteVersionView"));
    } else if (pageName == "Dashboard" && contact_role == 3) {
      dispatch(leftPanelFilters("", "", "all"));
      dispatch(setSelectedGloablView("liteVersionView"));
    }
    setSelectedOffice(0);
    setSelectedProject(0);
    if (userType == "staff") {
      localStorage.removeItem("selectedVirtualSpace");
    }
    if (pageName == "Chat") {
      // localStorage.setItem("isLiteVersion", true)
      setSelectedOffice(0);
      localStorage.setItem("selectedOffice", 0);
    } else {
      if (projectId > 0) {
        // navigate("/liteversion");
        handleChangeViewtoFavView();
        localStorage.setItem("selectedOffice", 0);
        // localStorage.setItem("isLiteVersion",true);
        setSelectedProject(0);
        const savedDate = localStorage.getItem("selectedDate");
        dispatch(
          getTaskList(
            client_id,
            contact_id,
            0,
            moment(savedDate).format("YYYY-MM-DD"),
            1,
            activeTaskStatusFilters,
            taskFilters,
            "",
            0,
            1,
            "",
            "",
            "liteVersionView"
          )
        );
      } else {
        handleChangeViewtoFavView();
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLeftpanelToggleOnLiteversion(false);
    }, 3000);
  }, [leftpanelToggleOnLiteversion]);
  useEffect(() => {
    if (isAdmin == "true" && userType == "staff" && isAllChatUserlistShow) {
      addExpandedClasstoProviderList();
      showLeftPanelNew();
    }
  }, [isAllChatUserlistShow]);

  const handleStaffWalletConfiguration = () => {
    setShowStaffIBANSetup(true);
  };

  const prepareToast = (className, messageBody, header) => {
    setShowToast(true);
    setToastHeader(header);
    setToastClass(className);
    setToastMessage(messageBody);
  };
  const handleSpaceDelete = () => {
    CustomerServices.deleteOrCancelSpace(
      officeId,
      project_id,
      estimateId,
      3
    ).then((res) => {
      if (res.status == 1) {
        dispatch(
          showMessage(
            "sucess",
            _l("l_success"),
            res.message
              ? _l(res.message)
              : _l("l_space") + _l("l_delete") + _l("l_successfully")
          )
        );
        dispatch(getCustomerSpaces(client_id, contact_id));
      } else {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            res.message ? _l(res.message) : _l("l_something_went_wrong")
          )
        );
      }
    });
    setShowToast(false);
  };
  const handleChangeViewtoFavView = () => {
    let FavView = localStorage.getItem("fav_dashboard");

    navigate("/");
    FavView == "AichatView"
      ? dispatch(setSelectedGloablView("AichatView"))
      : FavView == "calendar"
      ? dispatch(setSelectedGloablView("calendarView"))
      : FavView == "my_spaces"
      ? dispatch(setSelectedGloablView("spaceView"))
      : FavView == "kanban_view"
      ? dispatch(setSelectedGloablView("kanbanView"))
      : FavView == "my_tasks"
      ? dispatch(setSelectedGloablView("listView"))
      : contact_role == 3
      ? navigate("/")
      : dispatch(setSelectedGloablView("AichatView"));

    FavView == "kanban_view" ? setIsKanbanView(true) : setIsKanbanView(false);
  };
  useEffect(() => {
    CustomerServices.premiumEstimatePlan(project_id).then((res) => {
      if (res.status && res.data) {
        setEstimateId(res.data.estimate ? res.data.estimate.estimate_id : 0);
      }
    });
  }, [project_id]);

  const handleVisibleMenus = () => {
    if (!menuOptionsFloat) {
      setvisibleOptionIcons([]);
      setMenuOptionsFloat(true);
      VISIBLE_MENUS = [];
      SPACE_LIST_RESULTS_PER_PAGE = 8;
    } else {
      setVisibleOptions();
      setMenuOptionsFloat(false);
      SPACE_LIST_RESULTS_PER_PAGE = 5;
    }
  };

  const setVisibleOptions = () => {
    switch (userType) {
      case "contact":
        VISIBLE_MENUS = CONTACT_MENU_OPTIONS;
        setvisibleOptionIcons(CONTACT_MENU_OPTIONS);
        break;
      case "operator":
        VISIBLE_MENUS = OPERATOR_MENU_OPTIONS;
        setvisibleOptionIcons(OPERATOR_MENU_OPTIONS);
        break;
      default:
        VISIBLE_MENUS = STAFF_MENU_OPTIONS;
        setvisibleOptionIcons(STAFF_MENU_OPTIONS);
    }
  };

  const hideMenuOnOut = () => {
    setTimeout(() => {
      if (!showBackDropClass) {
        removeExpandedClasstoIntroOfficeList();
      }
      if (menuOptionsFloat) {
        setvisibleOptionIcons([]);
        VISIBLE_MENUS = [];
        SPACE_LIST_RESULTS_PER_PAGE = 8;
      }
      if (isSolarHubPlatform() && userType == "staff") {
        showSolairHubleftPanel();
        addExpandedClasstoOfficeList();
      } else if (
        (projectId == 0 ||
          projectId == null ||
          projectId == undefined ||
          projectId == "null") &&
        pageName != "dashboardnew" &&
        window.location.pathname != "/dashboard" &&
        !contactlistShow &&
        !proposalId &&
        !id
      ) {
        hideLeftPanelNew();
      } else {
        setShowForMeSection(false);
      }
      jquery("body").removeClass("hover-left-penal");
      const universalCommentModal = document.getElementById("comment_close");
      if (universalCommentModal) {
        universalCommentModal.click();
      }

      if (
        (userType == "contact" || userType == "operator") &&
        contact_role == 3
      ) {
        hideLeftPanelNew();
      }

      setShowForMeSection(false);

      if (SettingMenuRender) {
        setSettingMenuRender(false);
      }
    }, 750);
  };

  const showMenuOnHover = (event) => {
    if (
      menuButtonRef.current &&
      menuButtonRef.current.contains(event.target) &&
      menuOptionsFloat
    ) {
      SPACE_LIST_RESULTS_PER_PAGE = 5;
      setVisibleOptions();
    }
  };

  const updateActiveMenuOptions = (option) => {
    setActiveMenuOptions([option]);
  };

  const discardBenchmark = () => {
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    dispatch({
      type: USER_LOGOUT,
    });
    setBenchmarkDiscardModal({});
    navigate("/login");
  };

  const proceedWithPendingPayment = async () => {
    try {
      setSubscriptionLoader(true);
      const email = localStorage.getItem("email");

      const subscriptionDetail = await DocumentsServices.getSubscriptionDetail(
        0,
        0,
        pendingPaymentInfo.subscription_hash
      );
      const paymentMethod = subscriptionDetail.data.payment_mode;

      const response = await DocumentsServices.subscribe(
        pendingPaymentInfo.subscription_hash,
        "",
        email,
        1,
        pendingPaymentInfo.project_id,
        1,
        client_id,
        undefined,
        undefined,
        paymentMethod
      );
      if (response.status) {
        let pendingPayments = localStorage.getItem("pending_payments");
        pendingPayments = pendingPayments ? JSON.parse(pendingPayments) : [];
        pendingPayments = pendingPayments.filter(
          (w) => w.subscription_id != pendingPaymentInfo.subscription_id
        );
        localStorage.setItem(
          "pending_payments",
          JSON.stringify(pendingPayments)
        );
        dispatch(getCustomerSpaces(client_id, contact_id));
        setPendingPaymentInfo({});
        if (response.data.site) {
          window.location.href = response.data.site;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSubscriptionLoader(false);
    }
  };

  const generateThreadId = () => {
    //For Services
    const providerData = taskFilters.find((w) => w.main_key === "providers");
    if (
      providerData &&
      adminArchieve &&
      ["contact"].includes(userType) &&
      globalView === "AichatView"
    ) {
      const topic =
        defaultTopics && defaultTopics.find((w) => w.main_key === "providers");
      if (topic && topic.categories.length) {
        topic.categories.forEach(async (category) => {
          if (
            category.filter_key == providerData.filter_key &&
            category.assistant_id &&
            category.assistant_id !== "" &&
            (!category.thread_id || category.thread_id == "")
          ) {
            try {
              const response = await CustomerServices.generateAIThread(
                projectId,
                providerData.main_key,
                category.filter_key,
                category.main_sub_key
              );
              if (response.data) {
                dispatch(
                  setTopicThreadId(
                    response.data,
                    providerData.main_key,
                    category.filter_key,
                    category.main_sub_key
                  )
                );
              }
            } catch (e) {
              console.error(e);
            }
          }
        });
      }
    }
    //For AI Assistant Topic for people section
    const peopleSectionData = taskFilters.find(
      (w) => w.main_key === "my_contact"
    );
    if (
      peopleSectionData &&
      adminArchieve &&
      ["contact"].includes(userType) &&
      globalView === "AichatView"
    ) {
      const topic =
        defaultTopics && defaultTopics.find((w) => w.main_key === "my_contact");
      if (topic && topic.categories.length) {
        topic.categories.forEach(async (category) => {
          if (
            category.contact_role == 4 &&
            category.filter_key == peopleSectionData.filter_key &&
            (!category.thread_id || category.thread_id == "")
          ) {
            try {
              const response = await CustomerServices.generateAIThread(
                projectId,
                peopleSectionData.main_key,
                category.filter_key,
                category.main_sub_key
              );
              if (response.data) {
                dispatch(
                  setTopicThreadId(
                    response.data,
                    peopleSectionData.main_key,
                    category.filter_key,
                    category.main_sub_key
                  )
                );
              }
            } catch (e) {
              console.error(e);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    //For search effect similar to global search in left panel
    if (isSolarHubPlatform() && userType == "staff") {
      if (spaceSearchOnGoing) {
        if (showBackDropClass) {
          addExpandedClasstoIntroOfficeList(true);
          showSolairHubleftPanel();
        } else {
          jquery("#intro-office-list").removeClass("search-backdrop");
          if (!jquery("#intro-office-list").hasClass("expanded")) {
            addExpandedClasstoIntroOfficeList(false);
          }
          showSolairHubleftPanel();
        }
      }
    } else if (spaceSearchOnGoing) {
      if (showBackDropClass) {
        addExpandedClasstoIntroOfficeList(true);
      } else {
        removeExpandedClasstoIntroOfficeList();
      }
    }
  }, [showBackDropClass]);

  useEffect(() => {
    if (page && page == "create-benchmark") {
      setContactlistShow(false);
    }
    if (!page && spaceState.length > 0) {
      setSelectedSpace(spaceState[0]);
    }
  }, [page, spaceState]);
  useEffect(() => {}, [JSON.stringify(userDetails)]);

  // useEffect(() => {
    // if (userType != "staff" && isSolarHubPlatform() && groupListExternal && !localStorage.getItem("activeGroupId")) {
    //   let groupId = groupListExternal[0] ? groupListExternal[0].groupId : ""
    //   if (groupId != "") {
    //     chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupId).then((res) => {
    //       if (res && res.success) {
    //         // let unreadindex = groupListExternal.findIndex((gd)=> gd.groupId == groupId)
    //         // if (unreadindex > -1) {
    //         //   let tempData = groupListExternal[unreadindex]
    //         //   tempData.unreadCount = 0
    //         //   let finalData = Object.values({ ...groupListExternal, [unreadindex]: tempData })
    //         //   dispatch(updateExternalGroupListMessages(finalData))
    //         // }
    //         dispatch(getGroupDetails({...res.result,unreadMsgCount : 0}))
    //       }
    //     })
    //   }
    // }
    // if (groupListExternal && groupListExternal.length > 0 && leftPanelTab == "my-clients" && userType == "staff") {
    //     const selecteClients = groupListExternal
    //                             .sort((a, b) => {
    //                               const chatA = moment(
    //                                 a.lastMessageDate &&
    //                                   a.lastMessage &&
    //                                   a.lastMessage != ""
    //                                   ? a.lastMessageDate
    //                                   : ""
    //                               );
    //                               const chatB = moment(
    //                                 b.lastMessageDate &&
    //                                   b.lastMessage &&
    //                                   b.lastMessage != ""
    //                                   ? b.lastMessageDate
    //                                   : ""
    //                               );
                        
    //                               if (!a.lastMessage || a.lastMessage === "") return 1;
    //                               if (!b.lastMessage || b.lastMessage === "") return -1;
    //                               if (chatA.isBefore(chatB)) return 1;
    //                               if (chatA.isAfter(chatB)) return -1;
    //                             })
    //       handleUserForSolarStaff(selecteClients[0])
    // }
  // }, [JSON.stringify(groupListExternal)]);

  useEffect(() => {
    if (
      isSolarHubPlatform() &&
      ["create-benchmark", "register-provider"].includes(page)
    ) {
      dispatch(fetchGroupsExternal(chatUserId, 0, 1));
    }
  }, []);

  const handleChatGroup = (group) => {
    localStorage.setItem("activeGroupId", group.groupId);
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    chatServices.getGroupDetails(chatUserId, group.groupId).then((res) => {
      if (res.success) {
        if (
          chatUserId != "" &&
          activeGroupId != "" &&
          activeGroupId != undefined
        ) {
          var socketDataIn = {
            groupId: activeGroupId,
            userId: chatUserId,
          };
          // if (globalView == "AichatView") {
          dispatch(groupIn(socketDataIn));
          dispatch(
            fetchMessages(
              localStorage.getItem("chatUserId"),
              group.groupId,
              0,
              () => {},
              1,
              "",
              "",
              "",
              group.unread_count > 0 ? group.unread_count : 20
            )
          );
          // }
        }
        let groupWrapper = { ...res.result, groupName: group.groupName };
        dispatch(getGroupDetails(groupWrapper));
        localStorage.setItem("getGroupDetails", JSON.stringify(groupWrapper));
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const contactList = (groupListExternal, searhContact) => {
    const filteredCategories = groupListExternal.filter((contact) => {
      const isTitleMatch = contact.groupName
        .toLowerCase()
        .includes(searhContact.toLowerCase());
      return isTitleMatch;
    });
    return filteredCategories;
  };

  const contactDirTopicChange = (group, fromspace) => {
    if (fromspace == false) {
      if (!contactlistShow) {
        setSelectedSpace({});
        // setSelectedOffice(0)
        dispatch(leftPanelFilters("", "", "all"));
        setContactlistShow(true);
        dispatch(getGroupDetails({}));
        dispatch(setFetchedTaskandMessages([]));
        dispatch(updateTaskList([]));
        dispatch(setClearmessage());
        dispatch(clearChatTaskList());
        navigate(
          id
            ? `/aichat?proposal_id=${0}`
            : proposalId
            ? `/aichat?proposal_id=${proposalId}`
            : "/"
        );
        dispatch(setSelectedGloablView("AichatView"));
        dispatch(SetSelectedTask({}));
        TaskModalClose();
        dispatch(setBulkTaskRating([]));
        // localStorage.removeItem("selectedOffice")
        // localStorage.removeItem("selectedVirtualSpace")
        setSelectedProject(0);
        // handleChatGroup(group)
        dispatch(contactDirCount(group.groupId));
        localStorage.setItem("selectedTopic", JSON.stringify(group));
        localStorage.setItem("isContactDir", 1);
        localStorage.removeItem("activeGroupId");
      } else {
        handleChatGroup(group);
        dispatch(contactDirCount(group.groupId));
        localStorage.setItem("selectedTopic", JSON.stringify(group));
      }
    } else {
      setContactlistShow(false);
      localStorage.removeItem("isContactDir");
    }
  };

  const handleSolarhubSupportChannel = () => {
    // hideLeftPanelNew();
    dispatch(chatMessageLoader(true));
    setContactlistShow(true);
    navigate("/aichat?proposal_id=0");
    jquery(".company-logo-box").removeClass("active");
    dispatch(
      leftPanelFilters({
        main_key: "contact_directory",
        filter_key: localStorage.getItem("staff_id"),
        title: "Myr.ai Support",
        image: "",
        main_sub_key: "people",
        sub_category: [],
      })
    );
    customerServices
      .createChatGroup(
        0,
        "myr_support",
        localStorage.getItem("user_type"),
        localStorage.getItem("staff_id")
      )
      .then((res) => {
        if (res && res.status) {
          let groupID = res.data._id;
          localStorage.setItem("activeGroupId", groupID);
          chatServices
            .getGroupDetails(localStorage.getItem("chatUserId"), groupID)
            .then((res) => {
              const data = res.result
              if (res && res.success) {
                var activeGroupId = localStorage.getItem("activeGroupId");
                if (
                  chatUserId != "" &&
                  activeGroupId != "" &&
                  activeGroupId != undefined
                ) {
                  var socketDataIn = {
                    groupId: activeGroupId,
                    userId: chatUserId,
                  };
                  dispatch(groupIn(socketDataIn));
                  dispatch(
                    fetchMessages(
                      localStorage.getItem("chatUserId"),
                      groupID,
                      0,
                      () => {},
                      1
                    )
                  );
                }
                localStorage.setItem(
                  "getGroupDetails",
                  JSON.stringify(data)
                );
                dispatch(getGroupDetails({...data, unreadMsgCount: 0}));
              }
            });
        }
        dispatch(chatMessageLoader(false));
      });
  };

  const getGroupDetailsData = () => {
    customerServices
      .createChatGroup(
        0,
        "myr_support",
        localStorage.getItem("user_type"),
        localStorage.getItem("staff_id")
      )
      .then((res) => {
        if (res && res.status) {
          let groupID = res.data._id;
          localStorage.setItem("activeGroupId", groupID);
          chatServices
            .getGroupDetails(localStorage.getItem("chatUserId"), groupID)
            .then((res) => {
              if (res && res.success) {
                var activeGroupId = localStorage.getItem("activeGroupId");
                if (
                  chatUserId != "" &&
                  activeGroupId != "" &&
                  activeGroupId != undefined
                ) {
                  var socketDataIn = {
                    groupId: activeGroupId,
                    userId: chatUserId,
                  };
                  dispatch(groupIn(socketDataIn));
                  dispatch(
                    fetchMessages(
                      localStorage.getItem("chatUserId"),
                      groupID,
                      0,
                      () => {},
                      1
                    )
                  );
                }
                localStorage.setItem(
                  "getGroupDetails",
                  JSON.stringify(res.result)
                );
                dispatch(getGroupDetails(res.result));
              }
            });
        }
      });
  };

  const handleUserForSolarStaff = async (user) => { 
    dispatch(chatMessageLoader(true));
    try {
      await chatServices
        .getGroupDetails(localStorage.getItem("chatUserId"), user.groupId)
        .then((res) => {
          if (res && res.success) {
            var socketDataIn = {
              groupId: user.groupId,
              userId: chatUserId,
            };
            localStorage.setItem("activeGroupId", user.groupId);
            dispatch(setUserDetailsForStaffuser(user));
            dispatch(groupIn(socketDataIn));
            dispatch(getGroupDetails(res.result));
            dispatch(
              fetchMessages(
                localStorage.getItem("chatUserId"),
                user.groupId,
                0,
                () => {},
                1,
                "",
                "",
                "",
                0
              )
            );
          }
          let unreadindex = groupListExternal.findIndex(
            (item) => item.groupId === user.groupId
          );
          if (unreadindex > -1) {
            let tempData = groupListExternal[unreadindex];
            tempData.unreadCount = 0;
            let finalData = Object.values({
              ...groupListExternal,
              [unreadindex]: tempData,
            });
            dispatch(updateExternalGroupListMessages(finalData));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (leftPanelTab == "support") {
      handleSolarhubSupportChannel();
    }
  }, [leftPanelTab])
  // ---------------------------------------- Notificatio count development for SolarHubPlatform customer and provider start ----------------------------------------

  if (!jquery.isEmptyObject(newMessage)) {
    localStorage.setItem("newMessageId", newMessage && newMessage._id);
    dispatch(handleChatScrolltoStop(true));
    let activeGroupId = localStorage.getItem("activeGroupId");
    let tempProjectId =
      localStorage.getItem("isLiteVersion") == "true" ? 0 : projectId;
    // if (pageName == "Chat" && (pageName == "ChatAi" || globalView == "AichatView")) {
    dispatch(lastMessageSpace(newMessage.projectId, newMessage, "chat"));
    if (
      isSolarHubPlatform() &&
      userType != "staff" &&
      pageName != "ChatAi" &&
      groupDetails &&
      Object.keys(groupDetails).length > 0 &&
      activeGroupId != newMessage.group
    ) {
      let tempdata = Number(groupDetails.unreadMsgCount);
      dispatch(
        getGroupDetails({ ...groupDetails, unreadMsgCount: tempdata + 1 })
      );
    }
    if (
      page == "create-benchmark" ||
      pageName == "ChatAi" ||
      (groupListExternal && groupListExternal.length > 0)
    ) {
      let unreadindex = groupListExternal.findIndex(
        (gd) => gd.groupId == newMessage.group
      );
      if (unreadindex > -1 && activeGroupId != newMessage.group) {
        let tempData = groupListExternal[unreadindex];
        tempData.unreadCount = tempData.unreadCount + 1;
        tempData.lastMessage = newMessage.message;
        tempData.lastMsgUserName = newMessage.senderName;
        tempData.lastMessageDate = newMessage.updatedAt;
        let finalData = Object.values({
          ...groupListExternal,
          [unreadindex]: tempData,
        });
        dispatch(updateExternalGroupListMessages(finalData));
      }
    }
    dispatch(updateNewMessage());
    dispatch(handleChatScrolltoStop(false));
  }
  // ---------------------------------------- Notificatio count development for SolarHubPlatform customer and provider end ----------------------------------------

  useEffect(() => {
    if (userType == "staff") {
      showSolairHubleftPanelForSearch();
    }
  },[]);

  useEffect(() => {
    if (leftPanelTab == "my-request" || leftPanelTab == "my-drive" ) {
      dispatch(setSelectedGloablView(globalView == "kanbanView" ? "kanbanView" : globalView == "calendarView" ? "calendarView" : "listView"));
      dispatch(leftPanelFilters("","","applyForMewithAll"));
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }
  }, [leftPanelTab])

  useEffect(() => {
    let currSpace = localStorage.getItem("selectedOffice");
    dispatch(setSpaceNotificationCount(currSpace, 1, 0, "chat"));
  }, [activeSpaces])

  useEffect(() => {
    if (activeSpaces && Array.isArray(activeSpaces)) {
      const sum = activeSpaces.reduce((total, space) => {
        return total + (space && space.chat_notification_count ? Number(space.chat_notification_count) : 0);
      }, 0);
      setTotalNotificationCounts(sum);
    }
  }, [spaceState]);

  useEffect(() => {
    if (groupListExternal && Array.isArray(groupListExternal)) {
      const sum = groupListExternal.reduce((total, space) => {
        return total + (Number(space.unreadCount) || 0);
      }, 0);
      setTotalCounts(sum);
    }
  }, [groupListExternal]);

  useEffect(() => {
    getGroupDetailsData();
  }, [])
  
  return (
    <>

{/* new panel start */}
<div id="left-panel-main" className="fixed-left-panel">
  {
    ["proposal-list",'support', "installation-list", "adminstrative-list","homePage"].includes(leftPanelTab) ?  
    <LeftSubMenu 
    setSettingTopicName={setSettingTopicName}
    setTopicMainKey={setTopicMainKey}
    getMentionUsersForFilter={getMentionUsersForFilter}
    isSolarHubPlatform={isSolarHubPlatform}
    applyStaffUserFilter={applyStaffUserFilter}
    mentionStaffArray={mentionStaffArray}
    handleUserForSolarStaff={handleUserForSolarStaff}
    loginHandlerforsolarStaff={loginHandlerforsolarStaff}
    loginHandler={loginHandler}
    contactDirLoader={contactDirLoader}
    counts={totalNotificationCounts}
    totalCounts={totalCounts}
    supportChatCounts={groupDetails.unreadMsgCount}
    />
    :
     <LeftPanelSubmenu 
      spaces={getSearchedOffice} 
      handleUserForSolarStaff={handleUserForSolarStaff}
      loginHandlerforsolarStaff={loginHandlerforsolarStaff}
      loginHandler={loginHandler}
      setSettingTopicName={setSettingTopicName}
      setTopicMainKey={setTopicMainKey}
      onClickOfficeChange={onClickOfficeChange}
      projectId={projectId}
      mentionStaffArray={mentionStaffArray}
      setSearchString={setSearchString}
      applyStaffUserFilter={applyStaffUserFilter}
      isSolarHubPlatform={isSolarHubPlatform}
      getMentionUsersForFilter={getMentionUsersForFilter}
      contactDirLoader={contactDirLoader}
        setCompletedTasksCount={setCompletedTasks}
        setInProgressTasksCount={setInProgressTasks}
        setTodoTasksCount={setTodoTasks}
        searchCategory={searchCategory}
        pageName={pageName}
        teamLeftPanel={teamLeftPanel}
        setSelectOperators={setSelectOperators}
        setShowScheduleDropDown={setShowScheduleDropDown}
        setShowProviderDropDown={setModal1show}
        allMAilMessages={allMAilMessages}
        allImportantMessages={allImportantMessages}
        hideMailSyncBtn={hideMailSyncBtn}
        createTeamModal={createTeamModal}
        setCreateTeamModal={setCreateTeamModal}
        equipmentModal={equipmentModal}
        setEquipmentModal={setEquipmentModal}
        selectAdminTeam={(team, is_remove) =>
          selectAdminTeam(team, is_remove)
        }
        selectedTeam={selectedTeam}
        clientTeamData={clientTeamData}
        handleDeleteTeam={handleDeleteTeam}
        handleFavoriteAdminTeam={handleFavoriteAdminTeam}
        handleEditAdminTeam={handleEditAdminTeam}
        topicType={topicType}
        setTopicType={setTopicType}
        selectedTeamForEdit={selectedTeamForEdit}
        setSelectedTeamForEdit={setSelectedTeamForEdit}
        showAddTeamMember={showAddTeamMember}
        setshowAddTeamMember={setshowAddTeamMember}
        adminTeamList={adminTeamList}
        setAdminTeamList={setAdminTeamList}
        showUserList={isAllChatUserlistShow}
        searchCategoty={searchCategoty}
        searchTopic={searchTopic}
        showForMeSection={showForMeSection}
        setShowForMeSection={setShowForMeSection}
        filterTaskActive={filterTaskActive}
        filterTasksByStatus={filterTasksByStatus}
        projectTaskCount={projectTaskCount}
        totalCounts={totalCounts}
        supportChatCounts={groupDetails.unreadMsgCount}
      /> 
  }
      {/* <LeftSubMenu /> */}
      {/* <LeftPanelSubmenu /> */}
      </div>

{/* new panel over */}
      {spaceInvitationData.project_id > 0 ? (
        <AlertModal
          handleClose={closeInvitationAlert}
          message={spaceInvitationData.message}
          buttonText={"l_ok"}
          icon={{ name: CelebrationIcon }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {benchmarkDiscardModal.project_id > 0 ? (
        <AlertModal
          handleClose={() => {
            navigate(
              `/create-benchmark?space=${benchmarkDiscardModal.project_id}&provider-type=external-benchmark&id=${benchmarkDiscardModal.schedule_id}`
            );
          }}
          message={"l_external_benchmark_discard_alert"}
          buttonText={"l_continue"}
          icon={{ name: SignIn }}
          secondaryButtonHandler={discardBenchmark}
          secondaryButtonText={"l_login"}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {userType === "operator" &&
      pendingPaymentInfo &&
      pendingPaymentInfo.subscription_id ? (
        <PaymentModal
          address={pendingPaymentInfo.address}
          companyName={pendingPaymentInfo.company_name}
          amount={pendingPaymentInfo.subscription_amount}
          handleClose={() => {
            navigate("/");
          }}
          handleSave={proceedWithPendingPayment}
          show={pendingPaymentInfo.subscription_id > 0}
          loader={subscriptionLoader}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {loginAsUserLoader || fullScreenLoadChanSpace ? (
        <CommanLoader className="main-loader-wrapper full-screen z_20" />
      ) : (
        <></>
      )}
      {showPasswordSetModal ? (
        <SetUserPassword
          handleClose={() => {
            setShowPasswordSetModal(false);
          }}
        />
      ) : (
        <></>
      )}
      {isSolarHubPlatform() && userType != "staff" ? (
        <>
          <audio id="my-call-audio" className="d-none">
            <source src={myCallAudio} type="audio/mpeg" />
          </audio>
          <audio id="my-call-accept-audio" className="d-none">
            <source src={myCallAcceptAudio} type="audio/mpeg" />
          </audio>
          <audio id="my-call-end-audio" className="d-none">
            <source src={myCallEndAudio} type="audio/mpeg" />
          </audio>
          <audio id="my-call-recording-audio" className="d-none">
            <source src={myCallRecordingAudio} type="audio/mpeg" />
          </audio>
          {!jquery.isEmptyObject(callStatus) ? (
            callStatus.status == "initCall" ? (
              <FeedChatCallModal
                show={true}
                callStatus={callStatus}
                handleClose={() => {}}
              />
            ) : (
              <FeedChatAudioCall callStatus={callStatus} />
            )
          ) : (
            // : <AudioCall callStatus={callStatus} />
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      {/* Show task detail modal */}
      {showTaskCanvas ? (
        <CommanOffCanvas
          show={showTaskCanvas}
          handleClose={() => { setShowTaskCanvas(false); }}
          data={taskDetail}
          setData={()=>{}}
          docType={'task'}
          setSelectedId={()=>{}}
          is_fullscreen={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LeftPanel;
