import { GET_USER_ID_GLOBAL, LOGIN_SUCCESS, LOGOUT, USER_LOGOUT } from "./action-type";
import AuthService from "../services/auth-services";
import { connectToSocket, fetchUnreadMsgCount } from "./chat";
import { loginToMyrAdmin, getTeams } from "./customer";
import { fetchAuthToken } from "./chat-auth";
// import LogRocket from "logrocket";

const version = process.env.REACT_APP_VERSION;
export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then((data) => {
    let userData = data.data;

    if(userData.space_setup_pending == 1)
    {
      localStorage.setItem("space_setup_pending", 1);
    }
    else {
      localStorage.removeItem("space_setup_pending");
    }

    if(userData.ai_space_initialization_date)
    {
      localStorage.setItem("ai_space_initialization_date", userData.ai_space_initialization_date);
    }
    else {
      localStorage.removeItem("ai_space_initialization_date");
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { data },
    });
    setTimeout(() => {
      if (data.data.user_type == "staff") {
        dispatch(loginToMyrAdmin(username, password));
      }
      // Get chat auth token
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken != "" && accessToken != null) {
        const email = localStorage.getItem("email");
        const contact_id = localStorage.getItem("contact_id");
        dispatch(fetchAuthToken(email, contact_id, "client"));
        dispatch(getTeams());
      }
      if (version == 'v2') {
        localStorage.setItem('version', version)
      } else {
        localStorage.setItem('version', version)
      }
      // Connet to socket and fetch chat unread message count
      setTimeout(() => {
        const chatAccessToken = localStorage.getItem("chatAccessToken");
        if (chatAccessToken != "" && chatAccessToken != null) {
          dispatch(connectToSocket());
          var chatUserId = localStorage.getItem("chatUserId");
          var projectId = localStorage.getItem("selectedOffice");
          dispatch(fetchUnreadMsgCount(chatUserId, projectId));
        }
      }, 1000);
    }, 1500);
    // LogRocket.identify(userData.user_type == "contact" ? userData.contact_id : userData.staff_id, {
    //   name: userData.full_name,
    //   email: userData.email,
    //   userType: userData.user_type
    // });
    if (data.status != 1) {
      return data
    }else{
      return data
    }
  });
};

export const loginAsUser = (username, password, projectId ,filter_key) => (dispatch) => {
  return AuthService.loginAsUser(username, password, projectId,filter_key).then((data) => {
    if(data.status == 1){
      let archieve = [];
      if (!localStorage.getItem("adminArchieve")) {
        for (const [key, value] of Object.entries(localStorage)) {
          archieve.push({ key, value });
        }
      } else {
        archieve = JSON.parse(localStorage.getItem("adminArchieve"))
      }
      localStorage.clear();
      localStorage.setItem("version", process.env.REACT_APP_VERSION);
      dispatch({
        type: USER_LOGOUT
      });
      localStorage.setItem("adminArchieve", JSON.stringify(archieve));

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { data },
      });
      setTimeout(() => {
        if (data.data.user_type == "staff") {
          dispatch(loginToMyrAdmin(username, password));
        }
        // Get chat auth token
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken != "" && accessToken != null) {
          const email = localStorage.getItem("email");
          const contact_id = localStorage.getItem("contact_id");
          dispatch(fetchAuthToken(email, contact_id, "client"));
          dispatch(getTeams());
        }
        if (version == 'v2') {
          localStorage.setItem('version', version)
        } else {
          localStorage.setItem('version', version)
        }
        // Connet to socket and fetch chat unread message count
        setTimeout(() => {
          const chatAccessToken = localStorage.getItem("chatAccessToken");
          if (chatAccessToken != "" && chatAccessToken != null) {
            dispatch(connectToSocket());
            var chatUserId = localStorage.getItem("chatUserId");
            var projectId = localStorage.getItem("selectedOffice");
            dispatch(fetchUnreadMsgCount(chatUserId, projectId));
          }
        }, 1000);
      }, 1500);
    }
    return data
  });
};

export const autoLogin = (key) => (dispatch) => {
  return AuthService.autoLogin(key).then((data) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { data },
    });
    if (version == 'v2') {
      localStorage.setItem('version', version)
    } else {
      localStorage.setItem('version', version)
    }
    setTimeout(() => {
      // Get chat auth token
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken != "" && accessToken != null) {
        const email = localStorage.getItem("email");
        const contact_id = localStorage.getItem("contact_id");
        dispatch(fetchAuthToken(email, contact_id, "client"));
        dispatch(getTeams());
      }
      // Connet to socket and fetch chat unread message count
      setTimeout(() => {
        const chatAccessToken = localStorage.getItem("chatAccessToken");
        if (chatAccessToken != "" && chatAccessToken != null) {
          dispatch(connectToSocket());
          var chatUserId = localStorage.getItem("chatUserId");
          var projectId = localStorage.getItem("selectedOffice");
          dispatch(fetchUnreadMsgCount(chatUserId, projectId));
        }
      }, 1000);
    }, 1500);
  });
};


export const userAutoLogin = (staff_id) => (dispatch) => {
  return AuthService.userAutoLogin(staff_id).then((data) => {
    // console.log(data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { data },
    });

    if (version == 'v2') {
      localStorage.setItem('version', version)
    } else {
      localStorage.setItem('version', version)
    }

    setTimeout(() => {
      // Get chat auth token
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken != "" && accessToken != null) {
        const email = localStorage.getItem("email");
        const contact_id = localStorage.getItem("contact_id");
        dispatch(fetchAuthToken(email, contact_id, "client"));
        dispatch(getTeams());
      }
      // Connet to socket and fetch chat unread message count
      setTimeout(() => {
        const chatAccessToken = localStorage.getItem("chatAccessToken");
        if (chatAccessToken != "" && chatAccessToken != null) {
          dispatch(connectToSocket());
          var chatUserId = localStorage.getItem("chatUserId");
          var projectId = localStorage.getItem("selectedOffice");
          dispatch(fetchUnreadMsgCount(chatUserId, projectId));
        }
      }, 1000);
    }, 1500);
  });
};


export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const globalUserId = (id) => (dispatch) => {
  dispatch({
    type: GET_USER_ID_GLOBAL,
    payload:id
  });
};
